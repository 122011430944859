import { Button, Loader } from "@/components/ui";
import { useState, useEffect } from "react";
import { makeRequest } from "@/services/makeRequest";
import showToast from "../../toast/Toast";

import { viewList } from "../../pages/accounts/model/profiles/card/components/upAd/viewList";
import useLadyService from "../../../services/LadyService";
import Popup from "../Popup";

const RemoveTopAdPopup = (props) => {
  const {
    open,
    setOpen,
    position,
    slug,
    setPosition,
    setPrice,
    city_id,
    dynamicTop,
    setData: setProfilesData,
  } = props;

  const { t } = useLadyService();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPlace, setIsLoadingPlace] = useState(false);

  const [data, setData] = useState(null);
  const [newPos, setNewPos] = useState(null);

  const calculatePercentageValue = ({ currentPosition, position }) => {
    const mt = viewList[currentPosition - 1]["view"];

    const mp = viewList[position - 1]["view"];

    const res = Math.floor(((mp - mt) / mt) * 100);

    return res;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const route = `places/down/${slug}`;

        const method = "DELETE";

        const result = await makeRequest({ route, method });

        if (result?.message === "down" && dynamicTop) {
          const route = `user/profiles`;
          const method = "GET";

          const payload = {
            city: city_id,
            limit: 1000,
          };

          const { profiles } = await makeRequest({ route, method, payload });

          setProfilesData((prevData) => {
            const updatedData = [...prevData];

            profiles.forEach((profile) => {
              const index = updatedData.findIndex(
                (item) => item.slug === profile.slug
              );

              if (index !== -1) {
                updatedData[index] = profile;
              }
            });

            return updatedData;
          });

          setIsLoading(false);

          showToast({
            message: t("success"),
            variant: "success",
          });

          setOpen(false);
        } else if (result?.message === "down" && !dynamicTop) {
          setPosition(Number(data));
          setPrice(null);
          setIsLoading(false);
          showToast({
            message: t("success"),
            variant: "success",
          });
          setOpen(false);
        }
      } catch (error) {}
    };

    fetchData();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingPlace(true);
        const route = `places/off/${slug}`;

        const method = "GET";

        const { position_off } = await makeRequest({ route, method });

        if (position_off) {
          setNewPos(
            calculatePercentageValue({
              position: position_off,
              currentPosition: position,
            })
          );
          setData(position_off);

          setIsLoadingPlace(false);
        }
      } catch (error) {}
    };

    open && fetchData();
  }, [position, slug]);

  return (
    <Popup
      open={open}
      setOpen={setOpen}
      isLoading={isLoading}
      container={'div'}
      clazz={'gap-16'}
    >
      {isLoadingPlace ? (
        <Loader />
      ) : (
          <>
            <h2>{t("removetop")}?</h2>

            <span className="p1 color-main text-center">{t("yourtop")}</span>
            <span className="color-green title_h2 text-center">
            {position}
              {t("th")} {t("place").toLowerCase()}
          </span>
            <span className={"color-main text-center p2"}>
            {t("removetopanswer")}
          </span>

            <span className="ver__block__money m-auto event-none">
            {data}
              {t("th")} {t("place").toLowerCase()}
          </span>

            <span className="p1 color-green text-center mt-4">
            {newPos}% {t("toviews")} {t("around")}
          </span>

            <div className="fd-column gap-12">
              <Button
                size={"l"}
                clazz={"button--green justify-center"}
                onClick={() => setOpen(false)}
              >
                {t("keep")} {t("intop")}
              </Button>
              <Button
                size={"l"}
                clazz={"button--secondary justify-center"}
                onClick={onSubmit}
              >
                {t("deactive")}
              </Button>
            </div>
          </>
      )}
    </Popup>
  );
};

export default RemoveTopAdPopup;
