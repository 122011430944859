import { useEffect, useState } from "react";
import { Icon } from "@/components/ui";
import { useTranslation } from "react-i18next";

const Tubs = (props) => {
  const {
    notLetToggle,
    scrollToElem,
    setVisualContent,
    visualContent,
    onChange,
    noLink = false,
  } = props;
  const { t } = useTranslation("translation");
  const tabsBtns = [
    {
      title: t("photo"),
      icon: "image",
      link: "photo",
    },

    {
      title: t("videofotograph"),
      icon: "film",
      link: "video",
    },

    {
      title: t("interior"),
      icon: "home-main",
      link: "interior",
    },

    {
      title: t("erocontent"),
      hideTitle: "",
      icon: "pants",
      ero: true,
      link: "ero",
    },
  ];
  const [currentIndex, setCurrentIndex] = useState("photo");

  const handleTub = (link) => {
    if (!notLetToggle) {
      !!onChange && onChange();
      setCurrentIndex(link);
      setVisualContent(link);
    } else {
      !!scrollToElem && scrollToElem();
    }
  };

  useEffect(() => {
    setCurrentIndex(visualContent);
  }, [visualContent]);

  return tabsBtns.map((btn) => {
    return noLink ? (
      <div
        key={'tabs__nav-btn' + btn.title}
        onClick={() => handleTub(btn.link)}
        className={`tabs__nav-btn${
          currentIndex === btn.link ? " tabs__nav-btn--active" : ""
        }${btn.ero ? " tabs__nav-btn--erocontent" : ""}`}
      >
        <Icon size={"s"} spritePath={btn.icon} />

        {btn.title}
        {btn.hideTitle ? <span>{btn.hideTitle}</span> : null}
      </div>
    ) : (
      <a
        onClick={() => handleTub(btn.link)}
        href={`#${btn.link}`}
        className={`tabs__nav-btn${
          currentIndex === btn.link ? " tabs__nav-btn--active" : ""
        }${btn.ero ? " tabs__nav-btn--erocontent" : ""}`}
      >
        <Icon size={"s"} spritePath={btn.icon} />

        {btn.title}
        {btn.hideTitle ? <span>{btn.hideTitle}</span> : null}
      </a>
    );
  });
};
export default Tubs;
