import {
  BurgerMenu,
  DropdownLanguage,
  Logo,
  MenuNavBar,
  SelectCity,
  UserWrapperDesktop
} from "../components";
import Filters from "../components/Filters";
import ShortFilter from "@/components/shortFilter/ShortFilter";
import React from "react";
import useLadyService from "@/services/LadyService";
import { Burger, Toggle } from "@/components/ui";
import { useSelector } from "react-redux";
import { getScrolledClass } from "@/stores/slices/headerSlice";

const ModerOrAdmin = () => {
  const { windowWidth } = useLadyService();

  const scrolledClass = useSelector(getScrolledClass);

  const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');


  if (windowWidth > 1199.98) {
    return (
      <>
        <div className="header__top">
          <DropdownLanguage />

          <Toggle type={"theme"} id={'theme-main-toggle'} />
        </div>

        <div className={isJsdom ? "header__bottom ssr" : 'header__bottom'}>
          <Logo scrolledClass={scrolledClass} />

          <SelectCity />

          <Filters />

          <UserWrapperDesktop />
        </div>
      </>
    );
  }

  if (windowWidth > 767.98) {
    return (
      <>
        <div className="header__top">
          <Logo scrolledClass={scrolledClass} />

          <SelectCity />

          <Filters />

          <div className="user__wrapper">
            <DropdownLanguage size={"s"} />

            <Burger />

            <Toggle type={"theme"} id={'theme-main-toggle'} />

            <BurgerMenu />
          </div>

          <ShortFilter />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="header__top">
        <div className="user__wrapper">
          <Toggle type={"theme"} id={'theme-main-toggle'} />
        </div>

        <Logo scrolledClass={scrolledClass} />

        <div className="user__wrapper">
          <DropdownLanguage />
          <Burger />
        </div>

        <BurgerMenu />

        <MenuNavBar />

        <ShortFilter />
      </div>
    </>
  );
};

export default ModerOrAdmin