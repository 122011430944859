import useLadyService from "@/services/LadyService";
import {Button, Icon, InputInLabel, SelectCountry, Select, ErrorMessage, Skeleton, Loader} from "@/components/ui";
import {yupResolver} from "@hookform/resolvers/yup";
import {useFieldArray, useForm} from "react-hook-form";
import {object, string, number} from "yup";
import {LangAi, Map} from "@/components/pages/accounts/createProfile/pageComponent";
import {MediaZone} from "@/components/pages/accounts/agency/mediaZone/MediaZone.js";
import GeoInput from "@/components/ui/input/GeoInput";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {convertData} from "@/helper/convertData";
import {useDispatch, useSelector} from "react-redux";
import {getAddressStatus} from "@/stores/slices/userProfileSlice";
import useHandleLocationClick from "@/services/HandleLocationClick";
import {useNavigate, useParams} from "react-router-dom";
import {makeRequest} from "@/services/makeRequest";

import {getCurrency, setCurrency} from "@/stores/slices/userProfileSlice";
import showToast from "../../toast/Toast";
import {format} from "date-fns";

import '@/components/pages/partyPage/partyPage.scss'
import map from "lodash.map";
import axios from "axios";
import baseUrl from "@/services/apiConfig";
import useMediaService from "@/services/mediaService";
import ProfilesPage from "@/components/pages/accounts/model/profiles/ProfilesPage";


const ChangePartyPage = () => {
    const dispatch = useDispatch();
    const {removeMedia} = useMediaService()
    const navigate = useNavigate()
    const {
        t,
        lang,
        token
    } = useLadyService();

    const {partyid: partyId} = useParams();
    const userAddress = useSelector(getAddressStatus)
    const currency = useSelector(getCurrency)
    const [cityBounds, setCityBounds] = useState(null)
    const [selectedProfiles, setSelectedProfiles] = useState(false);
    const [cityError, setCityError] = useState("");
    const [currentActive, setCurrentActive] = useState("");
    const [isAddressActive, setIsAddressActive] = useState(true);
    const [bounds, setBounds] = useState("");
    const [currentDescription, setCurrentDescription] = useState("");
    const [langAi, setLangAi] = useState(lang);
    const [currentDescriptionLenght, setCurrentDescriptionLength] = useState(0)
    const [cover, setCover] = useState([]);
    const [interior, setInterior] = useState([]);
    const [currentAddress, setCurrentAddress] = useState(userAddress?.length > 0 ? {...userAddress} : {})
    const [currentCity, setCurrentCity] = useState({
        id: "",
        title: "",
        icon: "",
    });
    const [cities, setCities] = useState([]);
    const citiesOptions = useMemo(() => convertData(cities), [cities]);
    const [partyData, setPartyData] = useState(false);
    const [activeCountry, setActiveCountry] = useState("cz");
    const [selectedLangsAi, setSelectedLangsAi] = useState(() => {
        if (!partyData) {
            return [];
        }
        return map(partyData.description, (value, key) => {
            if (!value?.description && key !== langAi) {
                return key;
            }
            return null;
        });
    });
    const [selectedCity, setSelectedCity] = useState(false)

    const currencyOption = {
        main_title: t("currency"),
        options: [
            {id: 1, title: "EUR", value: "eur"},
            {id: 2, title: "CZK", value: "czk"},
        ],
    };

    const changeCity = (city) => {
        let userAddress = currentAddress;
        userAddress = {
            ...userAddress,
            city: city.name,
            city_id: city.id,
            address: "",
            latitude: "",
            longitude: "",
        };
        setCurrentAddress(userAddress);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const route = `services/all`;
                const method = "GET";
                const payload = {lang};
                const {cities} = await makeRequest({route, method, payload});
                setCities(cities);
            } catch (error) {
            }
        };

        lang && fetchData();
    }, [lang]);

    useEffect(() => {
        if (partyData) {
            if (partyData.city_id) {
                setSelectedCity(
                    citiesOptions[0].cz.cities.filter(
                        (item) => item.id === Number(partyData.city_id)
                    )[0]?.name || ""
                );
            }

            setCover(partyData.cover);
            setInterior(partyData.interior);

            const coordinates = partyData.coordinates?.split(',').map((item) => Number(item));
            if (coordinates && coordinates.length === 2) {
                setCurrentAddress({
                    address: partyData.address,
                    latitude: coordinates[0],
                    longitude: coordinates[1]
                });
            } else if (partyData.address?.length) {
                setCurrentAddress({
                    address: partyData.address,
                    latitude: "",
                    longitude: "",
                });
            }

            setCurrentDescription(partyData.description);


            if (partyData.name) setValue('name', partyData.name);
            if (partyData.email) setValue('email', partyData.email);
            if (partyData.telegram) setValue('telegram', partyData.telegram);
            if (partyData.time) setValue('time', partyData.time);
            if (partyData.include?.length) setValue('include', partyData.include);

            setSelectedProfiles(partyData.profiles);
        }
    }, [citiesOptions, partyData]);

    useEffect(() => {
        if (selectedCity.length) {
            setCurrentCity(citiesOptions[0].cz.cities.filter(
                (item) => item.name.toLowerCase() === selectedCity.toLowerCase()
            )[0])
        }
    }, [selectedCity, citiesOptions])
    const [geoError, setGeoError] = useState(false);
    const throwError = (e) => {
        if (
            !e.target.classList.contains("icon--location") &&
            !e.target.classList.contains("button--tetriary")
        ) {
            if (!selectedCity) {
                setGeoError(true);

                setTimeout(() => {
                    setGeoError(false);
                }, 2000);
            }
        }
    };


    const partyFeaturesBlock = () => {
      return (
        <div className="party-features-list mt-16">
          {fields.map((field, index) => (
            <div className="gap-8 mb-32" key={field.id}>
              <div className="party-feature-name gap-8 mb-8">
                <fieldset>
                    <legend>{t("name")}</legend>
                    <InputInLabel
                      type="text"
                      id={`include-name-${index}`}
                      placeholder=""
                      register={{...register(`include.${index}.name`)}}
                    >
                    </InputInLabel>
                </fieldset>
                <Button
                  clazz="tarifs-row-trash mt-10"
                  onClick={() => remove(index)}
                >
                  <Icon spritePath="trash" />
                </Button>
              </div>
              <InputInLabel
                clazz="mb-0"
                type="textarea"
                id={`include-desc-${index}`}
                placeholder=""
                register={ {...register(`include.${index}.description`)}}
              >
                {t("descandpef")}
              </InputInLabel>
            </div>
          ))}
        </div>
      );
    };


    const handleCancelChangeParty = () => {
        navigate(`/${lang}/lk/parties`)
    }


    const handleAddFeature = () => {
        append({ name: '', description: '' });
    };

    const deleteFeature = (index) => {
        remove(index);
    };


    const uploadMedia = (file, type) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("file_name", file.name);
        formData.append("type", type);
        return axios.post(baseUrl + `agency/parties/${partyId}/media`, formData, {
            headers: {
                Authorization: token ? `Bearer ${token}` : "",
                "Content-Type": "multipart/form-data",
            },
        });
    };

    useEffect(() => {
        if (cover !== undefined && cover.length && typeof cover[0] === "object") {
            uploadMedia(cover[0], "cover")
                .then((response) => {
                    setCover([response.data.link]);
                })
                .catch((error) => {
                    console.error("error: ", error);
                });
        }
    }, [cover, uploadMedia]);


    const uploadedItemsRef = useRef(new Set());


    useEffect(() => {
        const uploadFiles = async () => {
            for (let index = 0; index < interior.length; index++) {
                const item = interior[index];
                if (typeof item === "object" && !uploadedItemsRef.current.has(item)) {
                    try {
                        const response = await uploadMedia(item, "interior");

                        setInterior((prevInterior) => {
                            const updatedInterior = [...prevInterior];
                            updatedInterior[index] = response.data.link;
                            return updatedInterior;
                        });

                        uploadedItemsRef.current.add(item); 
                    } catch (error) {
                        console.error("error:", error);
                    }
                }
            }
        };

        uploadFiles();
    }, [interior, uploadMedia]);
    useEffect(() => {
        setCurrentDescriptionLength(currentDescription?.[langAi]?.description?.length ?? 0)
    }, [setCurrentDescriptionLength, currentDescription, langAi]);

    useEffect(() => {
        const fetchPartyData = async () => {
            try {
                const route = `agency/parties/${partyId}`;
                const method = "GET";

                const data = await makeRequest({route, method});

                if (!data) {
                    navigate("/404");
                }
                if (data) {
                    const result = data.data;
                    setPartyData(result);
                }
            } catch (error) {
                showToast({message: t('oops'), variant: 'error'})
            }
        }

        citiesOptions.length && fetchPartyData();
    }, [partyId, navigate, t, citiesOptions]);


    const formSchema = object().shape({
        phone: string().min(10, `${t("phonenumerrequired")}`),
        address: string().transform((value) => (value === "" ? undefined : value)),
        name: string().required(t("required")),
        date: string().required(t("required")),
        email: string().email().transform((value) => (value === "" ? undefined : value)),
        whatsapp: string().min(10, `${t("phonenumerrequired")}`),
        telegram: string().transform((value) => (value === "" ? undefined : value)),
    });


    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: {errors},
        clearErrors,
        control,
    } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(formSchema),
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "include"
    });

    const onSubmit = async (data) => {

        try {
            const payload = {
                ...data,
                city_id: currentCity.id,
                address: currentAddress.address,
                coordinates: `${currentAddress.latitude},${currentAddress.longitude}`,
                description_translate_to: {...selectedLangsAi},
                profiles: {...selectedProfiles},
            }
            Object.keys(currentDescription).map((keyLang) => {
                payload[keyLang] = {}
                payload[keyLang].description = currentDescription[keyLang].description;
            })
            const route = `agency/parties/${partyId}`;
            const method = "PUT";
            makeRequest({route, method, payload}).then(() => {
                showToast({message: t('success'), variant:'success'});
            }).catch((error) => {
                throw ErrorMessage(error.message);
            })

        } catch (error) {
            showToast({message: t('oops'), variant: 'error'})
        }
    }

    const onChangeDate = (value) => {
        clearErrors("date");
        setValue(
            'date',
            !value ? format(new Date(), "dd.MM.yyyy") : format(value, "dd.MM.yyyy")
        );
        clearErrors("date");
    };

    const setAddress = async (newAddress) => {
        if (newAddress === null) {
            let userAddress = {...currentAddress}
            setCurrentAddress({
                ...userAddress,
                address: null,
                latitude: null,
                longitude: null,
            });
        } else if (typeof newAddress === "string") {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({address: newAddress}, (results, status) => {
                if (status === "OK" && results[0]) {
                    newAddress = results[0];
                    let userAddress = {...currentAddress};
                    setCurrentAddress({
                        ...userAddress,
                        address: newAddress.formatted_address,
                        latitude: newAddress.geometry.location.lat(),
                        longitude: newAddress.geometry.location.lng(),
                    });
                }
            });
        } else {
            let userAddress = {...currentAddress};
            setCurrentAddress({
                ...userAddress,
                address: newAddress.formatted_address,
                latitude: newAddress.geometry.location.lat(),
                longitude: newAddress.geometry.location.lng(),
            });
        }
    };

    const {handleLocationClick} = useHandleLocationClick()
    return !partyData ? (<Loader height={100}/>) : (
        <main>
            <section className={"container"}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className={"d-flex justify-sb"}>
                        <h1 className="justify-start w-100">{t('createeditparty')}</h1>

                        <Button
                            clazz="button button--secondary justify-center w-160 mt-20 mb-20"
                            onClick={handleCancelChangeParty}>
                            {t('cancel')}
                        </Button>
                    </div>

                    <div className="change-party__body">
                        <div className="left-side">
                            <div className="fd-column gap-16">
                                <div className="first-row">

                                    {/* Party name */}
                                    <fieldset>
                                        <legend>{t("name")}</legend>
                                        <InputInLabel
                                            type="text"
                                            id="name_id"
                                            placeholder={t("name")}
                                            register={{...register("name", {required: true})}}
                                            required
                                            clazz="w-100"
                                        >
                                        </InputInLabel>
                                    </fieldset>

                                    <div className="d-grid grid-tp-col-2 gap-16 w-100">
                                        {/* Party date */}
                                        <fieldset>
                                            <legend>{errors.date ? (
                                                    <span className={"input-label__error"}>{t("date")}</span>
                                                ) : (
                                                    t("date")
                                                )}</legend>
                                            <InputInLabel
                                                clazz={`${errors.date ? " error" : ""}`}
                                                type={"date"}
                                                id={"orderDate"}
                                                register={{...register("date")}}
                                                required
                                                onChange={onChangeDate}
                                                value={partyData.date}
                                            >
                                            </InputInLabel>
                                        </fieldset>

                                        {/* Party Time */}
                                        <fieldset>
                                            <legend>{errors.time ?
                                                    (
                                                        <span className={"input-label__error"}>{t("time")}</span>
                                                    ) : (
                                                        t("time")
                                                    )}</legend>
                                            <InputInLabel
                                                clazz={`${errors.time ? " error" : ""}`}
                                                register={{...register("time")}}
                                                id={"orderTime"}
                                                placeholder={"18:00"}
                                                type={"time"}
                                                onChange={(e) => setValue('time', e.target.value)}
                                            >
                                                
                                            </InputInLabel>
                                        </fieldset>
                                    </div>
                                </div>

                                {/* Address  */}

                                <div className="search filter__map-input">
                                    <div className="d-grid selectors mt-10">
                                        <SelectCountry
                                            data={citiesOptions}
                                            name={"city-create"}
                                            clazzWrapper={"select"}
                                            clazz={`size-l required ${cityError ? "_error" : ""} grid-col-1 border-default`}
                                            clazzSvg={`select__flag ${currentCity.icon}`}
                                            arrowSize={"m"}
                                            type={"flag"}
                                            placeholder={t("yourcity")}
                                            defaultTitle={selectedCity || currentCity?.name}
                                            withoutIcon={!selectedCity}
                                            setValue={(city) => {
                                                setSelectedCity(city);
                                                setAddress(null);
                                            }}
                                            currentActiveIndex={currentActive}
                                            setActiveCountry={setActiveCountry}
                                            activeCountry={activeCountry}
                                            setActive={setCurrentActive}
                                            error={cityError}
                                            setError={setCityError}
                                            setIsAddressActive={setIsAddressActive}
                                            errorMessage={<ErrorMessage message={cityError}/>}

                                        />

                                        {/* <Button
                                            title={t("chekmyplace")}
                                            onClick={() => {
                                                handleLocationClick(currentCity.title)
                                            }}
                                            name={"location"}
                                            square={true}
                                            size={"xl"}
                                            clazz={`tablet-hidden ${isAddressActive ? "button--tetriary bg-color-500" : "button--tetriary"}`}
                                        >
                                            <Icon size={"xl"} spritePath={"location"}/>
                                        </Button> */}
                                    </div>

                                    <fieldset >
                                        <legend>{geoError ? <ErrorMessage message={t("cityfirst")}/> : t("youraddress")}</legend>
                            
                                        <GeoInput
                                            value={currentAddress.address}
                                            name={"location-create"}
                                            id="geosuggest__input-create"
                                            clazz={`header__search-input ${geoError ? "_error" : ""}`}
                                            placeholder={t("inuptadress", {City: selectedCity})}
                                            bounds={bounds}
                                            city={selectedCity}
                                            getAddress={setCurrentAddress}
                                            setValue={(value, address) => {
                                                if (typeof address === Object) {
                                                    setAddress(address?.address)
                                                } else {
                                                    setAddress(address)
                                                }
                                            }}
                                        />
                                    </fieldset>
                       
                                    <Button
                                        title={t("chekmyplace")}
                                        onClick={() => {
                                            handleLocationClick(currentCity.title).then((address) => {
                                                setAddress(`${address.city} ${address.road}, ${address.house_number}`);
                                            })
                                        }}
                                        name={"location"}
                                        square={true}
                                        size={"xl"}
                                        clazz={`${isAddressActive ? "button--tetriary bg-color-500 mt-10" : "button--tetriary mt-10"}`}
                                    >
                                        <Icon size={"xl"} spritePath={"location"}/>
                                    </Button>
                                </div>

                                
                                {selectedCity && (
                                    <Map
                                        defaultAddress={currentAddress.address}
                                        coords={{lat: currentAddress.latitude, lng: currentAddress.longitude}}
                                        setMarkerActive={setIsAddressActive}
                                        markerActive={isAddressActive}
                                        region={activeCountry}
                                        city={selectedCity}
                                        setCityBounds={setCityBounds}
                                        cityBounds={cityBounds}
                                        setBounds={setBounds}
                                        setAddress={setAddress}
                                    />
                                )}

                                <div className="contacts gap-16">

                                    {/* Phone */}

                                    <fieldset>
                                        <legend>{t('yourphone')}</legend>
                                        <InputInLabel
                                            clazz={`${errors.phone ? " error" : ""}`}
                                            id={"orderPhone"}
                                            type={"tel"}
                                            placeholder={t("yourphone")}
                                            register={{...register("phone")}}
                                            autocomplete={"tel"}
                                            maskValue={partyData.phone}
                                            setMaskValue={(value) => {
                                                if (value.length > 2) {
                                                    setValue('phone', parseInt(value.replace(/\D/g, '')))
                                                }
                                            }}
                                        >
                                        </InputInLabel>
                                    </fieldset>
                                    {(errors.phone && (
                                        <span className={"input-label__error"}>{errors.phone.message}</span>))}

                                    {/* Email */}

                                    <fieldset>
                                        <legend>Email</legend>
                                        <InputInLabel
                                            type={"email"}
                                            id={"email"}
                                            placeholder={"Email"}
                                            register={{...register("email")}}
                                        >
                                        </InputInLabel>
                                    </fieldset>

                                    {/* Whatsup */}

                                    <fieldset>
                                        <legend>
                                        {errors.whatsapp ? <ErrorMessage message={errors.whatsapp.message}/> : <span>Whatsapp</span>}</legend>
                                        <InputInLabel
                                            type={"tel"}
                                            id={"create-whatsapp"}
                                            placeholder={"whatsapp"}
                                            register={{...register("whatsapp")}}
                                            autocomplete={"whatsapp"}
                                            value={partyData.whatsapp}
                                            setMaskValue={(value) => {
                                                if (value.length > 2) {
                                                    setValue('whatsapp', parseInt(value.replace(/\D/g, '')))
                                                }
                                            }}
                                            errorType={"whatsapp"}
                                            clearErrors={clearErrors}
                                        >
                                            
                                        </InputInLabel>
                                    </fieldset>

                                    {/* telegramm */}

                                    <fieldset>
                                        <legend>{t("telegram")}</legend>
                                        <InputInLabel
                                            type={"text"}
                                            id={"create-telegram"}
                                            placeholder={""}
                                            register={{...register("telegram")}}
                                            autocomplete={"telegram"}
                                        >   
                                        </InputInLabel>
                                    </fieldset>
                                   
                                </div>

                                {/* Cover */}

                                <MediaZone
                                    h3={t("agenсycover")}
                                    title={t("addaagenсycover")}
                                    subtitle={t("prefersize") + " 1440х385 px"}
                                    setMedia={setCover}
                                    removeMedia={(media) => {
                                        removeMedia(media, () => {
                                            setCover([]);
                                        });
                                    }}
                                    media={cover}
                                    isBigPhoto
                                />

                                {/* Interior */}

                                <div className="interior-photo-container">
                                    <MediaZone
                                        h3={t("interiorphoto")}
                                        title={t("interdesc")}
                                        subtitle={t("showyourinter")}
                                        setMedia={setInterior}
                                        removeMedia={(media) => {
                                            removeMedia(media, () => {
                                                let newInterior = [...interior];
                                                newInterior = newInterior.filter((item) => item !== media);
                                                setInterior(newInterior);
                                            });
                                        }}
                                        media={interior}
                                        isBigPhoto
                                        interior={true}
                                        isMulti={true}
                                    />
                                </div>

                                {/* Party Description */}

                                <h3>{t("descandpef")}</h3>

                                <InputInLabel
                                    clazz="gap-16"
                                    type="textarea"
                                    id="partyDesc-lang"
                                    placeholder={t('')}
                                    value={currentDescription?.[langAi]?.description ?? ''}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 400) {
                                            const newDescription = {...currentDescription};
                                            if (!newDescription[langAi]) {
                                                newDescription[langAi] = {};
                                            }
                                            newDescription[langAi].description = e.target.value;
                                            setCurrentDescription(newDescription);
                                        }
                                    }}
                                >
                                    {t("symbols")}: {currentDescriptionLenght}/400
                                    {currentDescriptionLenght === 400 && (
                                        <ErrorMessage
                                            message={`${t("maxval")} 400 ${t("symbols")}`}
                                        />
                                    )}
                                </InputInLabel>

                                <div className="d-flex gap-16 align-center mt-16">
                                    <LangAi
                                        langAi={langAi}
                                        setLangAi={setLangAi}
                                        selectedLangsAi={selectedLangsAi}
                                        setSelectedLangsAi={setSelectedLangsAi}
                                    />
                                </div>


                                {<div className="tablet-hidden">
                                    {partyFeaturesBlock()}
                                </div>}

                            </div>
                        </div>
                        <div className="right-side">
                            <div>
                                <h3 className="mt-0">{t("priceandcond")}</h3>
                                <div className="prices-block gap-8">
                                    <fieldset>
                                        <legend>{t("price")}</legend>
                                        <InputInLabel
                                            type={"number"}
                                            id={`participate-price`}
                                            placeholder={0}
                                            value={partyData.price}
                                            onChange={(e) => {
                                                setValue("price", e.target.value)
                                            }}
                                            max={99999}
                                            currency={currency}
                                            handleCurrency={true}
                                            price={currency}
                                            multiplierStatus={true}
                                        >
                                        </InputInLabel>
                                    </fieldset>
                                    <fieldset>
                                        <legend>{t("prepayment")}</legend>
                                        <InputInLabel
                                            type={"number"}
                                            id={`prepayment-price`}
                                            placeholder={0}
                                            value={partyData.prepayment}
                                            onChange={(e) => {
                                                setValue("prepayment", e.target.value)
                                            }}
                                            max={99999}
                                            currency={currency}
                                            handleCurrency={true}
                                            price={currency}
                                            multiplierStatus={true}
                                        >
                                        </InputInLabel>
                                    </fieldset>
                                    <fieldset>
                                        <legend>{t("currency")}</legend>
                                        <Select
                                            onChange={(value) => {
                                                dispatch(setCurrency(value))
                                            }}
                                            options={currencyOption.options}
                                            clazz={"button button-xs justify-start mt-10"}
                                            arrowSize={"s"}
                                            name={"currency"}
                                            defaultTitle={
                                                currencyOption.options.filter(
                                                    (item) => item.value === currency
                                                )[0]?.title || "e"
                                            }
                                            withoutTitle={true}
                                            postApi={true}
                                        />
                                    </fieldset>
                                </div>
                                <h3>{t('includedinprice')}?</h3>
                                {partyFeaturesBlock()}
                                <Button
                                    size="s"
                                    clazz="button_outline--green-accent justify-center ml-auto w-160"
                                    onClick={handleAddFeature}
                                >
                                    {t("addposition")}
                                </Button>
                            </div>
                        </div>
                    </div>
                    {selectedProfiles && (<ProfilesPage asComponent={true} selectCheckboxHandler={(selected) => {
                        setSelectedProfiles(selected);
                    }} selectedCheckboxes={selectedProfiles}/>)}
                    <div className=" d-flex fd-row w-100 gap-16 bottom-buttons">
                        <Button
                            size="l"
                            clazz="button button--secondary justify-center w-160"
                            onClick={handleCancelChangeParty}>
                            {t('cancel')}
                        </Button>
                        <Button
                            size="l"
                            clazz="button  button--green justify-center w-160"
                            buttonType={"submit"}
                            disabled={!currentCity.id}
                        >
                            {t('save')}
                        </Button>
                    </div>
                </form>
            </section>
        </main>
    )
}

export default ChangePartyPage;