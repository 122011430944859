import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setLang } from "@/stores/slices/userSlice";
import i18next from "i18next";

const supportedLanguages = ["en", "ru", "cz"];

const useLanguageRedirect = () => {
  const { pathname, hash } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const pathSegments = pathname.split("/");
    const langSegment = pathSegments[1];
    const citySegment = pathSegments[2];
    if(!langSegment && !citySegment) {
      return
    }
    if (langSegment && !supportedLanguages.includes(langSegment)) {
      const userLanguage = navigator.language || navigator.userLanguage;
      let updateLang = localStorage.getItem("i18nextLng") || "";
      if (updateLang) {
        
        const newPathname = `/${updateLang}${pathname}${hash}`;
        window.history.replaceState({}, "", newPathname);
        dispatch(setLang(updateLang));

        
        i18next.changeLanguage(updateLang);

        return
      } else {
        switch (userLanguage.slice(0, 2)) {
          case "en":
            updateLang = "en";
            break;
          case "ru":
            updateLang = "ru";
            break;
          case "cs":
            updateLang = "cz";
            break;
          default:
            updateLang = "en";
        }
      }
      const newPathname = `/${updateLang}${pathname}`;
      window.history.replaceState({}, "", newPathname);
      dispatch(setLang(updateLang));
      i18next.changeLanguage(updateLang);
    }
  }, [pathname]);
};

export default useLanguageRedirect;
