import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useSelector} from "react-redux";
import {getSeoDescription, getSeoTitle} from "@/stores/slices/titleSlice";

function useTitle(title, set = true) {
    const location = useLocation();
    const seoTitle = useSelector(getSeoTitle);
    useEffect(() => {
        if (set) {
            if (seoTitle) {
                document.title = seoTitle;
            } else {
                document.title = title;
            }
        }
    }, [location, title, set, seoTitle]);
}

export default useTitle;
