import { Button } from "@/components/ui";
import { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";
import { Rating, Star } from "@smastrom/react-rating";
import { Controller, useForm } from "react-hook-form";
import { format, fromUnixTime, sub } from "date-fns";
import useLadyService from "@/services/LadyService";
import { useMakeRequest } from "@/hooks/useMakeRequest";
import { setPreviewsAmount } from "@/stores/slices/userSlice";
import { RatingStars } from "@/components/ui";

const statusLabels = {
    0: "workstatus",
    1: "approvestatus",
    2: "declinestatus",
    3: "completestatus",
};

const Variant = ({ status, id, book_at, setData, reviews }) => {
    const { t, dispatch } = useLadyService();
    const approveDate = format(fromUnixTime(book_at - 3600), "dd.MM.yyyy HH:mm");
    const [dateForDeclined, setDateForDeclined] = useState(approveDate);
    const [isDate, setIsDate] = useState(new Date((book_at - 3600) * 1000) > new Date());
    const [isCurrent, setIsCurrent] = useState(new Date() > new Date(book_at * 1000) && status === 1);
    const { control, getValues } = useForm({ mode: "onChange" });

    useEffect(() => {
        if (!isDate && status === 1) {
            const interval = setInterval(() => {
                setIsDate(new Date((book_at - 3600) * 1000) > new Date());
            }, 500);
            return () => clearInterval(interval);
        }
    }, [book_at, isDate, status]);

    useEffect(() => {
        if (!isCurrent && status === 1) {
            const interval = setInterval(() => {
                if (new Date() > new Date(book_at * 1000)) {
                    setIsCurrent(true);
                }
            }, 500);
            return () => clearInterval(interval);
        }
    }, [book_at, isCurrent, status]);


    const getLastReviews = (reviews) => {
        const lastReviews = { customer: null, indi: null };

        reviews?.data?.forEach((review) => {
            const userType = review.from.user_type;
            if (!lastReviews[userType] || review.created_at > lastReviews[userType]?.created_at) {
                lastReviews[userType] = review;
            }
        });
        return lastReviews;
    };

    const { indi: lastIndiReview } = getLastReviews(reviews);
    const [isIndi, setIsIndi] = useState(!!lastIndiReview);
    const [reviewScore, setIndiRating] = useState(reviews.data[0]?.score || 0);
    const makeRequest = useMakeRequest();
    const handleStatusUpdate = async (action, id) => {
        try {
            const route = action === "confirm" ? `previews/${id}/confirm` : `previews/${id}/cancel`;
            makeRequest({ route, method: "PUT" }).then(() => {
                if (action === "confirm") {
                    setData((prevData) =>
                        prevData.map((item) => (item.id === id ? { ...item, status: 1 } : item))
                    );
                } else {
                    setData((prevData) =>
                        prevData.map((item) => (item.id === id ? { ...item, status: 2 } : item))
                    );
                }
                dispatch(setPreviewsAmount((prev) => Math.max(prev - 1, 0)));
            })
        } catch (error) {
            console.error('error: ', error);
        }
    };

    const handleSubmitReview = async (data) => {
        setIndiRating(data.score)
        try {
            const payload = {
                score: data.score || 0,
                body: null,
                reviewable_type: "preview",
                reviewable_id: id,
            };
            const { message, review_id } = await makeRequest({
                route: "reviews",
                method: "PUT",
                payload,
            });
            if (message === "review_created" && review_id) {
                setIsIndi(true);
                setIndiRating(data.score);
            } else {
                throw new Error("Review submission failed");
            }
        } catch (error) {
            console.error('error: ', error);
        }
    };

    const RatingComponent = ({ name, onChange, defaultValue }) => (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue ?? 0}
            rules={{ validate: (value) => value > 0 }}
            render={({ field: { onChange: fieldChange, value } }) => (
                <Rating
                    className="rating-stars rating-stars--gold"
                    value={value}
                    onChange={(val) => {
                        fieldChange(val);
                        onChange({ score: val, body: getValues("body") });
                    }}
                    itemStyles={{ itemShapes: Star }}
                />
            )}
        />
    );

    if (isCurrent && !isIndi && statusLabels[status] === "approvestatus") {
        return (
            <>
                <span className="p1 color-green text-center">{t("approvestatus")}</span>
                <div className="res__status">
                    <span className="p3">{t("yourratingvideo")}</span>
                    <RatingComponent name="score" onChange={handleSubmitReview} />
                </div>
            </>
        );
    }

    if (statusLabels[status] === "completestatus") {
        return (
            <>
                <span className="p1 color-green text-center">{t("approvestatus")}</span>
                <div className="d-flex fd-column gap-12 justify-center align-center">
                    <span> {reviewScore ? t("yourclientreview") : t('yourratingvideo')}</span>
                    {reviewScore ?
                        <RatingStars color={'gold'} value={reviewScore} />
                        : <RatingComponent name="score" onChange={handleSubmitReview} />}
                </div>
            </>
        );
    }

    if (statusLabels[status] === "approvestatus") {
        return (
            <>
                <span className="p1 color-green">{t("orderaccept")}</span>
                <div className="res__status">
                    <span className="p3">
                        {t("mybecanceled")} <span className="color-main">{dateForDeclined}</span>
                    </span>
                    <Button
                        size="m"
                        clazz="button--secondary"
                        onClick={() => handleStatusUpdate("cancel", id)}
                    >
                        {t("cancel")}
                    </Button>
                </div>
            </>
        );
    }

    if (statusLabels[status] === "declinestatus") {
        return <p className="p1 color-red-700 text-center mr-auto ml-auto">{t("declinestatus")}</p>;
    }

    return (
        <>
            <span className="res__status__text p3">
                {t("confirm")} {t("until")}{" "}
                <span className="color-main p2">{approveDate}</span>
            </span>
            <div className="res__status__actions">
                <Button
                    size="m"
                    clazz="button--green justify-center"
                    onClick={() => handleStatusUpdate("confirm", id)}
                >
                    {t("confirm")}
                </Button>
                <Button
                    size="m"
                    clazz="button--secondary justify-center"
                    onClick={() => handleStatusUpdate("cancel", id)}
                >
                    {t("cancel")}
                </Button>
            </div>
        </>
    );
};

const VariantTimer = ({ id, setData, book_at }) => {
    const { t, dispatch } = useLadyService();
    const expiryTimestamp = sub(fromUnixTime(book_at), { hours: 1 })

    const handleExpire = async () => {
        setData((prevData) =>
            prevData.map((item) => (item.id === id ? { ...item, status: 2 } : item))
        );
    };

    const { seconds, minutes, hours } = useTimer({
        autoStart: true,
        expiryTimestamp,
        onExpire: handleExpire,
    });

    const formatTime = () => {
        const timeArr = [hours, minutes, seconds];
        return timeArr.map((item) => (item < 10 ? `0${item}` : item)).join(':')
    }

    const makeRequest = useMakeRequest()
    const handleStatusUpdate = async (action) => {
        try {
            const route = action === "confirm" ? `previews/${id}/confirm` : `previews/${id}/cancel`;
            makeRequest({ route, method: "PUT" }).then(() => {
                if (action === "confirm") {
                    setData((prevData) =>
                        prevData.map((item) => (item.id === id ? { ...item, status: 1 } : item))
                    );
                } else {
                    setData((prevData) =>
                        prevData.map((item) => (item.id === id ? { ...item, status: 2 } : item))
                    );
                }
                dispatch(setPreviewsAmount((prev) => Math.max(prev - 1, 0)));
            })
        } catch (error) {
            console.error('error', error);
        }
    }
    return (
        <>
            <span className="text-center">
                {t("timetoaccept")} <span className="p2 color-main">{formatTime()}</span>
            </span>
            <div className="res__status__actions">
                <Button
                    size="m"
                    clazz="button--green justify-center w-100"
                    onClick={() => handleStatusUpdate('confirm')}
                >
                    {t("confirm")}
                </Button>
                <Button
                    size="m"
                    clazz="button--secondary justify-center w-100"
                    onClick={() => handleStatusUpdate('cancel')}
                >
                    {t("cancel")}
                </Button>
            </div>
        </>
    );
};

const PreviewActions = (props) => {
    const { status } = props;
    const classVariant = statusLabels[status]?.split("status")[0] || "work";

    return (
        <div className={`res__status ${classVariant} align-self-center`}>
            {status === 0 ? <VariantTimer {...props} /> : <Variant {...props} />}
        </div>
    );
};

export default PreviewActions;
