import React, { useEffect, useRef, useState } from "react";
import { Icon } from "../index";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  defaultRangeState,
  filterPlace,
  rangeState,
} from "@/stores/slices/filterSlice";
import { getAllSorted } from "@/stores/slices/modelsState";

import "./dropdown.scss";

const Dropdown = ({ ...props }) => {
  const {
    name,
    children,
    title,
    size,
    buttonClass,
    clazz,
    clazzWrapper,
    leftIcon,
    openOnHover,
    warning = clazzWrapper,
    arrow = true,
    iconSize,
    iconClass,
    hideOnCHange = false,
    contentClass,
    handleClose = false,
    count = false,
    disabled = false,
    accordion = false,
    hideOnClick = false,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const buttonRef = useRef(null);
  const childrenRef = useRef(null);
  const defaultRangeValue = useSelector(defaultRangeState);
  const sortedValue = useSelector(getAllSorted);

  const filterPlaceSlice = useSelector(filterPlace);
  const rangeInputValue = useSelector(rangeState);

  useEffect(() => {
    if (hideOnCHange) {
      setIsOpen(false);
    }
  }, [name, title]);

  useEffect(() => {
    if (handleClose) {
      setIsOpen(false);
    }
  }, [handleClose]);

  const handleClickOutside = (event) => {
    if (
      childrenRef.current &&
      !childrenRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsOpen(false);
      event.stopPropagation();
    }
  };

  const handleHideOnCLick = (e) => {
    if (
      childrenRef.current &&
      childrenRef.current.contains(e.target) 
    ) {
      setIsOpen(false);
      e.stopPropagation();
    }
  }

  useEffect(() => {
    if(hideOnClick){
      document.addEventListener("mousedown", handleHideOnCLick);
      return () => {
        document.removeEventListener("mousedown", handleHideOnCLick);
      };
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      onMouseEnter={!!openOnHover ? () => setIsOpen(true) : null}
      onMouseLeave={!!openOnHover ? () => setIsOpen(false) : null}
      className={`dropdown${isOpen ? " dropdown_active" : ""}${
        clazzWrapper ? ` ${clazzWrapper}` : ""
      }`}
    >
      <button
        className={`button-square  ${clazz ? ` ${clazz}` : ""}`}
        onClick={() => {
          !disabled && setIsOpen((prev) => !prev);
        }}
        type="button"
        ref={buttonRef}
      >
        {leftIcon ? <Icon spritePath={leftIcon} /> : ""}
        {title}
        {iconClass ? (
          <Icon
            size={iconSize ? iconSize : size}
            clazz={iconClass}
            spritePath={iconClass}
          />
        ) : (
          ""
        )}
        {arrow && !iconClass ? (
          <Icon size={size} clazz={"chevrone"} spritePath={"chevrone-down"} />
        ) : (
          ""
        )}
        {/* {warning && checkedBoxes.length >= 1 ? (
          <span className={"count"}>!</span>
        ) : null} */}
        {name === "place" &&
        (filterPlaceSlice.length > 0 || sortedValue.includes("incall")) ? (
          <span className={"count lower"}></span>
        ) : null}
        {(name === "price" || name === "age") &&
        (rangeInputValue[name].min > defaultRangeValue[name].min ||
          rangeInputValue[name].max < defaultRangeValue[name].max) &&
        defaultRangeValue[name].min !== 0 &&
        defaultRangeValue[name].max !== 0 ? (
          <span className={"count lower"}></span>
        ) : null}
        {count && <span className={"count lower"}></span>}
      </button>

      <div ref={childrenRef} className={`dropdown__body ${contentClass ?? ""} ${accordion ? accordion : ''}`}>
        {children}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  title: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
  count: PropTypes.object,
  buttonClass: PropTypes.string,
  clazzWrapper: PropTypes.string,
};

export default Dropdown;
