import Geosuggest from "@ubilabs/react-geosuggest";

import {useDispatch, useSelector} from "react-redux";

import "./input.scss";
import {
    setAddressValue,
    setRadiusCoords,
    toggleSelected,
} from "@/stores/slices/filterSlice";
import {setRadiusCoordsCount} from "@/stores/slices/counterFilterSlice";
import {
    setOneProfileAddress,
    setOneProfileCoords,
} from "@/stores/slices/oneProfileSlice";
import {getGmapsApiReady} from "../../../stores/slices/mapModelSlice";
import {useEffect, useState} from "react";

const GeoInput = ({
                      name,
                      value,
                      clazz,
                      placeholder,
                      register,
                      setValue,
                      isAgency = false,
                      disabled,
                      city = false,
                      setCoords,
                  }) => {
    const dispatch = useDispatch();

    const apiReady = useSelector(getGmapsApiReady);

    const onSuggestSelect = (suggest) => {
        if (suggest) {
            if (name === "location" || name === "address") {
                dispatch(setAddressValue(suggest.description));
                dispatch(
                    toggleSelected({
                        value: suggest.description,
                        name: "radius",
                    })
                );

                dispatch(
                    setRadiusCoords({
                        latitude: suggest.location.lat,
                        longitude: suggest.location.lng,
                    })
                );
                dispatch(
                    setRadiusCoordsCount({
                        latitude: suggest.location.lat,
                        longitude: suggest.location.lng,
                    })
                );

                !!setValue && setValue(name, suggest.description);
                !!setCoords && setCoords(suggest.location.lat, suggest.location.lng);
            }
            if (name === "location-create") {
                dispatch(setOneProfileAddress(suggest.description));
                dispatch(
                    setOneProfileCoords({
                        latitude: suggest.location.lat,
                        longitude: suggest.location.lng,
                    })
                );
                !!setValue && setValue(name, suggest.description);
            }
        }
    };

    const onInputChange = (userInput) => {
        if (isAgency) {
            !!setValue && setValue(userInput);
        } else {
            if (!userInput) {
                !!setValue && setValue(name, null);
            }
        }
    };
    const [bounds, setBounds] = useState(null)
    const [checkReady, setCheckReady] = useState(false)
    const getCityBounds = async (city) => {
        const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(city)}&key=AIzaSyA81vyhvrBl3jUTFDK6LwYNm317prcxfVo`;
        try {
            const response = await fetch(geocodeUrl);
            const data = await response.json();

            if (data.status === 'OK') {
                const location = data.results[0].geometry.location;
                const lat = location.lat;
                const lng = location.lng;

                // Создадим LatLngBounds (расширяем их для простоты)
                const radius = 50000; // Радиус 50 км для примера
                const latLngNE = {
                    lat: lat + (radius / 111320), // 1 градус широты = 111.32 км
                    lng: lng + (radius / 111320), // 1 градус долготы = 111.32 км (на экваторе)
                };

                const latLngSW = {
                    lat: lat - (radius / 111320),
                    lng: lng - (radius / 111320),
                };

                setBounds({
                    northeast: latLngNE,
                    southwest: latLngSW,
                })
            } else {
                console.error('Geocoding failed, status:', data.status);
            }
        } catch (error) {
            console.error('Request error:', error);
        }

        setBounds(null)
    };
    useEffect(() => {
        if (city) {
            getCityBounds(city).then(setCheckReady(true))
        }
    }, [city])
    return (
        typeof window !== "undefined" &&
        !!apiReady && (checkReady || !city) &&  (
            <Geosuggest
                inputProps={!!register && {...register(name)}}
                onChange={onInputChange}
                disabled={disabled}
                initialValue={value}
                inputClassName={`input ${clazz ? clazz : ""}`}
                placeholder={placeholder}
                onSuggestSelect={onSuggestSelect}
                country={'cz'}
                bounds={bounds}
            />
        )
    )
        ;
};

export default GeoInput;
