import {useState} from "react";
import MultiRangeSlider from 'multi-range-slider-react'

import './inputRange.scss'
import useLadyService from "@/services/LadyService";

const InputRange = (props) => {
    const {
        min,
        max,
        step,
        inputIdLeft,
        inputIdRight,
        title,
        subtitle,
        resetButton = true,
        clazz,
        name,
        price
    } = props

    const {t} = useLadyService()

    const [minValue, setMinValue] = useState(min)
    const [maxValue, setMaxValue] = useState(max)

    const [maxInput, setMaxInput] = useState(maxValue)
    const [minInput, setMinInput] = useState(minValue)

    const [edit, setEdit] = useState(false)

    const handleInput = (e) => {
        if (!edit) {
            setMaxInput(e.maxValue)
            setMinInput(e.minValue)
        }
        setMinValue(e.minValue)
        setMaxValue(e.maxValue)
    }

    const resetValues = () => {
        setMaxValue(max)
        setMinValue(min)
    }

    const setMinValueInput = (e) => {
        setEdit(true)
        if (Number(e.target.value) > maxInput) {
            // если мин значение введено больше максимально
            setMinValue(maxInput - 10)
            setMinInput(e.target.value)
            return
        }
        if (Number(e.target.value) > min) {
            e.target.parentElement.classList.add('_active')
        }
        if (Number(e.target.value) <= min) {
            e.target.parentElement.classList.remove('_active')
        }
        if (Number(e.target.value) < min) {
            // если мин значение введено меньше мин
            setMinInput(min)
            setMinValue(e.target.value)
            return
        }
        setMinValue(Number(e.target.value))
        setMinInput(e.target.value)
    }

    const setMaxValueInput = (e) => {
        setEdit(true)
        if (Number(e.target.value) > max) {
            // если максимальное значение введено больше максимального
            setMaxValue(max)
            setMaxInput(e.target.value)
            return
        }
        if (Number(e.target.value) < max) {
            e.target.parentElement.classList.add('_active')
        }
        if (Number(e.target.value) >= max) {
            e.target.parentElement.classList.remove('_active')
        }
        if (Number(e.target.value) < minInput) {
            // если максимальное значение меньше минимального
            setMaxValue(minInput + 10)
            setMaxInput(e.target.value)
            return
        }
        setMaxValue(Number(e.target.value))
        setMaxInput(e.target.value)
    }

    const onBlurMinInput = (e) => {
        setEdit(false)
        if (Number(e.target.value) <= 0) {
            // если мин значение введено меньше минимального
            setMinValue(min)
            setMaxInput(min)
            return
        }
        if (Number(e.target.value) > max) {
            // если мин значение введено больше максимального
            setMinValue(maxValue)
            setMinInput(maxValue)
            return
        }
    }

    const onBlurMaxInput = (e) => {
        setEdit(false)
        if (Number(e.target.value) <= 0) {
            // если максимальное значение меньше минимального
            setMaxValue(min)
            setMaxInput(min)
            return
        }
        if (Number(e.target.value) > max) {
            // если максимальное значение введено больше максимального
            setMaxValue(maxValue)
            setMaxInput(maxValue)
            return
        }
    }

    const handleKeyPressMax = (event) => {
        if (event.key === 'Enter') {
            onBlurMaxInput()
        }
    }

    const handleKeyPressMin = (event) => {
        if (event.key === 'Enter') {
            onBlurMinInput()
        }
    }

    const checkInput = (e) => {

    }

    return (
        <div className={`range${clazz ? ` ${clazz}` : ''}`}>
            <div className={'range__body'}>
                <div className={'range__head'}>
                    <div className={'range__title'}>{title}{subtitle ? <span>{subtitle}</span> : ''}</div>
                    {resetButton ?
                        (
                            <button type='button' onClick={resetValues} className={'range__default'}>{t('reset')}</button>
                        )
                        : null}
                </div>
                <MultiRangeSlider
                    min={min}
                    max={max}
                    step={step}
                    stepOnly={!!step}
                    className={'range__input'}
                    ruler={false}
                    label={false}
                    minValue={minValue}
                    maxValue={maxValue}
                    onInput={e => {
                        handleInput(e)
                    }}
                />
                <div className={'range__inputs'}>

                    <label className={price ? 'input-price' : ''} htmlFor={inputIdLeft}>
                        <input
                            id={inputIdLeft}
                            type="tel"
                            step={step}
                            value={minInput}
                            autoComplete='off'
                            name={name}
                            onChange={e => setMinValueInput(e)}
                            onBlur={(e) => onBlurMinInput(e)}
                            onKeyDown={e => handleKeyPressMin(e)}
                        />
                        {minInput}
                    </label>

                    <label className={price ? 'input-price' : ''} htmlFor={inputIdRight}>
                        <input
                            id={inputIdRight}
                            type="tel"
                            step={step}
                            value={maxInput}
                            autoComplete='off'
                            name={name}
                            onChange={e => setMaxValueInput(e)}
                            onBlur={(e) => onBlurMaxInput(e)}
                            onKeyDown={e => handleKeyPressMax(e)}
                        />
                        {maxInput}
                    </label>

                </div>
            </div>
        </div>
    )
}
export default InputRange