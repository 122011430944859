import {
  setForgotPasswordStatus,
  setForgotPasswordEmailStatus,
  getForgotPasswordEmailStatus,
  setForgotPasswordPhoneStatus,
  getForgotPasswordStatus,
} from "@/stores/slices/popupSlice";
import { Helmet } from "react-helmet";
import { Button, Icon, InputInLabel } from "@/components/ui";
import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RestorePasswordPopupPhone from "./RestorePhonePopup";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { makeRequest } from "@/services/makeRequest";
import useLadyService from "@/services/LadyService";
import { setNewToken } from "../../../stores/slices/userSlice";
import { Popup } from "../index";
import { setNewPassStatus } from "../../../stores/slices/popupSlice";
import { LoadingButton } from "../../ui";
import { CodeInput } from "../../ui/codeInput/CodeInput";

const RestorePasswordPopup = () => {
  const { t, dispatch, navigate } = useLadyService();

  const [codeError, setCodeError] = useState("");
  const [email, setEmail] = useState("");

  const forgotPassState = useSelector(getForgotPasswordStatus);
  const forgotPassEmailState = useSelector(getForgotPasswordEmailStatus);
  const formSchema = object().shape({
    login: string().required(t("loginisness")).email(t("badformat")),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (data, e) => {
    reset();
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const route = `auth/password/reset`;
        const method = "POST";
        const payload = {
          identity: data.login,
        };

        const result = await makeRequest({ route, method, payload });
        if (result) {
          dispatch(setForgotPasswordEmailStatus(true));
          dispatch(setForgotPasswordStatus(false));
          reset();
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const onClose = () => {
    dispatch(setForgotPasswordStatus(false));
    reset();
  };

  const navigateTo = () => {
    dispatch(setForgotPasswordStatus(false));
    navigate(`/registration`);
  };
  const [otp, setOtp] = useState(['']);
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if(codeError) {
      setCodeError("");
    }
  }, [otp])

  const handleComplete = () => {
    reset();
    setCodeError("");
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const route = `auth/password/verify`;
        const method = "POST";
        const payload = {
          identity: email,
          code: otp.join(""),
        };

        const {result, token} = await makeRequest({ route, method, payload });

        if (result) {
          dispatch(setNewToken(token));
          dispatch(setForgotPasswordStatus(false));
          dispatch(setNewPassStatus(true));
          dispatch(setForgotPasswordEmailStatus(false));
          reset();
        } else {
          setCodeError(t("codeisincorrect"));
        }
        setIsLoading(false);
      } catch (error) {
        const status = error.response.status;
        setIsLoading(false);

        if (status === 422 || status === 404) {
          setCodeError(t("codeisincorrect"));
        }
      }
    };

    fetchData();
  };

  return (
    <>
      {!!forgotPassState && (
        <Popup
          id={"forgotPasswordPopup"}
          open={!!forgotPassState}
          setOpen={onClose}
          wrapperClazz="forgot-phone-inputs"
          onSubmit={handleSubmit(onSubmit)}
          closeOutside={false}
        >

          <h2 className="mb-12">
            {t("passwordrecovery")}
          </h2>
          <div className="popup-form__inner">
            <fieldset>
              <legend>{(errors.login && (
                  <span className={"input-label__error"}>
                    {errors.login.message}
                  </span>
                )) || t("loginuser")}</legend>
              <InputInLabel
                clazz={`${errors.login ? " _error" : ""}`}
                id={"loginForgotPass"}
                type={"email"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={"example@google.com"}
                register={{ ...register("login") }}
                autocomplete={"login"}
              >
                
              </InputInLabel>
            </fieldset>

            <LoadingButton
              buttonType={"submit"}
              size={"l"}
              square={false}
              isLoading={isLoading}
              clazz="button--green min-w-160"
            >
              {t("recoverpassword")}
            </LoadingButton>
          </div>
          <div className="popup-form__inner gap-0">
            <div className="popup-form__box popup-form__bottom-link">
              <span>{t("noemailaccess")}</span>
              <button
                type={"button"}
                onClick={() => {
                  dispatch(setForgotPasswordPhoneStatus(true))
                  dispatch(setForgotPasswordStatus(false))
                }}
              >
                {t("getsmscode")}
              </button>
            </div>

            <div className="popup-form__box popup-form__bottom-link">
              <span>{t("noaccount")}</span>
              <button type={"button"} onClick={() => navigateTo()}>
                {t("register")}
              </button>
            </div>
          </div>
        </Popup>
      )}

      {!!forgotPassEmailState && (
        <Popup
          id={"forgotPasswordEmailPopup"}
          open={!!forgotPassEmailState}
          setOpen={() => dispatch(setForgotPasswordEmailStatus(false))}
          wrapperClazz="forgot-phone-inputs"
          container={"div"}
        >
          <div className="popup-form__inner">
            <h2>{t("passwordrecovery")}</h2>
            <span className={"p2 text-center"}>{t("ifemailexist") + "."}</span>
          </div>

          {/* <p className={"fz-18 text-center lh-22 color-main"}>
              {t('ifemailexist') + "."}
            </p> */}

          <CodeInput setCodeError={setCodeError} setValue={setOtp} setIsComplete={setIsComplete}/>

          {codeError && (
            <span className={"popup-form__error text-center mb-8"}>
              {codeError}
            </span>
          )}

          <LoadingButton
            buttonType={"button"}
            size={"l"}
            onClick={() => handleComplete()}
            square={false}
            isLoading={isLoading}
            clazz="button--green"
          >
            {t("send")}
          </LoadingButton>
        </Popup>
      )}

      <RestorePasswordPopupPhone />
    </>
  );
};

export default RestorePasswordPopup;
