import { Helmet } from "react-helmet";
import { Button, Icon, Toggle } from "@/components/ui";
import { makeRequest } from "@/services/makeRequest";
import showToast from "../../toast/Toast";
import useLadyService from "@/services/LadyService";
import {
  getAddOrImportProfileState,
  setAddOrImportProfile,
} from "@/stores/slices/popupSlice";
import { useSelector } from "react-redux";
import { ButtonLink } from "../../ui";
import {Popup} from "../index";

const AddOrImportProfile = (props) => {
  const { profileSlug, setData, setCount, setOpen, onChangeStatus, status } =
    props;

  const { t, dispatch } = useLadyService();
  const afterAuth = useSelector(getAddOrImportProfileState);
  const handleCloseDelete = () => {
    dispatch(setAddOrImportProfile(false));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const fetchData = async () => {
      try {
        const route = `user/profile/${profileSlug}`;
        const method = "DELETE";

        await makeRequest({ route, method });

        setCount((prev) => prev - 1);
        setData((prevState) => {
          const newPrevState = prevState.filter(
            (item) => item.slug !== profileSlug
          );

          return newPrevState;
        });

        showToast({
          message: `${t("ad")} ${profileSlug} удалена`,
          variant: "success",
        });
        dispatch(setAddOrImportProfile(false));
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  };

  return (
    <Popup
      open={!!afterAuth}
      setOpen={handleCloseDelete}
      method={"DELETE"}
      clazz={'gap-16'}
    >
        <h2>{t("add")}</h2>
        <div className="d-flex gap-16 fd-column">
          <ButtonLink
            href={`/lk/create`}
            size={"l"}
            clazz={"button--green justify-center"}
            onClick={handleCloseDelete}
          >
            {t("createnew")}
          </ButtonLink>

          <p className="color-main text-center fz-18">{t("or")}</p>
          <div className="d-flex gap-4 fd-column align-center">
            <span className="color-700">
              {t("importfor")}
              {","}
            </span>
            <span className="color-700">{t("parametrs")}</span>
          </div>

          <Button
            size={"l"}
            clazz={"button--tetriary"}
            onClick={() => {
              setOpen(true);
              dispatch(setAddOrImportProfile(false));
            }}
          >
            <Icon spritePath={"import"} size={"l"} />
            <span className="p1">{t("import")}</span>
          </Button>
        </div>
    </Popup>
  );
};

export default AddOrImportProfile;
