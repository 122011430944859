import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    big_filter: false,
    short_filter: false
};

export const showFilterSlice = createSlice({
    name: 'show_filter',
    initialState,
    reducers: {
        show: (state) => {
            state.big_filter = true;
            state.short_filter = false;
        },
        hide: (state) => {
            state.big_filter = false;
        },
        showShort: (state) => {
            state.short_filter = !state.short_filter;
            state.big_filter = false;
        },
        hideShort: (state) => {
            state.short_filter = false;
        },
    },
});

export const { show, hide, showShort, hideShort } = showFilterSlice.actions;

export const showFilter = (state) => state.show_filter.big_filter;

export const showShortFilter = (state) => state.show_filter.short_filter;

export default showFilterSlice.reducer;
