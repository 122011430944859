import {
  Accordion,
  Button,
  Checkbox,
  Icon,
  InputInLabel,
  Toggle,
} from "@/components/ui";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import icons_db from "./iconsDB";
import './uiKitPage.scss'

const UIKitPage = () => {

  return (
    <main className={'ui-kit'}>
      <div className={"__container mb-48"}>

        <h1 style={{fontFamily: `'Libertinus'`}}>Лучшие эскорт-модели в г. Прага Lady4Love 14509 Libertinus</h1>
        <h1 style={{fontFamily: `'Libertinus'`, fontWeight: 300}}>Лучшие эскорт-модели в г. Прага Lady4Love 14509 Libertinus Display</h1>

        <h1>H1 48{'>'}28</h1>
        <h2>H2 32{'>'}22</h2>
        <h3>H3 22{'>'}18</h3>

        <b>Жирный текст</b>

        <p>Параграф без класса</p>

        <p className={'p1'}>Параграф p1 16{'>'}15</p>
        <p className={'p2'}>Параграф p2 14{'>'}13</p>
        <p className={'p3'}>Параграф p3 13{'>'}12</p>

        <br/>
        <span className={"mt-20 fz-20"}>Списки:</span>

        <ul>
          <li>li список 1</li>
          <li>li список 2</li>
          <li>li список 3</li>
        </ul>

        <ol>
          <li>ol список 1</li>
          <li>ol список 2</li>
          <li>ol список 3</li>
        </ol>

        <br/>

        <div className={"d-flex fd-column gap-12 width-max"}>
          <Toggle optionLabels id='uikit-toggle'/>
          <div
            className={'d-flex fd-column gap-10'}
            style={{
              width: '300px',
              border: '1px solid var(--color-600)',
              padding: '10px'
            }}
          >
            <span className={'mt-20 fz-20'}>Инпуты:</span>
            <Checkbox id={'uiCheck1'} title={'Чекбокс'}></Checkbox>

            <Checkbox flag={'eu'} id={'uiCheck2'} title={'Чекбокс с флагом'}></Checkbox>

            <Checkbox disabled title={'Чекбокс disabled'}></Checkbox>

            <InputInLabel
              type={'radio'}
              name={'radio'}
              checked={true}
              id={'radio1'}
            >
              Радиокнопка
            </InputInLabel>

            <InputInLabel
              type={'radio'}
              name={'radio'}
              id={'radio2'}
            >
              Радиокнопка
            </InputInLabel>

            <InputInLabel
              id={'textarea'}
              type={'textarea'}
            >
              Поле ввода
            </InputInLabel>

            <InputInLabel
              id={'date'}
              type={'date'}
            >
              Календарь
            </InputInLabel>

            <InputInLabel
              id={'number'}
              type={'number'}
            >
              Только цифры
            </InputInLabel>

            <InputInLabel
              id={'price'}
              type={'number'}
              price={'erocoin'}
            >
              Цена в эрокоинах
            </InputInLabel>

            <InputInLabel
              id={'price'}
              type={'number'}
              price={'euro'}
            >
              Цена в евро
            </InputInLabel>

            <Accordion title={"Аккордеон"}>
              <div className={"d-flex fd-column gap-10"}>
                <HashLink to="#">текст1</HashLink>
                <HashLink to="#">текст2</HashLink>
                <HashLink to="#">текст3</HashLink>
              </div>
            </Accordion>
          </div>

          <div className={'d-flex fd-column gap-10'}
               style={{
                 width: '500px',
                 border: '1px solid var(--color-600)',
                 padding: '10px'
               }}>
            <span className={'mt-20 fz-20'}>Ссылки:</span>
            <Link to={'#'} className={'text-underline'}>Ссылка 1</Link>
            <Link to={'#'} className={'hover-line'}>Ссылка 2</Link>
            <Link to={'#'} className={'color-green hover-line'}>Ссылка 3</Link>

            <span className={'mt-20 fz-20'}>Кнопки:</span>
            <div className={'d-flex gap-20'}>
              <div className={'d-flex fd-column gap-10'}>
                <span className={'mt-12'}>Расцветки:</span>

                <Button
                  size={"s"}
                  clazz="button--black"
                >
                  Black
                </Button>
                <Button size={"s"} clazz="button--primary">
                  Primary
                </Button>
                <Button size={"s"} clazz="button--error">
                  Error
                </Button>
                <Button size={"s"} clazz="button--secondary">
                  Secondary
                </Button>

                <Button size={"s"} clazz="button--tetriary">
                  Tetriary
                </Button>

                <Button size={"s"} clazz="button--green">
                  Green
                </Button>

                <Button size={"s"} clazz="button--green__secondary">
                  Green Secondary
                </Button>

                <Button size={"s"} clazz="button--green-accent">
                  Green Accent
                </Button>

                <Button size={"s"} clazz="button_outline--black">
                  Outline Black
                </Button>

                <Button size={"s"} clazz="button_outline--green">
                  Outline Green
                </Button>

                <Button size={"s"} clazz="button_outline--green-accent">
                  Outline Green Accent
                </Button>

                <Button size={"s"} clazz="button_outline--spec-yellow">
                  Outline Spec Yellow
                </Button>

                <Button size={"s"} clazz="button_outline--spec-green">
                  Outline Spec Green
                </Button>

                <Button size={"s"} clazz="button_no-fill">
                  No fill
                </Button>

                <Button size={"s"} disabled clazz="button--tetriary">
                  Disabled
                </Button>
              </div>

              <div className={"d-flex fd-column gap-10"}>
                <span className={"mt-12"}>Размеры:</span>

                <Button size={"xs"} clazz="button--black">
                  xs
                </Button>
                <Button size={"s"} clazz="button--primary">
                  s
                </Button>
                <Button size={"m"} clazz="button--error">
                  m
                </Button>
                <Button size={"l"} clazz="button--secondary">
                  l
                </Button>

                <span>Квадратные:</span>

                <Button size={"2xs"} clazz="button--secondary" square>
                  2xs
                </Button>

                <Button size={"xs"} clazz="button--green" square>
                  xs
                </Button>

                <Button size={"s"} clazz="button--primary" square>
                  s
                </Button>

                <Button size={"m"} clazz="button--error" square>
                  m
                </Button>

                <Button size={"l"} clazz="button--secondary" square>
                  l
                </Button>
              </div>
            </div>
          </div>

          <h3>Иконочный шрифт</h3>

          <div className="ui-kit__icons">
            {icons_db.map((i, index)=> (
              <div key={index}>
                {i.img && <img src={i.img} alt="#"/>}
                <Icon size={'l'} spritePath={i.name}/>
                {i.name}
              </div>
            ))}
          </div>


        </div>

      </div>
    </main>
  );
};

export default UIKitPage;
