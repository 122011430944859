import { getIsMainPage } from "@/stores/slices/modelsState";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export const ScrollToTop = () => {
    const { pathname, hash } = useLocation();
    const location = useLocation();
    const isMainPage = useSelector(getIsMainPage);

    const words = [
        "profile", 
        "lk", 
        "registration", 
        "support", 
        "guide", 
        "gdpr", 
        "rules", 
        "provisions", 
        "about", 
        "agencies",
        "history",
        "filter",
        "agency",
        "party",
        "call",
        "selection",
        "preview"
    ]
    

    useEffect(() => {
        setTimeout(() => {
            if(!isMainPage && words.some((word) => pathname.includes(word)) && !hash) {
                window.scrollTo(0, 0);
            }
        }, 300);
    }, [pathname, isMainPage]);

    return null;
}

