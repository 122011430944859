import React, { useEffect, useRef, useState } from "react";
import { Toggle } from "@/components/ui";
import useLadyService from "@/services/LadyService";
import showToast from "@/components/toast/Toast";
import { Link, useLocation } from "react-router-dom";
import { makeRequest } from "@/services/makeRequest";

import useUserProfileService from "../../../../../services/UserProfileService";
import ActivateWa from "../../../../popups/activeWa/ActiveWa";
import {setNotifyEmailStatus, setNotifyTelegramStatus} from "../../../../../stores/slices/userProfileSlice";
import {setActivateWhatsappStatus} from "../../../../../stores/slices/popupSlice";
import './notifyBlock.scss'
import {LoadingButton} from "../../../../ui";
import { resendEmail } from "@/helper/resendEmail.js";

const NotifyBlock = ({
  tariffsRef = false,
  setActiveWaOpen = false,
}) => {
  const {
    t,
    userTariffSlug,
    userTypeIndi,
    userTypeAgency,
    userTypeCustomer,
    dispatch
  } = useLadyService();

  const {
    notifyEmainStatus,
    notifyTelegramStatus,
    notifyWhatappStatus,
    emailVerifiedAtStatus
  } = useUserProfileService()

  const notifyRef = useRef(null);
  const { hash } = useLocation();

  const [isResendLoading, setIsResendLoading] = useState(false);
  const [emailNotifyError, setEmailNotifyError] = useState(false)

  const handleActiveNotify = async (type) => {
    if(type === "email" && !emailVerifiedAtStatus) {
      setEmailNotifyError(true)
      return
    }
    if(emailNotifyError) {
      setEmailNotifyError(false)
    }
    try {
      const route = `user/notify/${type}`;
      const method = "POST";
      const payload = {
        status: type === "email" ? !notifyEmainStatus : !notifyTelegramStatus,
      };
      const { success, link, message } = await makeRequest({
        route,
        method,
        payload,
      });
      if (success) {
        if (type === "email") {
          dispatch(setNotifyEmailStatus(prev => !prev))
        } else {
          dispatch(setNotifyTelegramStatus(prev => !prev))
        }
        showToast({
          message: t("success"),
          variant: "success",
        });
      } else if (message === "need_link") {
        if (typeof window !== "undefined") {
          window.open(link, "_blank");
        }
      } else {
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    } catch (error) {
      console.log('error: ', error);

      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  const [clientWaWarning, setClientWaWarning] = useState(false);

  const handleActiveNotifyWa = () => {
    if (!userTypeIndi) {
      if (userTariffSlug === "silver") {
        setClientWaWarning(true);
      }
    } else {
      if (setActiveWaOpen) {
        dispatch(setActivateWhatsappStatus(true))
      }
    }
  };

  const toTariffs = (e) => {
    e.preventDefault();

    if (tariffsRef && tariffsRef.current) {
      tariffsRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const mainText = userTypeIndi || userTypeAgency ? t("placefornotices") : t("clientnotices");

  useEffect(() => {
    if (notifyRef && notifyRef.current && hash === "#notify") {
      notifyRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [notifyRef, hash]);

  return (
    <>
      <div id={'notify'} className={`notify__block`}>
        <span className="color-main">{mainText}</span>
        <div
          ref={notifyRef}
          className={`notify__items${!userTypeCustomer ? ` justify-sb` : ''}`}
        >
          <div className="notify__item">
            <h3>Email</h3>

            <Toggle
              id={"isNotifyEmail"}
              checked={!!notifyEmainStatus}
              onClick={() => handleActiveNotify("email")}
            />

            {(userTypeIndi || userTypeAgency) && t("free")}

            {emailNotifyError && <span className="error color-red-700">{'Email ' + t('notconfirmed')}</span>}
          </div>

          <div className="notify__item">
            <h3>Telegram</h3>

            <Toggle
              id={"isNotifyTelegram"}
              checked={ notifyTelegramStatus }
              onClick={() => handleActiveNotify("telegram")}
            />

            {(userTypeIndi || userTypeAgency) && t("free")}
          </div>

          <div className={`notify__item`}>
            <h3>WhatsApp</h3>

            <Toggle
              disabled={true}

              id={"isNotifyWa"}
              checked={!!notifyWhatappStatus}
              onClick={handleActiveNotifyWa}
            />

            {(userTypeIndi || userTypeAgency) && (
              <div className="d-flex align-center">
                <span className="currency--erocoin">{userTypeIndi ? "20" : "50"}</span>
                {`/${t("month")}`}
              </div>
            )}

            {clientWaWarning && (
              <span>
              {t("wanotices")}
                {" \u2013 "}
                <Link to={`/lk/customer`} onClick={(e) => toTariffs(e)}>
                {t("changetariff")}
              </Link>
            </span>
            )}
          </div>
        </div>
      </div>

      {!emailVerifiedAtStatus && (
        <div className="account-page__confirm">
          <span className="p2">{t("toconfemail")}</span>
          <LoadingButton
            isLoading={isResendLoading}
            clazz="button_outline--green-accent"
            onClick={async () => {
              setIsResendLoading(true)
              const result = await resendEmail();
              if (result.success) {
                showToast({
                  message: t("sent"),
                  variant: "success",
                });
              }
              if (!result) {
                showToast({
                  message: t("tomanyrequests"),
                  variant: "error",
                });
              }
              setIsResendLoading(false)
            }}
          >
            {t("reconf")}
          </LoadingButton>
        </div>
      )}

      <ActivateWa/>
    </>

  );
};

export default NotifyBlock