import {
    Button,
    ButtonLink,
    CheckboxNavigation,
    Dropdown,
    Icon,
    Skeleton,
    Select,
    Checkbox
} from "@/components/ui";
import OrderPreviewsForm from "../orderPreviewsForm/OrderPreviewsForm";
import React, {useEffect, useState, useRef} from "react";
import GirlCard from "../cards/girlCard/GirlCard";
import axios from "axios";
import {useSelector} from "react-redux";
import {show} from "@/stores/slices/showFilterSlice";
import {
    showFilter,
    showShort,
    showShortFilter,
} from "@/stores/slices/showFilterSlice";
import {
    filterAllModels,
    getAllModels,
    getAllModelsMeta,
    getGenderSorted,
    getHasMorePage,
    getSearchValue,
    getStatusSorted,
    setNotDisabledCheckbox,
    setPage,
    getPage,
    getTypeSorted,
    setAllModels,
    setHasMorePage,
    updateAllModels,
    cleanSorted,
    getAgeForModels,
    getAllSorted,
    getIsLoading,
    getIsMainPage,
    getIsParamsExist,
    getPriceForModels,
    setArrSorted,
    setIsLoading,
    setIsMainPage,
    setIsParamsExist,
    setDisabledFooterLinks,
} from "@/stores/slices/modelsState";
import {getTitle, setDefaultTitle} from "@/stores/slices/titleSlice";
import {
    extractKeysAndNumbers,
    filterGender,
    filterPlace,
    filterStatus,
    isFilterSearch,
    resetAll,
} from "@/stores/slices/filterSlice";

import {
    getResultFilterString,
    resetStateCount
} from "@/stores/slices/counterFilterSlice";
import {getProfilesCount, setOneModelPosition} from "@/stores/slices/mapModelSlice";
import {setStoriesMap} from "@/stores/slices/popupSlice";
import {getUserCurrentCity, getCountriesData, setCity, setCurrentCity, setLinksCurrentCity} from "@/stores/slices/userSlice";
import baseUrl from "@/services/apiConfig";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import useLadyService from "@/services/LadyService";
import {
    setDefaultRange,
    setIsRangeLoad,
    toggleSelectedArr,
    toggleStateArr,
    rangeChange,
    resetAccordionByStatus,
    resetSelected,
    resetState,
    resetStateAcc,
    resetToggleAcc,
} from "@/stores/slices/filterSlice";
import {SelectCountry} from "../ui";
import ShortFilterData from "../shortFilter/ShortFilterData";
import {setFilterMiniPopupStatus} from "@/stores/slices/filterPopupSlice";
import "./navigation.scss";
import {getProfilesFilterCount} from "@/stores/slices/mapModelSlice";
import GetProfilesUrl from "@/services/GetProfiles";
import {getSelected} from "@/stores/slices/agencyFilterSlice";
import {getCountrySlug} from "@/stores/slices/userSlice";
import {getNotDisabledCheckbox} from "@/stores/slices/modelsState";
import Pagination from "../pagination/Pagination";
import {
    selectAdd,
    selectReset,
} from "@/stores/slices/agencyFilterSlice";
import {makeRequest} from "@/services/makeRequest";
import {
    restoreState,
    setSearchFilter
} from "@/stores/slices/filterSlice";
import { setSortedModelsByFilter } from "@/stores/slices/modelsState";
import Notifications from "@/components/notifications/Notifications";
import showToast from "@/components/toast/Toast";
import StoriesSlider from "@/components/sliders/storiesSlider/StoriesSlider";



const Navigation = ({
                        setOnMap,
                        isDataEmpty,
                        setIsDataEmpty,
                        agencyId = false,
                        partyId=false,
                        mainPage = false,
                        checkbox = false,
                        profiles_statistic = false,
                        onChange = false,
                        preview_price = false,
                        changeParty=false,
                        party = false, 
                        onMap
                    }) => {

    const {
        dispatch, 
        lang, 
        windowWidth, 
        t, 
        token, 
        userType, 
        userTypeAgency, 
        userTypeIndi,
        city: selctedCity,
        scrollToTop
    } = useLadyService();

    let city = selctedCity;
    dispatch(setLinksCurrentCity(selctedCity))

    const filterNavigation = [
        {id: 1, link: "#", title: t("bynew"), value: "date"},
        {id: 2, link: "#", title: t("byprice"), value: "price"},
        {id: 3, link: "#", title: t("byrating"), value: "rating"},
    ];

    // check what inside countries data (district)
    const countriesData = useSelector(getCountriesData);
    const selectedAdds = useSelector(getSelected)
    const models = useSelector(getAllModels);
    // check what incide current city
    const currentCity = useSelector(getUserCurrentCity);
    const morePage = useSelector(getHasMorePage);
    const meta = useSelector(getAllModelsMeta)
    // check this too
    const countrySlug = useSelector(getCountrySlug);
    // check why you need this 
    const url = window.location.pathname;
    const segments = url.split("/").filter(value => value.length);
    const isMainPage = useSelector(getIsMainPage);
    const isLoading = useSelector(getIsLoading);
    const title = useSelector(getTitle);
    const result = GetProfilesUrl();
    const sortedStatusValue = useSelector(getStatusSorted);
    const sortedGenderValue = useSelector(getGenderSorted);
    const sortBy = useSelector(getTypeSorted);
    const page = useSelector(getPage);
    const isFilterSelected = useSelector(isFilterSearch);
    const statusFilterValue = useSelector(filterStatus);
    const genderFilterValue = useSelector(filterGender);
    const filterValue = useSelector(showFilter);
    const placeValue = useSelector(filterPlace);
    const priceValue = useSelector(getPriceForModels);
    const ageValue = useSelector(getAgeForModels);
    const searchByName = useSelector(getSearchValue);
    const sortedByGenderOrStatus = useSelector(getAllSorted);
    // add here maybe some handler to convert district 
    const {params: thirdSegment, city: currentCityParam, uuid : filterUuid} = useParams();
    const paramsExist = useSelector(getIsParamsExist);
    const isShortFilterShow = useSelector(showShortFilter);
    const valueResultFilter = useSelector(getResultFilterString);
    // check why you need this
    const czCities = countriesData[0]?.cz?.cities;
    const profilesCountValue = useSelector(getProfilesCount);
    const profilesFilterCountValue = useSelector(getProfilesFilterCount);
    const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');
    const {keysWithValueTrue} = useSelector(getNotDisabledCheckbox);
    const location = useLocation()
    
    const requestIdRef = useRef(0);

    useEffect(()=>{
        if(mainPage)dispatch(setOneModelPosition(null));
    }, [location])

    
    // const urldistrict = currentCityParam.split('_')[1];
    //let currentDistrict = useSelector(getUserCurrentDistrict);
    // if (!currentDistrict || urldistrict !== currentDistrict) {
    //     dispatch(setCurrentDistrict(urldistrict))
    // }

    // check why you need this
    if (currentCity) {
        city = currentCity.slug
    }

    const [previewsModels, setPreviewsModels] = useState([]);

    useEffect(() => {
        if(models)setPreviewsModels(models.filter(model => model?.ready_preview));
    }, [models]);
    

    useEffect(() => {
        dispatch(setIsMainPage(mainPage));

        return () => {
            dispatch(setIsMainPage(false));
            dispatch(setFilterMiniPopupStatus(false));
            dispatch(setFilterMiniPopupStatus(false));

            if(!filterUuid){
                handleReset();
            }
        }
    }, []);

    const handleReset = () => {
        dispatch(cleanSorted());
        dispatch(resetState("gender"));
        dispatch(resetSelected("gender"));
        dispatch(resetState("status"));
        dispatch(resetSelected("status"));
        dispatch(resetStateCount("status"));
        dispatch(resetState('services'));
        dispatch(resetSelected('services'))
        dispatch(resetStateCount('services'))
        dispatch(resetState('nation'));
        dispatch(resetSelected('nation'))
        dispatch(resetStateCount('nation'))
        dispatch(
            resetAccordionByStatus({
                accordionName: "status",
                arr: ["couple", "male", "trans", "guys"],
            })
        );
        dispatch(
            resetAccordionByStatus({
                accordionName: "gender",
                arr: ["couple", "male", "trans"],
            })
        );
        dispatch(resetStateCount("gender"));
        dispatch(resetStateCount("sex"));
        dispatch(resetStateAcc("status"));
        dispatch(resetToggleAcc("status"));
        dispatch(cleanSorted());
        dispatch(setDefaultTitle([]))
        dispatch(setAllModels([]))
        // if(mainPage){
        //     navigate('/')
        // }

        // maybe will need to reset district 

        if (!mainPage) {
            dispatch(resetAll())
        }
    }
    
    const allowedWords = [
        "indi",
        "elitelady",
        "pornstar",
        "online",
        "new",
        "vchat",
        "booking",
        "ero",
        "verified",
        "healthy",
        "couple",
        "bdsm",
        "trance",
        "guys",
        "incall",
        "anal",
        'asian'
    ];

    const tagsForStatus = [
        "indi",
        "elitelady",
        "pornstar",
        "online",
        "new",
        "vchat",
        "booking",
        "ero",
        "verified",
        "healthy",
        "bdsm",
        "video",
        "escort",
        "travel",
        "gf",
        "incall",
        "anal",
        'asian'
    ];

    const tagsForGender = [
        "about",
        "couple",
        "trance",
        "male",
        "guys",
        "trans",
        "female",
    ];

    
    useEffect(() => {
        
        if (thirdSegment && lang && city && !paramsExist) {
            // add checks for district
            const arrParams = thirdSegment.split("-");
            const hasInvalidWord = arrParams.some(
                (word) => !allowedWords.includes(word)
            );
            if (hasInvalidWord) {
                navigate("/404");
                return;
            }
            if (thirdSegment) {
                // add checks for district
                dispatch(setDefaultTitle(thirdSegment.split("-")));
            }
            const result = arrParams.filter((tag) => allowedWords.includes(tag));
            dispatch(setArrSorted(result));
            const validStatus = arrParams.filter((tag) =>
                tagsForStatus.includes(tag)
            );
            if (!!validStatus.length) {
                dispatch(toggleStateArr({StateName: "status", value: validStatus}));
                dispatch(toggleSelectedArr({name: "status", value: validStatus}));
            }
            const validGender = arrParams.filter((tag) =>
                tagsForGender.includes(tag)
            );
            if (!!validGender.length) {
                const changeGenderTags = validGender;
                dispatch(
                    toggleStateArr({StateName: "gender", value: changeGenderTags})
                );
                dispatch(
                    toggleSelectedArr({name: "gender", value: changeGenderTags})
                );
                dispatch(
                    toggleSelectedArr({name: "status", value: changeGenderTags})
                );
            }
            dispatch(setIsParamsExist(true));
        }
        if (!thirdSegment && lang && city && !paramsExist) {
            dispatch(setIsParamsExist(true));
        }
    }, [lang, city, thirdSegment]);

    const [isHandlingUrlChange, setIsHandlingUrlChange] = useState(false);

    useEffect(() => {
        const handleUrlChange = () => {
            // Устанавливаем флаг, что мы обрабатываем изменение URL
            setIsHandlingUrlChange(true);
            
            // Вместо handleReset() сделаем более мягкий сброс
            // Это важно, чтобы не полностью сбросить все фильтры
            dispatch(cleanSorted());
            
            // add checks for district
            if (thirdSegment) {
                const arrParams = thirdSegment.split("-");
                
                const hasInvalidWord = arrParams.some(
                    (word) => !allowedWords.includes(word)
                );
                
                if (hasInvalidWord) {
                    navigate("/404");
                    return;
                }
                
                // Важно: сначала устанавливаем setArrSorted, потом setDefaultTitle
                const result = arrParams.filter((tag) => allowedWords.includes(tag));
                dispatch(setArrSorted(result));
                dispatch(setDefaultTitle(arrParams));
                
                // Теперь устанавливаем статусные фильтры
                const validStatus = arrParams.filter((tag) =>
                    tagsForStatus.includes(tag)
                );
                
                if (validStatus.length) {
                    dispatch(toggleStateArr({StateName: "status", value: validStatus}));
                    dispatch(toggleSelectedArr({name: "status", value: validStatus}));
                }
                
                // Устанавливаем гендерные фильтры
                const validGender = arrParams.filter((tag) =>
                    tagsForGender.includes(tag)
                );
                
                if (validGender.length) {
                    dispatch(toggleStateArr({StateName: "gender", value: validGender}));
                    dispatch(toggleSelectedArr({name: "gender", value: validGender}));
                }
                
                // Устанавливаем специфические фильтры
                if (arrParams.includes('anal')) {
                    dispatch(toggleStateArr({StateName: "services", value: ["2"]}));
                    dispatch(toggleSelectedArr({name: "services", value: ["2"]}));
                }
                
                if (arrParams.includes('asian')) {
                    dispatch(toggleStateArr({StateName: "nation", value: ["asian"]}));
                    dispatch(toggleSelectedArr({name: "nation", value: ["asian"]}));
                }
                
                // Важно: дадим время для обновления стейта перед запросом
                setTimeout(() => {
                    // Увеличиваем ID запроса и делаем запрос
                    requestIdRef.current++;
                    fetchData();
                    
                    // Снимаем флаг обработки URL через некоторое время
                    setTimeout(() => {
                        setIsHandlingUrlChange(false);
                    }, 300);
                }, 100);
                
            } else {
                handleReset();
                
                // Увеличиваем ID запроса и делаем запрос
                requestIdRef.current++;
                fetchData();
                
                // Снимаем флаг обработки URL
                setTimeout(() => {
                    setIsHandlingUrlChange(false);
                }, 400);
            }
        };

        handleUrlChange();

        window.addEventListener('popstate', handleUrlChange);
        
        return () => {
            window.removeEventListener('popstate', handleUrlChange);
        };
    }, [location.pathname, thirdSegment]);

    useEffect(() => {
        if (!url.includes("lk") && !url.includes("agencies") && !url.includes("agency")) {
            if (lang && city && segments.length < 3 && isMainPage) {
                if (lang === "en" && city === 'czechia') {
                    navigate(`/`);
                    scrollToTop()
                } else if(!currentCityParam) {
                    // district change handling here
                    navigate(`/${lang}/${currentCity.slug}`);
                    scrollToTop()
                    
                }
            }
        }
    }, [lang, city, isMainPage]);
    
    const getFilterValue = (value) => {
        switch (value) {
            case "couples":
                return "couple";
            case "male":
                return "guys";
            case "trans":
                return "trance";
            case "female":
                return "";
            case "video":
                return "";
            case "travel":
                return "";
            case "escort":
                return "";
            case "gf":
                return "";
            case "2":
                return "anal";
            default:
                return value;
        }
    }

    const orderSelections = (selectedArr, orderToArr) => {
        const tagIndexMap = new Map(orderToArr.map((tag, index) => [tag, index]));
        const sortedSelectedTags = selectedArr.sort((a, b) => {
            return (tagIndexMap.get(a) || Infinity) - (tagIndexMap.get(b) || Infinity);
        });
        return sortedSelectedTags
    }

    useEffect(() => {

        if (lang && city) {
            if (!filterValue) {
                if (!!sortedByGenderOrStatus.length) {
                    const result = sortedByGenderOrStatus.map((item) => {
                        return getFilterValue(item);
                    });
                    if (result.filter((item) => !!item).length && isMainPage) {
                        // here add district if selected
                        navigate(`/${lang}/${currentCity.slug}/${orderSelections(result,allowedWords).join("-")}`);
                    }
                }
                if (!sortedByGenderOrStatus.length && segments.length < 3 && isMainPage && !url.includes("agencies") && !url.includes("agency")) {
                    if (lang === "en" && city === "czechia") {
                        navigate("/");
                    } else {
                        // here add checks for district
                        navigate(`/${lang}/${currentCity.slug}`);
                    }
                }
            }
        }
    }, [sortedByGenderOrStatus, filterValue]);

    const deepCompare = (a1, a2) => {
        if(a1 === null || a2 === null) return false;

        if(Array.isArray(a1) && Array.isArray(a2)){
            if(a1.length !== a2.length) return false;
            return a1.every((item, index) => deepCompare(item, a2[index]))
        }

        if(typeof a1 === 'object' && typeof a2 === 'object'){
            const keys1 = Object.keys(a1);
            const keys2 = Object.keys(a2);
            if(keys1.length !== keys2.length) return false;
            return keys1.every(key => deepCompare(a1[key], a2[key]));
        }

        return a1 === a2;
    }


    const fetchData = () => {
        // Увеличиваем ID запроса
        const requestId = ++requestIdRef.current;
        setShowSceletons(true)
        
        //  console.info(`%cПланируем запрос #${requestId}`, `color:rgb(209, 154, 2); font-size: 10px;`);
        
        // Используем setTimeout вместо debounce
        setTimeout(() => {
            // Проверяем, не устарел ли наш запрос
            if (requestId !== requestIdRef.current) {
                //  console.info(`%cЗапрос #${requestId} отменен - есть более новый запрос`,  `color:rgb(156, 49, 49); font-size: 10px;`);
                 
                return;
            }
            
            //  console.info(`%cВыполняем запрос #${requestId} с фильтрами: ${result.params}`,  `color:rgb(43, 212, 9); font-size: 10px;`);
            
            const agencyFilter = agencyId ? `&agency_id=${agencyId}` : '';
            const partyFilter = partyId ? `&party_id=${partyId}` : '';
            // rewrite for payload objects or add district check here
            const cityFilter = (currentCity?.slug && !agencyId) ? `&region=${currentCityParam ?? currentCity.slug}` : '';
            
            axios
                .request({
                    headers: {
                        Authorization: token ? `Bearer ${token}` : "",
                    },
                    method: "GET",
                    url: baseUrl + "profiles" + result.params + `${cityFilter}&lang=${lang}${agencyFilter}${partyFilter}`,
                })
                .then((response) => {
                    // Проверяем снова, не устарел ли наш запрос к моменту получения ответа
                    if (requestId !== requestIdRef.current) {
                        //  console.info(`%cОтвет на запрос #${requestId} отброшен - есть более новый запрос`,  `color:rgb(173, 34, 34); font-size: 10px;`);
                        return;
                    }
                    
                    // Остальная обработка остается прежней
                    const data = response.data.profiles;
                    const dataMeta = response.data.meta;
                    const perPage = dataMeta.per_page;
                    const totalProfiles = dataMeta.total;
                    const currentPage = dataMeta.current_page;
                    const isLoadMore = perPage * currentPage < totalProfiles;

                    if (page === 1) {
                        if(!deepCompare(data, models)){
                            dispatch(setAllModels(data));
                        }
                        dispatch(setHasMorePage(isLoadMore));
                    } 
                    else {
                        if(models.length / 24 < currentPage) dispatch(updateAllModels(data));
                        dispatch(filterAllModels());
                        dispatch(setHasMorePage(isLoadMore));
                    }
                    if (data.length === 0) {
                        setIsDataEmpty(true);
                    } else {
                        setIsDataEmpty(false);
                    }
                    const filterData = response.data.filters;
                    dispatch(
                        setDefaultRange({
                            name: "price",
                            min: filterData.price_min,
                            max: filterData.price_max,
                        })
                    );
                    dispatch(
                        setDefaultRange({
                            name: "age",
                            min: filterData.age_min,
                            max: filterData.age_max,
                        })
                    );
                    dispatch(
                        setDefaultRange({
                            name: "weight",
                            min: filterData.weight_min,
                            max: filterData.weight_max,
                        })
                    );
                    dispatch(
                        setDefaultRange({
                            name: "height",
                            min: filterData.height_min,
                            max: filterData.height_max,
                        })
                    );

                    if (windowWidth !== 0 && windowWidth < 998) {
                        dispatch(
                            rangeChange({
                                name: "price",
                                min: filterData.price_min,
                                max: filterData.price_max,
                            })
                        );
                        dispatch(
                            rangeChange({
                                name: "age",
                                min: filterData.age_min,
                                max: filterData.age_max,
                            })
                        );
                        dispatch(
                            rangeChange({
                                name: "weight",
                                min: filterData.weight_min,
                                max: filterData.weight_max,
                            })
                        );
                        dispatch(
                            rangeChange({
                                name: "height",
                                min: filterData.height_min,
                                max: filterData.height_max,
                            })
                        );
                    }
                    const result = extractKeysAndNumbers(filterData);
                    dispatch(setNotDisabledCheckbox(result));
                    dispatch(setDisabledFooterLinks(result));
                    dispatch(setIsLoading(true));
                    setShowSceletons(false)
                })
                .then(dispatch(setIsRangeLoad(true)))
                .catch((error) => {
                    setShowSceletons(false)
                    showToast({
                        message: t("oops"),
                        variant: "error",
                    });
                    navigate('/')
                });
        }, 500); // Увеличиваем задержку до 500мс
    };


    // api filter
    
    const [filterData, setFilterData] = useState({})

    const fetchFilter = async (uuid) => {
          try {
            const route = `filter/${uuid}`;
            const method = "GET";
    
            const data = await makeRequest({ route, method });
            setFilterData(data?.data);
            dispatch(setAllModels(data?.profiles))

          } catch (error) {console.error(error)}
    };
      
    const applyFilter = async ( ) => {
        // check how filter handling the city to enshure districts will work 
        await dispatch(restoreState(filterData.filterState))
        const cityToFind = czCities.filter((city)=>{if(city?.slug === filterData.filter.city){
            return city}  
          })

        if (filterData.filter.status) {
            await dispatch(setArrSorted(filterData.filter.status.split(",")));
        }
        await dispatch(setSearchFilter(true));
        await dispatch(setSortedModelsByFilter(filterData.filter.originalStr));
        

        if(filterData.filter.city) {
            // here too
            dispatch(setCity(filterData.filter.city));
            dispatch(setCurrentCity(
                {
                    id: cityToFind[0]?.id ?? 1,
                    title: cityToFind[0].name,
                    icon: 'CZ',
                    highlight: cityToFind[0].highlight,
                    slug: cityToFind[0].slug,
                    profiles_count: cityToFind[0]?.profiles_count ?? 0,
                    sort: cityToFind[0].sort,
                }
            ))
        }

    }    

    

    useEffect(()=>{if(filterData.filter && filterData.filterState){
        applyFilter();
    }}, [filterData])

    useEffect(() => {
        // Если мы в процессе обработки изменения URL, не делаем запрос
        if (isHandlingUrlChange) {
            return;
        }
        
        if(filterUuid){
            return;
        }
        else if ((!filterValue && !isShortFilterShow && paramsExist) || (!mainPage && agencyId)) 
        {
            if (page === 1) {
                dispatch(setIsLoading(false));
            }
            if (page > 1) {
                dispatch(updateAllModels([...Array(12)]));
            }
            
            // Увеличиваем ID запроса
            requestIdRef.current++;
            fetchData();
        }
        
    }, [
        isHandlingUrlChange,
        paramsExist,
        placeValue,
        searchByName,
        ageValue,
        priceValue,
        sortedByGenderOrStatus,
        sortBy,
        page,
        currentCity,
        filterValue,
        isShortFilterShow,
        lang,
        agencyId,
    ]);

    useEffect(()=>{
       if(filterUuid) fetchFilter(filterUuid) 
    }, [filterUuid])

    //here to add district if needed
    let postCity = t("incity", {City: currentCity.title ?? ""})
    let postTitle = t("h1main", {City: currentCity.title ?? ""});
    let postTitleMobile = t("h1main", {City: ""});
    if (countrySlug) {
        postCity = `${currentCity?.title}`
        postTitle = t("h1country", {Country: currentCity.title, CountryCode: currentCity.code});
        postTitleMobile = t("h1country", {Country: "", CountryCode: currentCity.code});
    }

    const titleString =
        title.length <= 0 ? "" : title
            .filter((item) => !!item)
            .map((item) => t(item))
            .join(", ");

    const titleParams = !!titleString ? t('h1filters', {Country: postCity, Filters: titleString}) : postTitle;
    const mobileTitleParams = !!titleString ? t('h1filters', {Filters: titleString, Country: ''}) : postTitleMobile;


    useEffect(() => {
        if (isShortFilterShow) {
            // add district if selected
            const resultUrl = `${baseUrl}profiles/count?region=${currentCity.slug}`;

            const searchValue = searchByName && `&search=${searchByName}`;

            const paramsSortedByStatusValue =
                sortedStatusValue.length && (!isFilterSelected)
                    ? "&" + sortedStatusValue.map((item) => item + "=1").join("&")
                    : statusFilterValue.length > 0 && isFilterSelected
                        ? "&" + statusFilterValue.map((item) => item + "=1").join("&")
                        : "";

            const paramsSortedByGenderValue =
                sortedGenderValue.length && (!isFilterSelected)
                    ? "&gender=" + sortedGenderValue.join(",")
                    : genderFilterValue.length > 0 && isFilterSelected
                        ? "&gender=" + genderFilterValue.join(",")
                        : "";
            let additionalParams = "";
            if (sortedByGenderOrStatus.includes("anal")) {
                additionalParams += "&2=1";
            }
            if (sortedByGenderOrStatus.includes("asian")) {
                additionalParams += "&ethnos=asian";
            }

            const params = `?${paramsSortedByStatusValue}${paramsSortedByGenderValue}${additionalParams}${
                valueResultFilter.length > 0 ? valueResultFilter : ""
            }${searchValue}`;

             //console.log('Count request URL:', resultUrl + params);

            axios
                .request({
                    headers: {
                        Authorization: token ? `Bearer ${token}` : "",
                    },
                    method: "GET",
                    url: resultUrl + params,
                })
                .catch((error) => {
                    console.error('error: ', error);
                });
        }
    }, [
        searchByName,
        sortedStatusValue,
        isShortFilterShow,
        sortedGenderValue,
        sortedByGenderOrStatus,
        statusFilterValue,
        valueResultFilter,
    ]);
    const navigate = useNavigate();

    const skeletons = [...Array(12)].map((_, index) => <Skeleton key={index}/>);

    const [showSceletons, setShowSceletons] = useState(false)


    const checkboxNavTemplate = ({i}) => (
        <CheckboxNavigation
            key={i.id}
            id={i.id}
            checked={i.checked}
            title={i.title}
            path={i.path}
            value={i.value}
            name={i.name}
            filter_disabled={i.isLink ? true : i.forDisabled}
            isLink={i.isLink}
            translateKey={i.translateKey}
            important
            agencyId={agencyId}
            clazz={'no-break'}
        />
    );

    const [allSelected, setALlSelected] = useState(false);

    const selectAll = () => {
        const status = !allSelected
        setALlSelected(status)
        if (status) {
            models.forEach(model => {
                dispatch(selectAdd(model.id));
            });
        } else {
            dispatch(selectReset())
        }
    }
    

    
    const [currentActive, setCurrentActive] = useState("");
    const [mobileCityOpen, setMobileCityOpen] = useState(false);
    
    if (isJsdom) {
        dispatch(setIsLoading(true))
    }

    const checkOnMap = async () => {
        try {
            // rewrite maybe for object or don't change to remain without district
            const route = `profiles/count`;
            const method = "GET";
            const payload = {
                region: currentCityParam ?? currentCity.slug,
            }
    
            const data = await makeRequest({ route, method, payload });
            setOnMap(data?.count_on_map ?? 0)
          } catch (error) {
            console.error('error: ', error)
            setOnMap(0)
          }
    }

    useEffect(()=>{
       if(setOnMap && (currentCityParam || currentCity.slug)) {checkOnMap()}
    }, [models, isFilterSelected])


    return (
        <section id={"models"}
            className={!isMainPage && (agencyId || partyId) ? 'agency-filter __container' : (isJsdom ? 'navigation__container ssr' : 'navigation__container')}>
            {!isMainPage && (agencyId || partyId)  ?
            (<>
                <div className="agency-filter__top mb-24">
                    {profiles_statistic?.total && (
                        <h3 className={"mb-0"}>
                            {profiles_statistic?.total} {t("agencymodels")}
                        </h3>
                    )}
                    {checkbox && previewsModels.length > 0 && !(userTypeAgency || userTypeIndi) ? (
                        <a href={"#orderToLook"} className={"hover-line color-green"}>
                            {t('odrderseveral')}
                        </a>
                    ) : null}
                    {changeParty && 
                        <>
                            <h2 className={"mb-0"}>
                                {t("showparticipants")}
                            </h2>
                            <Checkbox
                                id={"agencyFilterAll"}
                                name={"all"}
                                clazzLabel={"p-0"}
                                checked={allSelected}
                                onChange={selectAll}
                                title={`${t("selectall")}`}
                            />
                        </>}
                </div>
            </>) : 
                (!party && 
                   (<>
                        <div className={"navigation__top"}>
                            {isJsdom || windowWidth > 767.98 ? (
                                <>
                                
                                <h1>{!isFilterSelected ? titleParams : t("matching")}</h1>
    
                                <Notifications/>
    
                                {/* <ButtonLink
                                    onClick={() => {
                                        profilesCountValue && dispatch(setStoriesMap(true));
                                    }}
                                    clazz={`button_outline--black`}
                                    size={"s"}
                                    disabled={isDataEmpty || profilesCountValue === 0 || profilesFilterCountValue === 0}
                                >
                                    {t("onmap")} <Icon size={"m"} spritePath={"map"}/>
                                </ButtonLink> */}
                                </>
                            ) : (
                                <>
                                <h1>
                                    
                                    {!isFilterSelected
                                        ? mobileTitleParams + " " : t("matching")}{" "}
                                    {
                                        <span
                                            className="select-city--word"
                                            onClick={() => setMobileCityOpen((prev) => !prev)}
                                        >
                                            {currentCity.title}
                                        </span>
                                    }
                                </h1>
    
                                <SelectCountry
                                    data={countriesData}
                                    name={"city-create"}
                                    clazz={"select-city"}
                                    withoutArrow
                                    type={"flag"}
                                    defaultTitle={""}
                                    currentActiveIndex={currentActive}
                                    isMain={true}
                                    activeCountry={currentCity.icon}
                                    setActive={setCurrentActive}
                                    open={mobileCityOpen}
                                    withoutTitle={true}
                                />
    
                                <div className={"navigation__top-wrapper"}>
                                    <ButtonLink
                                        anchor={true}
                                        onClick={() => {
                                            profilesCountValue && dispatch(setStoriesMap(true));
                                        }}
                                        square={true}
                                        href={"#models-map"}
                                        clazz={`button_outline--black`}
                                        size={"s"}
                                        disabled={profilesCountValue === 0}
                                    >
                                        <Icon spritePath={"map"}/>
                                    </ButtonLink>
    
                                    <Notifications/>
                                </div>
                                </>
                            )}
                        </div>
                    </>))
            }

            <div className="navigation__bottom">
                {party && <h3 className="w-100">{t('participatingmodels')} { profiles_statistic ??  0 }</h3>}
                <nav className={isJsdom ? `navigation__nav ssr` : `navigation__nav`}>
                    {!isJsdom && ShortFilterData().options.map((i) => checkboxNavTemplate({i, onChange}))}
                    {isJsdom && ShortFilterData().options.map((i) => {
                        if ((keysWithValueTrue.length && keysWithValueTrue.includes(i.forDisabled)) || i.value === 'agency') {
                            if (i.value === 'agency') {
                                return (
                                    <a className="hover-line"
                                       href={`/${lang}/agencies`}>
                                        {i.title}
                                    </a>
                                )
                            } else {
                                return (
                                    <a className="hover-line"
                                       href={`/${lang}/${currentCity.slug}/${getFilterValue(i.value)}`}>
                                        {i.title}
                                    </a>
                                )
                            }
                        }
                    })}
                    {!isJsdom ? (
                        <Dropdown
                            title={ShortFilterData().main_title}
                            size={"xs"}
                            hideOnClick={true}
                            clazzWrapper={"navigation__more"}
                        >
                            {ShortFilterData().options.map((i) => checkboxNavTemplate({i}))}
                            {
                                <Button
                                    clazz={"button--s button--green"}
                                    size={"xs"}
                                    onClick={() => {
                                        dispatch(show())
                                    }}
                                >

                                    {t('allfilters')}
                                </Button>
                            }
                        </Dropdown>
                    ) : null}

                    {(windowWidth < 767.98 && !isJsdom) ? (
                        <>
                            <Button
                                clazz={"button--secondary navigation__mobile-filter"}
                                size={"xs"}
                                onClick={() => dispatch(showShort())}
                            >
                                <Icon size={"m"} spritePath={"filter2"}/>
                                {t("smartfilter")}{" "}
                                <Icon size={"s"} spritePath={"chevrone-down"}/>
                            </Button>
                        </>
                    ) : null}
                </nav>

                {!!sortedByGenderOrStatus.length && (
                    <Button
                        onClick={() => {
                            handleReset()
                            navigate('/')
                        }}
                        size={"s"}
                        title={t("reset")}
                        clazz="header__reset hover"
                    >
                        {t("reset")}
                    </Button>
                )}

                {(windowWidth > 767.98 && !isJsdom) && 
                <ButtonLink
                    onClick={() => {
                        profilesCountValue && dispatch(setStoriesMap(true));
                    }}
                    clazz={`button button-icon on-map`}
                    size={"s"}
                    disabled={!(isDataEmpty || profilesCountValue === 0 || profilesFilterCountValue === 0 || onMap > 0)}
                    title={t('h2country')}
                >
                    <Icon size={"m"} spritePath={"map"}/>
                </ButtonLink>}



                {!isJsdom && 
                <Select
                    options={filterNavigation}
                    mainIcon={"sort"}
                    clazzSvg={"icon-s"}
                    clazz={"button--secondary filter-navigation no-outline"}
                    arrowSize={"s"}
                    size={"xs"}
                    sorted={true}
                    name={"sort"}
                ></Select>}
            </div>

            <div className="grid-cards">
                {(!isDataEmpty && models?.length > 0) && models.map((data) => {
                    if (data?.id)
                        return (
                            <GirlCard 
                                props={data} 
                                size={"m"} 
                                key={data.id} 
                                selected={
                                    data?.ready_preview && data?.ready_preview === 1
                                    ? 
                                    selectedAdds.includes(data.id) 
                                    : 
                                    false}
                                checkbox={!!(data?.ready_preview && data.ready_preview === 1) && !mainPage && checkbox}
                                outline={data?.is_individual}
                                />
                             ) 
                       
                })}
                {isLoading && (isMainPage || mainPage) ? <StoriesSlider size={"s"}/> : <></>}
                {isDataEmpty && <h2 className="navigation__empty">{t("notfound")}</h2>}

                {((!isLoading || !models.length) || showSceletons) && !isDataEmpty && skeletons}
            </div>

            {morePage && !isJsdom && (
                <Button
                    type="button"
                    onClick={() => dispatch(setPage())}
                    clazz={"navigation__show-more button--green"}
                    size={"l"}
                >
                    {t("showmore")}
                </Button>

            )}

            {isJsdom && meta && (
                <Pagination
                    pages={meta.total_pages}
                    activePage={meta.current_page}
                />
            )}

            {( (userType === "default" || userType === "customer") && agencyId && checkbox && previewsModels.length > 0) ?
             <OrderPreviewsForm agencyId={agencyId} checkbox={checkbox} preview_price={preview_price}/> : <></>}

        </section>
    );
};

export default Navigation;
