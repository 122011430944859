import React from "react";
import { Toggle } from "@/components/ui";
import { useSelector } from "react-redux";
import showToast from "../../toast/Toast";
import {
  getBookingAmount,
  getUserOrderStatus,
  getUserVideoStatus,
  getVideochatAmount,
  setReadyToOrder,
  setReadyToVideochat,
} from "@/stores/slices/userSlice";
import useLadyService from "@/services/LadyService";
import { makeRequest } from "@/services/makeRequest";

const OrderAndVidChatToggles = () => {
  const { dispatch, t } = useLadyService();
  const videochatAmount = useSelector(getVideochatAmount);
  const bookingAmount = useSelector(getBookingAmount);
  const isVideo = useSelector(getUserVideoStatus);
  const isRes = useSelector(getUserOrderStatus);

  const onChangeVideochat = async () => {
    try {
      dispatch(setReadyToVideochat(Number(!isVideo)));

      const route = `user/set-call-status`;
      const method = "PUT";
      const payload = {
        status: Number(!isVideo),
      };

      await makeRequest({ route, method, payload });

      showToast({
        message: t("success"),
        variant: "success",
      });
    } catch (error) {
      dispatch(setReadyToVideochat(Number(!isVideo)));
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  const onChangeOrder = async () => {
    try {
      dispatch(setReadyToOrder(Number(!isRes)));
      const route = `user/set-booking-status`;
      const method = "PUT";
      const payload = {
        status: Number(!isRes),
      };

      await makeRequest({ route, method, payload });

      showToast({
        message: t("success"),
        variant: "success",
      });
    } catch (error) {
      dispatch(setReadyToOrder(Number(!isRes)));
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  return (
    <>
      <Toggle
        link={"/lk/videochats"}
        title={t("videochat")}
        counter={videochatAmount}
        id={"readyToVideochat"}
        checked={!!isVideo}
        onChange={onChangeVideochat}
      />

      <Toggle
        link={"/lk/reservations"}
        title={t("myorder")}
        counter={bookingAmount}
        id={"readyToOrder"}
        checked={!!isRes}
        onChange={onChangeOrder}
      />
    </>
  );
};

export default OrderAndVidChatToggles;
