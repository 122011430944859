import "./adCard.scss";

import {
  Icon,
  Button,
  Toggle,
  ButtonLink,
  ProgressBar,
  Dropdown,
  Tippy
} from "@/components/ui";
import { UpAd } from "./components";

import {
  getUserOrderStatus,
  getUserVideoStatus,
  setReadyToVideochat,
  setReadyToOrder,
} from "@/stores/slices/userSlice";

import { useSelector } from "react-redux";

import { makeRequest } from "@/services/makeRequest";
import showToast from "@/components/toast/Toast";

import { useEffect, useState } from "react";
import {
  ConfirmDeletePopup,
  DeleteApPopup as DeleteAdPopup,
  LinkWithAgencyPopup,
  LinkWithGirlfriend,
} from "@/components/popups";
import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import useLadyService from "@/services/LadyService";
import GirlCard from "../../../../../cards/girlCard/GirlCard";
import { setRequestVerStatus } from "@/stores/slices/popupSlice";
import {
  getIndiHealthStatus,
  getIndiVerStatus,
} from "@/stores/slices/lkIndiSlice";
import { setFastVerStatus } from "@/stores/slices/popupSlice";
import { getIndiFetchVer } from "@/stores/slices/lkIndiSlice";
import {
  getIndiBoosted,
} from "@/stores/slices/lkIndiSlice";
import ToggleButton from "@/components/ui/toggleButton/ToggleButton";
import Verification from "../../../../../popups/verification/Verification";
import HealthyVerification from "../../../../../popups/healthyVerification/HealthyVerification";

const AdCard = ({
  setAgencySelectCheckbox,
  agencySelectCheckbox,
  data,
  view,
  setData,
  setCount,
  dataInfo,
  verRef,
  setCurrentSlug,
  setCurrentType,
  setCurrentCallback,
  healthyAt,
}) => {
  const {
    slug,
    ready_videochat,
    tag_booking,
    ready_preview,
    is_individual,
    is_enabled: statusAd,
    agency,
    gf,
    completion,
    address,
    current_position,
    is_elite,
    elite_to,
    is_top,
    city_id,
    city,
    name,
    id,
    position_price,
    main_photo,
    promotion_to_at,
    verified_at,
    healthy_at,
    verify,
    verify_media,
    healthy_media,
    healthy,
    admin_statistic,
    statistic,
    is_transferred,
  } = data;

  const {
    lang,
    windowWidth,
    t,
    getDayMonthYear,
    scrollToTop,
    navigate,
    userTypeAgency,
    userTypeIndi
  } = useLadyService();
  


  const [contactShow, setContactShow] = useState(0);
  const [visits, setVisits] = useState(0);
  const [agencyLinked, setAgecyLink] = useState(agency.length !== 0);


  useEffect(() => {
    admin_statistic?.forEach((el) => {
      switch (el.action) {
        case "contact": {
          setContactShow(el.action_count);
          break;
        }
        case "visit": {
          setVisits(el.action_count);
          break;
        }
        default: {
          break;
        }
      }
    });
  }, [admin_statistic]);

  const [verifyStatus, setVerifyStatus] = useState(verify?.status || 0);
  useEffect(() => {
    setVerifyStatus(verify?.status || 0);
  }, [verify?.status]);

  const [verifyMedia, setVerifyMedia] = useState(verify_media);
  useEffect(() => {
    setVerifyMedia(verify_media);
  }, [verify_media]);

  const [healthyMedia, setHealthyMedia] = useState(healthy_media);
  useEffect(() => {
    setHealthyMedia(healthy_media);
  }, [healthy_media]);

  const [boostStatus, setBoostStatus] = useState(verify?.boost || 0);
  useEffect(() => {
    setBoostStatus(verify?.boost || 0);
  }, [verify?.boost]);

  let verStatus = useSelector(getIndiVerStatus);

  const [indi, setIndi] = useState(is_individual);
  useEffect(() => {
    setIndi(is_individual);
  }, [is_individual]);

  const [booking, setBooking] = useState(tag_booking);
  useEffect(() => {
    setBooking(tag_booking);
  }, [tag_booking]);

  const [preview, setPreview] = useState(ready_preview);
  useEffect(() => {
    setPreview(ready_preview);
  }, [ready_preview]);

  const [status, setStatus] = useState(statusAd);
  useEffect(() => {
    setStatus(statusAd);
  }, [statusAd]);
  

  const [call, setCall] = useState(ready_videochat);
  useEffect(() => {
    setCall(ready_videochat);
  }, [ready_videochat]);

  const [isVerActive, setIsVerActive] = useState(verStatus);
  useEffect(() => {
    setIsVerActive(verStatus);
  }, [verStatus]);

  let isHealthActive = useSelector(getIndiHealthStatus);
  if (userTypeAgency) {
    verStatus = verified_at > 0;
    isHealthActive = healthy_media?.verified_at > 0;
  }

  const getHealthStatus = (moderated) => {
    if (moderated === 0) {
      return "wait";
    }
    if (moderated === 1) {
      return "true";
    }
    if (moderated === 2) {
      return "rejected";
    }
    return "false";
  };

  const [healthStatus, setHealthStatus] = useState(
    userTypeAgency && healthy_media.media?.data
      ? getHealthStatus(healthy_media.media?.data.moderated)
      : typeof dataInfo.healthy_photo === "object" &&
        Object.keys(dataInfo.healthy_photo).length > 0
      ? getHealthStatus(dataInfo.healthy_photo?.data.moderated)
      : "false"
  );

  const [elite, setElite] = useState(is_elite);
  const [healthyBoosted, setHealthyBoosted] = useState(healthy?.boost || 0);

  const [isOpenAgency, setIsOpenAgency] = useState(false);
  const [isOpenGirlfriend, setIsOpenGirlfriend] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const [isOpenRemoveGf, setIsOpenRemoveGf] = useState(false);
  const [isOpenRemoveAgency, setIsOpenRemoveAgency] = useState(false);

  const [gfData, setGfData] = useState(gf);

  const toggleButtonOptions = [
    {
      title: t("agencymodel"),
      value: false,
      id: "model",
    },
    { title: t("indireg"), value: true, id: "indireg" },
  ];

  const isVideo = useSelector(getUserVideoStatus);
  const isRes = useSelector(getUserOrderStatus);

  const dispatch = useDispatch();

  let standart;
  let viewCard;

  switch (true) {
    case windowWidth > 1199: {
      standart = view === "standart" ? "" : " small";
      viewCard = view === "standart";

      break;
    }

    default: {
      viewCard = false;
      standart = " small";
    }
  }

  const handleAddAgency = () => {
    setIsOpenAgency(true);
  };

  const handleAddGirlfriend = () => {
    setIsOpenGirlfriend(true);
  };

  const handleOpenDelete = () => {
    if(!is_transferred && userTypeIndi){
      setIsOpenDelete(true);
    }
    else if(userTypeAgency){
      setIsOpenDelete(true);
    }
  };

  const handleOpenDeleteAgency = () => {
    setIsOpenRemoveAgency(true);
  };

  const handleOpenDeleteGf = () => {
    setIsOpenRemoveGf(true);
  };

  const handleRemoveAgency = async () => {
    try {
        const route = `user/profile/${slug}/remove-agency`;
        const method = "DELETE";
        await makeRequest({route, method});
        showToast({
            message: t("success"),
            variant: "success",
        });
        setAgecyLink(false)
        setIsOpenRemoveAgency(false);
    } catch (error) {
        showToast({
            message: t("oops"),
            variant: "error",
        });
    }
  };

  const handleRemoveGirlfriend = async () => {
    try {
      const route = `user/profile/${slug}/remove-friend`;

      const method = "DELETE";

      const payload = { friend: gfData.slug };

      await makeRequest({ route, method, payload });

      showToast({
        message: t("success"),
        variant: "success",
      });
      setGfData(false);
      setIsOpenRemoveGf(false);
    } catch (error) {
      console.log('error: ', error);
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  const onChangeCall = async () => {
    try {
      const route = `user/profile/${slug}/set-call-status`;
      const method = "PUT";
      const payload = {
        status: !call,
      };
      setCall((prev) => !prev);
      await makeRequest({ route, method, payload });

      showToast({
        message: t("success"),
        variant: "success",
      });
    } catch (error) {
      setCall((prev) => !prev);
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  const changeIndiStatus = async (status) => {
    try {
      const route = `user/profile/${slug}/set-indi-status`;
      const method = "PUT";
      const payload = {
        status,
      };
      setIndi(status);
      await makeRequest({ route, method, payload });

      showToast({
        message: t("success"),
        variant: "success",
      });
    } catch (error) {
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  const onChangeBook = async () => {
    try {
      const route = `user/profile/${slug}/set-booking-status`;
      const method = "PUT";
      const payload = {
        status: !booking,
      };
      setBooking((prev) => !prev);
      await makeRequest({ route, method, payload });

      showToast({
        message: t("success"),
        variant: "success",
      });
    } catch (error) {
      setBooking((prev) => !prev);
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };
  const onChangePreview = async () => {
    try {
      const route = `user/profile/${slug}/set-preview-status`;
      const method = "PUT";
      const payload = {
        status: !preview,
      };
      setPreview((prev) => !prev);
      await makeRequest({ route, method, payload });

      showToast({
        message: t("success"),
        variant: "success",
      });
    } catch (error) {
      setPreview((prev) => !prev);
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  const onChangeStatus = async () => {
    if (main_photo) {
      try {
        const route = `user/profile/${slug}/set-status`;
        const method = "PUT";
        const payload = {
          status: !status,
        };
        setStatus((prev) => !prev);
        setIsOpenDelete(false);
        await makeRequest({ route, method, payload });

        showToast({
          message: `${t("ad")} ${name} ${
            !status ? t("activated") : t("deactivated")
          }`,
          variant: "success",
        });
      } catch (error) {
        setStatus((prev) => !prev);
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    } else {
      showToast({
        message: t("foractivate"),
        variant: "error",
      });
    }
  };

  const onChangeVideochat = async () => {
    try {
      dispatch(setReadyToVideochat(Number(!isVideo)));

      const route = `user/set-call-status`;
      const method = "PUT";
      const payload = {
        status: Number(!isVideo),
      };

      await makeRequest({ route, method, payload });

      showToast({
        message: t("success"),
        variant: "success",
      });
    } catch (error) {
      dispatch(setReadyToVideochat(Number(!isVideo)));
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  const onChangeOrder = async () => {
    try {
      dispatch(setReadyToOrder(Number(!isRes)));
      const route = `user/set-booking-status`;
      const method = "PUT";
      const payload = {
        status: Number(!isRes),
      };

      await makeRequest({ route, method, payload });

      showToast({
        message: t("success"),
        variant: "success",
      });
    } catch (error) {
      dispatch(setReadyToVideochat(Number(!isRes)));
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  const handleDuplicate = async (e) => {
    try {
      const route = `user/profile/${slug}/clone`;
      const method = "POST";

      const { status, profile } = await makeRequest({ route, method });
      if (status) {
        navigate(`/${lang}/lk/profile/${profile.slug}/edit`);
        scrollToTop();
        showToast({
          message: t("success"),
          variant: "success",
        });
      }
    } catch (error) {
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  const [isOpenVerifyForAgency, setIsOpenVerifyForAgency] = useState(false);
  const [isOpenHealthyForAgency, setIsOpenHealthyForAgency] = useState(false);


  const activeRequestVer = useSelector(getIndiFetchVer);
  const activeBoosted = useSelector(getIndiBoosted);

  const VerificationBlock = () => {
    const errorStatus = verifyStatus === 3;

    const verifyRequest = () => {
      setCurrentSlug(slug);

      if (isVerActive || userTypeAgency) {
        if (verifyStatus === 1 || activeRequestVer.includes(slug)) {
          dispatch(setFastVerStatus(true));
          setCurrentType("verification");
          setCurrentSlug(slug);
        }
        if (
          (!verifyStatus || errorStatus) &&
          !activeRequestVer.includes(slug)
        ) {
          if (userTypeAgency) {
            setIsOpenVerifyForAgency(true);
          } else {
            dispatch(setRequestVerStatus(true));
          }
        }
      } else if (errorStatus) {
        dispatch(setRequestVerStatus(true));
      } else {
        if (userTypeAgency) {
          setIsOpenVerifyForAgency(true);
        } else {
          verRef.current.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    };

    const handleVer = ( ) => {
      if (!userTypeAgency) {
        navigate("#verificate_health")
        if (verRef.current) {
            verRef.current.scrollIntoView({behavior: "smooth", block: "start"});
        }
    } else {
      setIsOpenVerifyForAgency(true)
    }
    }

    return (
      <div
        className={`d-flex gap-8 align-center ${
          (!status || (is_transferred && userTypeIndi)) ? "disabled-global" : ""
        }`}
      >
        <div className="d-flex fd-column">
            <Link
              onClick={handleVer}
            >
              <Icon
                spritePath={"verify-fill"}
                size={"l"}
                clazz={`${
                  verifyStatus === 2
                    ? "_green"
                    : verifyStatus === 1 ||
                      activeRequestVer.includes(slug) ||
                      activeBoosted.includes(slug)
                    ? ""
                    : "_red"
                }`}
              />
              <span className="p color-700">{t("verification")}</span>
            </Link>
          

          {!verifyStatus && !activeRequestVer.includes(slug) && (
            <span className="p2 color-red-700">{t("notact")}</span>
          )}

          {((verifyStatus === 1 && boostStatus === 0) ||
            activeRequestVer.includes(slug)) &&
            !activeBoosted.includes(slug) && (
              <span className="p2">{t("moderated24hour") + ` ~24 ` + t("hourfew")}</span>
            )}

          {((verifyStatus === 1 && boostStatus === 1) ||
            activeBoosted.includes(slug)) && (
            <span className="p2">{t("fastedmoder") + ` ~1 ` + t("hour")}</span>
          )}

          {verifyStatus === 2 && (
            <span className="p2">{t("activefrom") + ": " + getDayMonthYear(verified_at)}</span>
          )}

          {errorStatus && !activeRequestVer.includes(slug) && (
            <>
              <span className="p2 color-red-700">{t("declinedbymoderator")}{verify?.reason && 
                <>:<br/><span className="p2">{verify?.reason}</span></>}
              </span>
            </>
          )}
        </div>

        {(verifyStatus !== 2 &&
          boostStatus !== 1 &&
          !activeBoosted.includes(slug)) ||
        errorStatus ? (
          <Button
            disabled={!status || (is_transferred && userTypeIndi)}
            onClick={() => {
              verifyRequest();
            }}
            size={"s"}
            clazz={`button_outline--green-accent ml-auto ${
              (!status || (is_transferred && userTypeIndi)) ? "_disabled" : ""
            }`}
          >
            {isVerActive &&
              (!verifyStatus || errorStatus) &&
              !activeRequestVer.includes(slug) &&
              t("request ")}

            {isVerActive &&
              (verifyStatus === 1 || activeRequestVer.includes(slug)) &&
              t("fasttrack")}

            {!isVerActive && !verifyStatus && t("idphotoupload")}
            {!isVerActive && verifyStatus === 3 && t("idphotoupload")}
            {!isVerActive && verifyStatus === 1 && t("fastmoderated")}
          </Button>
        ) : null}
      </div>
    );
  };

  const [selectedGirl, setSelectedGirl] = useState({
    name: "",
    slug: "",
    status: ""
  })

  const handleConfirmFriend = async ( ) => {
    try {
      const route = `user/profile/${slug}/add-friend`;
      const method = "POST";
      const payload = {
        profiles: [gfData.id],
      };

      await makeRequest({ route, method, payload });

      showToast({
        message: t("success"),
        variant: "success",
      });
      setGfData({name: gfData.name, id: gfData.id, slug: gfData.slug, status: 1})
    } catch (error) {
      console.log('error: ', error);
      showToast({
        message: t("oops"),
        variant: "error",
      });
  }
}

  const handleDeclineFriend = ( ) =>{
    handleRemoveGirlfriend()
  }


  const HealthButton = () => {
    const healthyRequest = () => {
      if (!isHealthActive) {
        if (userTypeAgency) {
          setIsOpenHealthyForAgency(true);
        } else {
          verRef.current.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
      if (healthStatus !== "rejected") {
        if (isHealthActive) {
          dispatch(setFastVerStatus(true));
          setCurrentType("healthy");
          setCurrentSlug(slug);
          setCurrentCallback(() => {
            setHealthyBoosted(true);
          });
        }
        if (dataInfo.healthy_photo) {
          if (healthStatus === "wait" && !!verified_at) {
            dispatch(setFastVerStatus(true));
            setCurrentType("healthy");
            setCurrentSlug(slug);
            setCurrentCallback(() => {
              setHealthyBoosted(true);
            });
          }
        }
      } else {
        if (userTypeAgency) {
          setIsOpenHealthyForAgency(true);
        } else {
          verRef.current.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    };

    return (
      <div
        className={`d-flex gap-8 align-center ${
          (!status || (is_transferred && userTypeIndi)) ? "disabled-global" : ""
        } `}
      >
        
        <div className="d-flex fd-column">
          <Link
            onClick={() => {
              setIsOpenHealthyForAgency(true);
            }}
          >
            <Icon
              spritePath={"health-fill"}
              size={"s"}
              clazz={`${
                healthStatus === "true" && verified_at
                  ? "_green"
                  : (healthStatus === "wait" || isHealthActive) &&
                    healthStatus !== "rejected"
                  ? ""
                  : "_red"
              }`}
            />
            <span className="p color-700">{t("healthy")}</span>
          </Link>
          {(healthStatus === "false" || !verified_at) && !isHealthActive && (
            <span className="p2 color-red-700">{t("notact")}</span>
          )}
          {(healthStatus === "wait" || isHealthActive) &&
            !healthyBoosted &&
            healthStatus !== "true" &&
            healthStatus !== "rejected" && (
              <span className="p2">
                {t("moderated24hour") + ` ~ 24 ` + t("hourfew")}
              </span>
            )}
          {healthStatus === "wait" && healthyBoosted ? (
            <span className="p2">{t("fastedmoder") + ` ~ 1 ` + t("hour")}</span>
          ) : null}

          {healthStatus === "rejected" && (
            <>
              <span className="p2 color-red-700">{t("declinedbymoderator")}</span>
              {/* <span>{dataInfo.healthy_reason}</span> */}
            </>
          )}
          {healthStatus === "true" && verified_at ? (
            <span className="p2">{t("activefrom") + ": " + getDayMonthYear(healthyAt)}</span>
          ) : null}
          {healthStatus === "true" && !verified_at && (
            <span className="p2">{t("notvalidfor")}</span>
          )}
        </div>
        {healthStatus === "rejected" ||
        (healthStatus !== "true" && !healthyBoosted) ? (
          <Button
            onClick={() => {
              healthyRequest();
            }}
            size={"s"}
            clazz={`button_outline--green-accent ml-auto ${
              (!status || (is_transferred && userTypeIndi)) ? "_disabled" : ""
            }`}
          >
            {!isHealthActive && !isVerActive && t("confrimid")}
            {!isHealthActive && isVerActive && t("requestanalysis")}
            {isHealthActive &&
              (!healthyBoosted || healthyBoosted) &&
              healthStatus !== "rejected" &&
              t("fasttrack")}
            {healthStatus === "rejected" && t("requestanalysis")}
          </Button>
        ) : null}
      </div>
    );
  };

  const [profileInfo, setProfileInfo] = useState("");



  return (
    <>
      <div className={`adcard-main${standart}`}>
        <GirlCard
          agencySelectCheckbox={agencySelectCheckbox}
          setAgencySelectCheckbox={setAgencySelectCheckbox}
          targetBlank
          props={data}
          big={!!viewCard}
          status={status}
          edit
        />

        <div className={`adcard-main__root${standart}`}>
          <div className="adcard-main__header">
            <div className={"panel"}>
              <ProgressBar percentage={completion} />
              <ButtonLink
                target={"_blank"}
                href={`/${lang}/profile/${slug}`}
                clazz={"button--secondary button-icon"}
                square={true}
                size={"xs"}
                title={t("seead")}
                disabled={!statusAd}
              >
                <Icon size={"xs"} spritePath={"eye"} />
              </ButtonLink>
              <ButtonLink
                href={`/${lang}/lk/create/${slug}`}
                clazz={"button--secondary button-icon"}
                square={true}
                size={"xs"}
                title={t("edit") + " " + t("bysteps")}
                disabled={is_transferred && userTypeIndi}
              >
                <Icon size={"xs"} spritePath={"edit-2"} />
              </ButtonLink>
              <Button
                clazz={"button--secondary button-icon"}
                square={true}
                size={"xs"}
                title={t("duplicate")}
                onClick={handleDuplicate}
              >
                <Icon size={"xs"} spritePath={"copy"} />
              </Button>
            </div>

            <div className={`toggle-switch defaulttitle words`}>
              <span className={`p2 color-main`}>{""}</span>
              <Toggle id={'toggle-enable-ad-'+ id} onChange={onChangeStatus} checked={status} disabled={(is_transferred && userTypeIndi)}/>
            </div>
          </div>

          <div className={`adcard-main__toogle`}>
            <div className="">
              <Toggle
                id={'toggle-enable-call-'+ id}
                clazz={userTypeAgency ? "fd-column" : ""}
                titleDefault={t("videochat")}
                onChange={onChangeCall}
                checked={call}
                disabled={!status || !isVideo || (is_transferred && userTypeIndi)}
              />

              {!isVideo && (
                <div className="d-flex fd-column gap-4 mt-8">
                  <span className={"color-700 fz-13"}>{t("globalblock")}</span>
                  <Button
                    onClick={onChangeVideochat}
                    size={"s"}
                    clazz={`button_outline--black`}
                  >
                    {t("on")}
                  </Button>
                </div>
              )}
            </div>
            <div className="vertical-line"></div>

            <div className="">
              <Toggle
                id={'toggle-enable-book-'+ id}
                clazz={userTypeAgency ? "fd-column" : ""}
                titleDefault={t("onlineorder")}
                onChange={onChangeBook}
                checked={booking}
                disabled={!status || !isRes || (is_transferred && userTypeIndi)}
              />

              {!isRes && (
                <div className="d-flex fd-column gap-4 mt-8">
                  <span className={"color-700 fz-13"}>{t("globalblock")}</span>
                  <Button
                    onClick={onChangeOrder}
                    clazz={`button_outline--black`}
                    size={"s"}
                  >
                    {t("on")}
                  </Button>
                </div>
              )}
            </div>
            {userTypeAgency && (
              <>
                <div className="vertical-line"></div>

                <div className="">
                  <Toggle
                    id={'toggle-enable-prev-'+ id}
                    clazz={"fd-column"}
                    titleDefault={t("preview")}
                    onChange={onChangePreview}
                    checked={preview}
                    disabled={!status || !isRes ||( is_transferred && userTypeIndi)}
                  />

                  {!isRes && (
                    <div className="d-flex fd-column gap-4 mt-8">
                      <span className={"color-700 fz-13"}>
                        {t("globalblock")}
                      </span>
                      <Button
                        onClick={onChangePreview}
                        clazz={`button_outline--black`}
                        size={"s"}
                      >
                        {t("on")}
                      </Button>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <UpAd
            status={status && !(is_transferred && userTypeIndi)}
            address={address}
            current_position={current_position}
            is_elite={elite}
            elite_to={elite_to}
            is_top={is_top}
            city={city}
            slug={slug}
            name={name}
            city_id={city_id}
            setElite={setElite}
            id={id}
            main_photo={main_photo}
            position_price={position_price}
            promotion_to_at={promotion_to_at}
            setData={setData}
          />

          {/* statistics */}

          <Dropdown
            clazzWrapper={'align-self-center w-100'}
            title={`${t("mystat")} - 30 ${t("days")}`}
            accordion={true}
            clazz={'button--secondary'}
          >
            <div className="info-block-wrapper adCard">
              <div className="statistic-numbers-container__block">
                <Tippy arrow={false} title={t(`tclick`)}>
                  <div className="statistic-numbers-container__item1">
                    <div>
                        <Icon size={"s"} spritePath={"arrow-up-right"} />
                  </div>
                  <span className="">{visits ?? 0}</span>
                </div>
                </Tippy>
                <Tippy arrow={false} title={t("incomes")}>
                <div className="statistic-numbers-container__updown up">
                  <div>
                      <Icon
                        size={"s"}
                        spritePath={"cash-up"}
                        clazz={"_green mr-8"}
                      />
                  </div>

                  <span className="color-green currency--erocoin">
                    +{statistic?.income ?? 0}
                  </span>
                </div>
                </Tippy>
                
              </div>

              <div className="statistic-numbers-container__block">
                <Tippy arrow={false} title={t('tcontatct')}>
                  <div className="statistic-numbers-container__item1">
                  <div>
                   
                      <Icon size={"s"} spritePath={"call"} />
                     
                  </div>
                  <span>{contactShow ?? 0}</span>
                  </div>
                </Tippy>
                <Tippy arrow={false} title={t('expences')}>
                <div className="statistic-numbers-container__updown down">
                  <div>

                      <Icon
                        size={"s"}
                        spritePath={"cash-down"}
                        clazz={"_red mr-8"}
                      />

                    {/* <span className="mr-4">{t("expences")}</span> */}
                  </div>

                  <span className="color-red-700 currency--erocoin numbers">
                    -{statistic?.outcome ?? 0}
                  </span>
                </div>
                </Tippy>
              </div>
            </div>
            <a
              className="color-green hover-line text-center"
              href={`/${lang}/lk/statistic/${slug}/${name}`}
              target="__blank"
            >
              {`${t("i-detailedstat")}`}
            </a>
          </Dropdown>

          <VerificationBlock />

          <HealthButton />
          {userTypeAgency && (
            <ToggleButton
              options={toggleButtonOptions}
              handleChange={changeIndiStatus}
              defaultValue={indi}
            />
          )}

          <hr />

          {!userTypeAgency && (
            <div
              className={`adcard-main__link ${(!status)? "not_active" : ""} `}
            >
              <span className="d-flex fd-column align-start color-700">
                {agencyLinked
                  ? t("managedbyagency")
                  : t("connectagency")}{" "}
                {agencyLinked && (
                  <Link
                    to={`/${lang}/agency/${agency.id ?? agencyLinked.id}`}
                    className="green text-right"
                  >
                    {agency.name ?? agencyLinked.name}
                  </Link>
                )}
              </span>
              <Button
                size={"s"}
                clazz={"button_outline--green-accent"}
                disabled={!status}
                onClick={
                  agencyLinked ? handleOpenDeleteAgency : handleAddAgency
                }
              >
                {agencyLinked ? t("disconnect") : t("addad")}
              </Button>
            </div>
          )}

          <>
           
            {gfData?.status !== 0 ? <div className={`adcard-main__link ${(!status || (is_transferred && userTypeIndi)) ? "not_active" : ""}`}>
              <span className="color-700">
                {!!gfData || profileInfo ? t("connectedgf") : t("connectgf")}{" "}
                {!!gfData && (
                  <Link
                    target={"_blank"}
                    to={`/profile/${gfData.slug}`}
                    className="green text-right"
                  >
                    {gfData.name}
                  </Link>
                )}
                {!!profileInfo && (
                  <Link
                    target={"_blank"}
                    to={`/profile/${profileInfo.slug}`}
                    className="green text-right"
                  >
                    {profileInfo.name}
                  </Link>
                )}
                {gfData && (
                  <>
                    <br></br>
                    <span className="color-500">
                      {gfData.status === 1
                        ? t("publishedono")
                        : t("moderated24hour")}
                    </span>
                  </>
                )}
                {profileInfo && (
                  <>
                    <br></br>
                    <span className="color-500">
                      {profileInfo.status === 1
                        ? t("publishedono")
                        : t("moderated24hour")}
                    </span>
                  </>
                )}
              </span>
              <Button
                size={"s"}
                clazz={"button_outline--green-accent"}
                disabled={!status || (is_transferred && userTypeIndi)}
                onClick={
                  !!gfData || gfData?.status || profileInfo
                    ? handleOpenDeleteGf
                    : handleAddGirlfriend
                }
              >
                {!!gfData || gfData?.status || profileInfo ? t("disconnect") : t("addad")}
              </Button>
            </div> : null}

            {gfData?.status === 0 ?
            <div className="adcard-main__link">
              <span className="color-700">
                <span>{t("connectgf")} </span>
                {gfData ? <span>
                  
                  <Link
                    target={"_blank"}
                    to={`/profile/${gfData.slug}`}
                    className="green text-right"
                  >
                    {gfData.name}
                  </Link>? 
                </span>: null}
              </span>
              <div className="d-flex gap-8">
                <Button
                  size={"s"}
                  clazz={"button_outline--green-accent"}
                  onClick={
                    handleConfirmFriend
                  }
                >
                  {t("confirm")}
                </Button>
                <Button
                  size={'s'}
                  clazz={"button--secondary"}
                  onClick={
                    handleDeclineFriend
                  }
                >
                  {t("cancel")}
                </Button>
              </div >
            </div>: null}
          </>
          <div className="flex-fit-content gap-8">
            <Button
              type="button"
              size="s"
              clazz={"button--error"}
              onClick={handleOpenDelete}
              disabled={is_transferred && userTypeIndi}
            >
              {t("deletead")}
            </Button>
            {agencyLinked && is_transferred && userTypeAgency ? (
              <Button
                type="button"
                size="s"
                clazz={"button button--secondary"}
                onClick={() => setIsOpenRemoveAgency(true)}
              >
                {t("disconnectfroma")}
              </Button>
            ) : null}
          </div>
        </div>
      </div>

      {isOpenAgency && (
        <LinkWithAgencyPopup
          agencyLinked = {agencyLinked}
          setLinked = {setAgecyLink}
          open={isOpenAgency}
          setOpen={setIsOpenAgency}
          slug={slug}
        />
      )}

      {isOpenGirlfriend && (
        <LinkWithGirlfriend
          open={isOpenGirlfriend}
          setOpen={setIsOpenGirlfriend}
          slug={slug}
          city_id={city_id}
          setProfileInfo={setProfileInfo}
        />
      )}

      {isOpenDelete && (
        <DeleteAdPopup
          open={isOpenDelete}
          setOpen={setIsOpenDelete}
          profileSlug={slug}
          setData={setData}
          setCount={setCount}
          status={status || (is_transferred && userTypeIndi)}
          onChangeStatus={onChangeStatus}
        />
      )}

      {isOpenRemoveGf && (
        <ConfirmDeletePopup
          onSubmit={handleRemoveGirlfriend}
          open={isOpenRemoveGf}
          setOpen={setIsOpenRemoveGf}
        />
      )}

      {isOpenRemoveAgency && (
        <ConfirmDeletePopup
          onSubmit={handleRemoveAgency}
          open={isOpenRemoveAgency}
          setOpen={setIsOpenRemoveAgency}
        />
      )}

      {isOpenVerifyForAgency && (
        <Verification
          open={isOpenVerifyForAgency}
          setOpen={setIsOpenVerifyForAgency}
          setVerifyStatus={setVerifyStatus}
          verifyStatus={verifyStatus}
          verifyMedia={verifyMedia}
          setVerifyMedia={setVerifyMedia}
          isBoosted={boostStatus}
          setIsBoosted={setBoostStatus}
          setCurrentType={setCurrentType}
          setCurrentSlug={setCurrentSlug}
          setCurrentCallback={setCurrentCallback}
          verified_at={verified_at}
          verify={verify}
          slug={slug}
        />
      )}

      {isOpenHealthyForAgency && (
        <HealthyVerification
          open={isOpenHealthyForAgency}
          setOpen={setIsOpenHealthyForAgency}
          setCurrentType={setCurrentType}
          setCurrentSlug={setCurrentSlug}
          setCurrentCallback={setCurrentCallback}
          healthy_at={healthy_at}
          verified_at={verified_at}
          healthy={healthy}
          verify={verify}
          verifyMedia={verifyMedia}
          setVerifyMedia={setVerifyMedia}
          healthyMedia={healthyMedia}
          setHealthyMedia={setHealthyMedia}
          verifyStatus={isHealthActive}
          setSuperVerify={setIsVerActive}
          setHealthyStatus={setHealthStatus}
          healthyBoosted={healthyBoosted}
          setHealthyBoosted={setHealthyBoosted}
          slug={slug}
        />
      )}
    </>
  );
};

export default AdCard;
