import "./button.scss";

const Button = (props) => {
  const {
    children,
    size,
    clazz,
    buttonType = "button",
    title,
    ref,
    square = false,
    onClick,
    disabled = false,
    counter,
    requiredThrow = false,
    fooThrow,
    wrapperClazz
  } = props;

  let squareClass = `${square ? "button-square" : "button"}`
  let sizeClass = `${size ? square ? ` button-square-${size}` : ` button-${size}` : ""}`
  let classNames = `${squareClass}${sizeClass}${clazz ? ` ${clazz}` : ""}${disabled ? " _disabled" : ""}${counter < 1 ? " empty" : ""}`

  const defaultProps = {
    disabled: disabled,
    "data-counter": counter,
    title: title,
    type: buttonType,
    onClick: onClick,
    ref: ref,
    className: classNames,
  };

  return !requiredThrow ? (
    <button
      {...defaultProps}
    >
      {children}
    </button>
  ) : (
    <div onClick={fooThrow} className={`${wrapperClazz ? ` ${wrapperClazz}` : ""}`}>
      <button
        {...defaultProps}
      >
        {children}
      </button>
    </div>
  );
};

export default Button;
