import { Helmet } from "react-helmet";
import { Button, Icon } from "@/components/ui";
import { useSelector } from "react-redux";
import { getAfterAuth, setAfterAuth } from "../../../stores/slices/popupSlice";
import useLadyService from "@/services/LadyService";
import { useTranslation } from "react-i18next";
import {ButtonLink} from "../../ui";
import {Popup} from "../index";
import { getBookingAmount, getPreviewsAmount, getUserBookingBlock, getUserTickets } from "@/stores/slices/userSlice";

const AfterAuth = () => {
  const { dispatch, lang, userType, navigate, userBalance } = useLadyService();

  const { t } = useTranslation("translation");

  const afterAuth = useSelector(getAfterAuth);

  let link = "";
  switch (userType) {
    case "indi":
    case "agency":
    case "admin":
    case "moderator":
      link = `/lk/profiles`;
      break;

    case "customer":
      link = `/lk/customer`;
      break;
    default:
      link = `/`;
  }

  const linkByAdminAndModerator = userType === 'admin' || userType === 'moderator' ? 'indi' : userType

  const userTickets = useSelector(getUserTickets);
  const userPreviews = useSelector(getPreviewsAmount);
  const userOrders = useSelector(getBookingAmount)


  const onClose = () => {
    dispatch(setAfterAuth(false));
  };

  const stayOnPage =() => {
    window.location.reload();
    dispatch(setAfterAuth(false));
  }

  const handleRedirect = () => {
    navigate(`/${lang}/lk`);  
    dispatch(setAfterAuth(false));
  }

  const toLk = () => {
    navigate(`/lk/${linkByAdminAndModerator}`);
    onClose()
  };

  let wallet = {
    name: t("balance"),
    link: "/lk/balance",
    wallet: userBalance,
    icon: "wallet",
  }

  let support = {
    icon: "operator",
    name: t("support"),
    link: "/lk/support",
    counter: userTickets > 0 ? userTickets : null,
  }

  let instruction = {
    icon: "instruction-cont",
    name: t("guide"),
    link: `/guide`,
  }

  let photographer = {
    icon: "camera",
    name: t("photographer"),
    link: `/${lang}/lk/photograph`,
  }

  let videochat = {
    icon: "video",
    name: t("videochat"),
    link: `/lk/videochats`,
  }

  let profiles = {
    icon: "user-id",
    name: t("myads"),
    link: `/lk/profiles`,
  }

  let orders = {
    icon: "pay-content",
    name: t("myorder"),
    link: `/lk/reservations`,
    counter: userOrders > 0 ? userOrders : null,
  }

  let reviews = {
    icon: "star",
    name: t("reviews"),
    link: `/lk/reviews`,
  }

  let statistic = {
    icon: "stat",
    name: t("mystat"),
    link: `/lk/statistic`,
  }

  const headerIcons = {
      indi: [
        {
          icon: "person",
          name: t("profuser"),
          clazz: "authorised_user",
          onClick: () => toLk(),
        },
        profiles,
        videochat,
        orders,
        reviews,
        statistic,
        wallet,
        support,
        photographer,
        instruction,
      ],
      admin: [
        profiles,
        wallet,
      ],
      agency: [
        {
          icon: "person",
          name: t("profuser"),
          clazz: "authorised_user",
          onClick: () => toLk(),
        },
        videochat,
        orders,
        {
          icon: "casting",
          name: t("preview"),
          counter: userPreviews > 0 ? userPreviews : null,
          link: "lk/previews",
        },
  
        profiles,
        reviews,
        {
          icon: "operator",
          name: t("myoperator"),
          link: `/${lang}/lk/operators`,
          counter: userTickets > 0 ? userTickets : null,
        },
        {
          icon: "party",
          name: t("myparty"),
          link: "lk/parties",
        },
        statistic,
        wallet,
        photographer,
        instruction,
      ],
      customer_photographer: [
        {
          icon: "person",
          name: t("profuser"),
          clazz: "authorised_user",
          onClick: () => toLk(),
        },
        {
          icon: "filter2",
          name: t("myfilters"),
          link: `/lk/${linkByAdminAndModerator}/subscriptions`,
        },
        {
          icon: "time",
          name: t("history"),
          link: `/history`,
        },
  
        wallet,
  
        instruction,
  
        support,
      ],
    };

  

  let buttonTemplate = ({ i, index }) => {
    let propses = {
      title: i.name,
      size: "l",
      href: i.link,
      onClick: i.onClick ? i.onClick : () => onClose(),
      disabled: i.disabled,
      clazz: `menu__link${i.clazz ? ` ${i.clazz}` : ""}`,
    };

    let propsesIcon = {
      counter: i.wallet ? null : i.counter,
      size: "xl",
      clazz: i.clazz,
      spritePath: i.icon,
    };

    if (i.link) {
      return (
        <ButtonLink key={index + 1} {...propses}>
          {i.icon === 'wallet' ? (
            <span className={"menu__wallet currency--erocoin"}>{i.wallet}</span>
          ) : (
            <Icon {...propsesIcon} />
          )}

          <span>{i.name}</span>
        </ButtonLink>
      );
    } else {
      return (
        <Button key={index + 1} {...propses}>
          <Icon {...propsesIcon} />
          <span>{i.name}</span>
        </Button>
      );
    }
  };

  const iconsByUserType = () => {
    switch (userType) {
      case "indi":
        return headerIcons.indi

      case "admin":
      case "moderator":
        return headerIcons.admin

      case 'agency':
        return headerIcons.agency
      case 'default':
        return headerIcons.default
      default:
        return headerIcons.customer_photographer
    }
  };

  return (
    <>
      <Popup
        open={!!afterAuth}
        setOpen={onClose}
        id={"afterAuth"}
        wrapperClazz="popup-form"
        method={"POST"}
        clazz={'gap-16'}
      >

          <h2 className="mb-0">{t("successauth")}</h2>

          <div className="fd-column gap-16 justify-sb align-center">
            <Button
              size={"l"}
              clazz={"button--green w-100"}
              onClick={() => stayOnPage()}
            >
              {t("stayonweb")}
            </Button>
            
            {/* <span>{t('or')}</span>
            
            <ButtonLink
              size={"l"}

              href={link}
              clazz={" button--secondary w-100"}
              onClick={handleRedirect}
            >
              {t("toprofile")}
            </ButtonLink> */}

            <hr className="w-100"/>

            <div className="w-100">
              <p className={'title_h2 color-green text-center mb-12'}>
                {t("lk")}
              </p>

              <div className={"users__nav"}>
                {iconsByUserType().map((i, index) => buttonTemplate({ i, index }))}
              </div>
            </div>
          </div>
      </Popup>
    </>
  );
};

export default AfterAuth;
