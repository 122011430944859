import {Helmet} from "react-helmet";
import {Button, Icon} from "@/components/ui";
import {makeRequest} from "@/services/makeRequest";
import showToast from "../../toast/Toast";
import useLadyService from "@/services/LadyService";
import {Popup} from "../index";
import {getActivateWhatsappStatus, setActivateWhatsappStatus} from "../../../stores/slices/popupSlice";
import {useSelector} from "react-redux";

const ActivateWa = (props) => {
  const {setStatus, status} = props;

  const { t, dispatch} = useLadyService();
  const open = useSelector(getActivateWhatsappStatus)
  const handleClose = () => {
    dispatch(setActivateWhatsappStatus(false))
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const fetchData = async () => {
      try {
        const route = `user/set-profiles-status`;
        const method = "PUT";
        const payload = {
          status: !status,
        };

        await makeRequest({route, method, payload});

        setStatus((prev) => !prev);

        handleClose()

        showToast({
          message: t("success"),
          variant: "success",
        });
      } catch (error) {
        setStatus((prev) => !prev);
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };
  };

  return (
    <Popup
      open={open}
      setOpen={handleClose}
      container={'div'}
      id={'activateWa'}
    >
      <h2>{t("activatee") + " " + t('notifications').toLowerCase() + " WhatsApp"}</h2>

      <div className="popup-form__inner mb-16 gap-8">
        <p className={"p2 text-center color-main"}>
          {t('metafee')}
        </p>
      </div>

      <h3 className="color-main text-center ">
        {t("pricever")}{" "}
        <span className="currency--erocoin">20</span>
        <span>{`/${t('month')}`}</span>
      </h3>

      <div className="fd-column gap-8">
        <Button
          size={"l"}
          clazz={"button--secondary w-100"}
          onClick={handleClose}
        >
          {t("back")}
        </Button>

        <Button
          size={"l"}
          onClick={handleClose}
          clazz={"button--green w-100"}
        >
          {t("activatee")}
        </Button>
      </div>
    </Popup>
  );
};

export default ActivateWa;
