import React from "react";
import useLadyService from "@/services/LadyService";
import { ButtonLink, Icon } from "@/components/ui";
import { OrderAndVidChatToggles } from "./index";
import { useSelector } from "react-redux";
import { getBookingAmount, getPreviewsAmount, getVideochatAmount } from "../../../stores/slices/userSlice";

let TogglesOrLinks = () => {
  const { t, userType } = useLadyService();
  const previewsCount = useSelector(getPreviewsAmount);
  const bookingsCount = useSelector(getBookingAmount);
  const videochatsCount = useSelector(getVideochatAmount);

  return userType === 'agency' ? (
    <>
      <ButtonLink
        href={"/lk/videochats"}
        title={t("videochat")}
        size={"xs"}
        clazz={"button_outline--black _no-underline"}
        counter={videochatsCount}
      >
        <Icon size={"m"} spritePath={"video"} />
        {t("videochat")}
      </ButtonLink>

      <ButtonLink
        href={"lk/reservations"}
        title={t("myorder")}
        size={"xs"}
        clazz={"button_outline--black _no-underline"}
        counter={bookingsCount}
      >
        <Icon size={"m"} spritePath={"pay-content"} />
        {t("myorder")}
      </ButtonLink>

      <ButtonLink
        href={"lk/previews"}
        title={t("preview")}
        size={"xs"}
        clazz={"button_outline--black _no-underline"}
        counter={previewsCount}
      >
        <Icon size={"m"} spritePath={"casting"} />
        {t("preview")}
      </ButtonLink>
    </>
  ) : (
    <OrderAndVidChatToggles />
  )
};

export default TogglesOrLinks