import { useSelector } from "react-redux";
import { Button, Icon } from "@/components/ui";
import useLadyService from "@/services/LadyService";
import {
  getRequestVerStatus,
  setRequestVerStatus,
} from "@/stores/slices/popupSlice";
import showToast from "../../toast/Toast";
import { makeRequest } from "@/services/makeRequest";
import { setFetchVer } from "@/stores/slices/lkIndiSlice";
import {Popup} from "../index";

const RequestVerPopup = ({slug, onFetch = false}) => {
  const { dispatch, t, lang } = useLadyService();
  const openStatus = useSelector(getRequestVerStatus);

  const fetchVerification = () => {
    const fetchData = async () => {
      try {
        const route = `user/profile/${slug}/verify`;
        const method = "POST";
        dispatch(setFetchVer(slug));
        const { data = {} } = await makeRequest({ route, method });
        if(data) {
          if(onFetch && openStatus) {
            onFetch()
          }
          dispatch(setRequestVerStatus(false));
          
        }
      } catch (error) {
        showToast({
        message: t("oops"),
        variant: "error",
      });
      }
    };

    lang && fetchData();
  };

  const handleClose = () =>{
    dispatch(setRequestVerStatus(false))
  }
  return (
    <Popup
      open={!!openStatus}
      setOpen={handleClose}
      id={"requestStatus"}
    >
        <h2>{t("requestver")}</h2>
        <div className="popup-form__inner">
          <span
            title={t("photosverified")}
            className={"model__icon width-fit ml-auto mr-auto mb-8"}
          >
            <Icon spritePath={"verify-fill"} size={"l"} />
            {t("verification")}
          </span>
        </div>

        <div className="popup-form__inner mb-16 gap-4">
          <span className={"p2 text-center color-main"}>
            {t("verstatusget")}
          </span>
          <span className="text-center color-green">{t("verstatusinfo")}</span>
        </div>
        <span className="color-red-700">{t("important") + "!"}</span>
        <p className="color-main">{t("verstatusimpornant")}</p>
        <div className="d-flex justify-sb gap-16 mt-16">
          <Button
            size={"l"}
            clazz={"button--secondary w-100"}
            onClick={() => {
              dispatch(setRequestVerStatus(false));
            }}
          >
            {t("cancel")}
          </Button>
          <Button
            size={"l"}
            clazz={"button--green w-100"}
            onClick={() => fetchVerification()}
          >
            {" "}
            {t("request ")}
          </Button>
        </div>
    </Popup>
  );
};
export default RequestVerPopup;
