import { useEffect, useRef, useState } from "react";
import useLadyService from "@/services/LadyService";

import { rulesRu } from "./locales/ru";
import { rulesEn } from "./locales/en";
import { rulesCz } from "./locales/cz";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import useTitle from "../../../hooks/useTitle";
import {userDeclarationEN} from "../userDeclaration/locales/en";
import {userDeclarationCZ} from "../userDeclaration/locales/cz";
import {userDeclarationRU} from "../userDeclaration/locales/ru";

const RulesPage = () => {
  const { lang, t, scrollToHash } = useLadyService();
  const { hash } = useLocation();


  useEffect(() => {
    scrollToHash();
  }, []);

  const defaultTranslate = !lang || lang === 'en' ? rulesEn : lang === 'cz' ? rulesCz : lang === 'ru' ? rulesRu : rulesEn

  const [translate, setTranslate] = useState(defaultTranslate);

  useEffect(() => {
    switch (lang) {
      case "ru":
        return setTranslate(rulesRu);

      case "cz":
        return setTranslate(rulesCz);

      default:
        return setTranslate(rulesEn);
    }
  }, [lang]);
  useTitle(`${t("rulestitle")} Lady4Love.com`)
  return (
    <>
      <Helmet>
        <meta name="description" content={t("rulesdesc")} />
      </Helmet>
      <main>
        <section className="other-page__container">
          <h1>{translate.h1}</h1>

          <h2>1. {translate.one.title}</h2>
          <p>1.1. {translate.one.p1}</p>
          <p>1.2. {translate.one.p2}</p>
          <ul>
            {translate.one.list.map((i, index) => (
              <li key={index}>{i}</li>
            ))}
          </ul>
          <p>1.3. {translate.one.p3}</p>
          <p>1.4. {translate.one.p4}</p>
          <p>1.5. {translate.one.p5}</p>
          <p>1.6. {translate.one.p6}</p>
          <p>1.7. {translate.one.p7}</p>
          <p>1.8. {translate.one.p8}</p>

          <h2>2. {translate.two.title}</h2>
          <p>2.1. {translate.two.p1}</p>
          <p>2.2. {translate.two.p2}</p>
          <p>2.3. {translate.two.p3}</p>
          <p>2.4. {translate.two.p4}</p>
          <p>2.5. {translate.two.p5}</p>
          <p>2.6. {translate.two.p6}</p>
          <p>2.7. {translate.two.p7}</p>
          <p>2.8. {translate.two.p8}</p>
          <p>2.9. {translate.two.p9}</p>
          <p>2.10. {translate.two.p10}</p>
          <p>2.11. {translate.two.p11}</p>
          <p>2.12. {translate.two.p12}</p>
          <p>2.13. {translate.two.p13}</p>
          <p>2.14. {translate.two.p14}</p>

          <h2 id={"payments"}>3. {translate.three.title}</h2>
          <p>3.1. {translate.three.p1}</p>
          <p>3.2. {translate.three.p2}</p>
          <p>3.3. {translate.three.p3}</p>
          <p>3.4. {translate.three.p4}</p>
          <p>3.5. {translate.three.p8}</p>
          <p>3.6. {translate.three.p5}</p>
          <p>3.7. {translate.three.p6}</p>
          <p>3.8. {translate.three.p7}</p>

          <h2>4. {translate.four.title}</h2>
          <p>4.1. {translate.four.p1}</p>
          <p>4.2. {translate.four.p2}</p>
          <p>4.3. {translate.four.p3}</p>
          <p>4.4. {translate.four.p4}</p>
          <p>4.5. {translate.four.p5}</p>
          <p>4.6. {translate.four.p6}</p>
          <p>4.7. {translate.four.p7}</p>

          <h2>5. {translate.five.title}</h2>
          <p>5.1. {translate.five.p1}</p>
          <p>5.2. {translate.five.p2}</p>
          <p>5.3. {translate.five.p3}</p>
          <p>5.4. {translate.five.p4}</p>

          <h2>6. {translate.six.title}</h2>
          <p>6.1. {translate.six.p1}</p>
          <p>6.2. {translate.six.p2}</p>
        </section>
      </main>
    </>
  );
};

export default RulesPage;
