import { Link, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import useLadyService from "@/services/LadyService";
import { selectActiveTabId, setActiveTabId } from "@/stores/slices/tabSlice";
import { useSelector } from "react-redux";
import { TopLinksTabsObj } from "./index";

const HeaderTabs = ({ lk }) => {
  const { userType, dispatch } = useLadyService();

  const activeTabId = useSelector(selectActiveTabId);
  const { pathname } = useLocation();

  let tabs = {
    photograph: TopLinksTabsObj().photograph,
    indi: TopLinksTabsObj().indi_lk,
    admin: TopLinksTabsObj().admin_lk,
    moderator: TopLinksTabsObj().admin_lk,
    agency: TopLinksTabsObj().agency,
    customer: TopLinksTabsObj().customer,
  }

  useEffect(() => {
    const activeTab = tabs[userType].find((tab) => {
      const newUrl = tab.href.replace(/.*\/(lk)/, "$1");
      const newPathname = pathname.replace(/.*\/(lk)/, "$1");

      return newUrl === newPathname;
    });

    dispatch(setActiveTabId(activeTab?.id));

  }, [pathname, userType]);

  let userTypeLinks = () => {
    switch (userType) {
      case "photograph": {
        return TopLinksTabsObj().photograph;
      }

      case "indi": {
        return lk ? TopLinksTabsObj().indi_lk : TopLinksTabsObj().indi;
      }

      case "admin":
      case "moderator": {
        return TopLinksTabsObj().admin_lk;
      }

      case "agency": {
        return lk ? TopLinksTabsObj().lk : TopLinksTabsObj().agency;
      }

      default:
        return TopLinksTabsObj().customer;
    }
  };

  return userTypeLinks().map((i) => {
    return (
      <Link
        data-counter={i.count}
        key={i.id}
        className={`header__tab ${i.count < 1 ? " empty" : ""} ${activeTabId === i.id ? "_active" : ""
          }`}
        to={`${i.href}`}
        onClick={() => dispatch(setActiveTabId((i.id)))}
      >
        {i.name}
        {i.wallet || i.wallet === 0 ? (
          <span className="color-green currency--erocoin">{i.wallet}</span>
        ) : null}
      </Link>
    );
  });
};

export default HeaderTabs