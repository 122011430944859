import useLadyService from "@/services/LadyService";
import { Button, Icon } from "@/components/ui";
import { Link } from "react-router-dom";
import AttentionBox from "../components/AttentionBox/AttentionBox";
import topLadyLight from "@/assets/img/status/top-light.svg";
import topLady from "@/assets/img/status/top.svg";
import eliteLady from "@/assets/img/status/elite.svg";
import GuideTags from "../components/tags/GuideTags";
import verifyPicture from "@/assets/img/examplePhotos/verExample.svg";
import unilabs from "@/assets/img/unilabs-origin.svg";
import healtyPicture from "@/assets/img/examplePhotos/verExamplePlus2.svg";
import howToEarnImg from "@/assets/img/guide/indi/how-to-earn.webp";
import orderFromSiteImg from "@/assets/img/guide/indi/order-from-site.webp";
import deactivateProfileImg from "@/assets/img/guide/indi/deactivate-profile.webp";
import editImg from "@/assets/img/guide/agency/indiAdEdit.webp";
import agencyImg from "@/assets/img/guide/indi/agency.webp";
import GuideAside from "../components/aside/GuideAside";
import EliteStatusCards from "../../../eliteStatusCards/EliteStatusCards";
import "../guidePage.scss";
import React, { useEffect, useState, useRef } from "react";
import { DiscountToHealthPopup } from "../../../popups";
import {Helmet} from "react-helmet";
import useSearchHighlight from "@/hooks/useSearchHighlightText";

const GuideIndi = ({ scrolledClass, show, setShow, setShowSearch, showSearch }) => {
  const { lang, theme, city, t, navigate, scrollToHash, windowWidth } = useLadyService();

  const [isOpenDiscount, setIsOpenDiscount] = useState(false);

  useEffect(() => {
    scrollToHash()
  }, []);

  const handleOpenDiscount = () => {
    setIsOpenDiscount(true);
  };

  const IconTemplate = (translateKey, icon) => (
    <Icon
      title={t(translateKey)}
      square
      clazz={"color-green"}
      spritePath={icon}
      size={"l"}
    />
  );
  

  const [searchTerm, setSearchTerm] = useState('');
  const textRef = useRef(null);
  const [originalContent, setOriginalContent] = useState('');

  useEffect(() => {
    if (textRef.current) {
      setOriginalContent(textRef.current.innerHTML);
    }
  }, [textRef]);

  useEffect(() => {
    if (textRef.current) {
      textRef.current.innerHTML = originalContent;
  
      if (searchTerm !== '') {
        const regex = new RegExp(searchTerm, 'gi');
  
        function replaceTextInNodes(element) {
          if (!element) return;
  
          Array.from(element.childNodes).forEach(node => {
            if (node.nodeType === Node.TEXT_NODE) {
              const newText = node.textContent.replace(regex, (match) => `<mark>${match}</mark>`);
  
              const tempElement = document.createElement('template');
              tempElement.innerHTML = newText;
  
              Array.from(tempElement.content.childNodes).forEach(newNode => {
                node.parentNode.insertBefore(newNode, node);
              });
  
              node.remove();
            } else if (node.nodeType === Node.ELEMENT_NODE) {
              replaceTextInNodes(node);
            }
          });
        }
  
        replaceTextInNodes(textRef.current);
      }
      else{
        textRef.current.innerHTML = originalContent;
      }
    }
  }, [searchTerm, originalContent]);

  const [count, setCount] = useState(0)
  

  const handleScrollToSearch = () =>{
    const matches = textRef.current.querySelectorAll('mark');
    if (matches){
      if(count < matches.length){
        const target = matches[count];
        target.scrollIntoView({ behavior: 'smooth'});
        setCount((prev)=>prev+ 1)
      }
      else{
        setCount(0)
      }
    }

  }

  return (
    <>
    <main className="guide-page">
      <Helmet>
        <meta
            name="description"
            content={t("guidedesc")}
        />
      </Helmet>
      <section className="guide__container">
        <h1 className={"mb-24 mb-16-mob"}>{t("i-h1indi")}</h1>

        <Button
          clazz={`button--tetriary guide__button-heading${scrolledClass}`}
          onClick={() => setShow((prev) => !prev)}
        >
          <Icon size={"l"} spritePath={"instruction-cont"} />
          {t("i-contents")}
        </Button>

        <Button
          size={"xs"}
          clazz={`search__icon guide__button-heading${scrolledClass}`}
          onClick={()=>{setShowSearch((prev) => !prev)}}
        >
            <Icon size={"m"} spritePath={"search"} />
        </Button>

        <p>
          {t("i-greetings")}{" "}
          <Link to={"/"} className={"green"}>
            Lady4Love
          </Link>{" "}
          {t("i-greetingstext")}.
        </p>

        <div className="guide__body">
          
          <GuideAside
            props={{
              show,
              setShow,
              scrolledClass,
              searchTerm,
              setSearchTerm,
              handleScrollToSearch,
              setShowSearch, 
              showSearch
            }}
          />

          <div ref={textRef} className="guide__content">
            {/*<HowToEffective/>*/}
            <h2>{t("i-maxeffective")}</h2>

            <h3 id={"maxViews"}>{t("i-offermaxviews")}</h3>
            <p>
              {t("i-createfewads")}. <b>{t("i-postadsfree")}</b>{" "}
              {t("i-werecommend")}
            </p>

            <AttentionBox type={"important"}>
              {t("i-truthfulinfo")}.
            </AttentionBox>

            <p>{t("i-filloutthead")}.</p>

            <p className={"guide__box d-block"}>
              *{t("i-importfromsites")}:
              <Link
                target={"_blank"}
                className={"green"}
                to={`//eurogirlsescort.com`}
              >
                {" "}
                eurogirlsescort.com
              </Link>
              {`, `}
              <Link target={"_blank"} className={"green"} to={`//banging.cz`}>
                banging.cz
              </Link>
              {`, `}
              <Link target={"_blank"} className={"green"} to={`//escorts.cz`}>
                escorts.cz
              </Link>
              {`, `}
              <Link
                target={"_blank"}
                className={"green"}
                to={`//secretgirlprague.com`}
              >
                secretgirlprague.com
              </Link>
              {`, `}
              <Link
                target={"_blank"}
                className={"green"}
                to={`/dobryprivat.cz`}
              >
                dobryprivat.cz
              </Link>
            </p>

            <AttentionBox tag={"div"} type={"advice"}>
              <ul>
                <li>{t("i-completedads")}</li>
                <li>{t("i-includedetails")}.</li>
                <li>{t("i-loadbeatyphoto")}.</li>
                <li>{t("i-ifhaveshortvideo")}.</li>
                <li>{t("i-sexualdescribe")}.</li>
                <li>{t("i-fillmessengers")}.</li>
                <li>{t("i-pinninglocation")}.</li>
              </ul>
            </AttentionBox>

            <p className={"guide__box d-block"}>
              {t("i-contactmoderator")}{" "}
              <Link to={"#"} className={"green d-inline"}>
                {t("i-moderator")}
              </Link>
              .
            </p>

            <h3 id="mediaContent">
              {t("i-mediacontent")}: {t("i-contentmedia")}
            </h3>

            <p>{t("i-photosandvideos")}.</p>

            <AttentionBox tag={"div"} type={"advice"}>
              <ul>
                <li>
                  {t("i-uploadero")} <a href={"#eroContent"}>{t("moreinfo")}</a>
                  .
                </li>
                <li>
                  {t("i-photoeditstart")}{" "}
                  <a href={"#videochat"}>{t("videochat")}</a>{" "}
                  {t("i-photoeditend")}.
                </li>
                <li>
                  {t("i-portfolio")}{" "}
                  <Link to={`/photogapher`} className={"green"}>
                    {t("i-ourphotograph")}
                  </Link>
                  .
                </li>

                <li>{t("i-storiesrelease")}</li>
              </ul>
            </AttentionBox>

            <AttentionBox type={"important"}>
              {t("pesonalresp")}{" "}
              <Link to={"/rules"} target={"_blank"} className={"green"}>
                {t("portalules")}
              </Link>{" "}
              {t("notsanction")}.
              <br />
              <br />{" "}
              <span title={t("photosverified")} className={"model__icon"}>
                <Icon spritePath={"verify-fill"} size={"l"} />
                {t("verification")}
              </span>{" "}
              {t("and")}{" "}
              <span title={t("advantagehealth")} className={"model__icon"}>
                <Icon spritePath={"health-fill"} size={"l"} />
                {t("healthy")}
              </span>{" "}
              – {t("toget")}.
            </AttentionBox>

            <h3 id={"questionnairePosition"}>{t("i-positionpromo")}</h3>
            <p>
              {t("i-sortedprofiles")}.
            </p>
            <div className="guide__flexbox">
              <p>
                {t("i-sortedprofiles2")}{" "}
              <Link to={"/lk/profiles"}>{t("myadsall")}</Link>.
              <br/>
              <br/>
              {` ${t("i-yourposition")} TopLady.`}
              </p>
              <div className="guide__status">
                <img
                  src={theme === "light" ? topLadyLight : topLady}
                  alt="Top Lady"
                />
              </div>
            </div>

            <AttentionBox tag={"div"} type={"advice"}>
              <ul>
                <li>
                  {t("a-aktivate")}.
                </li>
                <li>
                  {t("i-lookatpositions")}{" "}
                  <Link
                    to={"/lk/profiles"}
                  >
                    {t("i-yourcards")}
                  </Link>
                  , {t("i-beingtop")}.
                </li>
                <li>
                  {t("i-promotion")}{" "}
                  <Link to={"/lk/statistic"}>{t("i-detailstat")}</Link>.
                </li>
              </ul>
            </AttentionBox>

            <h3 id="statusElite">{t("Status")} Elite Lady</h3>
            <div className={"guide__flexbox"}>
              <p>
                {t("i-elitetext")}.
                <br/>
                <br/>
                Elite Lady {t("i-elitedescription")}{" "}
                <Link
                  target={"_blank"}
                  to={`/${lang}/${city}/elitelady`}
                  className={"green"}
                >
                  https://lady4love.com/elitelady
                </Link>
                , {t("i-vipclients")}.
              </p>
              <Link
                target={"_blank"}
                to={`/${city}/elitelady`}
                className="guide__status"
              >
                <img src={eliteLady} alt="Elite Lady" />
              </Link>
            </div>

            <EliteStatusCards />

            <h3 id="statuses">
              {t("i-statusesobtain")}:
            </h3>

            <GuideTags />

            <h3 id="verify">
              {IconTemplate("verified1", "verify-fill")}
              {t("verification")}
            </h3>

            <p>
              {t("verification")} – {t("i-verifytext")}.
            </p>

            <div className="guide__box guide__box--verify">
              <div>
                <span className={"color-600"}>{t("procedure")}:</span>
                <p>{t("i-verifyprocess")}.</p>
                <b>{t("i-photoadmin")}</b>
                <p>{t("i-moderrequest")}.</p>
                <p>
                  {t("i-altmethod")} – <b>{t("i-moderatorcall")}</b> (
                  {t("i-payfunction")}).
                </p>
              </div>

              <div className="guide__box-img">
                <span className={"p2"}>{t("example")}</span>
                <img src={verifyPicture} alt="" />
              </div>
            </div>

            <h3 id="healthControl">
              {IconTemplate("healthy", "health-fill")}
              {t("healthy")}
            </h3>

            <p>
              {t("healthy")} – {t("i-healthytext")}.
            </p>

            <div className="guide__box guide__box--verify">
              <div>
                <span className={"color-600"}>{t("procedure")}:</span>
                <p>{t("i-verifyperson")}.</p>
                <b>{t("i-photoadmin")}</b>
                <p>{t("i-testresults")}.</p>
              </div>

              <div className="guide__box-img">
                <span className={"p2"}>{t("example")}</span>
                <img src={healtyPicture} alt="" />
              </div>
            </div>
            <div
              onClick={handleOpenDiscount}
              className="guide__box-img guide__unilabs gap-0"
            >
              <div className={"d-flex gap-10 align-center"}>
                <img src={unilabs} alt="" />
                <span>-20%</span>
              </div>
              <p className={"p3"}>{t("i-discountprice")}</p>
              <Button
                title={t("qrcodediscount")}
                clazz={"button--primary justify-center mt-16"}
                size={"s"}
              >
                {t("qrcodediscount")}
              </Button>
            </div>
            <h3 id="specials">{t("i-otherfeatures")}:</h3>
            <p>
              <b>{t("services")}</b> – {t("i-servicestext")}.
            </p>
            <p>
              <b>{t("i-welcoming")}</b> – {t("i-welcomingtext")}.
            </p>
            <p>
              <b>{t("i-tariffsettings")}</b> – {t("i-tariffsettingstext")}.
            </p>
            <p>
              <b>{t("i-workdiscount")}</b> – {t("i-workdiscounttext")}.
            </p>

            {/*//////////////////////////////////////////////////////////////////////////*/}

            <h2 id="howToEarn">{t("i-howearmoney")}</h2>

            <p>{t("i-freepost")}.</p>

            <p>
              {t("i-onfunction")}{" "}
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/${lang}/lk/videochats`);
                }}
                to={"/lk/videochats"}
                target={"_blank"}
              >
                «{t("videochat")}»
              </Link>{" "}
              {t("and")}{" "}
              <Link to={"/lk/reservations"} target={"_blank"}>
                «{t("onlineorder")}»
              </Link>{" "}
              - {t("i-readytoorder")}.
            </p>

            <img
              className={"img-contain b-radius-24"}
              src={howToEarnImg}
              alt=""
            />

            <h3 id="eroCoins">
              {IconTemplate("EroCoins", "erocoin")}
              EroCoins {t("and")} {t("i-payonportal")}
            </h3>

            <p>
              {t("i-cointext1")} <b>EroCoin</b>. {t("i-cointext2")}.
            </p>
            <p>{t("i-coinconvert")}.</p>

            <p>{t("i-coinsell")}.</p>

            <p>{t("i-coindeposit")}:</p>

            <ul>
              <li>{t("i-banktransit")} (IBAN).</li>
              <li>{t("i-bankcard")}.</li>
              <li>PayPal</li>
              <li>{t("i-crypto")}.</li>
            </ul>

            <p>{t("i-withdraw")}.</p>

            <AttentionBox type={"important"}>{t("i-profit")}.</AttentionBox>

            <h3 id="videochat">
              {IconTemplate("videochat", "video")}

              {t("videochat")}
            </h3>
            <p>
              <b>{t("videochat")}</b> – {t("i-vidchattext")}.
            </p>

            <div className="guide__box _column">
              <span>{t("procedure")}:</span>
              <p>
                {t("i-vchatduration")}{" "}
                <b>
                  {t("i-youget")} EroCoin {t("i-onyourwallet")},
                </b>{" "}
                {t("i-allowsearn")}.
              </p>
            </div>

            <h3 id="onlineOrder">
              {IconTemplate("onlineorder", "pay-content")}

              {t("onlineorder")}
            </h3>

            <p>
              <b>{t("onlineorder")}</b> – {t("i-alloworder")}.
            </p>

            <div className="guide__box _column">
              <span>{t("procedure")}:</span>
              <p>
                {t("i-notification")} <b>{t("i-orderarrow")}</b>{" "}
                {t("i-ordergiarantie")}.
              </p>
            </div>

            <h3 id="eroContent">
              {IconTemplate("erocontent", "pants")}

              {t("erocontent")}
            </h3>
            <p>
              <b>{t("erocontent")}</b> - {t("i-erodownload")}.
            </p>

            <div className="guide__box _column">
              <span>{t("procedure")}:</span>
              <p>
                {t("i-eromedia")}. <b>{t("i-profitacc")}.</b>
              </p>
            </div>

            <AttentionBox type={"important"}>
              {t("i-eroprovit")} <b>{t("i-only")}</b> {t("i-eroprovit2")}.
            </AttentionBox>

            {/*//////////////////////////////////////////////////////////////////////////*/}

            <h2>{t("i-rulesandinfo")}</h2>

            <h3 id="statistic">{t("i-detailstath3")}</h3>
            <p>
              {t("i-viewcount")} <b>{t("i-detailstattext")}.</b>
              <br />
              <br />
              {t("i-graph")}.{" "}
              <Link target={"_blank"} to={"/lk/statistic"}>
                {t("i-gotostat")}
              </Link>
              .
            </p>

            <h3 id="yourContacts">
              Email, WhatsApp {t("and")} Telegram {t("i-inyour")} {t("profile")}
            </h3>
            <p>
              WhatsApp {t("and")} Telegram – {t("i-socialtext")}.
            </p>
            <p>{t("i-hideemail")}.</p>
            <AttentionBox type={"important"}>
              {t("i-phonetext")}{" "}
              <Link to={"/lk/support"} target={"_blank"}>
                {t("i-phonesupport")}
              </Link>{" "}
              {t("i-phonechange")}.
            </AttentionBox>

            {/*//////////////////////////////////////////////////////////////////////////*/}

            <h2 id={"uniqueFeatures"}>{t("i-uniquefeatures")}</h2>

            <h3>{t("i-exportourside")}</h3>
            <p>{t("i-scratch")}.</p>

            <img
              className={"img-contain b-radius-24"}
              src={orderFromSiteImg}
              alt=""
            />


<p className={"guide__box d-block"}>
  *{t("i-importfromsites")}:
  {" "}
  <Link
    target={"_blank"}
    className={"green"}
    to={`/eurogirlsescort.com`}
  >           
  
    eurogirlsescort.com
  </Link>
  {`, `}
  <Link target={"_blank"} className={"green"} to={`//banging.cz`}>
    banging.cz
  </Link>
  {`, `}
  <Link target={"_blank"} className={"green"} to={`//escorts.cz`}>
    escorts.cz
  </Link>
  {`, `}
  <Link
    target={"_blank"}
    className={"green"}
    to={`/secretgirlprague.com`}
  >
    secretgirlprague.com
  </Link>
  {`, `}
  <Link
    target={"_blank"}
    className={"green"}
    to={`/dobryprivat.cz`}
  >
    dobryprivat.cz
  </Link>
</p>

            <h3 className={"mb-16"}>{t("i-changecity")}</h3>
            <p>{t("i-changecitytext")}.</p>
            <AttentionBox type={"important"}>
              {t("i-changecityper")}.
            </AttentionBox>

            <div className="guide__box--friend-agency">
              <div>
                <h3>{t("i-method")}</h3>
                <p>{t("i-methodtext")}.</p>
              </div>

              <img className={"img-contain b-radius-24"} src={editImg} alt="" />

              <div>
                <h3>{t("i-closecounry")}</h3>
                <p>{t("i-closecheck")}.</p>
              </div>
            </div>

            <h3>{t("i-offprofile")}</h3>
            <p>{t("i-offprofiletext")}.</p>
            <img
              className={"img-contain b-radius-24"}
              src={deactivateProfileImg}
              alt=""
            />

            <AttentionBox type={"important"}>
              {t("i-offprofilepromo")}.
            </AttentionBox>

            <h3>{t("crossnumber")}</h3>
            <p>
              {t("i-checknumtext1")}{" "}
              <Link
                to={"//numbuster.com/"}
                target={"_blank"}
                className={"green"}
              >
                Numbuster.
              </Link>{" "}
              {t("i-checknumres")}.
            </p>

            <div className={"guide__box--friend-agency"}>
              <h3>{t("i-agencyget")}</h3>
              <p>{t("i-agencygettext")}.</p>

              <h3>{t("i-gfconnect")}</h3>
              <p>{t("i-gfconnecttext")}.</p>

              <img
                className={"img-contain b-radius-24 "}
                src={agencyImg}
                alt=""
              />
            </div>

            <h3>{t("i-photograps")}</h3>
            <p>
              {t("i-photograpstext")}{" "}
              <Link className={"green"} to={"#"}>
                {t("i-photograpscat")}.
              </Link>
            </p>

            <h3>{t("i-reviews")}</h3>
            <p>
              {t("i-reviewstext")}.{" "}
              <Link to={"/lk/reviews"}>{t("i-gotoreviews")}</Link>.
            </p>

            <h3>{t("i-deleteprofile")}</h3>
            <p>
              {t("i-deleteprofiletext")}.{" "}
              <Link to={"/lk/indi"}>{t("i-deleteprofile")}</Link>.
            </p>

            <AttentionBox type={"important"}>
              {t("i-deletewarning")}.
            </AttentionBox>
          </div>
        </div>
      </section>
    </main>

  {isOpenDiscount &&
  <DiscountToHealthPopup
    open={isOpenDiscount}
    setOpen={setIsOpenDiscount}
  />
  }
  </>
  );
};

export default GuideIndi;
