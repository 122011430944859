import { useSelector } from "react-redux";
import {
  defaultRangeState,
  filterPlace,
  rangeState,
} from "../stores/slices/filterSlice";
import {
  getAgeForModels,
  getAllSorted,
  getPage,
  getPriceForModels,
  getSearchValue,
  getSortedModelsByFilter,
  getTypeSorted,
} from "@/stores/slices/modelsState";
import { getGenderCount } from "@/stores/slices/counterFilterSlice";
const tagsForStatus = [
  "indi",
  "elitelady",
  "pornstar",
  "online",
  "new",
  "vchat",
  "booking",
  "ero",
  "verified",
  "healthy",
  "bdsm",
  "video",
  "escort",
  "travel",
  "gf",
  "incall",
  "anal",
  'asian'
];
const tagsForGender = [
  "about",
  "couple",
  "trance",
  "male",
  "guys",
  "trans",
  "female",
];
const GetProfilesUrl = () => {
  const placeValue = useSelector(filterPlace);
  const searchByName = useSelector(getSearchValue);
  const defaultRangeValue = useSelector(defaultRangeState);
  const ageValue = useSelector(getAgeForModels);
  const priceValue = useSelector(getPriceForModels);
  const rangeValue = useSelector(rangeState);
  const sortedByGenderOrStatus = useSelector(getAllSorted);
  const genderCount = useSelector(getGenderCount);
  const sortedModels = useSelector(getSortedModelsByFilter);
  const paramsPlace =
    placeValue.length > 0 ? `&place=${placeValue.join(",")}` : "";
  const page = useSelector(getPage);
  const sortBy = useSelector(getTypeSorted);

  const searchValue = searchByName && `&search=${searchByName}`;


  // services and nation asian and anal filter
  // const paramsServices = sortedByGenderOrStatus.map((word)=>{
  //   switch (word) {
  //     case 'anal': {
  //       return 'anal=1'
  //     }
  //   }
  // })
  // const paramsNation = sortedByGenderOrStatus.map((word)=>{
  //   switch (word) {
  //     case 'asian':{
  //       return 'asian=1'
  //     }
  //   }
  // })

  const paramsPrice =
    priceValue.min > defaultRangeValue.price.min ||
    priceValue.max < defaultRangeValue.price.max
      ? `=${priceValue.min},${priceValue.max}`
      : "";
  const paramsAge =
    ageValue.min > defaultRangeValue.age.min ||
    ageValue.max < defaultRangeValue.age.max
      ? `=${ageValue.min},${ageValue.max}`
      : "";

  const resultPrice = `${
    (rangeValue.price.min > defaultRangeValue.price.min ||
      rangeValue.price.max < defaultRangeValue.price.max) &&
    rangeValue.price.min !== 0 &&
    rangeValue.price.max !== 0
      ? `&price${paramsPrice}`
      : ""
  }`;

  const resultAge = `${
    (rangeValue.age.min > defaultRangeValue.age.min ||
      rangeValue.age.max < defaultRangeValue.age.max) &&
    rangeValue.age.min !== 0 &&
    rangeValue.age.max !== 0
      ? `&age${paramsAge}`
      : ""
  }`;
  const paramsSortBy = sortBy ? sortBy : "";
  const paramsPage = `page=${page}`;

  const paramsSortedByStatusValue = sortedByGenderOrStatus
    .map((word) => (tagsForStatus.includes(word) ? word : ""))
    .filter((string) => string !== "")
    .map((item) => item + "=1")
    .join("&");
  const paramsSortedByGenderValue = sortedByGenderOrStatus
    .map((word) => {
      switch (word) {
        case "couple":
          return "couple";
        case "trance":
          return "trans";
        case "guys":
          return "male";
        default:
          return word;
      }
    })
    .map((tag) => (tagsForGender.includes(tag) ? tag : ""))
    .filter((item) => item !== "");
  if (genderCount.includes("female")) {
    paramsSortedByGenderValue.push("female");
  }
  
  // add for asian and anal 
  // ${paramsServices ? `&${paramsServices}` : ''}${paramsNation ? `&${paramsNation}` : ''}

  const params = `?${paramsPage}&per_page=24&sort=${paramsSortBy}${resultPrice}${resultAge}${paramsPlace}${
    sortedModels ? `&${sortedModels}` : ""
  }${
    !!paramsSortedByStatusValue.length ? "&" + paramsSortedByStatusValue : ""
  }${
    !!paramsSortedByGenderValue.length
      ? "&gender=" + paramsSortedByGenderValue.join(",")
      : ""}${searchValue}`;  
        
  return {
    params,
  };
};

export default GetProfilesUrl;
