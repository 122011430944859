import { ButtonLink, Icon } from "@/components/ui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useLadyService from "@/services/LadyService";

import "./benefitsBlock.scss";
import { makeRequest } from "@/services/makeRequest";
import { addSpacesEveryThreeDigits } from "@/helper/addSpacesEveryThreeDigits";

const benefitsMap = [
  { spritePath: "diploma-verified", name: "freepostingpromo" },
  { spritePath: "wallet-money", name: "earnchatsandero" },
  { spritePath: "profile-card", name: "severalprofiles" },
  { spritePath: "statistic", name: "detailedstatistics" },
  { spritePath: "photo", name: "photocatalogue" },
  { spritePath: "call-check", name: "freebackcheck" },
];

const BenefitsBlock = ({counters = true, showContent, clazz, statistic = false}) => {
  const { userCity, userTypeCustomer, userTypeAgency, userTypeIndi, userType } = useLadyService();
  

  const cityId = userCity.id;
  const [profiles, setProfiles] = useState(statistic ? statistic.profiles : 0);
  const [verified, setVerified] = useState(statistic ? statistic.verified : 0);
  const [online, setOnline] = useState(statistic ? statistic.online : 0);
  const [visits, setVisits] = useState(statistic ? statistic.visits : 0);

  const { t } = useTranslation("translation");

  const words = t("freeposting").split(" ");
  const lastIndex = words?.length - 1;
  const firstPart = words?.slice(0, lastIndex).join(" ");
  const lastWord = words[lastIndex];
 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = `statistic/${cityId}`;
        const method = "GET";

        const { data = {} } = await makeRequest({ route, method });

        if (!!Object.values(data).length) {
          setProfiles(data.profiles);
          setVerified(data.verified);
          setOnline(data.online);
          setVisits(data.visits);
        }
      } catch (error) {}
    };

    cityId && fetchData();
  }, [cityId]);
 



  
    return (
      <div className={`benefits__container${clazz ? ` ${clazz}` : ''}`}>
        {(showContent && userType === 'default') ? (
          <div className="benefits__content">
            <div className="benefits__star">
              <p>{firstPart}</p>
              <span className={"title"}>{lastWord}</span>
            </div>
            <div className="benefits__inner">
              <h2>{t("registernow")}</h2>

              <p className='p2 mb-32'>{t("citychange")}</p>

              {!userTypeCustomer && (
                <ButtonLink
                  href={`${userTypeIndi || userTypeAgency ? '/lk/profiles' : '/registration#indi'}`}
                  size={"m"}
                  clazz={"button--green"}
                >
                  <Icon size={"l"} spritePath={"plus"} />
                  {t("addprofile")}
                </ButtonLink>
              )}
            </div>

            <div className="benefits__items">
              {benefitsMap.map((item, index) => {
                const { spritePath, name } = item;
                return (
                  <div className="benefits__item" key={index}>
                    <Icon size={"l"} spritePath={spritePath} />
                    <p>{t(name)}</p>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        
        {counters &&
        <div className="benefits__counters">
          <div>
            {t("postedprofiles") + ":"}{" "}
            <span className="benefits__counter--questionnaire-make">
            {profiles}
          </span>
          </div>
          <div>
            {t("verified") + ":"}{" "}
            <span className="benefits__counter--verify">{verified}</span>
          </div>
          <div>
            {t("online") + ":"}{" "}
            <span className="benefits__counter--online">{online}</span>
          </div>
          <div>
            {t("visitorsperday") + ":"}{" "}
            <span className="benefits__counter--visitors">
            {addSpacesEveryThreeDigits(visits)}
          </span>
          </div>
        </div>}
      </div>
    );
};

export default BenefitsBlock;
