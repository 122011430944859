import {Button, Loader} from "../../ui";
import useLadyService from "@/services/LadyService";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import showToast from "../../toast/Toast";
import {makeRequest} from "../../../services/makeRequest";

const OperatorConfirm = () => {
    const {t, navigate} = useLadyService()
    const [isLoading, setIsLoading] = useState(false)
    const [confirmation, setConfirmation] = useState({agency: {}, opeator: {}})
    const {key} = useParams();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await makeRequest({route: `operator-confirm/${key}`});
                if (!data) {
                    navigate("/404");
                }
                if (data) {
                    const result = data.data;
                    setConfirmation(result);
                }
            } catch (error) {
                showToast({
                    message: t("oops"),
                    variant: "error",
                });
            }
            setIsLoading(false)
        };

        key && fetchData();
    }, [key]);
    const handleResponse = async (confirm) => {
        try {
            const data = await makeRequest({route: `operator-confirm/${key}`, method: confirm ? 'POST' : 'DELETE'});
            if (!data) {
                navigate("/404");
            }
            if (data) {
                showToast({
                    message: t("confirmed"),
                    variant: "success",
                });
                navigate('/login')
            }
        } catch (error) {
            console.log('error: ', error)
            showToast({
                message: t("oops"),
                variant: "error",
            });
        }
    }
    
    return isLoading ? <Loader/> : (
        <div className="error-page__container text-center">
            <h1>{t('operatorConfirm', {userName: confirmation?.opeator?.name, agencyName: confirmation?.agency?.name})}</h1>

            <div className="d-flex align-center justify-center gap-12">
                <Button
                  size={"l"}
                  clazz="button--secondary w-160"
                  onClick={() => handleResponse(false)}
                >
                    {t("cancel")}
                </Button>

                <Button
                  size={"l"}
                  onClick={() => handleResponse(true)}
                  buttonType={'button'}
                  clazz={"button--green w-160"}
                >
                    {t("confirm")}
                </Button>
            </div>
        </div>
    );
};

export default OperatorConfirm;
