import useLadyService from "@/services/LadyService";
import { InputInLabel, Icon } from "@/components/ui";
import { useEffect, useMemo, useState } from "react";
import { Select } from "@/components/ui";
import { calculateWithCommission } from "@/helper/calculateWithCommission";

const paymentsMethods = [{ icon: "paypal", name: "PayPal" }];

const InputsBlock = ({
  defaultValue,
  values,
  setValueTo,
  setValueFrom,
  registerFrom,
  registerTo,
  currency,
  setCurrency,
  euroValue,
  setEuroValue,
}) => {
  const windowWidth = window.innerWidth;
  const isMobile = useMemo(() => windowWidth < 767.98, [windowWidth]);

  const { t, resultBalance } = useLadyService();

  const currencyOption = {
    main_title: t("currency"),
    options: [
      { id: 1, title: "EUR", value: "eur" },
      { id: 2, title: "CZK", value: "czk" },
    ],
  };

  const currencyRates = {
    czk: 25,
    eur: 1,
  };

  const [coinValue, setCoinValue] = useState(values[`price-card-coin`]);

  const calculateEuro = (val) => {
    const value = parseFloat(val) || 0;
    const rate = currencyRates[currency] || 1;
    const adjustedValue = value * rate;
    const convertedValue = calculateWithCommission(adjustedValue, -0.01);
    const roundedValue = parseFloat(convertedValue.toFixed(2));

    setValueFrom("price-card-coin", value);
    setValueTo("price-card-euro", roundedValue);
    setEuroValue(roundedValue);
    setCoinValue(value);
  };

  useEffect(() => {
    calculateEuro(coinValue);
    if (!euroValue && currency === "czk") {
      setEuroValue(defaultValue * 25 + defaultValue * 25 * -0.01);
    }
  }, [currency, calculateEuro, euroValue, defaultValue, setEuroValue]);

  return (
    <>
      <fieldset>
        <legend>{t("amountwithdraw")}</legend>
        <InputInLabel
          price={"erocoin"}
          id={"earn-to"}
          type={"number"}
          max={resultBalance}
          placeholder={values[`price-card-coin`] || defaultValue}
          trigger={coinValue}
          register={registerTo}
          onChange={(e) => calculateEuro(e.target.value)}
          clazz={`${isMobile ? "" : "min-w-160"}`}
        >
        </InputInLabel>
      </fieldset>
      <fieldset>
        <legend>{t('currency')}</legend>
        <Select
          onChange={setCurrency}
          options={currencyOption.options}
          clazz={"button button-xs justify-start flex-sh"}
          arrowSize={"s"}
          name={'currency'}
          defaultTitle={
            currencyOption.options.filter((item) => item.value === currency)[0]
              .title
          }
          withoutTitle={true}
          postApi={true}
        />
      </fieldset>
      <Icon size={"m"} spritePath={"arrow-right"} clazz={'mt-10'}/>
      <fieldset className="mr-8">
        <legend>{t("youwillget")}</legend>
        <span
            className={`title_h3 mb-0 ${
            currency === "eur" ? "currency--euro" : "currency--czk"
          }`}
        >
          {euroValue || defaultValue - (defaultValue * 0.01)}
        </span>
      </fieldset>
    </>
  );
};

export const Paypal = ({
  values,
  setValueFrom,
  setValueTo,
  registerTo,
  registerFrom,
  currency,
  setCurrency,
  defaultValue
}) => {
  const { t } = useLadyService();
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 767.98;
  const [euroValue, setEuroValue] = useState(values[`price-card-euro`]);

  return (
    <>
      <div className={`balance-page__header ${isMobile ? " gap-0 mb-16" : ""}`}>
        <h3>{`PayPal ${t('or') + " " + t('linkdecard')}`}</h3>
        <div className="payment-methods">
          {paymentsMethods.map((i, index) => {
            return (
              <div
                className="payment-methods__icon"
                key={index + 1}
                title={i.name}
              >
                <Icon type={"payment"} spritePath={i.icon} />
              </div>
            );
          })}
        </div>
      </div>
      
        <div
          className={`withdraw-inputs`}
        >
          <InputsBlock
            defaultValue={defaultValue}
            values={values}
            setValueTo={setValueTo}
            setValueFrom={setValueFrom}
            registerFrom={registerFrom}
            registerTo={registerTo}
            currency={currency}
            setCurrency={setCurrency}
            euroValue={euroValue}
            setEuroValue={setEuroValue}
          />
        </div>

        {!isMobile && (
          <>
            <span className="w-50 p">
              {t("earingin") + " 1-2 " + t("workdays")}

              <br />
              <p className={"p3 color-green mt-0 mb-0"}>{t("fee") + " PayPal"} - 1%</p>
            </span>
          </>
        )}

      {isMobile && (
        <span className="w-100 mt-8">
          {t("earingin") + " 1-2 " + t("workdays")}
          <br />
          <p className={"color-green"}>{t("fee") + " PayPal"} - 1%</p>
        </span>
      )}
    </>
  );
};
