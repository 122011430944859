import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: [],
  seoTitle: false,
  seoDescription: false,
  seoContent: false,
};

export const titleSlice = createSlice({
  name: "title",
  initialState,
  reducers: {
    setTitle: (state, action) => {
      const value = String(action.payload);
      let result = "";
      switch (value) {
        case "pornstar":
          result = "star";
          break;
        case "elitelady":
          result = "elite";
          break;
        case "vchat":
          result = "videochat";
          break;
        case "booking":
          result = "onlineorder";
          break;
        case "ero":
          result = "erocontent";
          break;
        case "verified":
          result = "verification";
          break;
        case "couple":
          result = "couples";
          break;
        case "male":
          result = "guys";
          break;
        case "trance":
          result = "trans";
          break;
        case "video":
          result = "";
          break;
        case "travel":
          result = "";
          break;
        case "escort":
          result = "";
          break;
        case "anal":
          result = "anal-sex-s";
          break;
        case "asian":
          result = "asiatag";
          break;
        default:
          result = value;
      }
      const isSelected = state.title.includes(result);
      let updatedSelectedCheckboxes = [];
      if (!isSelected) {
        updatedSelectedCheckboxes = [...state.title, result];
      } else {
        updatedSelectedCheckboxes = state.title.filter(
          (item) => item !== result
        );
      }

      state.title = updatedSelectedCheckboxes;
    },

    clearTitle: (state) => {
      state.title = [];
    },

    setTitleArray: (state, action) => {
      state.title = action.payload
    },

    setDefaultTitle: (state, action) => {
      state.title = [];
      if (state.title.length === 0) {
        const replacements = {
          pornstar: "star",
          elitelady: "elite",
          vchat: "videochat",
          booking: "onlineorder",
          ero: "erocontent",
          verified: "verification",
          couple: "couples",
          male: "guys",
          trance: "trans",
          anal: "anal-sex-s",
          asian: "asiatag",
        };
        const result = action.payload.map((item) => replacements[item] || item);

        state.title = result;
      }
    },

    setSeoTitle: (state, action) => {
      state.seoTitle = action.payload;
    },

    setSeoContent: (state, action) => {
      state.seoContent = action.payload;
    },

    setSeoDescription: (state, action) => {
      state.seoDescription = action.payload;
    }
  },
});

export const { setTitle, setTitleArray, setDefaultTitle, clearTitle, setSeoTitle, setSeoDescription, setSeoContent} = titleSlice.actions;

export const getTitle = (state) => state.title.title;
export const getSeoTitle = (state) => state.title.seoTitle;
export const getSeoDescription = (state) => state.title.seoDescription;

export const getSeoContent = (state) => state.title.seoContent

export default titleSlice.reducer;
