import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { Button, Icon, InputInLabel } from "@/components/ui";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import {
  getFilterMiniPopupStatus,
  getFilterPopupStatus,
  getSavedFiltersIds,
  setFilterMiniPopupStatus,
  setSavedFiltersIds,
} from "@/stores/slices/filterPopupSlice";
import {
  setAuthorizationStatus,
  setFilterMode,
} from "@/stores/slices/popupSlice";
import useLadyService from "@/services/LadyService";
import showToast from "../toast/Toast";
import { makeRequest } from "@/services/makeRequest";
import {
  getAllSorted,
  getSortedModelsByFilter,
} from "@/stores/slices/modelsState";
import ReCAPTCHA from "react-google-recaptcha";
import { Checkbox } from "../ui";
import { getGenderCount } from "../../stores/slices/counterFilterSlice";
import {
  allFilter,
  filterAddress,
  filterRadius,
defaultRangeState,
} from "../../stores/slices/filterSlice";

import "./notifications.scss";
import { Legend } from "chart.js";

const SaveFilterForm = ({ fetchData }) => {
  const { dispatch, userType, navigate, t, lang, makeRequest, } = useLadyService();

  const isLogin = userType !== "default";

  const formSchema = object().shape({
    name: string().required(t("required")),
  });


  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const [saveText, setSaveText] = useState(false);

  const onSubmit = async (data) => {
    const result = await fetchData(data, "post");

    if (!!result) {
      setSaveText(true);
      reset();
      dispatch(setSavedFiltersIds(result));
    }
  };

  var lkLink = "";
  switch (userType) {
    case "indi":
      lkLink = `/lk/profiles`;
      break;
    case "photograph":
      lkLink = `/${lang}/lk/photograph`;
      break;
    case "agency":
      lkLink = `/lk/agency`;
      break;
    case "customer":
      lkLink = `/lk/customer/subscriptions`;
      break;
    default:
      lkLink = `/`;
  }

  const toLk = (e) => {
    if (userType === "default") {
      dispatch(setFilterMode(true));
      dispatch(setAuthorizationStatus(true));
    } else {
    }
    e.preventDefault();
    navigate(`${lkLink}`);
  };

  const handleSave = () => {
    !isLogin && dispatch(setFilterMode(true));
    !isLogin && dispatch(setAuthorizationStatus(true));
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="input-label__wrapper notifications__safe-filter"
      >
        <fieldset>
          <legend className={errors.name ? 'input-label__error' : ''}>
            {errors.name ? errors.name.message : t('filtername')}
          </legend>
          <InputInLabel
            clazz={`w-100 ${errors.name ? " _error" : ""}`}
            id={"loginRegistration"}
            type={"text"}
            placeholder={t("filterplaceholder")}
            register={{ ...register("name") }}
            autocomplete={"off"}
            onChange={()=>{}}
          >
          </InputInLabel>
        </fieldset>
        

        <Button
          buttonType={isLogin ? "submit" : "button"}
          clazz={"button-square"}
          onClick={handleSave}
        >
          {" "}
          {t("save")}
        </Button>
      </form>
      {saveText ? (
        <p className={"notifications__text mb-32"}>
          {t("filterissave")} {t("at")}{" "}
          <Link
            className={"green hover-line"}
            to={`/${lang}/lk/${userType}/subscriptions`}
          >
            {t("subandfilters").toLowerCase()}
          </Link>
        </p>
      ) : (
        <p className={"notifications__text mb-32"}>
          {t("savedfiltersaviable")}{" "}
          <Link to={lkLink} onClick={(e) => toLk(e)}>
            {t("profile")}
          </Link>
        </p>
      )}
    </>
  );
};

const Notifications = () => {
  const { dispatch, t, windowWidth, city, userType, emailVerifyAt, userEmail, userTg, userWa } =
    useLadyService();
  const statusFullPopup = useSelector(getFilterPopupStatus);
  const statusMiniPopup = useSelector(getFilterMiniPopupStatus);
  const sortedValue = useSelector(getAllSorted);
  const allStateFilter = useSelector(allFilter);
  const defaultRangeValue = useSelector(defaultRangeState);
  const [hasNonEmptyRange, setHasNonEmptyRange] = useState(false);
  const [hasNonEmptyFilters, setHasNonEmptyFilters] = useState(false);
  
  useEffect(() => {
    checkForEmtyFilters()
  }, [allStateFilter])

  const checkForEmtyFilters = () => {
    const { accordionSelected, range, ...fieldsToCheck } = allStateFilter;

    function allValueIsNull(obj) {
      for (let key in obj) {
        if (obj[key].min !== 0 && obj[key].max !== 0) {
          return true;
        }
      }
      return false;
    }
    if (allValueIsNull(defaultRangeValue) && allValueIsNull(range)) {
      setHasNonEmptyRange(
        range.price.min > defaultRangeValue.price.min ||
        range.price.max < defaultRangeValue.price.max
          ? true
          : range.age.min > defaultRangeValue.age.min ||
          range.age.max < defaultRangeValue.age.max
            ? true
            : range.weight.min > defaultRangeValue.weight.min ||
            range.weight.max < defaultRangeValue.weight.max
              ? true
              : range.height.min > defaultRangeValue.height.min ||
                    range.height.max < defaultRangeValue.height.max
      );
    }
    setHasNonEmptyFilters(Object.values(fieldsToCheck).some((filter) => Array.isArray(filter) && filter.length > 0) || sortedValue.includes('incall'));
  }


  const formSchema = object().shape({
    email: string().email().required(t("emailisnes")),
    text: string(),
  });

  const onSubmitBig = async (data) => {
    try{
      const result = await fetchData(data, "post");

      if (!!result) {
        showToast({
          message: t("success"),
          variant: "success",
        });
        reset();
      }
      else{
        showToast({
          message: t("oops"),
          variant: "error",
        })
      }
    }
    catch(error){
      console.log('error: ', error)
    }
    
  };
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });
  const filterParams = useSelector(getSortedModelsByFilter);
  const filterParamsStatus = useSelector(getAllSorted);

  const sortByStatus = useSelector(getAllSorted);
  const filterRadiusSlice = useSelector(filterRadius);
  const address = useSelector(filterAddress);

  function queryStringToObject(queryString) {
    const params = queryString.split("&");
    const result = {
      city: city,
      ...(genderCount.includes("female") && { gender: "female" }),
      ...(!!filterParamsStatus.length && {
        status: filterParamsStatus.join(", "),
      }),
      ...(!!filterRadiusSlice.value.length && { addressStr: address[0] }),
      originalStr: filterParams,
    };

    params.forEach((param) => {
      const [key, value] = param.split("=");
      result[key] = value;
    });
    if (sortByStatus.length) {
      return { ...result, status: sortByStatus.join(",") };
    } else {
      return result;
    }
  }

  const [email, setEmail] = useState();
  const recaptchaRef = useRef(null);
  const filterState = useSelector(allFilter);

  
  const fetchData = async (data, type = "post", id = false) => {
    try {
      const route = `user/filter${type === "put" ? `/${id}` : ""}`;

      const method = type === "post" ? "POST" : "PUT";
      const payload = {
        filter: queryStringToObject(filterParams),
        filterState: { ...filterState, city },
        title: data.name,
        email: data.email,
      };
      if (data.text?.length) {
        payload.message = data.text;
      }
      const result = await makeRequest({ route, method, payload });
      if (result && type === "post") {
        return result.data.id;
      } else {
        return false;
      }
    } catch (error) {
      console.log('error: ', error);
      showToast({
        message: t("oops"),
        variant: "error",
      });
      return false;
    }
  };
  const genderCount = useSelector(getGenderCount);

  const existSavedFiltersIds = useSelector(getSavedFiltersIds);

  const [messageError, setMessageError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (
      statusFullPopup &&
      statusMiniPopup &&
      windowWidth &&
      windowWidth > 998
    ) {
      setIsActive(true);
    }
  }, [statusFullPopup]);

  

  const handleClick = () => {
    setIsActive(true);
  };

  const [notifyType, setNotifyType] = useState([]);
  const [isEmailConfirm, setIsEmailConfirm] = useState(false);
  const [isWaConfirm, setIsWaConfirm] = useState(false);

  

  const handleNotifyType = (value) => {
    if (value === "email" && !emailVerifyAt) {
      setIsEmailConfirm(true);
      return;
    }
    if (value === "whatsapp") {
      setIsWaConfirm(true);
      return;
    }
    setNotifyType((prev) => {
      if (notifyType.includes(value)) {
        return prev.filter((item) => item !== value);
      } else {
        return [...prev, value];
      }
    });
  };
  return (
    <>
      {(hasNonEmptyFilters || hasNonEmptyRange) && (
        <Button
          onClick={() => handleClick()}
          clazz="button button-cornered notifications__main-button user__save"
        >
          <span>{t("savesub")}</span>
          <Icon size={"m"} spritePath={"save"} />
        </Button>
      )}
      {isActive ? (
        <div
          className={`popup-form__body notifications${
            isActive ? " _show" : ""
          }`}
        >
          <Button
            onClick={() => {
              setIsActive(false);
              dispatch(setFilterMiniPopupStatus(false));
            }}
            clazz={"button__close"}
          >
            <Icon size={"l"} spritePath={"close"} />
          </Button>

          {userType !== "agency" && userType !== "indi" && (
            <>
              <h2 className=" popup-form__title mb-32 mt-0">
                {t("savefilters")}
              </h2>

              <SaveFilterForm
                onSubmitForm={setIsActive}
                fetchData={fetchData}
              />
            </>)}
          

          <form
            className={"notifications__subscribe"}
            onSubmit={handleSubmit(onSubmitBig)}
          >
            <h2 className="popup-form__title mt-0 mb-32 text-center">
              {t("notifyaboutnew")}
            </h2>

            <fieldset className={'mb-20'}>
              <legend>{t("notificationmessage")}</legend>
              <label
                htmlFor="saveFilterText"
                className={`${
                  messageError ? "error" : ""
                } input-label p`}
              >
                <textarea
                  {...register("text")}
                  placeholder={t("wenotifyaboutnew")}
                  id={"saveFilterText"}
                  onChange={() => {
                    if (messageError) {
                      setMessageError(false);
                    }
                  }}
                />
              </label>
            </fieldset>
            
           

            <fieldset className={'mb-20'}>
              <legend>{
                (errors.email && (
                  <span className={"input-label__error"}>
                    {errors.email.message}
                  </span>
                )) || <span>{t("youremail")}</span>}</legend>
            <InputInLabel
              clazz={`${emailError ? " error" : ""} mb-12`}
              id={"subscribeFilterEmail"}
              type={"email"}
              placeholder={"example@google.com"}
              register={{ ...register("email") }}
              autocomplete={"email"}
              value={!!userEmail ? userEmail : ""}
              onChange={() => {
                if (emailError) {
                  setEmailError(false);
                }
              }}
            >
            </InputInLabel>
            </fieldset>
            
            {/* <>
                <span>{t("notify") + ":"}</span>
                <div className="d-flex gap-8 align-center mt-12 mb-24">
                  <Checkbox
                    onChange={() => {
                      handleNotifyType("email");
                    }}
                    checked={notifyType.includes("email") || emailVerifyAt}
                    id={"notify-by-email"}
                    clazz={"width-max"}
                    title={"Email"}
                  ></Checkbox>
                  <Checkbox
                    onChange={() => {
                      handleNotifyType("telegram");
                    }}
                    checked={notifyType.includes("telegram")}
                    id={"notify-by-telegram"}
                    clazz={"width-max"}
                    title={"Telegram"}
                    disabled={!!userTg}
                  ></Checkbox>
                  <Checkbox
                    onChange={() => {
                      handleNotifyType("whatsapp");
                    }}
                    checked={notifyType.includes("whatsapp")}
                    id={"notify-by-whatsapp"}
                    clazz={"width-max"}
                    title={"WhatsApp"}
                    disabled={!!userWa}
                  ></Checkbox>
                </div>
                {isEmailConfirm && (
                  <div className="d-flex gap-8 align-center">
                    <span className="color-red-700">
                      {"Email - " + t("notconfirmed")}
                    </span>
                    <Link className="color-700" to={`/lk/${userType}#notify`}>
                      {t("confirm")}
                    </Link>
                  </div>
                )}
                {isWaConfirm && (
                  <div className="d-flex gap-8 align-center">
                    <span className="color-red-700">
                      {"WhatsApp - " + t("notconfirmed")}
                    </span>
                    <Link className="color-700" to={`/lk/${userType}#notify`}>
                      {t("confirm")}
                    </Link>
                  </div>
                )}
            </> */}


            <Button
              size={"l"}
              buttonType={"submit"}
              clazz={"button--green"}
              onClick={()=>{
                try{
                  handleSubmit(onSubmitBig)
                }
                catch(error){
                  console.log('error: ', error)
                }}}
            >
              {t("subscribe")}
            </Button>
            {email ? (
              <p className={""}>
                {t("onthe")} {email} {t("linksent")}
              </p>
            ) : null}
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LfP8iUqAAAAAEPL4hfdJluwEE6cnuzXd_au2QuP"
              size="invisible"
            />
          </form>
        </div>
      ) : null}
    </>
  );
};
export default Notifications;
