import {Icon} from "@/components/ui";
import {useState} from "react";

import "./exampleImage.scss";
import {DeletePhotoPopup} from "@/components/popups";
import useLadyService from "@/services/LadyService";
import axios from "axios";
import {makeRequest} from "@/services/makeRequest";

const ExampleImage = ({
                          image,
                          imageType,
                          setImage,
                          moderated,
                          removeLink,
                          removeCallback = false,
                          name,
                          setDataInfo = false,
                          type = "superId"
                      }) => {
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const {t, token} = useLadyService();

    const handleOpenDelete = () => {
        const dataImagePattern = /^data:image\/[a-zA-Z]+;base64,/;
        const dataPdfPattern = /^data:application\/pdf/;
        if (!dataImagePattern.test(image) && !dataPdfPattern.test(image)) {
            setIsOpenDelete(true);
        } else {
            setImage(null)
        }
    };
    const fetchData = async () => {
        try {
            const route = `user/info`;
            const method = "GET";

            const data = await makeRequest({route, method});

            if (data) {
                const result = data.data;
                if (setDataInfo) {
                    setDataInfo(result);
                }
            }
        } catch (error) {
            console.log('error: ', error);
        }
    };
    const removeImg = () => {

        axios
            .delete(`${removeLink}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(() => {
                setImage(null)
                setIsOpenDelete(false)
                if (!removeCallback) {
                    fetchData()
                } else {
                    removeCallback()
                }
            });
    };

    return (
        <>
            <div className={`example__photo`}>
                {(image.includes(".pdf") || imageType === "pdf" || name?.includes(".pdf")) ? (
                    <a
                        target="_blank"
                        href={image}
                        className="pdf-template d-flex fd-column align-center justify-center gap-12"
                    >
                        <span className="p1 color-green">{t('healthy')}</span>

                        <Icon spritePath={"health-fill"} size={"xxl"} clazz={"_green"}/>

                        <div className="d-flex fd-column gap-4 align-center">
                            <span className="color-main">PDF</span>

                            <span className="color-green">{name}</span>
                        </div>
                    </a>
                ) : (
                    <img src={image} alt={""}/>
                )}

                <span className="icon-remove" onClick={() => handleOpenDelete()}>
          <Icon size={"s"} spritePath={"close"}/>
        </span>

                {moderated === 2 && (
                    <span className="example__photo__ero-status text-center color-red-700">
            {t("declinedbymoderator")}
          </span>
                )}

                {moderated === 1 && (
                    <span className="example__photo__ero-status text-center color-green">
            {t("publishedono")}
          </span>
                )}

                {moderated === 0 && (
                    <span className="example__photo__ero-status text-center color-main">
            {t("moderated24hour")}
          </span>
                )}
            </div>

            {isOpenDelete &&
                <DeletePhotoPopup
                    type={type}
                    open={isOpenDelete}
                    setOpen={setIsOpenDelete}
                    removeImg={removeImg}
                />
            }
        </>
    );
};

export default ExampleImage;
