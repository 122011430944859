import { useState } from "react";
import { Button, ButtonLink, Icon } from "@/components/ui";
import { useSelector } from "react-redux";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import {
  getMapStatus,
  setAuthorisationAccessType,
  setAuthorizationStatus,
  setMapMode,
  setOrderModelStatus,
  setStoriesMap,
  setVideoChatMode,
} from "@/stores/slices/popupSlice";
import { useTranslation } from "react-i18next";
import useLadyService from "@/services/LadyService";
import { setLadyForCall } from "@/stores/slices/videoCallSlice";
import {makeRequest} from "@/services/makeRequest";
import { useNavigate } from "react-router-dom";

const GirlCardButtons = ({ props, edit }) => {
  const { slug, phone, telegram, whatsapp, ready_videochat, tag_booking } = props;

  const videoRes = {
    profile: {
      name: props.name,
      slug: props.slug,
      photo: !!props?.photos?.length ? [props?.photos[0]] : null,
    },
  };

  const { userType, dispatch } = useLadyService();

  const { t, lang } = useTranslation("translation");

  const mapPopupStatus = useSelector(getMapStatus);
  const navigate = useNavigate()

  const [clicked, setClicked] = useState(false);

  const phoneRes = !!formatPhoneNumberIntl(`${phone}`)
    ? formatPhoneNumberIntl(`${phone}`)
    : formatPhoneNumberIntl(`+${phone}`);

  const handlePhoneClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if(clicked){
      return
    }
    else{
      try{
        setClicked(true);
        await makeRequest({route: `event/contact/profile/${slug}`, method: "POST"});
        window.ym(98088419,'reachGoal','getphone')}
      catch(error){
        console.log('error: ',error)
      }
    }
  }  

  const handlePreorder = ( ) => {
    if (userType === "default") {
      dispatch(setAuthorizationStatus(true));
      dispatch(setAuthorisationAccessType("order"));
    } else {
      navigate(`/${lang}/profile/${slug}`);
      dispatch(setOrderModelStatus(true));
    }
  }
 
  return (
    <div className="girl-card__buttons">
      {edit ? (
        <>
          <Button size={"m"} clazz={"button--primary"}>
            {phoneRes}
          </Button>

          {!!whatsapp ? (
            <Button
              href={whatsapp}
              clazz={"button--primary button-icon"}
              size={"m"}
              title={'Whatsapp'}
            >
              <Icon size={"xl"} spritePath={"whatsapp"} />
            </Button>
          ) : null}

          {!!telegram ? (
            <Button 
              clazz={"button--primary button-icon"} 
              size={"m"}
              title={'Telegram'}
            >
              <Icon size={"xl"} spritePath={"telegram"} />
            </Button>
          ) : null}
        </>
      ) : (
        <>
          <ButtonLink
            href={clicked ? `tel:${phone}` : "#"}
            size={"m"}
            clazz={`button--primary phone-button${clicked ? " girl-card__phone" : ""}`}
            onClick={(e)=> handlePhoneClick(e)}
          >
            <span className="text">{t("shownumber")}</span>
            <span className="phone">{phoneRes ? phoneRes : phone}</span>
            {/* {clicked ? phoneRes : t("shownumber")} */}
          </ButtonLink>

          {!!whatsapp ? (
            <ButtonLink
              href={`https://wa.me/${whatsapp}?text=Hi!%20I%20am%20interested%20in%20your%20profile%20https://lady4love.com/ru/profile/${slug}`}
              onClick={ () => {
                makeRequest({route: `event/contact/profile/${slug}`, method: "POST"});
              }}
              clazz={"button--primary button-icon"}
              target={"_blank"}
              size={"m"}
              title={'Whatsapp'}
            >
              <Icon size={"m"} spritePath={"whatsapp"} />
            </ButtonLink>
          ) : null}

          {!!telegram && !(!!ready_videochat && !!tag_booking && !!whatsapp) ? (
            <ButtonLink
              href={`https://t.me/${telegram}?text=Hi!%20I%20am%20interested%20in%20your%20profile%20https://lady4love.com/ru/profile/${slug}`}
              onClick={ () => {
                makeRequest({route: `event/contact/profile/${slug}`, method: "POST"});
              }}
              clazz={"button--primary button-icon"}
              target={"_blank"}
              size={"m"}
              title={'Telegram'}
            >
              <Icon size={"m"} spritePath={"telegram"} />
            </ButtonLink>
          ) : null}

          {!!tag_booking &&
          !(userType === "indi" || userType === "agency") ? (
            <Button
              clazz={"button--green button-icon girl-card__preorder"}
              size={"m"}
              title={t('sendpreorder')}
              onClick={handlePreorder}
            >
              <Icon size={"m"} spritePath={"pay-content"} />
            </Button>
          ) : null}

          {!!ready_videochat && !(userType === "indi" || userType === "agency") ? 
          (
            <Button
              clazz={"button--green button-icon girl-card__videochat"}
              size={"m"}
              title={t('startvideochat')}
              onClick={() => {
                if (userType !== "default") {
                  dispatch(setLadyForCall(videoRes));
                } else {
                  dispatch(setVideoChatMode(true));
                  dispatch(setAuthorizationStatus(true));
                }
                if (mapPopupStatus) {
                  dispatch(setMapMode(true));
                  dispatch(setStoriesMap(false));
                }
              }}
            >
              <Icon size={"m"} spritePath={"video"} />
            </Button>
          ) : null}
        </>
      )}
    </div>
  );
};

export default GirlCardButtons;
