import React, { useEffect, useRef, useState } from "react";
import useLadyService from "../../../../../../../services/LadyService";
import { Loader } from "../../../../../../ui";

const EroVideo = (props) => {
  const {
    url,
    title,
    clazz,
    playsInline,
    muted,
    poster,
    controls,
    onClick,
    playButton = true,
  } = props;

  const { token } = useLadyService();
  const [play, setPlay] = useState(false);

  const [media, setMedia] = useState(null);

  const [load, setLoad] = useState(true);

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        setLoad(true);
        const addPlayBeforeMp4 = (url) => {
          if (!url.includes("play.mp4")) {
            const index = url.lastIndexOf(".mp4");
            return url.substring(0, index) + "/play" + url.substring(index);
          }
          return url;
        };

        const response = await fetch(addPlayBeforeMp4(url), {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Error fetching media: ${response.statusText}`);
        }

        const contentType = response.headers.get("content-type");
        let mediaUrl;

        if (contentType.includes("image")) {
          const imageBlob = await response.blob();
          mediaUrl = URL.createObjectURL(imageBlob);
        } else if (contentType.includes("video")) {
          const videoBlob = await response.blob();
          mediaUrl = URL.createObjectURL(videoBlob);
        } else {
          throw new Error("Unsupported media type");
        }
        
        setMedia(mediaUrl, contentType);
        setLoad(false);
      } catch (error) {
        console.error('error: ', error);
      }
    };

    token && fetchMedia();
  }, [url, token]);

  const videoRef = useRef(null);
  useEffect(() => {
    if (play) {
      videoRef.current.play();
    }
  }, [play]);

  if (!media || load) {
    return <Loader height={50} />;
  }

  return (
    <div
      title={title}
      className={`video${clazz ? ` ${clazz}` : ""}`}
      onClick={onClick}
    >
      {play ? null : playButton ? (
        <button onClick={() => setPlay(true)} className="video-button"></button>
      ) : null}

      <video
        ref={videoRef}
        poster={poster}
        playsInline={playsInline}
        muted={muted}
        src={token ? media : url}
        controls={play ? controls : null}
        controlsList={"nodownload"}
      />
    </div>
  );
};

export default EroVideo;
