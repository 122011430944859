import {
    BurgerMenu,
    DropdownLanguage,
    FavoriteButton,
    Filters,
    Logo,
    MenuNavBar,
    SelectCity,
} from "../components";
import ShortFilter from "@/components/shortFilter/ShortFilter";
import React from "react";
import { Burger, Button, ButtonLink, Dropdown, Icon, Toggle } from "@/components/ui";
import { setAuthorisationAccessType, setAuthorizationStatus } from "@/stores/slices/popupSlice";
import useLadyService from "@/services/LadyService";
import { useSelector } from "react-redux";
import { getScrolledClass } from "@/stores/slices/headerSlice";

const Default = () => {
    const {
        dispatch,
        userType,
        t,
        windowWidth,
        lang,
    } = useLadyService();

    const scrolledClass = useSelector(getScrolledClass);

    const infoLinks = [
        { id: 1, link: `/${lang}/about`, name: t("about") },
        { id: 2, link: `/${lang}/guide`, name: t("guide") },
        { id: 4, link: `/lk/support`, name: t("support") },
    ];
    const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');
    const LogInButton = () => (
        <Button
            onClick={() => dispatch(setAuthorizationStatus(true))}
            clazz="button--green user__log-in"
        >
            {t("login")}
        </Button>
    );


    const RegistrationLinkIndi = (iconSize = 'l', square = false) => {
        return (
            <ButtonLink
                href={`/registration#indi`}
                title={t("add")}
                clazz={"add-form button button_outline--green _no-underline center-screen"}
                square={square}
            >
                <Icon size={iconSize} spritePath={"plus"} />
                {!square && t("add")}

            </ButtonLink>
        )
    }

    if (windowWidth > 1199.98 || isJsdom)
        return (
            <>
                <div className="header__top">

                    {RegistrationLinkIndi('s')}

                    <Dropdown
                        buttonClass={"_no-fill"}
                        size={"xs"}
                        title={t("forusers")}
                    >
                        {infoLinks.map((i) => {
                            if (i.id === 4 && userType === "default") {
                                return (
                                    <ButtonLink
                                        onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(setAuthorisationAccessType("support"));
                                            dispatch(setAuthorizationStatus(true));
                                        }}
                                        href={i.link}
                                        clazz={"language"}
                                        title={i.name}

                                        key={i.id}
                                        children={i.name}
                                    />
                                );
                            }

                            return (
                                <ButtonLink
                                    href={`${i.link}`}
                                    clazz={"language"}
                                    title={i.name}
                                    key={i.id}
                                    children={i.name}
                                />
                            );
                        })}
                    </Dropdown>

                    <DropdownLanguage />

                    <Toggle type={"theme"} id={'theme-main-toggle'} />

                </div>

                <div className={isJsdom ? 'header__bottom ssr' : 'header__bottom'}>
                    <Logo scrolledClass={scrolledClass} />

                    <SelectCity />

                    <Filters />

                    <div className="user__wrapper">
                        {!isJsdom ? <FavoriteButton size={"s"} /> : null}

                        <LogInButton />
                    </div>
                </div>
            </>
        );

    if (windowWidth > 767.98)
        return (
            <div className={isJsdom ? 'header__bottom ssr' : 'header__bottom'}>
                <Logo scrolledClass={scrolledClass} />

                <SelectCity />

                <Filters />

                <div className="user__wrapper">
                    <LogInButton />

                    <FavoriteButton size={"s"} />

                    {RegistrationLinkIndi('l', true)}

                    <DropdownLanguage size={"s"} />

                    <Burger />

                    <Toggle type={"theme"} id={'theme-main-toggle'} />

                    <BurgerMenu />
                </div>
                <ShortFilter />
            </div>
        );

    return (
        <div className="header__top">
            <div className="user__wrapper">
                {RegistrationLinkIndi('l', true)}

                <Toggle type={"theme"} id={'theme-main-toggle'} />
            </div>

            <Logo scrolledClass={scrolledClass} />

            <div className="user__wrapper">
                <DropdownLanguage />

                <Burger />
            </div>


            <BurgerMenu />

            <MenuNavBar />

            <ShortFilter />
        </div>
    );
};



export default Default