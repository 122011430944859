import { useEffect, useState } from "react";
import Button from "../button/Button";

import './toggleButton.scss';

const ToggleButton = ({ options = [], defaultValue = false, handleChange = false, clazz }) => {
  const [activeValue, setActiveValue] = useState(defaultValue);

  useEffect(() => {
      setActiveValue(defaultValue)
  }, [defaultValue])

  const handleClick = (val) => {
    if (handleChange) {
      handleChange(val)
      return
    }
    setActiveValue(val)
  }
  
  return (
    <div className={"toggle-button " + clazz}>
      {options.length
        ? options.map((item, index) => {
            return (
              <Button key={index} onClick={() => handleClick(item.value)} size={"s"} clazz={`button--secondary w-100 ${activeValue === item.value ? "active" : ""}`}>
                {item.title}
              </Button>
            );
          })
        : null}

    </div>
  );
};

export default ToggleButton;
