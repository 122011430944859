import "./statisticTable.scss";
import useLadyService from "../../../../../services/LadyService";
import { Tippy } from "@/components/ui";
import {format, fromUnixTime} from "date-fns";
import {useEffect, useState} from "react";

const StatisticTable = ({
  statistic,
  transactions,
  promoPeriods,
  elitePeriods,
  profileFilter,
  modelsTable=false,
  profiles,
}) => {
  const { t, navigate, lang } = useLadyService();
  const header = [
    { title: modelsTable ? "myads" : "date", tippy: "" },
    { title: "position", tippy: "tposition" },
    { title: "profileshows", tippy: "tview" },
    { title: "viewprofile", tippy: "tclick" },
    { title: "viewcontact", tippy: "tcontatct" },
    { title: "erocontentaccess", tippy: "tero" },
    { title: "minofvideo", tippy: "tvideo" },
    { title: "g-preorders", tippy: "tpre" },
    { title: "expenses", tippy: "" },
    { title: "income", tippy: "" },
  ];

  const combinedData = Object.entries(statistic).map(([timestamp, actions]) => {
    const income = transactions.income[timestamp] || 0; 
    const expenses = transactions.expenses[timestamp] || 0;

    return {
      date:
        timestamp === "forperiod"
          ? "forperiod"
          : timestamp,
      position: actions?.position || 0, 
      profileshows: actions?.profileshows || 0,
      viewprofile: actions?.viewprofile || 0,
      viewcontact: actions?.viewcontact || 0,
      erocontentaccess: actions?.erocontentaccess || 0,
      minofvideo: actions?.minofvideo || 0,
      prepaidorders: actions?.prepaidorders || 0,
      expenses: expenses,
      income: income,
    };
  });

  const combinedGirlData = Object.entries(statistic).map(([id, actions]) => {
    const income = transactions?.income[id] || 0;
    const expenses = transactions?.expenses[id] || 0; 
    const girlSlug = profiles?.[id]?.slug ?? 'forperiod';
    const girlName = profiles?.[id]?.name;
    const is_enabled = profiles?.[id]?.is_enabled ?? false;

    return {
      name: {name: girlName, slug: girlSlug},
      position: actions?.position || 0, 
      profileshows: actions?.profileshows || 0,
      viewprofile: actions?.viewprofile || 0,
      viewcontact: actions?.viewcontact || 0,
      erocontentaccess: actions?.erocontentaccess || 0,
      minofvideo: actions?.minofvideo || 0,
      prepaidorders: actions?.prepaidorders || 0,
      expenses: expenses,
      income: income,
      is_enabled: is_enabled,
    };
  })
  const sortedGirlsData = combinedGirlData.sort((a, b) => a.profileshows - b.profileshows);
  const sortedBaseData = combinedData.sort((a, b) => a.date - b.date);

  sortedGirlsData.unshift(sortedGirlsData.pop());
  sortedBaseData.unshift(sortedBaseData.pop());


  const [sortedData, setSortedData] = useState(modelsTable ? sortedGirlsData : sortedBaseData)

  const handleLink = (i) => {
    if(i?.is_enabled)navigate(`/${lang}/profile/${i?.name?.slug}`)
      else{return}
  }

  const handleSortBy = (title = 'aboba') => {
    switch (title){
      case "position" :{
        let sorted = sortedData.filter((a) => {if(a.name?.slug !== 'forperiod' || a.date !== 'forperiod') return a}).sort((a, b) => a.position - b.position)
        //sorted.unshift(sorted.pop())
        setSortedData(sorted)
        break
      }     
      case "profileshows" :{
        const sorted = modelsTable ? combinedGirlData.sort((a, b) => a.profileshows - b.profileshows) : combinedData.sort((a, b) => a.profileshows - b.profileshows)
        sorted.unshift(sorted.pop())
        setSortedData(sorted)
        break
      }
      case "viewprofile" :{
        const sorted = modelsTable ? combinedGirlData.sort((a, b) => a.viewprofile - b.viewprofile) : combinedData.sort((a, b) => a.viewprofile - b.viewprofile)
        sorted.unshift(sorted.pop())
        setSortedData(sorted)
        break
      } 
      case "viewcontact" :{
        const sorted = modelsTable ? combinedGirlData.sort((a, b) => a.viewcontact - b.viewcontact) : combinedData.sort((a, b) => a.viewcontact - b.viewcontact)
        sorted.unshift(sorted.pop())
        setSortedData(sorted)
        break
      } 
      case "erocontentaccess" :{
        const sorted = modelsTable ? combinedGirlData.sort((a, b) => a.erocontentaccess - b.erocontentaccess) : combinedData.sort((a, b) => a.erocontentaccess - b.erocontentaccess)
        sorted.unshift(sorted.pop())
        setSortedData(sorted)
        break
      }
      case "minofvideo" :{
        const sorted = modelsTable ? combinedGirlData.sort((a, b) => a.minofvideo - b.minofvideo) : combinedData.sort((a, b) => a.minofvideo - b.minofvideo)
        sorted.unshift(sorted.pop())
        setSortedData(sorted)
        break
      }
      case "g-preorders" :{
        const sorted = modelsTable ? combinedGirlData.sort((a, b) => a.prepaidorders - b.prepaidorders) : combinedData.sort((a, b) => a.prepaidorders - b.prepaidorders)
        sorted.unshift(sorted.pop())
        setSortedData(sorted)
        break
      }
      case "expenses" :{
        const sorted = modelsTable ? combinedGirlData.sort((a, b) => a.expenses - b.expenses) : combinedData.sort((a, b) => a.expenses - b.expenses)
        sorted.unshift(sorted.pop())
        setSortedData(sorted)
        break
      }
      case "income" :{
        const sorted = modelsTable ? combinedGirlData.sort((a, b) => a.income - b.income) : combinedData.sort((a, b) => a.income - b.income)
        sorted.unshift(sorted.pop())
        setSortedData(sorted)
        break
      }
      default :{
        const sorted = modelsTable ? combinedGirlData.filter((a)=>{if(a.name?.slug !== 'forperiod')return a}).sort((a, b) => a.profileshows - b.profileshows) : combinedData.filter((a)=>{if(a.date !== 'forperiod')return a}).sort((a, b) => a.date - b.date)
        setSortedData(sorted)
        break
      }
    }
  }

  console.log('data: ', sortedData)
  
  return (
    <>
    <div className="statistic-table3">
      <table >
        <thead>
          <tr>
            {header.map((head, index) => {
              switch (head.title){
                case 'date' : case 'myads':{
                  return <th key={index} className="sticky" onClick={()=>handleSortBy(head.title)}>{t(head.title)}</th>;
                }
                case 'income': case 'expenses':{
                  return <th key={index} onClick={()=>handleSortBy(head.title)}>{t(head.title)}</th>;
                }
                case 'position':{
                  return (<>{(profileFilter || modelsTable) && <th key={index} onClick={()=>handleSortBy(head.title)}>{t(head.title)}</th>}</>);
                }
                default :{
                  return(
                    <th key={index} onClick={()=>handleSortBy(head.title)}>
                      <Tippy quote={true} arrow={false} title={t(head.tippy)}>{t(head.title)}</Tippy>
                    </th>)
                }
              }
            })}
          </tr>
        </thead>
        <tbody>{
          sortedData.toReversed().map((row, index) => (
            <tr className={`${row.date === 'forperiod' ? 'bold' : ''} ${row.name?.slug === 'forperiod' ? 'bold' : ''}`} key={index}>
              {Object.keys(row).map((key) => {
                switch(key){
                  case 'name':{
                    return <td key={key} className={row?.is_enabled ? "hover-line color-green sticky" : " sticky"} onClick={()=>{handleLink(row)}}>{row[key]?.name ?? t('allads')}</td>;
                  }
                  case 'date':{
                    return (
                      <td
                        key={key}
                        className={` ${promoPeriods?.includes(parseInt(row[key])) ? 'promoted' : ''} ${elitePeriods?.includes(parseInt(row[key])) ? 'elite' : ''} sticky`}
                      >
                        {row[key] === 'forperiod' ? t('forperiod') :  format(fromUnixTime(row[key]), 'dd.MM.yyyy')}
                      </td>
                    );
                  }
                  case 'position': {
                    return(<>{(profileFilter || modelsTable) && <td key={key} className={`col-1 ${!row[key].name ? '' : 'tilda'}`}>{modelsTable & row[key] !== 0 ? '~ ' : ''}{row[key] !== 0 ? row[key] : '-'}</td>}</>);
                  }
                  case 'profileshows':{
                    return <td key={key} className="col-1">{row[key]}</td>;
                  }
                  case 'viewprofile': case 'viewcontact': {
                    return <td key={key} className="col-2">{row[key]}</td>;
                  }
                  case 'erocontentaccess': case'minofvideo': case 'prepaidorders':{
                    return <td key={key} className="col-3">{row[key]}</td>;
                  }
                  case 'income': case 'expenses':{
                    return <td key={key} className={`${key === 'income'? 'income currency--erocoin' : 'expense currency--erocoin'}`}>{row[key]}</td>;
                  }
                  case 'is_enabled':{
                    return ;
                  }
                  default : {
                    return <td key={key}>{row[key]}</td>;
                  }
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      

      
      <div className="statistic-table__options">
        <span className={"statistic-table__option--elite"}>
          {t("Status")} Elite
        </span>
        <span className={"statistic-table__option--promo"}>
          {t("activepromo")}
        </span>
      </div>
    </>
  );
};

export default StatisticTable;
