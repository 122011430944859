import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import React, { useEffect, useState } from "react";
import useLadyService from "@/services/LadyService";
import {
  setAuthorisationAccessType,
  setAuthorizationStatus,
  setErocontentAccessStatus,
} from "@/stores/slices/popupSlice";
import { Icon, Button } from "@/components/ui";
import ZeroZone from "@/components/zeroZone/ZeroZone";
import EroMedia from "../../pages/accounts/createProfile/pageComponent/stepSection/media/EroMedia";
import ShowMoreContent from "../../pages/modelPage/components/showMoreContent/ShowMoreContent";
import {
  getGalleryMediaEroPhoto,
  getGalleryMediaInterior,
  getGalleryMediaPhoto,
  getGalleryMediaVideo,
  setGalleryMediaIndex,
  setGalleryStatus,
} from "../../../stores/slices/popupSlice";
import { useSelector } from "react-redux";
import EditBlock from "../../pages/modelPage/components/editBlock/EditBlock";

const PrivateSlider = ({ ero, setOpenEdit, setVisualElem, edit }) => {
  const { dispatch, userType, setMediaSize, t } = useLadyService();
  let maxCountPhoto = useSelector(getGalleryMediaPhoto);
  let maxCountVideo = useSelector(getGalleryMediaVideo);
  let maxCountInterior = useSelector(getGalleryMediaInterior);
  let maxCountEroPhoto = useSelector(getGalleryMediaEroPhoto);

  const [indexMedia, setIndexMedia] = useState(0);

  useEffect(() => {
    setIndexMedia(maxCountPhoto + maxCountVideo + maxCountInterior);
  }, [maxCountPhoto > 0, maxCountVideo > 0, maxCountInterior > 0]);

  const ErocontentTemplate = (data, index, type = "photo") => {
    const handleClick = () => {
      if (userType === "default") {
        dispatch(setAuthorizationStatus(true));
        dispatch(setAuthorisationAccessType("ero"));
      } else if (!ero?.data?.hide) {
        dispatch(
          setGalleryMediaIndex(
            indexMedia + (type === "video" ? maxCountEroPhoto + index : index)
          )
        );
        dispatch(setGalleryStatus(true));
      } else {
        dispatch(setErocontentAccessStatus(true));
      }
    };

    return (
      <SwiperSlide
        key={index}
        className={"model-main__private-slide"}
        onClick={() => handleClick()}
      >
        {ero?.data?.hide ? <ShowMoreContent /> : null}

        {type === "video" ? <Icon size={"m"} spritePath={"triangle"} /> : null}

        <EroMedia src={setMediaSize(data + type, "s")} />

        {edit ? (
          data?.photo_moderated || data?.video_moderated ? null : (
            <span className="ero-status color-main text-center">
              {t("moderated24hour")}
            </span>
          )
        ) : null}
      </SwiperSlide>
    );
  };

  const sliderParams = {
    className: "model-main__private",
    modules: [Navigation],
    slidesPerView: 3,
    spaceBetween: 10,
    breakpoints: {
      0: {
        slidesPerView: 3,
      },
      599.98: {
        slidesPerView: 2,
      },
      767.98: {
        slidesPerView: 3,
      },
    },
    navigation: {
      nextEl: ".model-main__private_button-next",
      prevEl: ".model-main__private_button-prev",
    },
  };

  return (
    <div className={"model-main__private-wrapper mt-48 mt-24-mob"}>
      <h3
        className={
          !!edit
            ? "mt-0 mb-12 d-flex fd-row gap-12 align-center  justify-sb"
            : "mb-20 mb-16-mob"
        }
      >
        {t("privatephotos")}
        {!!edit && (
          <EditBlock
            btnTitle={t("edit")}
            mb={0}
            onClick={() => {
              setOpenEdit(true);
              setVisualElem("ero");
            }}
          />
        )}
      </h3>

      {!!edit &&
      !(!!ero?.data?.photo?.length || !!ero?.data?.video?.length) ? (
        <ZeroZone
          mainText={t("addad") + " " + t("erocontent").toLowerCase()}
          setOpen={setOpenEdit}
          disc={25}
        />
      ) : (
        <Swiper {...sliderParams}>
          <button
            slot={"container-start"}
            className="swiper-button-prev model-main__private_button-prev"
          ></button>

          {!!ero?.data?.photo.length
            ? ero.data.photo.map((data, index) =>
                ErocontentTemplate(data, index)
              )
            : null}

          {!!ero?.data?.video?.length
            ? ero.data.video.map((data, index) => ErocontentTemplate(data, index, "video"))
          : null}

          <button
            slot={"container-end"}
            className="swiper-button-next model-main__private_button-next"
          ></button>
        </Swiper>
      )}
    </div>
  );
};

export default PrivateSlider;
