import { Checkbox, Button, InputInLabel, Icon } from "@/components/ui";
import { useState, useEffect } from "react";
import { makeRequest } from "@/services/makeRequest";
import showToast from "../../../../toast/Toast";
import useLadyService from "../../../../../services/LadyService";
import "./countriesFilter.scss";

const CountriesFilter = ({ data, isModel = false, formData = false, setFormData = false }) => {
  const { blocked_countries = [] } = data;
  const {slug, blocked_countries: model_blocked_countries = []} = formData;
  const { lang, t } = useLadyService();
  
  const [isFull, setIsFull] = useState(false);
  const [query, setQuery] = useState("");

  const [initialCountries, setInitialCountries] = useState([]);
  const [countries, setCountries] = useState([]);

  const options = isFull ? countries : countries.slice(0, 12);

  const handleChange = (event) => {
    const term = event.target.value;
    setQuery(term);
    const filteredCountries = initialCountries.filter((country) =>
      country.name.toLowerCase().startsWith(term.toLowerCase())
    );
    setCountries(filteredCountries);
    
  };

  const hanldleFull = () => {
    setIsFull(true);
  };

  const hanldleClear = () => {
    setCountries(initialCountries);
    setQuery("");
  };

  const handleCountrySelect = (code, title) => {
    const updatedCountries = [...countries];
    const updatedInitialCountries = [...initialCountries];

    const index = updatedCountries.findIndex(
      (country) => country.code === code
    );
    const initialIndex = initialCountries.findIndex(
      (country) => country.code === code
    );

    updatedCountries[index].checked = !updatedCountries[index].checked;
    const newCheck = updatedCountries[index].checked;

    setCountries(updatedCountries);
    
    updatedInitialCountries[initialIndex].checked = newCheck;
    
    setInitialCountries(updatedInitialCountries);
    
    const newCheckedValue = updatedCountries[index].checked;
    const fetchData = async () => {
      try {
        const variant = newCheckedValue ? "include" : "exclude";
        const route = isModel ? `countries/${slug}/${variant}/${code}` : `countries/${variant}/${code}`;
        const method = newCheckedValue ? "POST" : "DELETE";
        const payload = {
          code,
        };

        const result = await makeRequest({ route, method, payload });
        if(result) {
          if(setFormData) {
            const arrayResult = countries.filter(item => item.checked).map(country => country.code)
            setFormData(prev => ({
              ...prev,
              blocked_countries: arrayResult
            }))
          }
          showToast({
            message: `${t("accessscountry")} ${title} ${
              variant === "exclude" ? t("open") : t("close")
            }`,
            variant: "success",
          });
        }
       
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = `nationalities`;
        const method = "GET";
        const payload = { lang };

        const data = await makeRequest({ route, method, payload });
        if (data) {
          const sortedCountries = data
            .sort((a, b) => {
              const aChecked = isModel ? model_blocked_countries.includes(a.code) : blocked_countries.includes(a.code);
              const bChecked = isModel ? model_blocked_countries.includes(b.code) : blocked_countries.includes(b.code);
              if (aChecked && !bChecked) {
                return -1;
              } else if (!aChecked && bChecked) {
                return 1;
              } else {
                return 0;
              }
            })
            .map((country) => {
              if (isModel ? model_blocked_countries.includes(country.code) : blocked_countries.includes(country.code)) {
                country.checked = true;
              } else {
                country.checked = false;
              }
              return country;
            });

          setInitialCountries(sortedCountries);
          setCountries(sortedCountries);
        }
      } catch (error) {}
    };

    fetchData();
  }, [lang]);

  return (
    <div className="filter__root">
      <span className="p1 color-main w-100">{t("restrict")}</span>
      <p className="w-100">{t("invisible")}</p>
      {isFull && (
        <div className="filter__search">
          <fieldset>
            <legend >{t("country")}</legend>
            <InputInLabel
              placeholder={t("writecountry")}
              clazz={"w-100"}
              value={query}
              onChange={handleChange}
            >
            </InputInLabel>
          </fieldset>

          <Button
            square={true}
            size={"s"}
            onClick={hanldleClear}
            disabled={!query}
            clazz={'mt-10'}
          >
            <Icon size={"l"} spritePath={"close"} />
          </Button>
        </div>
      )}
      {!!options.length ? (
        <div className="filter-countries__body">
          {options?.map((i) => {
            const { code, name: title, checked, id } = i;
            const name = code.toUpperCase();

            return (
              <Checkbox
                flag={name}
                key={'filter-countries__body-chkbx-' + id}
                name={name}
                id={code}
                value={name}
                checked={checked}
                title={title}
                onChange={() => handleCountrySelect(code, title)}
              />
            );
          })}
        </div>
      ) : (
        <span className="p2">{t('emptyrecord')}</span>
      )}
      {!isFull && (
        <Button
          title={t('showallcountries')}
          clazz={"button--secondary justify-center"}
          size={"s"}
          onClick={hanldleFull}
        >
          {t("showallcountries")}
        </Button>
      )}
    </div>
  );
};

export default CountriesFilter;
