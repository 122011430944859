import { Link, Navigate } from "react-router-dom";
import React from "react";
import { LogoSvg } from "@/components/ui";
import useLadyService from "@/services/LadyService";
import { cleanSorted, setPageDefault, setSearchValue, setSortedModelsByFilter } from "@/stores/slices/modelsState";
import { resetAll, setSearchFilter } from "@/stores/slices/filterSlice";
import { clearTitle } from "@/stores/slices/titleSlice";
import { resetAllCounters } from "@/stores/slices/counterFilterSlice";

import logoDarkScrolled from "@/assets/img/logo-scroll--dark.svg";
import logoLightScrolled from "@/assets/img/logo-scroll--light.svg";
import { useSelector } from "react-redux";
import { getScrolledClass } from "@/stores/slices/headerSlice";
import { setProfileFilterCount } from "../../../stores/slices/mapModelSlice";
import { getUserCurrentCity, getUserLang } from "../../../stores/slices/userSlice";
import { useResetFilter } from "@/components/filter/Filter";
import { useToggleCountryCZ } from "@/components/ui/select/SelectCountry";

const Logo = () => {
    const {
        theme,
        windowWidth,
        dispatch,
        scrollToTop,
        t,
        region,
    } = useLadyService();

    const currentCity = useSelector(getUserCurrentCity)

    const postAltLogo = t("altlogo", { City: currentCity?.title, Country: region });
    const scrolledClass = useSelector(getScrolledClass);
    const toggleCountry = useToggleCountryCZ()

    const handleLogo = () => {
        toggleCountry()

        if (scrolledClass) {
            scrollToTop()
        }
    };

    const getThemeForLogo = () => {
        if (theme === "dark") {
            return logoDarkScrolled;
        } else {
            return logoLightScrolled;
        }
    };
    const lang = useSelector(getUserLang)
    const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');
    let mainUri = `/${lang}/czechia`
    if (lang === 'en') {
        mainUri = '/'
    }
    if (isJsdom) {
        if (lang === 'en') {
            mainUri = '/'
        } else {
            mainUri = `/${lang}/czechia`
        }
    }
    return (
        <Link
            to={mainUri}
            title={t("titlelogo", { City: currentCity?.title, Country: region })}
            className="logo"
            onClick={() => handleLogo()}
        >
            {scrolledClass === " _scroll" && windowWidth > 767.98 ? (
                <img src={getThemeForLogo()} alt={postAltLogo} />
            ) : (
                <LogoSvg alt={postAltLogo} />
            )}
        </Link>
    );
}

export default Logo