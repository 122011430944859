import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSeoDescription } from "@/stores/slices/titleSlice";
import { useEffect } from "react";

function useDescription(description, set = true) {
    const location = useLocation();
    const seoDescription = useSelector(getSeoDescription);

    useEffect(() => {
        if (set) {
            const content = seoDescription || description;
            let metaTag = document.querySelector('meta[name="description"]');
            if (!metaTag) {
                metaTag = document.createElement("meta");
                metaTag.setAttribute("name", "description");
                document.head.appendChild(metaTag);
            }
            metaTag.setAttribute("content", content);
        }
    }, [location, description, set, seoDescription]);
}

export default useDescription;
