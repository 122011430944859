import {Popup} from "../index";
import {Button, LoadingButton} from "../../ui";
import useLadyService from "../../../services/LadyService";
import {makeRequest} from "../../../services/makeRequest";
import showToast from "../../toast/Toast";
import {useState} from "react";

const ConfirmChangeTariffPopup = ({data, open, setOpen}) => {
  const {t, token} = useLadyService();

  const {
    id,
    slug,
    name,
    price,
    old_price
  } = data

  const [loading, setLoading] = useState(false)

  const setTariff = async () => {
    try {
      setLoading(true)
      const result = await makeRequest({
        route: `user/set-tariff/${id}`,
        method: "POST",
      });
      if(result){
        window.location.reload()
      }
    } catch (error) {
      console.log('error status: ', error.status)
      showToast({
        message: t( "oops"),
        variant: 'error'
      });
    }
    finally {
      setOpen(false)
      setLoading(false)
    }
  };

  return (
    <Popup
      open={open}
      setOpen={setOpen}
      container={'div'}
      clazz={'text-center'}
    >
      <h2>{t('set_tariff')}</h2>

      <p className={'m-0'}>{`${t('new')} ${t('tarif')}`}</p>

      <h3 className={'color-green'}>{name}</h3>

      <div className="popup-form__fake-input fd-column justify-center mb-20 bg-color-200">
        <div className={'d-flex justify-sb w-100 align-center'}>
          <p className={'m-0'}>
            {old_price ? `${t("first")} ${t('month')}` : t("everymonth")}
          </p>
          <span className={"title_h3 currency--erocoin"}>{price}</span>
        </div>

        {old_price && (
          <div className={'d-flex justify-sb w-100 align-center'}>
            <p className={'m-0'}>{t("everymonth")}</p>
            <span className={"title_h3 currency--erocoin"}>{old_price}</span>
          </div>
        )}
      </div>

      <div className="d-flex gap-8">
        <Button
          size={"l"}
          buttonType={"reset"}
          clazz={"button--primary w-100"}
          onClick={() => setOpen(false)}
        >
          {t("cancel")}
        </Button>

        <LoadingButton
          isLoading={loading}
          size={"l"}
          onClick={() => setTariff()}
          clazz={"button--green w-100"}
        >
          {t("confirm")}
        </LoadingButton>
      </div>
    </Popup>
  )
}

export default ConfirmChangeTariffPopup