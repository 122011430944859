import axios from "axios";
import useLadyService from "./LadyService";

const useVideoCallService = () => {
  const { token } = useLadyService();
  const BASE_API_URL = `https://${process.env.REACT_APP_CALL_URL}`;

  function createVideoCallRoom(profile) {
    return axios.post(
      `${BASE_API_URL}/create`,
      { profile },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  }

  return {
    createVideoCallRoom,
  };
};

export default useVideoCallService;
