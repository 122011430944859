import { useSelector } from "react-redux";
import {
  getOrderModelStatus,
  setOrderModelStatus,
  setStoriesMap,
} from "@/stores/slices/popupSlice";
import { InputInLabel, Loader, Button, Icon } from "@/components/ui";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { makeRequest } from "@/services/makeRequest";
import useLadyService from "@/services/LadyService";
import { setOneModelPosition } from "@/stores/slices/mapModelSlice";

import GeoInput from "@/components/ui/input/GeoInput";

import "./orderModel.scss";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import showToast from "../../toast/Toast";
import { LoadingButton } from "../../ui";
import { Popup } from "../index";
import {format, getUnixTime} from "date-fns";
import { setOneProfileAddress, setOneProfileCoords } from "@/stores/slices/oneProfileSlice";
import axios from "axios";

const OrderModel = ({ address, name, prices, slug }) => {
  const removeZeroPriceAndSortByDuration = (data) => {
    for (const key in data) {
      data[key] = data[key]
        .filter((item) => Number(item.price) !== 0)
        .sort((a, b) => a.duration - b.duration);
    }
    return data;
  };

  const filtredPrices = removeZeroPriceAndSortByDuration(prices);

  const {
    incall: pricesIncall = [],
    outcall: pricesOutcall = [],
    escort: pricesEscort = [],
  } = filtredPrices;

  const { dispatch, userType, minutesToDhms, t, lang } = useLadyService();

  const [price, setPrice] = useState(null);
  const [idPrice, setIdPrice] = useState(null);

  const [addressUser, setAddressUser] = useState(null);

  const [valueLoad, setValueLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [isLoadingGlobal, setIsLoadingGlobal] = useState(false);

  const [phoneValue, setPhoneValue] = useState("");
  const [waValue, setWaValue] = useState("");

  const [active, setActive] = useState({
    incall: !!pricesIncall.length,
    outcall: !pricesIncall.length && !!pricesOutcall.length,
    escort:
      !pricesIncall.length && !pricesOutcall.length && !!pricesEscort.length,
  });

  const [newDate, setNewDate] = useState(
    format(new Date(), "dd.MM.yyyyy")

  );

  const orderModelValue = useSelector(getOrderModelStatus);

  const data = {
    place: [
      {
        id: "placeOrderIncall",
        title: t("incall"),
        name: "placeOrder",
        value: "incall",
        onClick: () =>
          setActive({
            incall: true,
            outcall: false,
            escort: false,
          }),
        checked: active.incall,
        isVis: !!pricesIncall.length,
      },
      {
        id: "placeOrderOutcall",
        title: t("onclient"),
        name: "placeOrder",
        value: "outcall",
        onClick: () =>
          setActive({
            incall: false,
            outcall: true,
            escort: false,
          }),
        checked: active.outcall,
        isVis: !!pricesOutcall.length,
      },
      {
        id: "placeOrderEscort",
        title: t("accompaniment"),
        name: "placeOrder",
        value: "escort",
        onClick: () =>
          setActive({
            incall: false,
            outcall: false,
            escort: true,
          }),
        checked: active.escort,
        isVis: !!pricesEscort.length,
      },
    ],
  };

  const handleClickPrice = (id) => {
    setIdPrice(id.id);
    setPrice(id.price);
  };

  const formSchema = object().shape({
    phone: string()
      .min(14, `${t("phonenumerrequired")}`)
      .test("phone-is-empty", t("phonenumerrequired"), function () {
        return !!phoneValue;
      }),
    address:
      active.outcall || active.escort ? string().required(t("required")) : null,
    book_at: string().required(t("required")),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const fullReset = () => {
    reset();
    setNewDate(null);
    setPrice(null);
    setIdPrice(null);
    setWaValue("");
    setPhoneValue("");
    dispatch(setOrderModelStatus(false));
  };
  useEffect(() => {
    console.log('errors: ', errors)
  }, [errors]);
  const onChangeDate = (value) => {
    clearErrors("date");

    setNewDate(
      !value
        ?  format(new Date(), "dd.MM.yyyy")
        : format(value,"yyyy-MM-dd")
    );
    clearErrors("date");
  };

  useEffect(() => {
    setValueLoad(false);
    setValue("phone", String(phoneValue));
    setNewDate( format(new Date(),"yyyy-MM-dd"));
    setValue("date",format(new Date(), "dd.MM.yyy"));
    setValueLoad(true);
  }, [phoneValue, setValue]);

  useEffect(() => {
    const fetchData = async () => {
      setValueLoad(false);
      setIsLoading(true);
      try {
        const route = `user/info`;
        const method = "GET";

        const data = await makeRequest({ route, method });

        const result = data?.data;
        setPhoneValue(result?.phone);
        setWaValue(result?.whatsapp);
        setAddressUser(`${result?.address?.[0]?.city ? result.address?.[0]?.city + ', ' : ''}${result.address?.[0]?.address}`);
        setValue("address", `${result?.address?.[0]?.city ? result.address?.[0]?.city + ', ' : ''}${result.address?.[0]?.address}`);

        setIsLoading(false);
        setValueLoad(true);
      } catch (error) {}
    };

    userType !== "default" && fetchData();
  }, [userType]);

  useEffect(() => {
    switch (true) {
      case active.incall: {
        setIdPrice(pricesIncall[0]["id"]);
        setPrice(pricesIncall[0]["price"]);
        break;
      }
      case active.outcall: {
        setIdPrice(pricesOutcall[0]["id"]);
        setPrice(pricesOutcall[0]["price"]);
        break;
      }
      case active.escort: {
        setIdPrice(pricesEscort[0]["id"]);
        setPrice(pricesEscort[0]["price"]);
        break;
      }
      default: {
        setIdPrice(null);
        setPrice(null);
      }
    }
  }, [active]);

  const CheckboxTemplate = (id, name) => {
    return (
      <InputInLabel
        register={{ ...register("id") }}
        key={id.id}
        name={name}
        id={id.id}
        clazz={"p-8"}
        value={id.price}
        checked={id.id === idPrice}
        type={"radio"}
        onClick={() => handleClickPrice(id)}
      >
        {minutesToDhms(id.duration)}
      </InputInLabel>
    );
  };

  let discountHtml = price * 0.1;
  let discountCurrency = (price * 0.1) / 25;
  if (discountHtml % 1 !== 0) {
    discountHtml = Math.ceil(discountHtml);
  }
  const onSubmit = (data, e) => {
    e.preventDefault();
    const { book_at, address: fromAddress } = data;

    const combineDateTime = (date, time) => {
      const dateComponents = date.split("-");
      const timeComponents = time.split(":");

      const year = parseInt(dateComponents[0]);
      const month = parseInt(dateComponents[1]) - 1;
      const day = parseInt(dateComponents[2]);

      const hours = parseInt(timeComponents[0]);
      const minutes = parseInt(timeComponents[1]);

      return new Date(year, month, day, hours, minutes);
    };

    const combinedDateTime = combineDateTime(newDate, book_at);

    const fetchData = async () => {
      setIsLoadingGlobal(true);
      try {
        const route = `bookings/${idPrice}`;

        const unixTimestamp = getUnixTime(combinedDateTime);

        const checkTime = (unixTime) => {
          const targetTime = new Date(unixTime * 1000);
          const currentTime = new Date();

          let targetTimePlusOneHour = new Date(targetTime);

          let currentTimePlusOneHour = new Date(currentTime);
          currentTimePlusOneHour.setHours(currentTime.getHours() + 1);

          return currentTimePlusOneHour < targetTimePlusOneHour;
        };

        const unixTimestampResult = checkTime(unixTimestamp);

        if (!unixTimestampResult) {
          showToast({
            message: t("timemorehour"),
            variant: "error",
          });
          setIsLoadingGlobal(false);
          return;
        }

        let discount = price * 0.1;
        if (discount % 1 !== 0) {
          discount = Math.ceil(discount);
        }

        const method = "POST";
        const payload = {
          book_at: unixTimestamp,
          address: fromAddress,
          phone: String(phoneValue).replace(/[" "+]/g, ""),
          ...(!!waValue.replace(/["+"+]/g, "") && {
            whatsapp: String(waValue).replace(/[" "+]/g, ""),
          }),
          prepay: discount,
        };
        const result = await makeRequest({ route, method, payload });
        if (result.message === "booked") {
          showToast({
            message: t("ordersuccess"),
            variant: "order",
          });
          setIsLoadingGlobal(false);
          fullReset();
        }
      } catch (error) {
        setIsLoadingGlobal(false);
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };

    !!combinedDateTime && fetchData();
  };

  const [isAddressActive, setIsAddressActive] = useState(false);

  const handleLocationClick = () => {
          setIsAddressActive(true);
  
          if ("geolocation" in navigator) {
              navigator.geolocation.getCurrentPosition(async function (position) {
                  const {latitude, longitude} = position.coords;
  
                  try {
                      const response = await axios.get(
                          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1&accept-language=${lang}`
                      );
                      const {
                          city,
                          road,
                          house_number,
                      } = response?.data?.address;
  
                      dispatch(
                          setOneProfileAddress(
                              `${road ?? ''} ${house_number ? ", " + house_number : ""}`
                          )
                      );
                      dispatch(setOneProfileCoords({latitude, longitude}));
                      setAddressUser(`${city + ', '?? ''}${road ?? ''} ${house_number ? ", " + house_number : ""}`)
                  } catch (error) {
                      console.error("Error fetching address:", error);
                  }
              });
  
          } else {
              console.log("Geolocation is not supported by this browser.");
          }
      };

  return (
    <Popup
      open={!!orderModelValue}
      setOpen={fullReset}
      id={"orderModel"}
      wrapperClazz="popup-form"
      clazz={"gap-20 popup-order"}
      onSubmit={handleSubmit(onSubmit)}
    >
      <h2>
        {t("orderonline")}
        <span className={"d-block"}>{name}</span>
      </h2>

      <div className="popup-form__inner fd-row gap-8">
       <fieldset>
        <legend>{errors.date ? (
              <span className={"input-label__error"}>{t("date")}</span>
            ) : (
              t("date")
            )}</legend>
          <InputInLabel
            clazz={`${errors.date ? " error" : ""}`}
            type={"date"}
            id={"orderDate"}
            register={{ ...register("date") }}
            required
            onChange={onChangeDate}
            value={newDate}
          >
          </InputInLabel>
       </fieldset>

        <fieldset>
          <legend>{errors.book_at ? (
              <span className={"input-label__error"}>{t("time")}</span>
            ) : (
              t("time")
            )}</legend>
          <InputInLabel
            clazz={`${errors.book_at ? " error" : ""}`}
            register={{ ...register("book_at") }}
            registerName={"book_at"}
            setValue={setValue}
            placeholder={"18:00"}
            type={"time"}
            required
          >
          </InputInLabel>
        </fieldset>
      </div>

      <div className="popup-form__radio-grid gap-8">
        {!!pricesIncall?.length &&
          active.incall &&
          pricesIncall.map((i) => CheckboxTemplate(i, "incall"))}

        {!!pricesOutcall.length &&
          active.outcall &&
          pricesOutcall.map((i) => CheckboxTemplate(i, "outcall"))}

        {!!pricesEscort.length &&
          active.escort &&
          pricesEscort.map((i) => CheckboxTemplate(i, "escort"))}
      </div>

      <div className="filter__checkbox-buttons gap-8">
        {data.place.map((i, index) => {
          if (i.isVis) {
            return (
                <InputInLabel
                  register={{ ...register("place") }}
                  key={i.id}
                  name={i.name}
                  id={i.id}
                  value={i.value}
                  type={"radio"}
                  clazz={"popup-order__radio"}
                  checked={i.checked}
                  onClick={i.onClick}
                >
                  {i.title}
                </InputInLabel>
            );
          } else {
            return null;
          }
        })}
      </div>

      {active.outcall || active.escort ? (
        <div className="search filter__map-input">
          <span className={"filter__map-title"}>
            
          </span>
          <fieldset>
            <legend>
              {errors.address ? (
                <span
                  className={`input-label__error ${
                    errors.address ? "error" : ""
                  }`}
                >
                  {errors.address.message}
                </span>
              ) : (
                t("youraddress")
              )}
            </legend>
            <GeoInput
                value={addressUser}
                name={"address"}
                id="geosuggest__input-create"
                clazz={`header__search-input`}
                placeholder={t("fulladdress")}
                register={register}
                setValue={setValue}
              />
          </fieldset>

          <Button
              title={t("chekmyplace")}
              onClick={handleLocationClick}
              name={"location"}
              square={true}
              size={"l"}
              clazz={isAddressActive ? "button--tetriary bg-color-500 mt-10" : "button--tetriary mt-10"}
          >
              <Icon size={"xl"} spritePath={"location"}/>
          </Button>
          
        </div>
      ) : address && !!pricesIncall?.length ? (
        <div className={"popup-form__inner gap-4 popup-order__address"}>
          <p className={"m-0"}>{t("adresofmodel")}</p>
          <button
            className={"color-green text-left td-underline"}
            onClick={(e) => {
              e.preventDefault();
              dispatch(setOneModelPosition(slug));
              dispatch(setStoriesMap(true));
            }}
          >
            {address}
          </button>
        </div>
      ) : null}

      <div className="connectad__day popup-form__inner fd-row flex-wrap gap-10 justify-sb color-700">
        <div className="popup-form__inner">
          <p className={"m-0"}>{t("paynow")}</p>
          <span className={"title fz-32 lh-24 color-main currency--erocoin"}>
            {lang === "cz" ? discountCurrency : discountHtml}
          </span>
        </div>
        <div className="popup-form__inner width-max">
          <p className={"m-0"}>{t("remainder")}</p>
          <span className={"title fz-32 lh-24 color-main"}>
            {price - discountHtml}
            {lang === "cz" ? "Kč" : "€"}
          </span>
        </div>
      </div>
      <p className={"fz-13"}>{t("amounttranfered")}</p>

      {isLoading ? (
        <Loader />
      ) : (
        <div className="d-flex fd-column gap-10">
          <fieldset>
            <legend>
            {errors.phone ?
                <span className={"input-label__error"}>
                  {errors.phone.message}
                </span>
              : <span>{t("yourphone")}</span>}
            </legend>
            <InputInLabel
              clazz={`${errors.phone ? " error" : ""}`}
              id={"orderPhone"}
              type={"tel"}
              placeholder={t("yourphone")}
              register={{ ...register("phone") }}
              autocomplete={"tel"}
              maskValue={phoneValue}
              setMaskValue={setPhoneValue}
              valueLoad={valueLoad}
              required
            >
              
            </InputInLabel>
          </fieldset>

          <div className={"d-flex justify-sb gap-8"}>
            <fieldset>
              <legend><span>Whatsapp</span></legend>
              <InputInLabel
                id={"orderWaPhone"}
                clazz={"w-100"}
                type={"tel"}
                placeholder={"Whatsapp"}
                register={{ ...register("whatsapp") }}
                autocomplete={"tel"}
                maskValue={waValue}
                setMaskValue={setWaValue}
                valueLoad={valueLoad}
              >
              </InputInLabel>
            </fieldset>
          </div>
        </div>
      )}

      <LoadingButton
        isLoading={isLoadingGlobal}
        buttonType={"submit"}
        size={"l"}
        clazz={"button--green"}
        disabled={!newDate}
      >
        {t("sendpreorder")}
      </LoadingButton>
    </Popup>
  );
};
export default OrderModel;
