import useLadyService from "@/services/LadyService";
import { useEffect } from "react";
import { Loader } from "@/components/ui";
import { useDispatch, useSelector } from "react-redux";
import { getApiUserReady } from "@/stores/slices/userSlice";
import { hideAdult, setAuthorizationStatus } from "@/stores/slices/popupSlice";
import { Authorization } from "@/components/popups";
import { useLocation } from "react-router-dom";

export const RedirectLk = () => {
  const { userType, navigate, token } = useLadyService();
  const apiReady = useSelector(getApiUserReady);
  const dispatch = useDispatch()
  const {path} = useLocation()

  useEffect(() => {
    if(apiReady) {
      if (userType !== "default" && token) {
        const targetPath = `/lk/${userType}`;
        navigate(targetPath);
      } else {
        
        dispatch(hideAdult(false));
        dispatch(setAuthorizationStatus(true))
       // navigate("/");
      }
    }
    if (!token && !apiReady) {
      dispatch(hideAdult(false));
      dispatch(setAuthorizationStatus(true))
      //navigate("/");
    }
    
  }, [userType, token, navigate, apiReady]);

  return (
      <>
        <Loader height={"100"}></Loader>
        <Authorization/>
      </>
  );
};

export default RedirectLk