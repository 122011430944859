import {
  getAddReviewStatus,
  setAddReviewStatus,
} from "@/stores/slices/popupSlice";
import { Button, Icon, InputInLabel } from "@/components/ui";
import { useSelector } from "react-redux";
import { Rating, Star } from "@smastrom/react-rating";
import "@smastrom/react-rating/style.css";
import { Controller, useForm } from "react-hook-form";
import { makeRequest } from "@/services/makeRequest";

import showToast from "../../toast/Toast";

import "./addReview.scss";

import useLadyService from "@/services/LadyService";
import {Popup} from "../index";

const AddReview = ({ name, id, reviewable_type }) => {
  const AddReviewValue = useSelector(getAddReviewStatus);

  const { t, dispatch } = useLadyService();

  const { register, handleSubmit, reset, control } = useForm({
    mode: "onSubmit",
    defaultValues: {
      reviewable_type,
      reviewable_id: id,
      body: "",
      score_quality: 0,
      score_atmosphere: 0,
      score_communication: 0,
      score_charm: 0,
      score_emotions: 0,
    },
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    const fetchData = async () => {
      try {
        const route = `reviews`;
        const method = "PUT";
        const payload = {
          ...data,
        };

        await makeRequest({ route, method, payload });
        showToast({
          message: t("successreview"),
          subMessage: t("willpublish"),
          variant: "success",
        });
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };
    dispatch(setAddReviewStatus(false))
    fetchData();
    reset();
  };

  const onClose = () => {
    reset();
    dispatch(setAddReviewStatus(false));
  };
  const starsStyles = {
    itemShapes: Star,
  };

  const RatingComponent = (props) => {
    const { name } = props;
    return (
      <Controller
        control={control}
        name={name}
        rules={{
          validate: (clazz) => clazz > 0,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Rating
            className={"rating-stars rating-stars--gold"}
            value={value}
            isRequired
            spaceInside="none"
            onChange={onChange}
            onBlur={onBlur}
            itemStyles={starsStyles}
          />
        )}
      />
    );
  };

  return (
      <Popup
        open={!!AddReviewValue}
        setOpen={() => onClose()}
        id="addReview"
        method="POST"
        onSubmit={handleSubmit(onSubmit)}
        clazz="gap-16"
      >

          <h2>{t("writereview")}</h2>
          <div className="popup-form__inner gap-10">

            <div className={"popup-form__inner text-center gap-4"}>
              <p className={"color-600 fz-13 m-0"}>{t("modelname")}</p>
              <span className={"color-green p1"}>{name}</span>
            </div>
            <div className="stars-box">
              <span>
                {t("qualityservices")}
                <RatingComponent name={"score_quality"}/>
              </span>
              <span>
                {t("atmosphere")}
                <RatingComponent name={"score_atmosphere"}/>
              </span>
              <span>
                {t("communication")}
                <RatingComponent name={"score_communication"}/>
              </span>
              <span>
                {t("attractiveness")}
                <RatingComponent name={"score_charm"}/>
              </span>
              <span>
                {t("sensibility")}
                <RatingComponent name={"score_emotions"}/>
              </span>
            </div>
          </div>

          <div className="popup-form__inner">
            <InputInLabel
              register={{...register("body")}}
              type={"textarea"}
              id={"AddReviewText"}
            >
              {t("textreview")}
            </InputInLabel>

            <Button
              size={"l"}
              buttonType={"submit"}
              clazz={"button--green"}
            >
              {t("send")}
            </Button>
          </div>
      </Popup>
  );
};

export default AddReview;
