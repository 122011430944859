import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {makeRequest} from "@/services/makeRequest";
import {getSeoContent, setSeoContent, setSeoDescription, setSeoTitle} from "@/stores/slices/titleSlice";
import {useDispatch, useSelector} from "react-redux";

const SEOBlock = ({}) => {
    const location = useLocation();
    const pageSlug = location?.pathname.slice(4);
    const dispatch = useDispatch();
    const seoContent = useSelector(getSeoContent)
    const fetchData = async () => {
        const route = `static/${pageSlug}`;
        const method = "GET";

        makeRequest({route, method}).then((response) => {
            dispatch(setSeoContent(response?.content))
            dispatch(setSeoTitle(response?.seo_title))
            dispatch(setSeoDescription(response?.seo_description))
        }).catch(() => {
            dispatch(setSeoTitle(false))
            dispatch(setSeoDescription(false))
            dispatch(setSeoContent(false))
        })
    };

    useEffect(() => {
        fetchData();
    }, [location])

    if(seoContent){
        return (
            <>
                <div className="container w-100 gap-0" dangerouslySetInnerHTML={{ __html: seoContent }}></div>
            </>
        )
    }

}

export default SEOBlock;
