import { Helmet } from "react-helmet";
import { Button, Icon } from "@/components/ui";
import useLadyService from "../../../services/LadyService";
import showToast from "../../toast/Toast";
import {Popup} from "../index";

const DeactivateRefillPopup = (props) => {
  const { open, setOpen } = props;

  const { t } = useLadyService();

  const handleCloseDelete = () => {
    setOpen(false);
  };

  return (
    <Popup
      open={open}
      setOpen={handleCloseDelete}
      clazz={'gap-16'}
      container={'div'}
    >
        <h2>{t("suredeleterefill") + "?"}</h2>
        <div className="popup-form__buttons">
          <Button
            size={"l"}
            clazz={"button--secondary justify-center"}
            onClick={handleCloseDelete}
          >
            {t("cancel")}
          </Button>

          <Button
            size={"l"}
            clazz={"button--error justify-center"}
            onClick={() => {
              showToast({
                message: "Запрос на сервер",
                variant: "success",
              });
              setOpen(false)
            }}
          >
            {t("deactive")}
          </Button>
        </div>
    </Popup>
  );
};

export default DeactivateRefillPopup;
