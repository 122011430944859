import { useEffect, useState } from "react";
import { makeRequest } from "@/services/makeRequest";
import {  useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {getCountrySlug, getUserCurrentCity, getUserRegion} from "../../../stores/slices/userSlice";
import { ButtonLink } from "../../ui";
import { Helmet } from "react-helmet";
import useTitle from "@/hooks/useTitle";
import useLadyService from "@/services/LadyService";
import { getSelections} from "../../../stores/slices/modelsState";

const Selection = ({isPage = true}) => {

  const {t, navigate, lang, dispatch, scrollToTop} = useLadyService()

  const currentCity = useSelector(getUserCurrentCity);
  const defaultSelections = useSelector(getSelections)
  const [data, setData] = useState(defaultSelections);
  const region = useSelector(getUserRegion);
  const { city } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = `selections/${city}`;
        const method = "GET";

        const result = await makeRequest({ route, method });
        if (!result) {
          navigate("/404");
        }

        if (result) {
          setData(result);
        }
      } catch (error) {}
    };

    if(city)fetchData();
  }, [city]);

  useEffect(() => {
    scrollToTop()
  }, []);

  const countrySlug = useSelector(getCountrySlug)
  let title = t("selectiontitle", {City: currentCity.title ?? "", Country: `, ${region}`})
  let h1 = t("selectionmodels", {City: currentCity.title ?? "", Country: `, ${region}`})
  if (countrySlug) {
    title = t("selectiontitle", {City: "", Country: ""}) + " " + currentCity?.title
    h1 = t("selectionmodels", {City: "", Country: ""}) + " " + currentCity?.title
  }
  useEffect(() => {
    if (isPage) {
      document.title = title
    }
  }, [isPage, title]);
  return (
    <>
      {isPage ? (
      <Helmet>
        <meta name="description" content={t("selectdesc", {City: currentCity.title ?? "", Country: region})} />
      </Helmet>
      ) : null}
      <main>
        <section className="navigation__container">
            {isPage ? (<h1>{h1}</h1>) : (<h2>{h1}</h2>) }
          <div className="d-flex gap-12 flex-wrap flex-fit-content">
            {!!data?.length &&
              data.map((item) => {
                const segments = item.split("/");
                const lastSegment = segments[segments.length - 1];
                const title = lastSegment
                  .split("-")
                  .map((str) => {
                    switch (str) {
                      case "elitelady":
                        return "elite";
                      case "vchat":
                        return "videochat";
                      case "ero":
                        return "erocontent";
                      case "booking":
                        return "onlineorder";
                      case "anal":
                        return "anal-sex";
                      case "asian":
                        return "asiatag";
                      default:
                        return str;
                    }
                  })
                  .map((result) => t(result));

                return (
                  <ButtonLink
                    href={`/${lang}/${city}/${lastSegment}`}
                    title={title.join(", ")}
                    size={"xs"}
                    clazz={"button_outline--black _no-underline"}
                    onClick={() => {window.location.href = `/${lang}/${city}/${lastSegment}`}}
                  >
                    {title.join(", ")}
                  </ButtonLink>
                );
              })}
          </div>
        </section>
      </main>
    </>
  );
};
export default Selection;
