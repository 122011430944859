export const phoneCodeList = [

    // Группа 2: Страны Центральной Европы по убыванию населения
    { id: 49, title: "DE +49", value: '+49', icon: "DE", population: 83237992 },    // Германия
    { id: 48, title: "PL +48", value: '+48', icon: "PL", population: 37958143 },    // Польша
    { id: 36, title: "HU +36", value: '+36', icon: "HU", population: 9684679 },     // Венгрия
    { id: 43, title: "AT +43", value: '+43', icon: "AT", population: 9066700 },     // Австрия
    { id: 381, title: "RS +381", value: '+381', icon: "RS", population: 6871547 },   // Сербия
    { id: 423, title: "LI +423", value: '+423', icon: "LI", population: 39039 },      // Лихтенштейн

    // Группа 3: Остальные страны по убыванию населения
    { id: 62, title: "ID +62", value: '+62', icon: "ID", population: 273523600 },   // Индонезия
    { id: 52, title: "MX +52", value: '+52', icon: "MX", population: 128932753 },   // Мексика
    { id: 234, title: "NG +234", value: '+234', icon: "NG", population: 213400000 },   // Нигерия
    { id: 55, title: "BR +55", value: '+55', icon: "BR", population: 214754000 },     // Бразилия
    { id: 91, title: "IN +91", value: '+91', icon: "IN", population: 1406631776 },     // Индия
    { id: 95, title: "MM +95", value: '+95', icon: "MM", population: 54409800 },    // Мьянма
    { id: 20, title: "EG +20", value: '+20', icon: "EG", population: 102334404 },   // Египет
    { id: 90, title: "TR +90", value: '+90', icon: "TR", population: 84339067 },    // Турция
    { id: 44, title: "GB +44", value: '+44', icon: "GB", population: 68207116 },    // Великобритания
    { id: 33, title: "FR +33", value: '+33', icon: "FR", population: 67485768 },    // Франция
    { id: 39, title: "IT +39", value: '+39', icon: "IT", population: 59236213 },    // Италия
    { id: 27, title: "ZA +27", value: '+27', icon: "ZA", population: 60041994 },    // ЮАР
    { id: 57, title: "CO +57", value: '+57', icon: "CO", population: 51515854 },    // Колумбия
    { id: 59, title: "VE +59", value: '+59', icon: "VE", population: 28435943 },    // Венесуэла
    { id: 81, title: "JP +81", value: '+81', icon: "JP", population: 125700000 },    // Япония
    { id: 34, title: "ES +34", value: '+34', icon: "ES", population: 47351567 },    // Испания
    { id: 84, title: "VN +84", value: '+84', icon: "VN", population: 97338000 },   // Вьетнам
    { id: 213, title: "DZ +213", value: '+213', icon: "DZ", population: 44177969 },   // Алжир
    { id: 92, title: "PK +92", value: '+92', icon: "PK", population: 231402117 },   // Пакистан
    { id: 255, title: "TZ +255", value: '+255', icon: "TZ", population: 63588347 },   // Танзания
    { id: 60, title: "MY +60", value: '+60', icon: "MY", population: 33573900 },    // Малайзия
    { id: 966, title: "SA +966", value: '+966', icon: "SA", population: 35950000 },   // Саудовская Аравия
    { id: 93, title: "AF +93", value: '+93', icon: "AF", population: 40218234 },    // Афганистан
    { id: 30, title: "GR +30", value: '+30', icon: "GR", population: 10678632 },    // Греция
    { id: 221, title: "SN +221", value: '+221', icon: "SN", population: 16743930 },   // Сенегал
    { id: 40, title: "RO +40", value: '+40', icon: "RO", population: 19186162 },    // Румыния
    { id: 54, title: "AR +54", value: '+54', icon: "AR", population: 45808747 },    // Аргентина
    { id: 256, title: "UG +256", value: '+256', icon: "UG", population: 45853699 },   // Уганда
    { id: 64, title: "NZ +64", value: '+64', icon: "NZ", population: 5135300 },     // Новая Зеландия
    { id: 41, title: "CH +41", value: '+41', icon: "CH", population: 8654622 },     // Швейцария
    { id: 82, title: "KR +82", value: '+82', icon: "KR", population: 51784059 },    // Южная Корея
    { id: 31, title: "NL +31", value: '+31', icon: "NL", population: 17590672 },    // Нидерланды
    { id: 1, title: "US +1", value: '+1', icon: "US", population: 331449281 },     // США
    { id: 32, title: "BE +32", value: '+32', icon: "BE", population: 11589623 },    // Бельгия
    { id: 358, title: "FI +358", value: '+358', icon: "FI", population: 5548242 },    // Финляндия
    { id: 233, title: "GH +233", value: '+233', icon: "GH", population: 31072945 },    // Гана
    { id: 94, title: "LK +94", value: '+94', icon: "LK", population: 21413000 },    // Шри-Ланка
    { id: 351, title: "PT +351", value: '+351', icon: "PT", population: 10167925 },   // Португалия
    { id: 263, title: "ZW +263", value: '+263', icon: "ZW", population: 15092171 },   // Зимбабве
    { id: 359, title: "BG +359", value: '+359', icon: "BG", population: 6924716 },    // Болгария
    { id: 86, title: "CN +86", value: '+86', icon: "CN", population: 1444216107 },   // Китай
    { id: 994, title: "AZ +994", value: '+994', icon: "AZ", population: 10127877 },    // Азербайджан
    { id: 375, title: "BY +375", value: '+375', icon: "BY", population: 9449323 },    // Беларусь
    { id: 66, title: "TH +66", value: '+66', icon: "TH", population: 69950850 },    // Таиланд
    { id: 212, title: "MA +212", value: '+212', icon: "MA", population: 37076584 },   // Марокко
   { id: 237, title: "CM +237", value: '+237', icon: "CM", population: 26545863 },    // Камерун
    { id: 254, title: "KE +254", value: '+254', icon: "KE", population: 54985698 },    // Кения
     { id: 63, title: "PH +63", value: '+63', icon: "PH", population: 110800000 },   // Филиппины
    { id: 243, title: "CD +243", value: '+243', icon: "CD", population: 92377917 },   // ДР Конго
    { id: 373, title: "MD +373", value: '+373', icon: "MD", population: 2620000 },   // Молдова
    { id: 98, title: "IR +98", value: '+98', icon: "IR", population: 83992953 },    // Иран
    { id: 387, title: "BA +387", value: '+387', icon: "BA", population: 3280819 },   // Босния и Герцеговина
    { id: 382, title: "ME +382", value: '+382', icon: "ME", population: 628066 },     // Черногория
    { id: 218, title: "LY +218", value: '+218', icon: "LY", population: 6871292 },    // Ливия
    { id: 968, title: "OM +968", value: '+968', icon: "OM", population: 5106626 },    // Оман
    { id: 972, title: "IL +972", value: '+972', icon: "IL", population: 9500000 },    // Израиль
    { id: 966, title: "SA +966", value: '+966', icon: "SA", population: 35950000 },    // Саудовская Аравия
    { id: 355, title: "AL +355", value: '+355', icon: "AL", population: 2842321 },     // Албания
   { id: 353, title: "IE +353", value: '+353', icon: "IE", population: 5011295 },      // Ирландия
    { id: 244, title: "AO +244", value: '+244', icon: "AO", population: 32866272 },   // Ангола
    { id: 962, title: "JO +962", value: '+962', icon: "JO", population: 10203134 },    // Иордания
    { id: 421, title: "SK +421", value: '+421', icon: "SK", population: 5457873 },    // Словакия
   { id: 260, title: "ZM +260", value: '+260', icon: "ZM", population: 19473125 },    // Замбия
   { id: 258, title: "MZ +258", value: '+258', icon: "MZ", population: 32970517 },    // Мозамбик
   { id: 386, title: "SI +386", value: '+386', icon: "SI", population: 2108708 },     // Словения
   { id: 880, title: "BD +880", value: '+880', icon: "BD", population: 164689383 },   // Бангладеш
   { id: 58, title: "VE +58", value: '+58', icon: "VE", population: 28435943 },     // Венесуэла
   { id: 45, title: "DK +45", value: '+45', icon: "DK", population: 5825641 },     // Дания
   { id: 53, title: "CU +53", value: '+53', icon: "CU", population: 11189595 },     // Куба
   { id: 230, title: "MU +230", value: '+230', icon: "MU", population: 1266000 },     // Маврикий
   { id: 265, title: "MW +265", value: '+265', icon: "MW", population: 19129952 },    // Малави
   { id: 371, title: "LV +371", value: '+371', icon: "LV", population: 1883000 },     // Латвия
   { id: 234, title: "NG +234", value: '+234', icon: "NG", population: 213400000 },    // Нигерия
    
    //Оставшиеся страны:
    { id: 352, title: "LU +35", value: '+352', icon: "LU", population: 632275 },    // Люксембург
    { id: 370, title: "LT +37", value: '+370', icon: "LT", population: 2800666 },    // Литва
    { id: 47, title: "NO +4", value: '+47', icon: "NO", population: 5488984 },     // Норвегия
   { id: 291, title: "ER +29", value: '+291', icon: "ER", population: 3601467 },     // Эритрея
   { id: 51, title: "PE +51", value: '+51', icon: "PE", population: 33396698 },    // Перу
   { id: 501, title: "BZ +501", value: '+501', icon: "BZ", population: 408487 },     // Белиз
   { id: 504, title: "HN +504", value: '+504', icon: "HN", population: 9904608 },    // Гондурас
   { id: 505, title: "NI +505", value: '+505', icon: "NI", population: 6702735 },    // Никарагуа
   { id: 506, title: "CR +506", value: '+506', icon: "CR", population: 5153000 },    // Коста-Рика
   { id: 508, title: "PM +508", value: '+508', icon: "PM", population: 6069 },      // Сен-Пьер и Микелон
   { id: 509, title: "HT +509", value: '+509', icon: "HT", population: 11402528 },    // Гаити
   { id: 590, title: "GP +590", value: '+590', icon: "GP", population: 390252 },     // Гваделупа
   { id: 591, title: "BO +591", value: '+591', icon: "BO", population: 11673021 },    // Боливия
   { id: 592, title: "GY +592", value: '+592', icon: "GY", population: 786559 },     // Гайана
   { id: 593, title: "EC +593", value: '+593', icon: "EC", population: 17797737 },    // Эквадор
   { id: 594, title: "GF +594", value: '+594', icon: "GF", population: 309603 },     // Французская Гвиана
   { id: 595, title: "PY +595", value: '+595', icon: "PY", population: 7132530 },    // Парагвай
   { id: 596, title: "MQ +596", value: '+596', icon: "MQ", population: 375705 },     // Мартиника
   { id: 597, title: "SR +597", value: '+597', icon: "SR", population: 586632 },     // Суринам
   { id: 598, title: "UY +598", value: '+598', icon: "UY", population: 3473730 },    // Уругвай
   { id: 599, title: "CW +599", value: '+599', icon: "CW", population: 163483 },     // Кюрасао
   { id: 61, title: "AU +61", value: '+61', icon: "AU", population: 25917000 },    // Австралия
   { id: 65, title: "SG +65", value: '+65', icon: "SG", population: 5453600 },     // Сингапур
   { id: 670, title: "TL +670", value: '+670', icon: "TL", population: 1342800 },     // Восточный Тимор
   { id: 672, title: "AQ +672", value: '+672', icon: "AQ", population: 0 },         // Антарктида
   { id: 673, title: "BN +673", value: '+673', icon: "BN", population: 468400 },      // Бруней
   { id: 674, title: "NR +674", value: '+674', icon: "NR", population: 10876 },      // Науру
   { id: 675, title: "PG +675", value: '+675', icon: "PG", population: 9136100 },     // Папуа-Новая Гвинея
   { id: 676, title: "TO +676", value: '+676', icon: "TO", population: 100200 },      // Тонга
   { id: 677, title: "SB +677", value: '+677', icon: "SB", population: 724273 },      // Соломоновы Острова
   { id: 678, title: "VU +678", value: '+678', icon: "VU", population: 312000 },      // Вануату
   { id: 679, title: "FJ +679", value: '+679', icon: "FJ", population: 909389 },      // Фиджи
   { id: 680, title: "PW +680", value: '+680', icon: "PW", population: 18058 },      // Палау
   { id: 681, title: "WF +681", value: '+681', icon: "WF", population: 11558 },      // Уоллис и Футуна
   { id: 682, title: "CK +682", value: '+682', icon: "CK", population: 17458 },      // Острова Кука
   { id: 683, title: "NU +683", value: '+683', icon: "NU", population: 1626 },       // Ниуэ
   { id: 685, title: "WS +685", value: '+685', icon: "WS", population: 205557 },      // Самоа
   { id: 686, title: "KI +686", value: '+686', icon: "KI", population: 121100 },      // Кирибати
   { id: 687, title: "NC +687", value: '+687', icon: "NC", population: 271407 },      // Новая Каледония
   { id: 688, title: "TV +688", value: '+688', icon: "TV", population: 11992 },      // Тувалу
   { id: 689, title: "PF +689", value: '+689', icon: "PF", population: 283007 },      // Французская Полинезия
   { id: 690, title: "TK +690", value: '+690', icon: "TK", population: 1500 },       // Токелау
   { id: 691, title: "FM +691", value: '+691', icon: "FM", population: 103500 },      // Микронезия
   { id: 692, title: "MH +692", value: '+692', icon: "MH", population: 59610 },       // Маршалловы Острова
   { id: 850, title: "KP +850", value: '+850', icon: "KP", population: 25955000 },    // Северная Корея
   { id: 852, title: "HK +852", value: '+852', icon: "HK", population: 7500700 },     // Гонконг
   { id: 853, title: "MO +853", value: '+853', icon: "MO", population: 649300 },      // Макао
   { id: 855, title: "KH +855", value: '+855', icon: "KH", population: 16718965 },   // Камбоджа
   { id: 856, title: "LA +856", value: '+856', icon: "LA", population: 7421232 },    // Лаос
   { id: 886, title: "TW +886", value: '+886', icon: "TW", population: 23561236 },   // Тайвань
   { id: 960, title: "MV +960", value: '+960', icon: "MV", population: 540544 },      // Мальдивы
   { id: 961, title: "LB +961", value: '+961', icon: "LB", population: 6848925 },    // Ливан
   { id: 963, title: "SY +963", value: '+963', icon: "SY", population: 18275702 },   // Сирия
   { id: 965, title: "KW +965", value: '+965', icon: "KW", population: 4270571 },    // Кувейт
   { id: 967, title: "YE +967", value: '+967', icon: "YE", population: 30491000 },    // Йемен
   { id: 970, title: "PS +970", value: '+970', icon: "PS", population: 5227193 },    // Палестина
   { id: 971, title: "AE +971", value: '+971', icon: "AE", population: 9991083 },    // ОАЭ
   { id: 975, title: "BT +975", value: '+975', icon: "BT", population: 771608 },      // Бутан
   { id: 976, title: "MN +976", value: '+976', icon: "MN", population: 3296866 },    // Монголия
   { id: 977, title: "NP +977", value: '+977', icon: "NP", population: 29136808 },   // Непал
   { id: 992, title: "TJ +992", value: '+992', icon: "TJ", population: 9750000 },    // Таджикистан
   { id: 993, title: "TM +993", value: '+993', icon: "TM", population: 6031187 },    // Туркменистан
   { id: 995, title: "GE +995", value: '+995', icon: "GE", population: 3978000 },    // Грузия
   { id: 996, title: "KG +996", value: '+996', icon: "KG", population: 6630623 },    // Киргизия
   { id: 998, title: "UZ +998", value: '+998', icon: "UZ", population: 34943208 },   // Узбекистан
   { id: 20, title: "EG +20", value: '+20', icon: "EG", population: 102334404 },   // Египет
   { id: 211, title: "SS +211", value: '+211', icon: "SS", population: 11193729 },   // Южный Судан
   { id: 220, title: "GM +220", value: '+220', icon: "GM", population: 2484948 },    // Гамбия
   { id: 222, title: "MR +222", value: '+222', icon: "MR", population: 4649660 },    // Мавритания
   { id: 223, title: "ML +223", value: '+223', icon: "ML", population: 20855735 },   // Мали
   { id: 224, title: "GN +224", value: '+224', icon: "GN", population: 13531600 },   // Гвинея
   { id: 225, title: "CI +225", value: '+225', icon: "CI", population: 31075387 },   // Кот-д'Ивуар
   { id: 226, title: "BF +226", value: '+226', icon: "BF", population: 21935373 },   // Буркина-Фасо
   { id: 227, title: "NE +227", value: '+227', icon: "NE", population: 25126827 },   // Нигер
   { id: 228, title: "TG +228", value: '+228', icon: "TG", population: 8478979 },    // Того
   { id: 229, title: "BJ +229", value: '+229', icon: "BJ", population: 13078482 },   // Бенин
   { id: 231, title: "LR +231", value: '+231', icon: "LR", population: 5057681 },    // Либерия
   { id: 232, title: "SL +232", value: '+232', icon: "SL", population: 8605718 },    // Сьерра-Леоне
   { id: 235, title: "TD +235", value: '+235', icon: "TD", population: 16913882 },   // Чад
   { id: 236, title: "CF +236", value: '+236', icon: "CF", population: 5457745 },    // ЦАР
   { id: 238, title: "CV +238", value: '+238', icon: "CV", population: 555987 },     // Кабо-Верде
   { id: 239, title: "ST +239", value: '+239', icon: "ST", population: 219159 },     // Сан-Томе и Принсипи
   { id: 240, title: "GQ +240", value: '+240', icon: "GQ", population: 1468777 },    // Экваториальная Гвинея
   { id: 241, title: "GA +241", value: '+241', icon: "GA", population: 2334805 },    // Габон
   { id: 242, title: "CG +242", value: '+242', icon: "CG", population: 5657000 },    // Республика Конго
   { id: 245, title: "GW +245", value: '+245', icon: "GW", population: 2000000 },     // Гвинея-Бисау
   { id: 246, title: "IO +246", value: '+246', icon: "IO", population: 3000 },        // Британская территория в Индийском океане
   { id: 247, title: "AC +247", value: '+247', icon: "AC", population: 806 },        // Остров Вознесения
   { id: 248, title: "SC +248", value: '+248', icon: "SC", population: 98462 },      // Сейшелы
   { id: 249, title: "SD +249", value: '+249', icon: "SD", population: 44900000 },   // Судан
   { id: 250, title: "RW +250", value: '+250', icon: "RW", population: 12952218 },   // Руанда
]