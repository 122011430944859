
import { useSelector } from "react-redux";
import {
  setLadyForCall,
  getCreateVideoRoomModalStatus,
  getLadyForCall,
  setVideoCallRoom,
} from "@/stores/slices/videoCallSlice";
import { Helmet } from "react-helmet";
import { Button, Icon } from "@/components/ui";
import { useEffect } from "react";
import useVideoCallService from "@/services/VideoChatService";

import "./CreateVideoCallRoom.scss";

import { toast } from "react-toastify";
import { getUserTariff } from "../../../stores/slices/userSlice";
import useLadyService from "../../../services/LadyService";
import {Popup} from "../index";

const CreateVideoCallRoom = () => {
  const { createVideoCallRoom } = useVideoCallService();

  const { t, dispatch } = useLadyService();

  const modalStatus = useSelector(getCreateVideoRoomModalStatus);
  const userTariff = useSelector(getUserTariff);
  const { data } = userTariff;

  const tariff = data;

  const ladyForCall = useSelector(getLadyForCall);

  const createRoom = () => {
    createVideoCallRoom(ladyForCall.profile.slug)
      .then((response) => {
        if (!response.data.status) {
          toast.warning("busy");
          return;
        }
        if (response.data.room_id) {
          dispatch(setVideoCallRoom(response.data));
        }
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  };

  const handleClose = () => {
    dispatch(setLadyForCall(null))
  }

  return (
    <Popup
      open={!!modalStatus}
      setOpen={handleClose}
      wrapperClazz="start-videochat"
      container={'div'}
    >
        <div className="popup-form__inner">
          <h2>{t("startvideochat")}</h2>
          <p className={"p2 text-center"}>{t("meetmodelonline")}</p>
        </div>

        <div className="popup-form__inner">
          <div className="start-videochat__box">
              <div>
                <p className={"p2 color-green"}>{t("freewithtariff")} </p>
                <span className={"title_h3 color-green"}>{tariff.free_minutes} {t("minit")}</span>
              </div>
            <div>
              <p className={"p2"}>
                {tariff.free_minutes > 0  ? t("after") : t("pricever")}{" "}
              </p>
              <span className={"title_h3"}>
                {/*<span className={"old-price"}>3€</span>*/}
                <span>
                    {tariff.call_price}
                    <Icon
                        title={t("EroCoins")}
                        clazz={`color-main ml-4`}
                        spritePath={"erocoin"}
                        size={"s"}
                    />
                  </span>
                /{t("inminute")}
              </span>
            </div>
          </div>

          <p className={"p2 text-center"}>{t("partlytransfered")}</p>
        </div>
        <Button
          size={"l"}
          clazz={"button--green"}
          onClick={() => dispatch(createRoom)}
        >
          {" "}
          {t("call")}
        </Button>
    </Popup>
  );
};
export default CreateVideoCallRoom;
