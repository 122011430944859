import { useEffect, useState } from "react";
import QRCode from "qrcode";
import useLadyService from "@/services/LadyService";

const QRCodeGenerator = ({ currency, iban, currentSum }) => {
  const { userId } = useLadyService();
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const windowWidth = window.innerWidth;
  useEffect(() => {
    generateQRCode();
  }, [currentSum]);

  const generateQRCode = () => {
    const result = `SPD*1.0*ACC:${iban}+BACXCZPP*AM:${currentSum}*CC:${currency.toUpperCase()}*RN:IT4L*MSG:NAKUP EROCOINU, CLIENTID ${userId}*X-VS:${userId}`;
    QRCode.toDataURL(result, { errorCorrectionLevel: "H" }, (error, url) => {
      if (error) {
        console.error('error: ', error);
        return;
      }
      setQrCodeUrl(url);
    });
  };

  return (
    <>
      {qrCodeUrl && (
        <img
          src={qrCodeUrl}
          alt="QR Code"
          style={{
            borderRadius: "16px",
          }}
        />
      )}
    </>
  );
};

export default QRCodeGenerator;
