import { Checkbox, Button, LoadingButton } from "@/components/ui";
import { Link } from "react-router-dom";
import useLadyService from "@/services/LadyService";

export const Checkboxes = ({
  setRulesError,
  rulesError,
  setIsRulesCheck,
  isRulesCheck,
  setIsMonthCheck,
  isMonthCheck,
  isFactureCheck,
  setIsFactureCheck,
  typePayCheckbox,
  setTypePayCheckbox,
  type,
  invoiceLoad,
  fetchInvoice
}) => {
  const { t } = useLadyService();

  const isCrypto = type === "crypto";
  const isBank = type === "bank";

  const handleLink = (e, link) => {
    e.preventDefault();

    const url = ` ${link}`;
    window.open(url, "_blank");
  };

  return (
    <>
      {isBank && (
        <p className="color-main">
          {t("read") + " "}
          <Link
            to={"/rules"}
            target="_blank"
            className={"color-green hover-line"}
            onClick={(e) => handleLink(e, "/rules")}
          >
            {t("portalules")}
          </Link>
          {" " + t("and") + " "}
          <Link
            to={"/rules#payments"}
            target="_blank"
            className={"color-green hover-line"}
            onClick={(e) => handleLink(e, "/rules#payments")}
          >
            {t("conditions")}
          </Link>
        </p>
      )}
      {!isBank && (
        <Checkbox
          onChange={() => {
            setIsRulesCheck((prev) => !prev);
            if (rulesError) {
              setRulesError(false);
            }
          }}
          checked={isRulesCheck}
          id={"pay-check-rules"}
          clazz={`width-max mt-8 ${rulesError ? "" : "mb-8"}`}
          title={t("iagree")}
        >
          <Link
            to={"/rules"}
            target="_blank"
            className={"color-green hover-line ml-4"}
          >
            {t("portalules")}
          </Link>
        </Checkbox>
      )}
      {rulesError && !isBank && (
        <span className="color-red-700">{t("required")}</span>
      )}
      {!isCrypto && !isBank && (
        <div className="d-flex gap-4 align-center mb-8 flex-wrap">
          <Checkbox
            id={"pay-check-month"}
            clazz={"width-max"}
            title={t("monthlyearing")}
            onChange={() => setIsMonthCheck((prev) => !prev)}
            checked={isMonthCheck}
          ></Checkbox>
          <span>{t("cancelanytime")}</span>
        </div>
      )}
      <div className="d-flex align-center flex-wrap gap-12 mb-12 topup-facture__checkbox">
        <Checkbox
          onChange={() => {
            setTypePayCheckbox("phiz");
            setIsFactureCheck((prev) => !prev);
          }}
          checked={!!isFactureCheck}
          id={"pay-check-invoice"}
          clazz={"width-max"}
          title={t("invoiceneeded")}
        ></Checkbox>
        {isFactureCheck && (
          <>
            <Checkbox
              id={"pay-check-phiz"}
              clazz={"width-max"}
              title={t("payperson")}
              checked={typePayCheckbox.includes("phiz")}
              onChange={() => {
                setTypePayCheckbox("phiz");
              }}
            ></Checkbox>
            <Checkbox
              id={"pay-check-firm"}
              clazz={"width-max"}
              title={t("paycompany")}
              checked={typePayCheckbox.includes("firm")}
              onChange={() => {
                setTypePayCheckbox("firm");
              }}
            ></Checkbox>
            {!invoiceLoad && isBank && (
              <Button
                size={"l"}

                clazz={`button--primary min-w-160 ml-auto`}
                onClick={() => fetchInvoice()}
              >
                {t("invoicedownload")}
              </Button>
            )}
            {invoiceLoad && isBank && (
              <LoadingButton
                buttonType={"button"}
                isLoading={invoiceLoad}
                size={"l"}
                clazz={`button--primary min-w-160 ml-auto`}
              >
                {t("continue")}
              </LoadingButton>
            )}
          </>
        )}
      </div>
    </>
  );
};
