import React from 'react';
import {ErrorHandler} from "../ui";
import {makeRequest} from "@/services/makeRequest";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }
    
    render() {
        if (this.state.hasError) {
            return (<ErrorHandler/>);
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
