import { ButtonLink, Button, Icon } from "@/components/ui";
import React, {useState} from "react";
import ZeroZone from "@/components/zeroZone/ZeroZone";
import useLadyService from "@/services/LadyService";
import EditBlock from "../editBlock/EditBlock";

const ModelPornstarCard = ({porn_star, setIsOpenEditPorno, setVisualElem, edit}) => {
  const { t } = useLadyService();

  
  return (
    <div className={"model-card d-flex fd-column gap-12"}>

      {!!edit ?
        <EditBlock
          title={t("pornstar")}
          btnTitle={t("edit")}
          titleType={'h3'}
          mb={'0'}
          onClick={() => {
            setVisualElem("video")
            setIsOpenEditPorno(true)
          }}
          clazz={'align-center justify-sb'}
        />
        : (
          <h3 className={'mb-0'}>{t("pornstar")}</h3>
        )}

      {!!porn_star.length ? (
        porn_star.map((i, index) => 
            {if(i.video_link || i.website_link) {
              return(
              <div className={"agency-card"} key={index}>
              {i.video_link ?
              <div className="agency-card__img">
                <iframe
                  allowFullScreen
                  src={i.video_link}
                ></iframe>
              </div>  :
                null}
              

              {i.website_link ? (
                <ButtonLink
                  href={i.website_link
                  }
                  size={"m"}
                  clazz={`button--tetriary w-100 hover-line`}
                >
                  {i.website_link
                  }
                </ButtonLink>
              ) : null}

              {i.status === 0 && <span className="p1 ero-status color-main text-center">{t('moderated24hour')}</span>}
              </div>)
            }
            
            else{
              return(
                <ButtonLink
                  href={i.original_link}
                  target={'_blank'}
                  size={"m"}
                  clazz={`button--tetriary w-100 hover-line`}
                >
                  {i.original_link.length > 100 ? i.original_link.slice(0, 80) + '...' : i.original_link}
                </ButtonLink>
              )
            }
            }
          )) : edit ? (
        <ZeroZone
          setOpen={setIsOpenEditPorno}
          setVisualElem={setVisualElem}
          visualElem={'video'}
          longDisc={t('getporno')}
        />
      ) : null}
    </div>
  );
};
export default ModelPornstarCard;
