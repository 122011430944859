import React, {useEffect, useState} from "react";
import {Banner, Breadcrumbs, ButtonLink, ShowMoreText, InputInLabel, ErrorMessage, Checkbox} from "@/components/ui";
import {
    setGalleryMediaIndex,
    setGalleryStatus,
} from "@/stores/slices/popupSlice";
import {SwiperSlide} from "swiper/react";
import "../agency/agencyPage.scss";
import "./partyPage.scss";
import useLadyService from "@/services/LadyService";
import {useParams, Link} from "react-router-dom";
import {makeRequest} from "@/services/makeRequest";
import {useTranslation} from "react-i18next";
import {Icon, Loader} from "@/components/ui";
import showToast from "../../toast/Toast";
import Navigation from "@/components/navigation/Navigation";
import {formatPhoneNumberIntl} from "react-phone-number-input";
import Gallery from "@/components/popups/gallery/Gallery";
import Participate from "@/components/popups/participate/Participate";
import Slider from "@/components/sliders/Slider";



const PartyPage = () => {
    const {dispatch, lang, setMediaSize, navigate, isJsdom, windowWidth} = useLadyService();

    const {t} = useTranslation("translation");

    const {partyId} = useParams();

    const [profilesData, setIsProfilesDataEmpty] = useState({})
    const [onePartyData, setOnePartyData] = useState(false);

    const {
        name,
        time,
        date,
        address,
        profiles_count,
        price,
        description,
        cover,
        id,
        prepayment,
        include,
        interior = {},
        phone,
        agency,
        whatsapp,
        telegram
    } = onePartyData


    const tabsBtns = [
        {
            title: t("interior"),
            icon: "home-main",
            link: "interior",
            disabled: !interior.length
        },
    ];

    const breadcrumbsList = [
        {
            id: 1,
            title: t('mainpage'),
            link: "/",
        },
        {
            id: 2,
            title: agency?.name,
            link: agency?.id ? `/${lang}/agency/${agency.id}` : '',
        },
        {
            id: 3,
            title: name ?? 'palceholder',
            link: "",
        },
    ];

    const formatDate = () =>{
        const arr = date.split('-')
        return `${arr[2]}.${arr[1]}.${arr[0]}`
    }

    const fetchParty = async () => {
        try {
            const route = `parties/${partyId}`;
            const method = "GET";

            const data = await makeRequest({route, method});

            if (!data) {
                navigate("/404");
            }
            if (data) {
                const result = data.data;
                setOnePartyData(result);
            }
        } catch (error) {
            showToast({message: t('oops'), variant: 'error'})
            console.log('error: ', error)
        }
    };

    const [userPhone, setUserPhone] = useState()
    const [error, setError] = useState()
    const phoneRes = !!formatPhoneNumberIntl(`${phone}`)
        ? formatPhoneNumberIntl(`${phone}`)
        : formatPhoneNumberIntl(`+${phone}`);

 
    const dateNPlaceBlock = () => {
        return (
            <>
                <div className="date-place-block">

                    <h2 className="mb-24 mb-16-mob">{t("dateandplace")}</h2>

                    <div className="agency__links mb-32">

                        {address ? (
                            <Link
                                to={`https://www.google.com/maps/search/${address}`}
                                target="__blank"
                                className="hover-line"
                                title={t('viewonmap')}
                            >
                                <Icon type={"flag"} spritePath={"CZ"}/>
                                {` ${address}`}
                            </Link>) : null}

                        {date || (time) ? (
                            <a
                                href="#"
                                className="model-header__address hover-line"
                                title="Добавить в календарь"
                            >
                                {`${formatDate()} ${time ? (`${t("at")} ${time}`) : ''} `}
                                <i className="icon icon-m icon--calendar"></i>
                            </a>
                        ) : null}
                    </div>
                </div>
            </>)
    }
    const pricesNParticipateBlock = () => {
        return (<>
            <div className="d-flex gap-12 w-100 justify-center mb-32 mb-24-mob">
                <div className="start-videochat__box w-100">
                    <div>
                        <p className="p2">{t("costofparticipation")}</p>
                        <span className="title_h3">
                {price ? <span className="currency--euro">{price}</span> : t('no')}
              </span>
                    </div>
                </div>
                <div className="start-videochat__box w-100">
                    <div>
                        <p className="p2">{t("prepayment")}</p>
                        <span className="title_h3">
                {prepayment  ? <span className="currency--euro">{prepayment}</span> : t('no')}
              </span>
                    </div>

                </div>
            </div>

            <button
                data-open-popup="takePart"
                type="button"
                className="button button-l button--green justify-center w-100 mb-32 mb-24-mob"
                onClick={handleParticipateButtonClick}
            >
                {t("signmeup")}
            </button>
        </>)
    }
    const contactsBlock = () => {
        return (<>
            {phone || whatsapp || telegram ?
                <>
                    <h2 className="mb-24 mb-16-mob">{t("contacts")}</h2>

                    <div className="agency__buttons">
                        {phone ?
                            <ButtonLink
                                size={'l'}
                                href={`tel:+${phone}`}
                                clazz="button--primary td-none w-100">
                                {phoneRes ?? ''}
                            </ButtonLink>
                            : null}
                        {whatsapp ?
                            <ButtonLink
                                square={true}
                                size={'l'}
                                href={`https://wa.me/${whatsapp}`}
                                target="__blank"
                                clazz="button--primary"
                            >
                                <i className="icon icon-xl icon--whatsapp"></i>
                            </ButtonLink>
                            : null}

                        {telegram ?
                            <ButtonLink
                                square={true}
                                size={'l'}
                                target="__blank"
                                href={`https://t.me/${telegram}`}
                                clazz="button--primary"
                            >
                                <i className="icon icon-xl icon--telegram"></i>
                            </ButtonLink>
                            : null}
                    </div>
                </>
                : null}
        </>)
    }
    const interiorBlock = () => {
        return (<>
            <h3 className="mt-8">
                <Icon size={"s"} spritePath={tabsBtns[0].icon}/>
                {` ${tabsBtns[0].title}`}
            </h3>
            
               {windowWidth < 430 ? <Slider>
                    {interior.slice(0, 6).map((image, index) => {
                        const result = () => {
                            dispatch(setGalleryMediaIndex(index));
                            dispatch(setGalleryStatus(true));
    
                        };
                        return (
                            <SwiperSlide
                                className={`agency-tabs__slide interior-mobile mt-16`}
                                data-hash={tabsBtns[0].link}
                            >
                                <img
                                  src={setMediaSize(image, "l")}
                                  onClick={() => result()}
                                  alt=""
                                />
                              </SwiperSlide>
                        );
                    })}
                </Slider> : 
                <SwiperSlide
                        className={`agency-tabs__slide mt-16`}
                        data-hash={tabsBtns[0].link}
                    >
                        {interior.slice(0, 6).map((image, index) => {
                            const result = () => {
                                dispatch(setGalleryMediaIndex(index));
                                dispatch(setGalleryStatus(true));
                
                        };
                        return (
                            <img
                                src={setMediaSize(image, "s2")}
                                onClick={() => result()}
                                alt=""
                            />
                        );
                        })}
                </SwiperSlide>
                }
          
        </>)
    }



    const handleParticipateButtonClick = () => {
        //paicipate ? setIsOpenParticipate(true) : showToast('you already participate')
        setIsOpenParticipate(true)
    }

    const [isOpenParticipate, setIsOpenParticipate] = useState(false)

    useEffect(() => {
        fetchParty(id)
    }, [])

    return !onePartyData ? (<Loader height={100}/>) : (
        <>
            <main className={"agency-party"}>
                <Breadcrumbs data={breadcrumbsList} clazz={"__container mb-20"}/>

                {/* mock props */}
                <Banner props={{
                    title: name ?? '',
                    img: setMediaSize(cover, "xl"),
                    imgDesktop: setMediaSize(cover, "m"),
                    imgMobile: setMediaSize(cover, "xs"),
                    logoImg:  setMediaSize(agency?.logo, "s"),
                    logoImgMobile: setMediaSize(agency?.logo, "s"),
                    date: date ?? '',
                    model_total: profiles_count,
                    partyPage: true
                }}/>

                <section className="agency__top __container d-flex flex-wrap-no">

                    <div className="agency-party__description">

                        {!isJsdom && interior?.length > 0 && windowWidth < 1200 ?
                            <>
                                {interiorBlock()}
                                <h1 className="mt-32 mb-16">{name ?? ''}</h1>
                            </> : null}

                        {windowWidth > 1200 ? <h1 className="mt-32 mb-16">{name ?? ''}</h1> : null}

                        {description ? (
                            <p clazz="p1 mb-24">{description ?? ''}</p>
                        ) : null}


                        {interior?.length > 0 && dateNPlaceBlock()}

                        {interior?.length > 0 && pricesNParticipateBlock()}

                        {contactsBlock()}

                    </div>


                    <section className="agency__media__interior">
                        {!isJsdom && interior?.length > 0 && windowWidth > 1100 ?
                            interiorBlock() : null}
                        {!(interior?.length > 0) &&
                            <>
                                {dateNPlaceBlock()}
                                {pricesNParticipateBlock()}
                            </>
                        }
                    </section>

                </section>

                <section className="agency-party__list __container">

                    {include?.length > 0 &&
                        <>
                            <h3>{t('priceincludes')}</h3>
                            <ul className="model__serfices-box p1 color-main">
                                {include?.map((option) => {
                                    return (
                                        <li key={option.name}>
                                            <p className="mt-0 mb-0 p1 color-600"><span className="color-main">{option.name}</span><br/>
                                            {option.description}
                                            </p>
                                        </li>
                                    )
                                })}
                            </ul>
                        </>}
                </section>

                <Navigation
                    profiles_statistic={profiles_count}
                    partyId={partyId}
                    checkbox={false}
                    mainPage={false}
                    setIsDataEmpty={setIsProfilesDataEmpty}
                    isDataEmpty={profilesData}
                    party={true}
                />

            </main>

            <Gallery id={'Interior'} name={name} media={{interior}}/>
            <Participate party={partyId} isOpen={isOpenParticipate} setIsOpen={setIsOpenParticipate} />
        </>
    );
};

export default PartyPage;
