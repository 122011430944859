import Navigation from "../navigation/Navigation";
import {useDispatch, useSelector} from "react-redux";
import {getStories} from "@/stores/slices/modelsState";
import {useEffect, useState} from "react";
import {useParams} from 'react-router-dom'
import useLadyService from "@/services/LadyService";
import {setVideoCallRoom} from "@/stores/slices/videoCallSlice";
import {getProfilesCount} from "@/stores/slices/mapModelSlice";
import useTitle from "@/hooks/useTitle";
import {getUserCurrentCity, getUserRegion} from "@/stores/slices/userSlice";
import {getTitle} from "@/stores/slices/titleSlice";
import {Helmet} from "react-helmet";
import {getCountrySlug} from "@/stores/slices/userSlice";
import BestCityAgencySlider from "../sliders/bestCityAgencySlider/BestCityAgencySlider";
import GirlMap from "../girlMap/GirlMap";
import AboutService from "../aboutService/AboutService";
import BenefitsBlock from "../benefitsBlock/BenefitsBlock";
import StoriesComponent from "../stories/StoriesComponent";
import Selection from "@/components/pages/selection/Selection";
import {defaultRangeState,} from "@/stores/slices/filterSlice";
import {getAllSorted} from "@/stores/slices/modelsState";
import useDescription from "@/hooks/useDescription";

const MainPage = ({initialData}) => {
    const dispatch = useDispatch();
    const {t, userCity, navigate, lang, filterState, userType} = useLadyService();
    const {room, uuid} = useParams();
    const stories = useSelector(getStories);
    const [isDataEmpty, setIsDataEmpty] = useState(false);
    const profilesCountValue = useSelector(getProfilesCount);
    const currentCity = useSelector(getUserCurrentCity);
    const title = useSelector(getTitle);
    const region = useSelector(getUserRegion);
    const [filterData, setFilterData] = useState([])
    const [onMap, setOnMap] = useState(0)
    const defaultRangeValue = useSelector(defaultRangeState);
    const sortedValue = useSelector(getAllSorted);

    useEffect(() => {
        if (room) {
            dispatch(setVideoCallRoom({room_id: room}));
        }
    }, [dispatch, room]);


    const countrySlug = useSelector(getCountrySlug)
    let postTitle = t("title", {City: currentCity.title ? currentCity.title : "", CountryCode: 'cz'});
    let postDesc = t("description", {City: currentCity.title, Country: region})
    if (countrySlug) {
        postTitle = t("titlecountry", {Country: currentCity.title, CountryCode: 'cz'});
        postDesc = t("descriptioncountry", {Country: currentCity.title})
    }
    const titleString = !!title.length
        ? title
            .filter((item) => !!item)
            .map((item) => t(item))
            .join(", ")
        : "";

    const titleParams = titleString
        ? t('fastFiltersTitle', {Filters: titleString, City: currentCity.title})
        : postTitle;

    if (titleString) {
        postDesc = t('fastFiltersDesc', {Filters: titleString, City: currentCity.title})
    }
    useTitle(titleParams)
    useDescription(postDesc)
    const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');

    const checkForEmtyFilters = () => {
        const { accordionSelected, range, ...fieldsToCheck } = filterState;
        let rangeSelected = false;
        let fieldsSelected = false;        
    
        function allValueIsNull(obj) {
          for (let key in obj) {
            if (obj[key].min !== 0 && obj[key].max !== 0) {
              return true;
            }
          }
          return false;
        }
        //check if range is not empty
        if (allValueIsNull(defaultRangeValue) && allValueIsNull(range)) {
          rangeSelected = (
            range.price.min > defaultRangeValue.price.min ||
              range.price.max < defaultRangeValue.price.max
              ? true
              : range.age.min > defaultRangeValue.age.min ||
                range.age.max < defaultRangeValue.age.max
                ? true
                : range.weight.min > defaultRangeValue.weight.min ||
                  range.weight.max < defaultRangeValue.weight.max
                  ? true
                  : range.height.min > defaultRangeValue.height.min ||
                  range.height.max < defaultRangeValue.height.max
          );
        }
        //check if filters are not empty
        fieldsSelected = (Object.values(fieldsToCheck).some((filter) => Array.isArray(filter) && filter.length > 0) || sortedValue.includes('incall'));

        return !(fieldsSelected || rangeSelected)
      }


    return (
        <>
            <main>
                <Navigation setIsDataEmpty={setIsDataEmpty} isDataEmpty={isDataEmpty} mainPage={true} setOnMap={setOnMap} onMap={onMap}/>
                {/*<NewGirlsSlider />*/}

                {((!isDataEmpty && !!profilesCountValue) || isJsdom) && onMap ? (<GirlMap/>) : null}
                {(!isJsdom || initialData?.fastFilters === undefined) && <BestCityAgencySlider/>}
                {((isJsdom && countrySlug && initialData?.fastFilters === undefined) 
                  || (!isJsdom && countrySlug && checkForEmtyFilters() && userType === 'default')) && <AboutService/>}
                {((isJsdom && countrySlug && initialData?.fastFilters === undefined) 
                  || (!isJsdom && countrySlug)) && <BenefitsBlock statistic={initialData?.statistics} showContent={checkForEmtyFilters()}/>}
                {(!countrySlug) && <Selection isPage={false}/>}
            </main>

            {!!stories.length && (
                <StoriesComponent
                    favoriteButton
                    descriptionText
                    profileLink
                    stories={stories}
                />
            )}
        </>
    );
};

export default MainPage;
