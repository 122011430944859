import useLadyService from "../../services/LadyService";
import {useSelector} from "react-redux";
import {getServicesWords} from "../../stores/slices/userSlice";

const FilterData = ()=> {
  const {t} = useLadyService();

  const servicesCategory = useSelector(getServicesWords);
  const categorySex = servicesCategory.sex;
  const categoryAdditional = servicesCategory.additional;

  const categoryBdsm = servicesCategory.bdsm;
  const categoryExtreme = servicesCategory.extreme;
  const categoryMassage = servicesCategory.massage;
  const categoryStriptease = servicesCategory.striptease;
  return{
    place: [
      {
        id: "placeIncall",
        title: t("incall"),
        name: "place",
        value: "incall",
        count: "incall",
      },
      {
        id: "placeOutcall",
        title: t("outcall"),
        name: "place",
        value: "outcall",
        count: "outcall",
      },
      {
        id: "placeSalon",
        title: t("brothel"),
        name: "place",
        value: "salon",
        count: "salon",
      },
      {
        id: "placeEscort",
        title: t("accompaniment"),
        name: "place",
        value: "escort",
        count: "escort",
      },
    ],

    tag: [
      {
        id: "tagVideochat",
        title: t("videochat"),
        name: "tag",
        value: "vchat",
        count: "vchat",
      },
      {
        id: "tagEro",
        title: t("erocontent"),
        name: "tag",
        value: "ero",
        count: "ero",
      },
      {
        id: "tagBooking",
        title: t("onlineorder"),
        name: "tag",
        value: "booking",
        count: "booking",
      },
    ],
    penis: {
      size: [
        {
          id: "penisSmall",
          title: t("penissmall"),
          name: "penis_size",
          value: "small",
          count: "small",
        },
        {
          id: "penisMedium",
          title: t("penismiddle"),
          name: "penis_size",
          value: "medium-p",
          count: "medium",
        },
        {
          id: "penisBig",
          title: t("penisbig"),
          name: "penis_size",
          value: "big-p",
          count: "big",
        },
      ],
    },
    breast: {
      size: [
        {
          id: "breastSmall",
          title: `${t("small")} (0-2)`,
          name: "breast_size",
          value: "breastSmall",
          count: "small",
        },
        {
          id: "breastMedium",
          title: `${t("medium")} (3-4)`,
          name: "breast_size",
          value: "breastMedium",
          count: "medium",
        },
        {
          id: "breastBig",
          title: `${t("big")} (5+)`,
          name: "breast_size",
          value: "breastBig",
          count: "big",
        },
      ],
      type: [
        {
          id: "breastNature",
          title: t("natural"),
          name: "breast_type",
          value: "nat",
          count: "nat",
        },
        {
          id: "breastSil",
          title: t("silicone"),
          name: "breast_type",
          value: "sil",
          count: "sil",
        },
      ],
    },

    ethnos: [
      {
        id: "ethnosWhite",
        title: t("white"),
        name: "ethnos",
        value: "white",
        count: "white",
      },
      {
        id: "ethnosAsian",
        title: t("asian"),
        name: "ethnos",
        value: "asian",
        count: "asian",
      },
      {
        id: "ethnosAfrican",
        title: t("african"),
        name: "ethnos",
        value: "african",
        count: "african",
      },
      {
        id: "ethnosArabic",
        title: t("arabic"),
        name: "ethnos",
        value: "arab",
        count: "arabic",
      },
      {
        id: "ethnosIndian",
        title: t("indian"),
        name: "ethnos",
        value: "indian",
        count: "indian",
      },
      {
        id: "ethnosMulatto",
        title: t("tanned"),
        name: "ethnos",
        value: "mulatto",
        count: "mulatto",
      },
      {
        id: "ethnosMixed",
        title: t("mixed"),
        name: "ethnos",
        value: "mixed",
        count: "mixed",
      },
      {
        id: "ethnosLatin",
        title: t("latin"),
        name: "ethnos",
        value: "latin",
        count: "latin",
      },
    ],

    hair: {
      color: [
        {
          id: "hairBlonde",
          title: t("blond"),
          name: "hair_color",
          value: "blonde",
          count: "blonde",
        },
        {
          id: "hairBrown",
          title: t("brown"),
          name: "hair_color",
          value: "brown",
          count: "brown",
        },
        {
          id: "hairBrunette",
          title: t("brunette"),
          name: "hair_color",
          value: "brunette",
          count: "brunette",
        },
        {
          id: "hairRed",
          title: t("ginger"),
          name: "hair_color",
          value: "red",
          count: "red",
        },
      ],
      size: [
        {
          id: "hairSizeShort",
          title: t("short"),
          name: "hair_size",
          value: "short",
          count: "short",
        },
        {
          id: "hairSizeMedium",
          title: t("mediuma"),
          name: "hair_size",
          value: "medium",
          count: "medium",
        },
        {
          id: "hairSizeLong",
          title: t("long"),
          name: "hair_size",
          value: "long",
          count: "long",
        },
      ],
      intim: [
        {
          id: "hairIntimShaved",
          title: t("shaved"),
          name: "hair_intim",
          value: "shaved",
          count: "shaved",
        },
        {
          id: "hairIntimHaircut",
          title: t("cut"),
          name: "hair_intim",
          value: "haircut",
          count: "haircut",
        },
        {
          id: "hairIntimNature",
          title: t("natural"),
          name: "hair_intim",
          value: "natural",
          count: "natural",
        },
      ],
    },

    eye: {
      color: [
        {
          id: "eyeColorBlue",
          title: t("blue"),
          name: "eye_color",
          value: "blue",
          count: "blue",
        },
        {
          id: "eyeColorGreen",
          title: t("green"),
          name: "eye_color",
          value: "green",
          count: "green",
        },
        {
          id: "eyeColorBrown",
          title: t("brownyies"),
          name: "eye_color",
          value: "browns",
          count: "brown",
        },
        {
          id: "eyeColorGray",
          title: t("gray"),
          name: "eye_color",
          value: "gray",
          count: "gray",
        },
      ],
      type: [
        {
          id: "eyeTypeLenses",
          title: t("lenses"),
          name: "eye_type",
          value: "lenses",
          count: "lens",
        },
        {
          id: "eyeTypeGlasses",
          title: t("glasses"),
          name: "eye_type",
          value: "glasses",
          count: "glass",
        },
      ],
    },

    special: {
      lips: [
        {
          id: "specialLipsNatural",
          title: t("natural"),
          name: "special_lips",
          value: "naturale",
          count: "natural",
        },
        {
          id: "specialLipsPumped",
          title: t("enhanced"),
          name: "special_lips",
          value: "pumped_up",
          count: "pumped_up",
        },
      ],
      tattoo: [
        {
          id: "specialTattooNo",
          title: t("none"),
          name: "special_tattoo",
          value: "no-t",
          count: "no",
        },
        {
          id: "specialTattooFew",
          title: t("few"),
          name: "special_tattoo",
          value: "few-t",
          count: "few",
        },
        {
          id: "specialTattooMany",
          title: t("many"),
          name: "special_tattoo",
          value: "many-t",
          count: "many",
        },
      ],
      piercing: [
        {
          id: "specialPiercingNo",
          title: t("none"),
          name: "special_piercing",
          value: "no-p",
          count: "no",
        },
        {
          id: "specialPiercingFew",
          title: t("few"),
          name: "special_piercing",
          value: "few-p",
          count: "few",
        },
        {
          id: "specialPiercingMany",
          title: t("many"),
          name: "special_piercing",
          value: "many-p",
          count: "many",
        },
      ],
      smoke: [
        {
          id: "specialSmokeNo",
          title: t("none"),
          name: "special_smoke",
          value: "no-s",
          count: "no",
        },
        {
          id: "specialSmokeSometimes",
          title: t("rarely"),
          name: "special_smoke",
          value: "sometimes-s",
          count: "sometimes",
        },
        {
          id: "specialSmokeOften",
          title: t("often"),
          name: "special_smoke",
          value: "regularly-s",
          count: "regularly",
        },
      ],
    },

    status: [
      {
        id: "statusIndividual",
        title: t("indi"),
        name: "status",
        value: "indi",
        count: "indi",
      },
      {
        id: "statusVerification",
        title: t("verification"),
        name: "status",
        value: "verified",
        count: "verified",
      },
      {
        id: "statusCouples",
        forDisabled: "genderCouple",
        title: t("couples"),
        name: "gender",
        value: "couple",
        count: "couple",
      },
      {
        id: "statusElite",
        title: t("elite"),
        name: "status",
        value: "elitelady",
        count: "elitelady",
      },
      {
        id: "statusHealthControl",
        title: t("healthy"),
        name: "status",
        value: "healthy",
        count: "healthy",
      },
      {
        id: "statusBDSM",
        title: t("bdsm"),
        name: "status",
        value: "bdsm",
        count: "bdsm",
      },
      {
        id: "statusPornstar",
        title: t("star"),
        name: "status",
        value: "pornstar",
        count: "pornstar",
      },
      {
        id: "statusFriends",
        title: t("girlfriend"),
        name: "status",
        value: "gf",
        count: "gf",
      },
      {
        id: "statusTrance",
        forDisabled: "genderTrans",
        title: t("trans"),
        name: "gender",
        value: "trans",
        count: "trans",
      },
      {
        id: "statusOnline",
        title: t("online"),
        name: "status",
        value: "online",
        count: "online",
      },
      {
        id: "statusEscort",
        title: t("accompaniment"),
        name: "status",
        value: "escort",
        count: "escort",
      },
      {
        id: "statusGuys",
        forDisabled: "genderMale",
        title: t("guys"),
        name: "gender",
        value: "male",
        count: "male",
      },
      {
        id: "statusNew",
        title: t("newmodels"),
        name: "status",
        value: "new",
        count: "new",
      },
      {
        id: "statusTravel",
        title: t("travel"),
        name: "status",
        value: "travel",
        count: "travel",
      },
      {
        id: "statusVideo",
        title: t("video"),
        name: "status",
        value: "video",
        count: "video",
      },
    ],

    gender: [
      {
        id: "genderFemale",
        title: t("woman"),
        name: "gender",
        value: "female",
        count: "female",
      },
      {
        id: "genderMale",
        title: t("guys"),
        name: "gender",
        value: "male",
        count: "male",
      },
      {
        id: "genderTrans",
        title: t("trans"),
        name: "gender",
        value: "trans",
        count: "trans",
      },
      {
        id: "genderCouple",
        title: t("couples"),
        name: "gender",
        value: "couple",
        count: "couple",
      },
    ],

    sex: [
      {
        id: "sexStraight",
        title: t("hetero"),
        name: "sex",
        value: "straight",
        button_checkboxes: true,
        count: "straight",
      },
      {
        id: "sexHomosexual",
        title: t("homo"),
        name: "sex",
        value: "homosexual",
        button_checkboxes: true,
        count: "homosexual",
      },
      {
        id: "sexLesbian",
        title: t("lesbi"),
        name: "sex",
        value: "lesbian",
        button_checkboxes: true,
        count: "lesbian",
      },
      {
        id: "sexBisexual",
        title: t("bi"),
        name: "sex",
        value: "bisexual",
        button_checkboxes: true,
        count: "bisexual",
      },
    ],

    services: {
      for: [
        {
          id: "forMen",
          title: t("men"),
          name: "for",
          value: "forMan",
          count: "men",
        },
        {
          id: "forWomen",
          title: t("women"),
          name: "for",
          value: "forWomen",
          count: "women",
        },
        {
          id: "forCouples",
          title: t("couples"),
          name: "for",
          value: "forCouples",
          count: "couples",
        },
        {
          id: "forGroups",
          title: t("groups"),
          name: "for",
          value: "forGroups",
          count: "groups",
        },
      ],

      for_mobile: [
        {
          id: "forMen",
          title: t("men"),
          name: "for",
          value: "forMan",
          count: "men",
        },
        {
          id: "forCouples",
          title: t("couples"),
          name: "for",
          value: "forCouples",
          count: "couples",
        },
        {
          id: "forWomen",
          title: t("women"),
          name: "for",
          value: "forWomen",
          count: "women",
        },
        {
          id: "forGroups",
          title: t("groups"),
          name: "for",
          value: "forGroups",
          count: "groups",
        },
      ],

      sex: [
        {
          id: "servicesSexClassic",
          title: categorySex?.services[1].slug,
          name: "services",
          value: "1",
          count: "1",
        },
        {
          id: "servicesSexBlowjobDeep",
          title: categorySex?.services[8].slug,
          name: "services",
          value: "8",
          count: "8",
        },
        {
          id: "servicesSexAnal",
          title: categorySex?.services[2].slug,
          name: "services",
          value: "2",
          count: "2",
        },
        {
          id: "servicesSexLickingBalls",
          title: categorySex?.services[9].slug,
          name: "services",
          value: "9",
          count: "9",
        },
        {
          id: "servicesSexSixtyNine",
          title: categorySex?.services[3].slug,
          name: "services",
          value: "3",
          count: "3",
        },
        {
          id: "servicesSexCunnilingus",
          title: categorySex?.services[10].slug,
          name: "services",
          value: "10",
          count: "10",
        },
        {
          id: "servicesSexKissing",
          title: categorySex?.services[4].slug,
          name: "services",
          value: "4",
          count: "4",
        },
        {
          id: "servicesSexToys",
          title: categorySex?.services[11].slug,
          name: "services",
          value: "11",
          count: "11",
        },
        {
          id: "servicesSexMasturbation",
          title: categorySex?.services[5].slug,
          name: "services",
          value: "5",
          count: "5",
        },
        {
          id: "servicesSexFinishOnBreast",
          title: categorySex?.services[12].slug,
          name: "services",
          value: "12",
          count: "12",
        },
        {
          id: "servicesSexBlowjobInCondom",
          title: categorySex?.services[6].slug,
          name: "services",
          value: "6",
          count: "6",
        },
        {
          id: "servicesSexFinishOnFace",
          title: categorySex?.services[13].slug,
          name: "services",
          value: "13",
          count: "13",
        },
        {
          id: "servicesSexBlowjob",
          title: categorySex?.services[7].slug,
          name: "services",
          value: "7",
          count: "7",
        },
        {
          id: "servicesSexFinishInMouth",
          title: categorySex?.services[14].slug,
          name: "services",
          value: "14",
          count: "14",
        },
      ],

      sex_mobile: [
        {
          id: "servicesSexClassic",
          title: categorySex?.services[1].slug,
          name: "services",
          value: "1",
        },
        {
          id: "servicesSexAnal",
          title: categorySex?.services[2].slug,
          name: "services",
          value: "2",
        },
        {
          id: "servicesSexBlowjob",
          title: categorySex?.services[6].slug,
          name: "services",
          value: "blowjob",
        },
        {
          id: "servicesSexBlowjobDeep",
          title: categorySex?.services[8].slug,
          name: "services",
          value: "blowjob_deep",
        },
        {
          id: "servicesSexToys",
          title: categorySex?.services[11].slug,
          name: "services",
          value: "sex_toys",
        },
        {
          id: "servicesSexFinishOnBreast",
          title: categorySex?.services[12].slug,
          name: "services",
          value: "finish_on_breast",
        },
        {
          id: "servicesSexFinishOnFace",
          title: categorySex?.services[13].slug,
          name: "services",
          value: "finish_on_face",
        },
        {
          id: "servicesSexFinishInMouth",
          title: categorySex?.services[14].slug,
          name: "services",
          value: "finish_in_mouth",
        },
        {
          id: "servicesSexBlowjobInCondom",
          title: categorySex?.services[6].slug,
          name: "services",
          value: "blowjob_in_condom",
        },
        {
          id: "servicesSexCunnilingus",
          title: categorySex?.services[10].slug,
          name: "services",
          value: "cunnilingus",
        },
        {
          id: "servicesSexKissing",
          title: categorySex?.services[4].slug,
          name: "services",
          value: "kissing",
        },
        {
          id: "servicesSexSixtyNine",
          title: categorySex?.services[3].slug,
          name: "services",
          value: "sixty_nine",
        },
      ],

      strip: [
        {
          id: "servicesStrip1",
          title: categoryStriptease?.services[15].slug,
          name: "services",
          value: "15",
          count: "15",
        },
        {
          id: "servicesStrip2",
          title: categoryStriptease?.services[17].slug,
          name: "services",
          value: "17",
          count: "17",
        },
        {
          id: "servicesStrip3",
          title: categoryStriptease?.services[16].slug,
          name: "services",
          value: "16",
          count: "16",
        },
        {
          id: "servicesStrip4",
          title: categoryStriptease?.services[18].slug,
          name: "services",
          value: "strip4",
          count: "18",
        },
      ],

      massage: [
        {
          id: "servicesMassage1",
          title: categoryMassage?.services[19].slug,
          name: "services",
          value: "19",
          count: "19",
        },
        {
          id: "servicesMassage2",
          title: categoryMassage?.services[23].slug,
          name: "services",
          value: "23",
          count: "23",
        },
        {
          id: "servicesMassage3",
          title: categoryMassage?.services[20].slug,
          name: "services",
          value: "20",
          count: "20",
        },
        {
          id: "servicesMassage4",
          title: categoryMassage?.services[24].slug,
          name: "services",
          value: "24",
          count: "24",
        },
        {
          id: "servicesMassage5",
          title: categoryMassage?.services[21].slug,
          name: "services",
          value: "21",
          count: "21",
        },
        {
          id: "servicesMassage6",
          title: categoryMassage?.services[25].slug,
          name: "services",
          value: "25",
          count: "25",
        },
        {
          id: "servicesMassage7",
          title: categoryMassage?.services[22].slug,
          name: "services",
          value: "22",
          count: "22",
        },
        {
          id: "servicesMassage8",
          title: categoryMassage?.services[27].slug,
          name: "services",
          value: "27",
          count: "27",
        },
        {
          id: "servicesMassage9",
          title: categoryMassage?.services[26].slug,
          name: "services",
          value: "26",
          count: "26",
        },
      ],

      bdsm: [
        {
          id: "servicesBdsm1",
          title: categoryBdsm?.services[28].slug,
          name: "services",
          value: "28",
          count: "28",
        },
        {
          id: "servicesBdsm3",
          title: categoryBdsm?.services[29].slug,
          name: "services",
          value: "29",
          count: "29",
        },
        {
          id: "servicesBdsm5",
          title: categoryBdsm?.services[30].slug,
          name: "services",
          value: "30",
          count: "30",
        },
        {
          id: "servicesBdsm9",
          title: categoryBdsm?.services[32].slug,
          name: "services",
          value: "32",
          count: "32",
        },
        {
          id: "servicesBdsm11",
          title: categoryBdsm?.services[34].slug,
          name: "services",
          value: "34",
          count: "34",
        },
        {
          id: "servicesBdsm13",
          title: categoryBdsm?.services[35].slug,
          name: "services",
          value: "35",
          count: "35",
        },
        {
          id: "servicesBdsm18",
          title: categoryBdsm?.services[33].slug,
          name: "services",
          value: "33",
          count: "33",
        },
      ],

      extrim: [
        {
          id: "servicesBdsm2",
          title: categoryExtreme?.services[39].slug,
          name: "services",
          value: "39",
          count: "39",
        },
        {
          id: "servicesBdsm4",
          title: categoryExtreme?.services[40].slug,
          name: "services",
          value: "40",
          count: "40",
        },
        {
          id: "servicesBdsm6",
          title: categoryExtreme?.services[41].slug,
          name: "services",
          value: "41",
          count: "41",
        },
        {
          id: "servicesBdsm8",
          title: categoryExtreme?.services[42].slug,
          name: "services",
          value: "42",
          count: "42",
        },
        {
          id: "servicesBdsm10",
          title: categoryExtreme?.services[43].slug,
          name: "services",
          value: "43",
          count: "43",
        },
        {
          id: "servicesBdsm14",
          title: categoryExtreme?.services[45].slug,
          name: "services",
          value: "45",
          count: "45",
        },
        {
          id: "servicesBdsm16",
          title: categoryExtreme?.services[46].slug,
          name: "services",
          value: "46",
          count: "46",
        },
        {
          id: "servicesBdsm17",
          title: categoryExtreme?.services[37].slug,
          name: "services",
          value: "37",
          count: "37",
        },
        {
          id: "servicesBdsm19",
          title: categoryExtreme?.services[38].slug,
          name: "services",
          value: "38",
          count: "38",
        },
        {
          id: "servicesBdsm20",
          title: categoryExtreme?.services[47].slug,
          name: "services",
          value: "47",
          count: "47",
        },
      ],

      extra: [
        {
          id: "servicesExtra1",
          title: categoryAdditional?.services[48].slug,
          name: "services",
          value: "48",
          count: "48",
        },
        {
          id: "servicesExtra2",
          title: categoryAdditional?.services[49].slug,
          name: "services",
          value: "49",
          count: "49",
        },
        {
          id: "servicesExtra3",
          title: categoryAdditional?.services[50].slug,
          name: "services",
          value: "50",
          count: "50",
        },
        {
          id: "servicesExtra4",
          title: categoryAdditional?.services[51].slug,
          name: "services",
          value: "51",
          count: "51",
        },
        {
          id: "servicesExtra5",
          title: categoryAdditional?.services[52].slug,
          name: "services",
          value: "52",
          count: "52",
        },
        {
          id: "servicesExtra6",
          title: categoryAdditional?.services[53].slug,
          name: "services",
          value: "53",
          count: "53",
        },
        {
          id: "servicesExtra7",
          title: categoryAdditional?.services[54].slug,
          name: "services",
          value: "54",
          count: "54",
        },
        {
          id: "servicesExtra8",
          title: categoryAdditional?.services[55].slug,
          name: "services",
          value: "55",
          count: "55",
        },
        {
          id: "servicesExtra9",
          title: categoryAdditional?.services[56].slug,
          name: "services",
          value: "56",
          count: "56",
        },
        {
          id: "servicesExtra10",
          title: categoryAdditional?.services[57].slug,
          name: "services",
          value: "57",
          count: "57",
        },
        {
          id: "servicesExtra11",
          title: categoryAdditional?.services[58].slug,
          name: "services",
          value: "58",
          count: "58",
        },
      ],
    },

    min_tariffs: [
      {
        id: "minTarifs1",
        title: `15 ${t("min")}`,
        name: "min_tariffs",
        value: "min-15",
        count: "15",
        button_checkboxes: true,
      },
      {
        id: "minTarifs2",
        title: `30 ${t("min")}`,
        name: "min_tariffs",
        value: "min-30",
        count: "30",
        button_checkboxes: true,
      },
      {
        id: "minTarifs3",
        title: t("hour"),
        name: "min_tariffs",
        value: "min-60",
        count: "60",
        button_checkboxes: true,
      },
    ],

    last_visit: [
      {
        id: "lastVisitToday",
        title: t("today"),
        name: "last_visit",
        value: "visitToday",
        count: "today",
        radio: true,
      },
      {
        id: "lastVisitYesterday",
        title: t("yesterday"),
        name: "last_visit",
        value: "visitSubDay",
        count: "subDay",
        radio: true,
      },
      {
        id: "lastVisitOnWeek",
        title: t("thisweek"),
        name: "last_visit",
        value: "visitSubWeek",
        count: "subWeek",
        radio: true,
      },
      {
        id: "lastVisitInMonth",
        title: t("thismonth"),
        name: "last_visit",
        value: "visitSubMonth",
        count: "subMonth",
        radio: true,
      },
    ],

    radius: [
      {
        id: "radius1",
        title: `1 ${t("km")}`,
        name: "radius",
        value: "1",
        count: "1",
        radio: true,
      },
      {
        id: "radius3",
        title: `3 ${t("km")}`,
        name: "radius",
        value: "3",
        count: "3",
        radio: true,
      },
      {
        id: "radius5",
        title: `5 ${t("km")}`,
        name: "radius",
        value: "5",
        count: "5",
        radio: true,
      },
      {
        id: "radius10",
        title: `10 ${t("km")}`,
        name: "radius",
        value: "10",
        count: "10",
        radio: true,
      },
      {
        id: "radius20Plus",
        title: `20+ ${t("km")}`,
        name: "radius",
        value: "20",
        count: "20",
        radio: true,
      },
    ],
  }
};

export default FilterData