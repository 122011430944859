import { useEffect, useState } from "react";
import {
  AddStoriesPopup,
  LinkWithGirlfriend,
  PostAdPopup,
} from "../../../../../../popups";

import { HashLink } from "react-router-hash-link";

import { Button, ButtonLink, Icon, Toggle } from "@/components/ui";

import "./confirmation.scss";
import { UpAd } from "../../../../model/profiles/card/components";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import GirlCard from "@/components/cards/girlCard/GirlCard";
import { makeRequest } from "@/services/makeRequest";
import { useSelector } from "react-redux";
import { getUserLang } from "@/stores/slices/userSlice";

import showToast from "@/components/toast/Toast";
import { Loader, Tag } from "@/components/ui";
import { setShortProfileData } from "@/stores/slices/oneProfileSlice";
import useLadyService from "@/services/LadyService";
import StoriesCircle from "../../../../../../stories/circle/StoriesCircle";
import StoriesComponentEdit from "../../../../../../stories/StoriesComponentEdit";
import {
  getIndiSuperVerStatus,
  getIndiVerStatus,
} from "@/stores/slices/lkIndiSlice";
import {
  setFastVerStatus,
  setRequestVerStatus,
} from "@/stores/slices/popupSlice";
import FastVerification from "../../../../../../popups/fastVerification/FastVerification";
import RequestVerPopup from "../../../../../../popups/requestVerPopup/RequestVerPopup";

const ToggleItem = ({
  textTop,
  textBottom,
  toggleTitle,
  checked,
  onChangeMain,
  href,
}) => {
  const { t } = useTranslation("translation");

  return (
    <div className="form-confirmation__toggle">
      <div className="form-confirmation__toggle-left">
        <Toggle
          titleDefault={toggleTitle}
          onChange={onChangeMain}
          checked={checked}
          id={'confirmation-toggle-' + toggleTitle}
        />
        <ButtonLink
          href={href}
          size={"s"}
          clazz={"button_outline--black _no-underline"}
          target={"_blanck"}
        >
          {t("moreinfo")}
        </ButtonLink>
      </div>
      <div className="form-confirmation__toggle-right">
        <span>{textTop}</span>
        <span className="green">{textBottom}</span>
      </div>
    </div>
  );
};

const IconItem = ({ textTop, textBottom, IconPath, buttonTitle, onClick }) => {
  return (
    <div className="form-confirmation__toggle">
      <div className="form-confirmation__toggle-left buttonIcon">
        <Button
          onClick={onClick}
          size={"s"}
          clazz={"button_outline--green-accent"}
        >
          <Icon clazz={"green"} size={"l"} spritePath={IconPath} />
          {buttonTitle}
        </Button>
      </div>
      <div className="form-confirmation__toggle-right">
        <span>{textTop}</span>
        <span className="green">{textBottom}</span>
      </div>
    </div>
  );
};

const Confirmation = ({
  formData,
  rootElem,
  scrollToHealt,
  scrollToVer,
  eliteGlobal,
  setEliteGlobal,
  setStatusAd,
  setVisualElem,
  changeStep,
  setIsVerificationExist,
  isVerificationExist,
  isBoosted,
  setIsBoosted
}) => {
  const { t, setMediaSize, windowWidth,  } = useLadyService();
  const textBottom1 = `+50% ${t("toviews")} ${t("and")} +2 ${t("foronemin")}`;
  const textTop2 = t("activateorder");
  const textBottom2 = `+70% ${t("toviews")} ${t("and")} +25% ${t("toorders")}`;
  const textTop3 = t("adderocont");
  const textBottom3 = `+50% ${t("toviews")}`;
  const textTop4 = t("verifyphotos");
  const textBottom4 = `+50% ${t("toviews")} ${t("and")} +50% ${t("toorders")}`;
  const textTop6 = t("linktogirl");
  const textBottom6 = `+10% ${t("toviews")} ${t("and")} +5% ${t("toorders")}`;

  

  const { verify } = formData;

  const [isLoading, setIsLoading] = useState(false);

  const [isOpenGirlfriend, setIsOpenGirlfriend] = useState(false);

  const [isPost, setIsPost] = useState(false);



  const handleAddGirlfriend = () => {
    setIsOpenGirlfriend(true);
  };

  const [profileData, setProfileData] = useState({});

  const {
    tag_booking,
    ready_videochat,
    slug,
    current_position,
    name,
    city,
    is_top,
    address,
    city_id,
    id,
    position_price,
    promotion_to_at,
    main_photo,
    media,
    ero,
    is_enabled,
    stories: storiesRes,
    is_new,
  } = profileData;

  const photo = !!main_photo
    ? main_photo
    : !!media?.photo?.length
    ? media.photo[0]
    : null;

  const lang = useSelector(getUserLang);

  const [call, setCall] = useState(ready_videochat);
  const [booking, setBooking] = useState(tag_booking);

  const dispatch = useDispatch();

  const handleEroStep = () => {
    rootElem.scrollIntoView({
      behavior: "smooth",
    });
    setVisualElem("ero");
    changeStep(2);
  };



 

  const onChangeCall = async () => {
    try {
      const route = `user/profile/${slug}/set-call-status`;
      const method = "PUT";
      const payload = {
        status: !call,
      };
      setCall((prev) => !prev);
      await makeRequest({ route, method, payload });
    } catch (error) {
      setCall((prev) => !prev);
    }
  };

  const onChangeBook = async () => {
    try {
      const route = `user/profile/${slug}/set-booking-status`;
      const method = "PUT";
      const payload = {
        status: !booking,
      };
      setBooking((prev) => !prev);
      await makeRequest({ route, method, payload });
    } catch (error) {
      setBooking((prev) => !prev);
    }
  };

  const navigate = useNavigate();

  const [stories, setStories] = useState(storiesRes);

  const [isOpenAddStory, setIsOpenAddStory] = useState(false);
  const [isOpenStory, setIsOpenStory] = useState(false);

  const handleOpenAddStory = (e) => {
    e.preventDefault();
    setIsOpenAddStory(true);
  };

  const handleOpenStory = () => {
    setIsOpenStory(true);
  };

  const onPost = async (e) => {
    e.preventDefault();
    try {
      const route = `user/profile/${slug}/set-status`;
      const method = "PUT";
      const payload = {
        status: true,
      };

      await makeRequest({ route, method, payload });

      !is_enabled && setStatusAd((prev) => !prev);

      navigate(`/lk/profiles`);

      showToast({
        message: `${t("ad")} ${name} ${t("publishedone")}`,
        variant: "success",
      });
    } catch (error) {
      setStatusAd((prev) => !prev);
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };
  const FerifyOnModerete = () => {
    return (
      <div className={`form-confirmation__toggle fd-row align-center`}>
        <Icon spritePath={"verify-fill"} size={"l"} clazz={``} />
        <div className="d-flex fd-column">
          {verify.boost !== 1 && !isBoosted && (
            <span>{t("moderated24hour") + ` ~ 24 ` + t("hourfew")}</span>
          )}
          {(verify.boost === 1 || isBoosted) && (
            <span>{t("fastedmoder") + ` ~ 1 ` + t("hour")}</span>
          )}
        </div>
        {(verify.boost !== 1 && !isBoosted) && (
          <Button
            onClick={() => {
              if(!isBoosted) {
                setIsBoosted(true)
              }
              dispatch(setFastVerStatus(true));
            }}
            size={"s"}
            clazz={`button_outline--green-accent`}
          >
            {t("fasttrack")}
          </Button>
        )}
      </div>
    );
  };

 

  const verStatus = useSelector(getIndiVerStatus);
  const superVerStatus = useSelector(getIndiSuperVerStatus);

  const isVerActive = verStatus || superVerStatus;
  let verText = t("verification");
  if (isVerActive) {
    verText = t("verification") + " - " + t("request ");
  }
 
  const verHandle = () => {
    if (scrollToVer && !isVerActive) {
      scrollToVer();
      return;
    }

    if (isVerActive) {
      dispatch(setRequestVerStatus(true))
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const route = `user/profiles`;

        const method = "GET";
        const payload = {
          slug: formData.slug,
        };

        const data = await makeRequest({ route, method, payload });

        if (data) {
          const result = data.profiles[0];

          setProfileData(result);
          setCall(result.ready_videochat);
          setBooking(result.tag_booking);
          setStories(result.stories);

          dispatch(setShortProfileData(result));
          rootElem.scrollIntoView({
            behavior: "smooth",
          });
          setIsLoading(false);
        }
        setIsLoading(false);
      } catch (error) {}
    };

    lang && fetchData();
  }, [lang, rootElem, formData.slug]);

  return (
    <>
      <h2>{t("checkandapprove")}</h2>
      {isLoading ? (
        <Loader />
      ) : (
        <form className={`create-page__form confirmation`}>
          <div className="d-flex gap-16">
            <div className="form-confirmation__left">
              {profileData?.id && (
                <GirlCard
                  edit={true}
                  props={profileData}
                  big={true}
                  status={true}
                />
              )}
              {/* {windowWidth < 1199.98 && (
                <div className="d-flex gap-12 mt-12">
                  <StoriesCircle
                    onClick={handleOpenAddStory}
                    clazz={"model-header__stories"}
                    img={null}
                    edit
                    slug={slug}
                    setStories={setStories}
                    stories={stories}
                  />
                  {!!stories?.length && stories[0]?.media ? (
                    <StoriesCircle
                      onClick={handleOpenStory}
                      img={setMediaSize(stories[0]?.media, "xs")}
                      clazz={"model-header__stories"}
                    />
                  ) : null}
                </div>
              )} */}
            </div>

            <div className="form-confirmation__right">
              <div className="form-confirmation__right-top">
                <UpAd
                  slug={slug}
                  status={true}
                  address={address}
                  current_position={current_position}
                  is_elite={eliteGlobal}
                  is_top={is_top}
                  setElite={setEliteGlobal}
                  id={id}
                  main_photo={photo}
                  city={city}
                  city_id={city_id}
                  name={name}
                  position_price={position_price}
                  promotion_to_at={promotion_to_at}
                />
                <div className="form-confirmation__right--text">
                  {is_new && (
                    <>
                      <p>
                        {t("ad")} {t("get")} {t("Status").toLowerCase()}{" "}
                        <Tag title={t("newmodel")}>NEW</Tag>
                        <br />
                        <span className="green">({textBottom3})</span>{" "}
                        {t("newincity")} <span className="green">{city}</span>{" "}
                        {t("influence")} 14 {t("days")}.
                      </p>
                      <p>
                        {t("moreinfostat")}{" "}
                        <HashLink
                          to={`/guide#statuses`}
                          target={"_blank"}
                          className="green text-right"
                        >
                          {t("see")}
                        </HashLink>
                      </p>
                    </>
                  )}
                  {
                    <div className="d-flex gap-12 mt-12 justify-center align-center">
                      <StoriesCircle
                        onClick={handleOpenAddStory}
                        clazz={"model-header__stories"}
                        img={null}
                        edit
                      />
                      {!!stories?.length && stories[0]?.media ? (
                        <StoriesCircle
                          onClick={handleOpenStory}
                          img={setMediaSize(stories[0]?.media, "xs")}
                          clazz={"model-header__stories"}
                        />
                      ) : null}
                    </div>
                  }
                </div>
              </div>
              <ToggleItem
                toggleTitle={t("videochat")}
                textTop={t("activatechat")}
                textBottom={textBottom1}
                type={"video"}
                checked={call}
                onChangeMain={onChangeCall}
                href={`/guide#videochat`}
              />
              <ToggleItem
                toggleTitle={t("onlineorder")}
                textTop={textTop2}
                textBottom={textBottom2}
                checked={booking}
                onChangeMain={onChangeBook}
                href={`/guide#onlineOrder`}
              />

              {!ero?.length ? null : (
                <IconItem
                  buttonTitle={t("addero")}
                  IconPath={"pants"}
                  textTop={textTop3}
                  textBottom={textBottom3}
                  onClick={handleEroStep}
                />
              )}

              {(!verify && !isVerificationExist) && (
                <IconItem
                  buttonTitle={verText}
                  IconPath={"verify-fill"}
                  textTop={textTop4}
                  textBottom={textBottom4}
                  onClick={verHandle}
                />
              )}
              {(isBoosted || verify.status === 1 || isVerificationExist) && <FerifyOnModerete />}
             
              {!profileData?.gf?.length && 
              <IconItem
                buttonTitle={t("connectgf")}
                IconPath={"girlfriend"}
                textTop={textTop6}
                textBottom={textBottom6}
                onClick={handleAddGirlfriend}
              />}

            </div>
          </div>
          <div
            className={`create-form__buttons ${
              windowWidth && windowWidth > 1199.98
                ? " align-center"
                : ""
            }`}
          >
            <Button
              onClick={onPost}
              size={"l"}
              square={false}
              clazz={`button--green min-w-160 ${
                windowWidth < 479.98 ? "w-100" : ""
              }`}
            >
              {formData.slug
                ? t("save") + " " + t("and") + " " + t("publish").toLowerCase()
                : t("publish")}
            </Button>
          </div>
        </form>
      )}

      <FastVerification
        setIsHealthBoosted={setIsBoosted}
        slug={slug}
      />

      {isOpenGirlfriend &&
        <LinkWithGirlfriend
          open={isOpenGirlfriend}
          setOpen={setIsOpenGirlfriend}
          slug={slug}
          city_id={city_id}
        />
      }

      {isOpenAddStory &&
        <AddStoriesPopup
          open={isOpenAddStory}
          setOpen={setIsOpenAddStory}
          slug={slug}
          setStories={setStories}
          stories={stories}
        />
      }

      {isOpenStory &&
        <StoriesComponentEdit
          open={isOpenStory}
          setOpen={setIsOpenStory}
          setStories={setStories}
          status={true}
          data={!!stories?.length && stories}
        />
      }

      {isPost &&
        <PostAdPopup
          open={isPost}
          setOpen={setIsPost}
          slug={formData.slug}
          name={formData.name}
          status={formData.is_enabled}
          elite={!eliteGlobal}
          helth={!formData?.healthy_at}
          ver={!formData.verified_at}
        />
      }

      <RequestVerPopup
        slug={slug}
        onFetch={() => setIsVerificationExist(true)}
      />
    </>
  );
};

export default Confirmation;
