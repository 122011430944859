import { Helmet } from "react-helmet";
import React from "react";
import { Button, CheckboxNavigation, Icon, InputNick } from "../ui";
import { useDispatch, useSelector } from "react-redux";
import ShortFilterData from "./ShortFilterData";
import useLadyService from "@/services/LadyService";
import {
    hideShort,
    show,
    showShortFilter,
} from "@/stores/slices/showFilterSlice";

import "./shortFilter.scss"
const ShortFilter = () => {
    const { t } = useLadyService()
    const dispatch = useDispatch();
    const shortFilterOpen = useSelector(showShortFilter);

    return (
        <>
            {!!shortFilterOpen ? (
                <Helmet>
                    <html className={"lock"}></html>
                </Helmet>
            ) : null}
            <form className={`short-filter${shortFilterOpen ? " _active" : ""}`}>
                <Button onClick={() => dispatch(hideShort())} clazz={"button-icon filter__close"}>
                    <Icon size={"l"} spritePath={"close"} />
                </Button>
                <div className="short-filter__body">

                    {/* SEARCH HERE */}

                    <InputNick
                        type="search"
                        placeholder={t("modelname")}
                        id="headerSearch"
                        clazz={"search__input"}
                        name={"nickname"}
                    />

                    <div className="short-filter__group">
                        {ShortFilterData().options.map((i) => {
                            return (
                                <CheckboxNavigation
                                    key={i.id}
                                    id={i.id}
                                    checked={i.checked}
                                    title={i.title}
                                    path={i.path} w
                                    value={i.value}
                                    name={i.name}
                                    filter_disabled={i.forDisabled}
                                    isLink={i.isLink}
                                    onClick={() => i?.onClick()}
                                    translateKey={i.translateKey}
                                />
                            );
                        })}
                    </div>

                    <Button
                        size={"l"}
                        clazz={"button--tetriary short-filter__button"}
                        onClick={() => dispatch(show())}
                    >
                        {t("allfilters")}
                        <Icon size={"l"} spritePath={"filter"} />
                    </Button>

                </div>
            </form>
        </>
    )
}

export default ShortFilter