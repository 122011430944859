import useLadyService from "@/services/LadyService";
import { InputInLabel, Icon } from "@/components/ui";
import { useEffect, useMemo, useState } from "react";
import solana from "@/assets/img/icons/solana.svg";
import { Select } from "@/components/ui";
import { calculateWithCommission } from "@/helper/calculateWithCommission";

const paymentsMethods = [
  { icon: "bitcoin", name: "Bitcoin" },
  { icon: "tether", name: "Tether" },
  // { icon: "usdc", name: "USDC" },
  // { icon: "etherium", name: "Ethereum" },
  // { icon: "ltc", name: "Litecoin" },
  // { icon: "matic", name: "Matic" },
  // { icon: "tron", name: "Tron" },
];

const InputsBlock = ({
  values,
  setValueTo,
  setValueFrom,
  registerFrom,
  registerTo,
  currency,
  setCurrency,
  euroValue,
  setEuroValue,
  defaultValue
}) => {
  const windowWidth = window.innerWidth;
  const isMobile = useMemo(() => windowWidth < 767.98, [windowWidth]);

  const { t, resultBalance } = useLadyService();

  const currencyOption = {
    main_title: t("currency"),
    options: [
      { id: 10, title: "USDT", value: "usdt" },
      { id: 20, title: "BTC", value: "btc" },
    ],
  };

  const [coinValue, setCoinValue] = useState(values[`price-crypto-coin`]);

  const calculateEuro = (val) => {
    const value = parseFloat(val) || 0;
    const rate = 1;
    const adjustedValue = value * rate;
    const convertedValue = calculateWithCommission(
      adjustedValue,
      -0.01,
    );
    const roundedValue = parseFloat(convertedValue.toFixed(2));

    setValueFrom("price-crypto-coin", value);
    setValueTo("price-crypto-euro", roundedValue);
    setEuroValue(roundedValue);
    setCoinValue(value);
  };

  return (
    <>
      <fieldset>
        <legend>{t('amountwithdraw')}</legend>
        <InputInLabel
          price={"erocoin"}
          id={"earn-to"}
          type={"number"}
          placeholder={values[`price-crypto-coin`] || defaultValue}
          max={resultBalance}
          trigger={coinValue}
          register={registerTo}
          onChange={(e) => calculateEuro(e.target.value)}
          clazz={`${isMobile ? '' : "min-w-160"}`}
        >
        </InputInLabel>
      </fieldset>
      <fieldset>
        <legend>{t("currency")}</legend>
        <Select
          onChange={setCurrency}
          options={currencyOption.options}
          clazz={"button button-xs justify-start mt-10"}
          arrowSize={"s"}
          name={"currency"}
          defaultTitle={
            currencyOption.options.filter((item) => item.value === currency)[0]
              .title
          }
          withoutTitle={true}
          postApi={true}
        />
      </fieldset>
      <Icon size={"m"} spritePath={"arrow-right"} clazz={'mt-10'}/>
      <fieldset>
        <legend>{t("youwillget")}</legend>
        <span
            className={`title_h3 mb-0 ${
            "currency--euro"
          }`}
        >
          {euroValue || defaultValue - (defaultValue * 0.01)}
        </span>
      </fieldset>
    </>
  );
};

export const Crypto = ({
  values,
  setValueTo,
  setValueFrom,
  registerTo,
  registerFrom,
  currency,
  setCurrency,
  defaultValue
}) => {
  const { t } = useLadyService();
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 767.98;

  const [euroValue, setEuroValue] = useState(values[`price-crypto-euro`]);

  return (
    <>
      <div className={`balance-page__header${isMobile ? " gap-0 mb-16" : ""}`}>
        <h3>{t("i-crypto")}</h3>
        <div className="payment-methods">
          {paymentsMethods.map((i, index) => {
            return (
              <div
                className="payment-methods__icon"
                key={index + 1}
                title={i.name}
              >
                <Icon type={"payment"} spritePath={i.icon} />
              </div>
            );
          })}
          <div title={"Solana"} className="payment-methods__icon">
            <img className={"w-100 height-100"} src={solana} alt={"Solana"} />
          </div>
        </div>
      </div>
      
        <div
          className={`withdraw-inputs`}
        >
          <InputsBlock
          defaultValue={defaultValue}
            values={values}
            setValueTo={setValueTo}
            setValueFrom={setValueFrom}
            registerFrom={registerFrom}
            registerTo={registerTo}
            currency={currency}
            setCurrency={setCurrency}
            euroValue={euroValue}
            setEuroValue={setEuroValue}
          />
        </div>
        {!isMobile && (
          <span className="w-50 p">
            {t("earingin") + " 1-2 " + t("workdays")}
            <br />
            <p className={"p3 mt-0 mb-0 color-green"}>{t("fee") + " Confirmo"} - 1%</p>
          </span>
        )}

      {isMobile && windowWidth !== 0 && (
        <span className="w-100 mt-8">
          {t("earingin") + " 1-2 " + t("workdays")}
          <br />
          <p className={"color-green"}>{t("fee") + " Confirmo"} - 1%</p>
        </span>
      )}
    </>
  );
};
