import { useEffect, useState } from "react";
import { makeRequest } from "@/services/makeRequest";
import showToast from "@/components/toast/Toast";
import useLadyService from "@/services/LadyService";
import { Loader } from "../../../ui";
import { Link } from "react-router-dom";
import { setUserTickets } from "../../../../stores/slices/userSlice";
import {format, fromUnixTime} from "date-fns";

export const Dialog = ({ ticketId, setLoading, loading, setDialogData, dialogData, messages_count, new_count }) => {
  const { t, dispatch } = useLadyService();
  useEffect(() => {
    const fetchData = async () => {
    setLoading(true);
      try {
        const route = `tickets/${ticketId}`;
        const method = "GET";

        const result = await makeRequest({ route, method });
        if (result) {
          setDialogData(result.ticket.data.messages.data.slice(1));
          dispatch(setUserTickets(prev => prev - new_count))
        }
        setLoading(false);
      } catch (res) {
        setLoading(false);
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  }, []);

  return !loading ? (
    dialogData.map((item, index) => {
      const createdMoment = format(fromUnixTime(item.created_at),"dd.MM.yyyy HH:mm")

      return (
        <div key={index} className={`${item.self ? "" : "message-admin"}  mb-24`}>
          <span>
            <span className="p1 color-main">
              {item.self ? `${t("you")}` : item.moderator}
            </span>{" "}
            {createdMoment}
          </span>

          <p className="p1 color-main">{item.message}</p>
          {!!item.attach.length && (
            <div className="d-flex gap-16 flex-wrap">
              {item.attach.map((item, index) => {
                return <Link key={index} target="_blank" to={item.link} className="color-main">{item.file_name}</Link>;
              })}
            </div>
          )}
        </div>
      );
    })
  ) : (
    <Loader />
  );
};
