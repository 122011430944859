import { makeRequest } from "@/services/makeRequest";
import showToast from "../components/toast/Toast";
import useLadyService from "../services/LadyService";

const ImportAdComponent = () => {
    const { t, navigate } = useLadyService();
  
    const importAd = async (importAdValue, setLoading) => {
      if(!importAdValue) {
        return 'error-empty'
      }
      if(setLoading && importAdValue) {
        setLoading(true)
      }
      try {
        const route = `user/profile/import`;
        const method = "POST";
        const payload = {
          url: importAdValue,
        };
  
        const result = await makeRequest({ route, method, payload });
        if (result) {
          showToast({
            message: t("success"),
            variant: "success",
          });
          if(setLoading) {
            setLoading(false)
          }
          navigate(`/lk/profile/${result.slug}/edit`)
          return true
        } else {
          showToast({
            message: t("oops"),
            variant: "error",
          });
        }
        if(setLoading) {
          setLoading(false)
        }
      } catch (error) {
        console.log('error: ', error);
        if(setLoading) {
          setLoading(false)
        }
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };
  
    return {
        importAd
    };
  };
  
  export default ImportAdComponent;