import { useSelector } from "react-redux";
import {
  getErocontentAccessStatus,
  setErocontentAccessStatus,
} from "@/stores/slices/popupSlice";
import { Button, Icon } from "@/components/ui";
import useLadyService from "@/services/LadyService";
import {HashLink} from "react-router-hash-link";
import {makeRequest} from "@/services/makeRequest";
import showToast from "../../toast/Toast";
import {Popup} from "../index";
import {format, fromUnixTime} from "date-fns";

const ErocontentAccess = ({ ero, profileSlug }) => {
  const { dispatch, t, lang, resultBalance, userType } = useLadyService();

  const formattedDate = format(fromUnixTime(ero?.data?.updated_at || null),"dd.MM.yyyy")

  const erocontentAccessValue = useSelector(getErocontentAccessStatus);

  const handleActivate = async () => {
      try {
        if(resultBalance >= ero.data.price){

          const route = `profiles/${profileSlug}/get-ero-access`;
          const method = "GET";

          const payload = {};

          await makeRequest({ route, method, payload });
          window.location.reload()
          dispatch(setErocontentAccessStatus(false))

          showToast({
            message: t("success"),
            variant: "success",
          });
        } else {
          dispatch(setErocontentAccessStatus(false))
          showToast({
            message: `${t("insuff")} ${t("thistran")}`,
            variant: "error",
            type: 'erocontentAccess',
            userType: userType,
          });
        }
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
  };

  const handleClose = () => {
    dispatch(setErocontentAccessStatus(false))
  };

  return (
    <Popup
      open={!!erocontentAccessValue}
      setOpen={handleClose}
      id={"erocontentAccess"}
      wrapperClazz="popup-form-erocontent"
      container={'div'}
      clazz={'text-center'}
    >
        <div className="popup-form__inner mb-12 gap-4">
          <h2 className="mb-8">
            {t("paidaccess")}
          </h2>
          <div className="popup-form__fake-input popup-form__fake-input-erocontent">
            {ero?.data?.description && (
              <div className={"color-green"}>
                <Icon size={"l"} clazz={"_green"} spritePath={"pants"}/>
                {ero?.data?.description}
              </div>
            )}

            {!!ero?.data?.photo?.length && (
              <div>
                <Icon size={"l"} spritePath={"image"}/>
                {ero?.data?.photo_count} {t("eroticophotosand")}
              </div>
            )}

            {!!ero?.data?.video?.length && (
              <div>
                <Icon size={"l"} spritePath={"video-play"} />
                {ero?.data?.video?.length} {t("videos")}
              </div>
            )}

            <p className={"mb-0"}>
              <span className={"color-600"}>{t("postedon")}:</span>{" "}
              {formattedDate}
            </p>
          </div>
        </div>

        <div className="popup-form__inner">
          <div className="popup-form__fake-input">
            <p className={"p2"}>{t("accessfee")}</p>
            <span className={"title_h2 currency--erocoin"}>{ero?.data?.price}</span>
          </div>
          <p>{t("paymenttransfered")}</p>
        </div>

        <div className="popup-form__inner d-flex fd-column gap-16">
          <Button
            size={"l"}
            clazz={"button--green"}
            onClick={() => handleActivate()}
          >
            {t("payandwatch")}
          </Button>

          <span className={"popup-form__text title"}>
            {t("or")}{" "}
            <HashLink
              reloadDocument={true}
              to={`/${lang}/lk/customer#tariffs`}
              className={"color-green title td-none d-inline-block"}
            >
              {t("changetariff")}
            </HashLink>{" "}
            {t("freeaccess")}
          </span>
        </div>
    </Popup>
  );
};

export default ErocontentAccess;
