import {
  BurgerMenu,
  DropdownLanguage, HeaderTabs,
  InstructionLink,
  Logo,
  MenuNavBar,
  SelectCity,
  SupportButton,
  UserWrapperDesktop
} from "../components";
import Filters from "../components/Filters";
import ShortFilter from "@/components/shortFilter/ShortFilter";
import React from "react";
import useLadyService from "@/services/LadyService";
import { Burger, ButtonLink, Icon, Toggle } from "@/components/ui";
import { useSelector } from "react-redux";
import { getScrolledClass } from "@/stores/slices/headerSlice";
import TogglesOrLinks from "../components/TogglesOrLinks";

const ModelOrAgency = ({ isLk }) => {
  const { windowWidth, t, lang } = useLadyService();

  const scrolledClass = useSelector(getScrolledClass);

  const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');


  if (windowWidth > 1199.98)
    return (
      <>
        <div className="header__top">
          <ButtonLink
            href={`/${lang}/lk/photograph`}
            title={t("photographer")}
            size={"xs"}
            clazz={"add-form button_outline--green _no-underline"}
          >
            <Icon size={"m"} spritePath={"camera"} />
            {t("photographer")}
          </ButtonLink>

          <InstructionLink />

          <TogglesOrLinks />

          <SupportButton square={false} size={"xs"} iconSize={"m"} />

          <DropdownLanguage />

          <Toggle type={"theme"} id={'theme-main-toggle'} />
        </div>

        <div className={isJsdom ? "header__bottom ssr" : "header__bottom"}>
          <Logo scrolledClass={scrolledClass} />

          {isLk ? (
            <div className={"header__tabs"}>
              <HeaderTabs />
            </div>
          ) : (
            <>
              <SelectCity />

              <Filters />
            </>
          )}

          <UserWrapperDesktop />
        </div>
      </>
    );

  if (windowWidth > 767.98)
    return (
      <>
        <div className="header__top">
          <Logo scrolledClass={scrolledClass} />
          {!isLk ? (
            <>
              <SelectCity />

              <Filters />
            </>
          ) : null}

          <div className="user__wrapper">
            {windowWidth > 1000.98 ? <TogglesOrLinks /> : null}

            <SupportButton square={true} size={"s"} iconSize={"m"} />

            <DropdownLanguage size={"s"} />

            <Burger />

            <Toggle type={"theme"} id={'theme-main-toggle'} />

            <BurgerMenu lk={isLk} />
          </div>

          {!isLk && <ShortFilter />}
        </div>

        {/* {windowWidth < 1000.98 && (
          <div className={isJsdom ? "header__bottom ssr" : "header__bottom"}><TogglesOrLinks /></div>
        )} */}
      </>
    );

  return (
    <>
      <div className="header__top">

        <div className="user__wrapper">
          <SupportButton square={true} />
          <Toggle type={"theme"} id={'theme-main-toggle'} />
        </div>


        <Logo scrolledClass={scrolledClass} />


        <div className="user__wrapper">
          <DropdownLanguage />
          <Burger />
        </div>

        <BurgerMenu lk={isLk} />

        <MenuNavBar />

        {!isLk && <ShortFilter />}
      </div>

      {/* <div className={isJsdom ? "header__bottom ssr" : "header__bottom"}><TogglesOrLinks /></div> */}
    </>
  );
};

export default ModelOrAgency