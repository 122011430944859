
import { ButtonLink } from "@/components/ui";
import useLadyService from "../../../services/LadyService.js";
import Popup from "../Popup";

const MinBalancePopup = ({open, setOpen}) => {
  const { navigate, lang, t } = useLadyService();

  return (
    <>
      <Popup
        open={open}
        setOpen={setOpen}
        container={'div'}
      >
          <h2>{t("insuff")}</h2>
        <div className="popup-form__inner">
          
          <span className="text-center p2">
            {t("insuffonbal")} {t("thistran")}
          </span>

          <ButtonLink
            href={"/lk/topup"}
            clazz={"button--green _no-underline justify-center"}
            size="l"
            onClick={(e) => {
              e.preventDefault();
              const a = document.createElement("a");
              a.href = `/lk/topup`;
              a.target = "_blank";
              a.style.display = "none";
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }}
          >
            {t("topupbalance")}
          </ButtonLink>
        </div>
      </Popup>
    </>
  );
};

export default MinBalancePopup;
