import { Link } from "react-router-dom";
import React, { useRef, useState, useEffect } from "react";

import { Button, ButtonLink, Icon } from "@/components/ui";
import { setStoriesMap } from "@/stores/slices/popupSlice";
import { setOneModelPosition } from "@/stores/slices/mapModelSlice";
import useLadyService from "@/services/LadyService";

import GirlCardButtons from "./components/GirlCardButtons";
import GirlCardSlider from "./components/GirlCardSlider";
import GirlCardIconsAndTags from "./components/GirlCardIconsAndTags";

import "./girlCard.scss";
import { useSelector } from "react-redux";
import { getUserCurrentCity } from "@/stores/slices/userSlice";
import { Checkbox } from "../../ui";
import GirlCardAdvantages from "./components/GirlCardAdvantages";
import {AddStoriesPopup} from "@/components/popups";
import StoriesCircle from "../../stories/circle/StoriesCircle";
import StoriesComponentEdit from "@/components/stories/StoriesComponentEdit";



const GirlCard = ({ 
  props, 
  isCheckbox=true, 
  agencySelectCheckbox = [], 
  setAgencySelectCheckbox = false, 
  checkbox = false, 
  selected = false, 
  ox = false, 
  clazz, 
  edit, 
  status, 
  targetBlank = false, 
  infoCard = false ,
  changeParty = false,
  topModelsSlider = false
}) => {

  const {
    gender,
    orientation,
    id,
    slug,
    name,
    rating,
    address,
    city,
    age,
    height,
    weight,
    breast,
    penis_size,
    prices,
    languages,
    services,
    is_online,
    is_individual,
    is_top,
    is_elite,
    is_escort,
    is_healthy,
    is_verified,
    is_pornstar,
    is_new,
    tag_video,
    tag_friends,
    tag_booking,
    tag_bdsm,
    ready_travel,
    count_photos,
    count_videos,
    photos,
    is_favorite,
    healthy_at,
    verified_at,
    has_ero,
    price,
    main_photo,
    media,
    statistic = {},
    archive,
    stories: profile_stories,
    is_transferred,
    new_prices
  } = props;

  const { favorites } = statistic;

  const { windowWidth, dispatch, setMediaSize, t, travelVariants, userTypeAgency, lang, userTypeIndi, navigate } = useLadyService();

  const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');

  const toggleMapOpen = (slug) => {
    dispatch(setOneModelPosition(slug));
    dispatch(setStoriesMap(true));
  };
  
  const [expand, setExpand] = useState(false);

  const linkRef = useRef(null);

  const setServices = Array.from(new Set(services));
  const currentCity = useSelector(getUserCurrentCity);


  let newArray = [];

  if (services?.length) {
      if (!services[0]["category_id"]) {
        newArray = setServices;
      } else {
        newArray = services.flatMap(category => category.services.map(service => service.slug));
      }
  }


  const ParamTemplate = (translateKey, param, decimal, langs = false) => (
    <span className={"girl-card__param"}>
      <span>{t(translateKey)}</span>
      {!langs ? `${param} ${decimal ? t(decimal) : ""}` : 
        <div className={'flags'}>
          {languages.slice(0, 3).map((lang, index) => {
            const key = Object.keys(lang)[0];
            return (
              <Icon
                key={'flag' + index}
                title={lang[key].name}
                type={"flag"}
                spritePath={lang[key].code.toUpperCase()}
              />
            );
          })}
          { langs.length > 3 ? `+${langs.length - 3}` : ''}
      </div>
      }
    </span>
  );

  const PriceTemplate = (clazz, translateKey, price, status) => (
    <span className={`girl-card__price--${clazz} ${status ? '' : 'disabled'}`}>
      <span className={'price-title'}>{t(translateKey)}</span>
      {status ? (price > 0 ? price + (lang === "cz" ? "Kč" : "€") : t('yes') ) : t('no')}
    </span>
  );

  const handleAgencyCheckbox = (slug) => {
    if(setAgencySelectCheckbox) {
      if(!agencySelectCheckbox.includes(slug)) {
        setAgencySelectCheckbox(prev => [...prev, slug])
      } else {
        setAgencySelectCheckbox(prev => prev.filter(item => item !== slug))
      }
    }
  }

  const truncateString = (str, length) => {
    return str?.length > length ? str?.slice(0, length) + "..." : str;
  }

  const IconTemplate = (translateKey, icon) => {
    let clazz = translateKey === "erocontent" ? is_top? "" : "color-green" : "";
    let travelTitle = translateKey === "travel" ? `: ${travelVariants[ready_travel]}` : "";
    return (
      <Icon
        square
        clazz={clazz}
        title={`${t(translateKey)}${travelTitle}`}
        spritePath={icon}
        size={"l"}
      />
    );
  };

  const link = `/${lang}/profile/${slug}`;

  const archiveClass = archive ? " not_active" : "";

  const topClass = is_top ? " girl-card--top" : "";

  const eliteClass = is_elite ? " girl-card--elite" : "";

  const statusClass = edit ? (!status ? " not_active" : "") : "";

  const setBigClass = " girl-card__big";

  const setClass = clazz ? ` ${clazz}` : "";

  const topSliderClass = topModelsSlider ? " top-slider-card" : '';

  const classes = `girl-card${topClass}${eliteClass}${statusClass}${setBigClass}${setClass}${archiveClass}${topSliderClass}${isJsdom ? ' ssr' : ''}`;

  const photo = !!main_photo
    ? main_photo
    : !!media?.photo?.length
    ? media.photo[0]
    : null;
   
  const showAdditionalInfo = (!isJsdom)

  const [stories, setStories] = useState(profile_stories);
  const [isOpenAddStory, setIsOpenAddStory] = useState(false);
  const [isOpenStory, setIsOpenStory] = useState(false);

  const handleOpenAddStory = () => {
    setIsOpenAddStory(true);
  };

  const handleOpenStory = () => {
      setIsOpenStory(true);
  };

  const HandleGrowCard = () => {
    const el = document.querySelector(`#grow-part-${id}`)
    const offset = el?.offsetHeight ;
    const girlCardStyle = isJsdom
        ? {}
        : { bottom: `-${offset ? offset - 20 : 182}px` };
    return (
        <div className={`girl-card__group ${isJsdom ? '' : 'show-hover'}`} id={`grow-part-${id}`} style={girlCardStyle}>

          <div className="girl-card__modes">
            {showAdditionalInfo &&
              <>
                {!!tag_video ? IconTemplate("video", "video-play") : <></>}
                {!!tag_friends ? IconTemplate("girlfriend", "girlfriend") : <></>}
                {!!has_ero ? IconTemplate("erocontent", "pants") : <></>}
                {!!tag_booking ? IconTemplate("onlineorder", "pay-content") : <></>}
                {!!ready_travel ? IconTemplate("travel", "travel") : <></>}
                {!!is_escort ? IconTemplate("escort", "escort") : <></>}
                {!!newArray.length ? newArray.map((item) => {
                return <span key={item}>{t(`${item}-s`)}</span>;
                }): <></>}
              </>
            }
          </div>

          {showAdditionalInfo && (!!height || !!weight || !!breast || !!penis_size) ? (
            <div className="girl-card__params">
              {!!height && ParamTemplate("height", height, "cm")}
              {!!weight && ParamTemplate("weight", weight, "kg")}
              {!!penis_size && ParamTemplate("penissize", penis_size)}
              {!!breast && ParamTemplate("breastsize", breast)}

              {!isJsdom && (!!rating || !!languages?.length) ? (
                infoCard ? <></> :
                  ParamTemplate("ispeak", false, false, languages)
              ) : <></>}
            </div>
          ) : <></>}

          {!isJsdom ? (
            infoCard ? <></> :
              <div className={'fd-column gap-12'}>
                <hr/>
                <GirlCardButtons props={props} edit={edit}/>
              </div>
            ) : <></> }

        
        </div>
    )
  }

  const expandCard = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setExpand((prev)=>!prev)
  }

  const cardRef = useRef(null)
  const wrapperRef = useRef(null)
  const [heightCard, setHeightCard] = useState()
  const [widthCard, setCardWidth] = useState()

  useEffect(()=>{
    setCardWidth(wrapperRef.current.getBoundingClientRect().width)
    setHeightCard(cardRef.current.getBoundingClientRect().height)
  }, [windowWidth])


  if(topModelsSlider) {
    return (
     <>
      <div 
        ref={cardRef}  
        className={classes}
      >
        <div 
          ref={wrapperRef}
          className={`girl-card__wrapper ${expand ? 'expand' : ''} ${edit ? 'edit' : ''} ${isJsdom ? 'ssr' : ''}`}
        >
          <div
            onClick={() => linkRef.current.click()}
            className="swiper-slide girl-card__slide"
          >
            {!!photo ? (
              <img
                src={setMediaSize(photo, "s")}
                alt={t("altphoto", {GirlName: name, City: currentCity.name })}
              />
            ) : <></>}
          </div>
  
          <div className="girl-card__info-wrapper">
            <div className={`girl-card__info`}>
              <Link
                target={targetBlank ? "_blank" : ''}
                to={!!archive ? '' : edit ? link : `/${lang}/profile/${slug}`}
                ref={linkRef}
                title={
                  is_online ? `${name} ${t("online")}` : `${name} ${t("offline")}`
                }
                className={`girl-card__name mb-0 mt-0 text-dots`}
              >
                {truncateString(name, 10)}
              </Link>
    
              <div className={'small-card-prices girl-card__prices'}>
                <span className={`girl-card__price ${new_prices?.incall?.status ? '' : 'disabled'}`}>
                  <span className={'price-title'}>{t('incall')}</span>
                  {new_prices?.incall?.status ? (new_prices?.incall?.price > 0 ? new_prices?.incall?.price + (lang === "cz" ? "Kč" : "€") : t('yes') ) : t('no')}
                </span>
                <span className={`girl-card__price ${new_prices?.outcall?.status ? '' : 'disabled'}`}>
                  <span className={'price-title'}>{t('outcall')}</span>
                  {new_prices?.outcall?.status ? (new_prices?.outcall?.price > 0 ? new_prices?.outcall?.price + (lang === "cz" ? "Kč" : "€") : t('yes') ) : t('no')}
                </span>
              </div>
            </div>
          </div>
          
        </div>
      </div>
     </>)
  }
  else {
    return (
      <>
        <div 
          ref={cardRef}  
          className={classes} 
          style = {{height: (edit || isJsdom || expand || (windowWidth < 768)) ? 'unset' : heightCard}} >
          <div 
            ref={wrapperRef} 
            style = {{width: (edit || isJsdom || expand || (windowWidth < 768)) ? 'unset' : widthCard}} 
            className={`girl-card__wrapper ${expand ? 'expand' : ''} ${edit ? 'edit' : ''} ${isJsdom ? 'ssr' : ''}`}
          >
            {edit ? (
              <>
                <div
                  className="girl-card__slider _edit"
                >
                  <Button clazz={`girl-card__favorite`}>
                    <span className="color-green">{favorites}</span>
                    <Icon size={"l"} spritePath={"favorite-fill"}/>
                  </Button>
    
                  {!(is_transferred && userTypeIndi) &&
                   <Button
                    size={"xs"}
                    title={t("edit") + " " + t("onpage") + " " + t("myads")}
                    clazz={`button--secondary girl-card__edit`}
                    onClick={()=>{navigate(`/${lang}/lk/profile/${slug}/edit`)}}
                  >
                    <Icon spritePath={"edit-2"} size={"xs"}/>
                    {t("edit")}
                  </Button>}
    
                  {(edit && isCheckbox && !changeParty) ? <div className="stories_wrapper">
                    <StoriesCircle
                      onClick={handleOpenAddStory}
                      clazz={
                          !status
                              ? "model-header__stories disabled"
                              : "model-header__stories"
                      }
                      img={null}
                      edit
                      data={stories}
                    />
                    {stories?.media && 
                    <StoriesCircle
                        onClick={handleOpenStory}
                        img={setMediaSize(stories?.media, "xs")}
                        clazz={"model-header__stories"}
                    />}
                  </div> : <></>}
    
                  <div
                    onClick={() => linkRef.current.click()}
                    className="swiper-slide girl-card__slide"
                  >
                    {!!photo ? (
                      <img
                        src={
                             windowWidth < 599.98
                              ? setMediaSize(photo, "l")
                              : setMediaSize(photo, "m")
                        }
                        alt={t("altphoto", {GirlName: name, City: currentCity.name })}
                      />
                    ) : <></>}
                  </div>
    
                  <div className={`girl-card__descr ${isJsdom ? 'ssr' : ''}`}>
                    <div className={`girl-card__tags-wrapper`}>
                      <GirlCardIconsAndTags
                        props={{
                          gender,
                          orientation,
                          is_individual,
                          is_healthy,
                          is_verified,
                          is_pornstar,
                          is_new,
                          tag_bdsm,
                          healthy_at,
                          verified_at,
                        }}
                      />
                    </div>
                  </div>
                  {userTypeAgency && isCheckbox && (
                    <Checkbox checked={agencySelectCheckbox.includes(slug)} title={""}
                            onClick={() => handleAgencyCheckbox(slug)}/>
                  )}
                </div>
              </>
            ) : (
              <GirlCardSlider
                checkbox={checkbox}
                selected={selected}
                linkRef={linkRef}
                props={{
                  gender,
                  orientation,
                  id,
                  slug,
                  name,
                  is_healthy,
                  is_verified,
                  count_photos,
                  count_videos,
                  photos,
                  is_favorite,
                  has_ero,
                  is_individual,
                  is_new,
                  tag_bdsm,
                  is_pornstar,
                  verified_at,
                  healthy_at,
                }}
              />
            )}
            <div className="girl-card__info-wrapper">
              {showAdditionalInfo ? (
              <div className="girl-card__age">
                {age} <span>{t("years")}</span>
              </div>
              ) : <></>}
    
              <div className={`girl-card__info ${isJsdom ? 'girl-card__age' : ''}`}>
                <div>
                  <Link
                    target={targetBlank ? "_blank" : ''}
                    to={!!archive ? '' : edit ? link : `/${lang}/profile/${slug}`}
                    ref={linkRef}
                    title={
                      is_online ? `${name} ${t("online")}` : `${name} ${t("offline")}`
                    }
                    className={`girl-card__name${
                      is_online ? " _online" : " _offline"
                    } mb-0 mt-0 text-dots`}
                  >
                    {truncateString(name, 15)}
                  </Link>
                  {rating ? 
                    <div className={'rating'}>
                      <span>{Math.round(rating)}</span>
                      <Icon clazz={'color-green'} spritePath={"star-fill"} size={"xs"}/>
                    </div>: <></>}
                </div>
                
                {!isJsdom && !!address ? (
                  <Link
                    to={"#"}
                    title={t("onmap")}
                    className="girl-card__address"
                    onClick={() => toggleMapOpen(slug)}
                  >
                    <Icon spritePath={"map"} size={"xs"}/>
                    {city}, {address}
                  </Link>
                ) : !!city ? (
                  <span title={t("onmap")} className="girl-card__address">
                    <Icon spritePath={"map-pin"} size={"xs"}/>
                    {city}
                  </span>
                ) : <></>}
      
                  
               
              </div>
            </div>
    
            <div id={'dev-pull'} className="girl-card__prices">
                {PriceTemplate("private", "incall", new_prices?.incall?.price, new_prices?.incall?.status)}
                {PriceTemplate("escort", "outcall", new_prices?.outcall?.price, new_prices?.outcall?.status)}
                {PriceTemplate("accompaniment", "accompaniment", new_prices?.escort?.price, new_prices?.escort?.status)}
                {windowWidth < 1024 && !isJsdom ? 
                <Button
                  clazz={'girl-card__expand button-icon ' + (expand ? 'expand' : '')}
                  onClick={(e)=>expandCard(e)}
                >
                  <Icon spritePath={'chevrone-down'}/>
                </Button> : <></>}
            </div>
    
            <HandleGrowCard />
            
          </div>
    
          {isOpenStory && (
                    <StoriesComponentEdit
                        open={isOpenStory}
                        setOpen={setIsOpenStory}
                        setStories={setStories}
                        status={status}
                        data={stories}
                    />
                )}
    
          {isOpenAddStory && (
                    <AddStoriesPopup
                        open={isOpenAddStory}
                        setOpen={setIsOpenAddStory}
                        slug={slug}
                        setStories={setStories}
                        stories={stories}
                    />
                )}
        </div>
      </>
    );
  }
};

export default GirlCard;
