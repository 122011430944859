import {
  Icon,
  Tag,
} from "@/components/ui";
import {useTranslation} from "react-i18next";
import useLadyService from "@/services/LadyService";

const GirlCardIconsAndTags = ({props}) => {
  const {
    gender,
    orientation,
    is_individual,
    is_healthy,
    is_verified,
    is_pornstar,
    is_new,
    tag_bdsm,
    healthy_at,
    verified_at,
  } = props;

  const {
    orientations,
    gendersTitle,
    getDayMonthYear
  } = useLadyService();

  const {t} = useTranslation("translation");

  let gendersText = `${gendersTitle[gender]}${orientation ? ` — ${orientations[orientation]}` : ''}`

  return (
        <>
          <div className="d-flex gap-4">
            {!!is_individual && <Tag title={t('indireg')}>INDI</Tag>}
            {!!is_new && <Tag title={t('newmodel')}>NEW</Tag>}
            {!!tag_bdsm && <Tag title={t('bdsmservice')}>BDSM</Tag>}  
            {!!is_pornstar && <Tag title={t('pornstar')}>STAR</Tag>}
          </div>

          {!!gender ? (
            <Icon
              square
              title={gendersText}
              clazz={"girl-card__icon"}
              spritePath={`gender-${!!gender ? gender : "female"}`}
              size={"l"}
            />
          ) : null}

          {!!verified_at ? (
            <Icon
              square
              clazz={"girl-card__icon"}
              spritePath={"verify-fill"}
              size={"l"}
              title={`${t("verified")}. ${t("photosverified")}.  ${t(
                "lastcheck"
              )}: ${getDayMonthYear(verified_at)}`}
            />
          ) : null}

          {!!healthy_at ? (
            <Icon
              square
              clazz={"girl-card__icon"}
              spritePath={"health-fill"}
              size={"l"}
              title={`${t("healthy")}. ${t("modeltests")}. ${t(
                "lastcheck"
              )}: ${getDayMonthYear(healthy_at)}`}
            />
          ) : null}
        </>
  )
}

export default GirlCardIconsAndTags