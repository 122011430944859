import  { useEffect } from 'react';

const CountdownTimer = ({setEndTime, time = 60, setTime}) => {

  useEffect(() => {
    let interval = null;

    if (time > 0) {
      interval = setInterval(() => {
        setTime((prev)=>prev - 1);
      }, 1000);
    } else {
      if(setEndTime) {
        setEndTime(true)
      }
    }

    return () => clearInterval(interval);
  }, [time, setEndTime, setTime]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <span className='title_h3 color-green text-center'>{formatTime(time)}</span>
  );
};

export default CountdownTimer;
