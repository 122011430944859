import { VideoContainer } from "./pageComponents";
import { useState, useEffect } from "react";
import { makeRequest } from "@/services/makeRequest";

import Pagination from "../../pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserVideochatBlock,
  getUserVideochatCanceledBlock,
  setReadyToVideochat,
} from "../../../stores/slices/userSlice";
import useLadyService from "../../../services/LadyService";
import { CustomSkeleton, Toggle } from "../../ui";
import { useLocation, useNavigate } from "react-router-dom";
import useCheckAccess from "../../../hooks/useCheckAccess";
import { getUserVideoStatus } from "@/stores/slices/userSlice";
import showToast from "@/components/toast/Toast";

const skeletons = [...Array(12)].map((_, index) => {
  return <CustomSkeleton key={index} height={150} />;
});

const Res = ({ isLoading, data, setData }) => {
  const { t } = useLadyService();

  switch (true) {
    case isLoading: {
      return skeletons;
    }

    case !!data?.length: {
      return data?.map((i) => {
        return <VideoContainer key={i.uuid} data={i} setData={setData} />;
      });
    }

    default: {
      return <h3 className="text-center mt-12">{t("emptytransaction")}</h3>;
    }
  }
};

const VideochatHistoryPage = () => {
  const { t, token, lang, scrollToTop, windowWidth, userTypeIndi } = useLadyService();
  const navigate = useNavigate();
  const dispatch= useDispatch()

  const {hasAccess, AccessTitle} = useCheckAccess('videochats')

  const [data, setData] = useState([]);
  const { hash } = useLocation();

  const newVideochatBlock = useSelector(getUserVideochatBlock);

  const newVideochatCanceledBlock = useSelector(getUserVideochatCanceledBlock);


  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);

  const [pagesList, setPagesList] = useState([]);

  const handlePage = (e, value) => {
    e.preventDefault();
    navigate(`/${lang}/lk/videochats#${value}`);
    setPage(value);
  };

  useEffect(() => {
    setData((prev) => {
      return [newVideochatBlock.data, ...prev];
    });
  }, [newVideochatBlock]);

  useEffect(() => {
    if (newVideochatCanceledBlock && newVideochatCanceledBlock.uuid) {
      setData((prev) => {
        return prev.map((item) => {
          if (item.uuid === newVideochatCanceledBlock.uuid) {
            return { ...item, status: 6 };
          }
          return item;
        });
      });
    }
  }, [newVideochatCanceledBlock]);

  useEffect(() => {
    const fetchData = async () => {
      scrollToTop()
      setIsLoading(true);
      try {
        const route = `user/calls`;
        const method = "GET";
        const payload = {
          limit: 12,
          page,
        };

        const { calls = [], meta } = await makeRequest({
          route,
          method,
          payload,
        });

        const { per_page, total } = meta;
        const result = Math.ceil(total / per_page);
        const arrayRes = Array.from({ length: result }, (_, i) => i + 1);
        setPagesList(arrayRes);

        setData(calls);
        setIsLoading(false);
      } catch (error) {}
    };

    page !== 0 && token && fetchData();
  }, [token, page]);

  const isVideo = useSelector(getUserVideoStatus);

  const onChangeVideochat = async () => {
    try {
      dispatch(setReadyToVideochat(Number(!isVideo)));

      const route = `user/set-call-status`;
      const method = "PUT";
      const payload = {
        status: Number(!isVideo),
      };

      await makeRequest({ route, method, payload });

      showToast({
        message: t("success"),
        variant: "success",
      });
    } catch (error) {
      dispatch(setReadyToVideochat(Number(!isVideo)));
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  useEffect(() => {
    let str = hash;
    str = str.replace("#", "");
    setPage(Number(str) === 0 ? 1 : Number(str));
  }, [hash]);

  return !hasAccess ? AccessTitle : (
    <main>
      <section className={"container"}>
        <div className="fd-row justify-sb">
          <h1>{t("videochats")}</h1>
          {userTypeIndi && windowWidth < 1000 &&
           <Toggle
            id={"readyToVideochat"}
            checked={!!isVideo}
            onChange={onChangeVideochat}
          /> }
        </div>
        <Res isLoading={isLoading} data={data} setData={setData} />
        {pagesList.length > 1 && (
          <Pagination
            pages={pagesList}
            activePage={page}
            onPageChange={handlePage}
          />
        )}
      </section>
    </main>
  );
};

export default VideochatHistoryPage;
