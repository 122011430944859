import {Button, RatingStars, UserBadge} from "@/components/ui";
import {
  setAddReviewStatus,
  setAppealStatus,
  setAuthorisationAccessType,
  setAuthorizationStatus
} from "@/stores/slices/popupSlice";
import useLadyService from "@/services/LadyService";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import "./reviews.scss";
import {AddReview, Appeal} from "../popups";
import SuccessAppealMassage from "../popups/appeal/SuccessAppealMassage";

const Reviews = ({props, id, reviewable_type}) => {
  const {
    slug,
    clazz,
    rating,
    rating_atmosphere,
    rating_charm,
    rating_communication,
    rating_emotions,
    rating_quality,
    name,
    reviews,
    reviews_count,
  } = props;

  const {dispatch, userType} = useLadyService();

  const {t} = useTranslation("translation");

  const [loadMore, setLoadMore] = useState(false);
  return (
    <>
      <section id={"reviews"} className={`reviews${clazz ? ` ${clazz}` : ""}`}>
        <div className="reviews__top">
            <div className={"reviews__buttons"}>
              <Button
                onClick={
                  () => {
                    if (userType === "default") {
                      dispatch(setAuthorizationStatus(true));
                      dispatch(setAuthorisationAccessType("complain"));
                    } else {
                      dispatch(setAppealStatus(true));
                    }
                  }
                }
                title={t("complain")}
                size={"m"}
                clazz={"button_outline--black"}
              >
                {t("complain")}
              </Button>

              {userType === 'indi' || userType === 'agency' ? null : <Button
                title={t("writereview")}
                onClick={
                  () => {
                    if (userType === "default") {
                      dispatch(setAuthorizationStatus(true));
                      dispatch(setAuthorisationAccessType("review"));
                    } else {
                      dispatch(setAddReviewStatus(true));
                    }
                  }}
                clazz={"button--secondary "}
                size={"m"}
              >
                {t("writereview")}
              </Button>}
            </div>

          <h3>
            {t("customerreviews")}
            <span> - {reviews_count ? reviews_count : "0"}</span>
          </h3>
        </div>

        <div className="reviews__stars  color-main mb-36">
          <div className="reviews__total">
            <span>{rating}</span>
            <RatingStars color={'gold'} value={rating} readOnly={true}/>

            <span>
              {t("reviewses")} - {!!reviews_count ? reviews_count : "0"}
            </span>
          </div>

          {!!reviews_count &&
            <div className="reviews__items">
              <div className="reviews__item">
                <RatingStars color={'gold'} value={rating_charm} readOnly={true}/>
                {t("attractiveness")}
              </div>

              <div className="reviews__item">
                <RatingStars color={'gold'} value={rating_quality} readOnly={true}/>
                {t("qualityservices")}
              </div>

              <div className="reviews__item">
                <RatingStars
                  color={'gold'}
                  value={rating_communication}
                  readOnly={true}
                />
                {t("communication")}
              </div>

              <div className="reviews__item">
                <RatingStars
                  color={'gold'}
                  value={rating_emotions}
                  readOnly={true}
                />
                {t("sensibility")}
              </div>

              <div className="reviews__item">
                <RatingStars
                  color={'gold'}
                  value={rating_atmosphere}
                  readOnly={true}
                />
                {t("atmosphere")}
              </div>
            </div>
          }
        </div>

        {reviews && reviews.length > 0 ? (
          <div className="reviews__comments">
            {reviews.slice(0, 3).map((data, index) => (
              <ReviewComment key={index} props={{name, ...data}}/>
            ))}

            {reviews && reviews.length > 3 ? (
              loadMore ? (
                reviews
                  .slice(3)
                  .map((data, index) => <ReviewComment key={index} props={{name, ...data}}/>)
              ) : (
                <Button
                  onClick={() => setLoadMore(true)}
                  title={t("showmore")}
                  clazz={"button--secondary"}
                  size={"m"}
                >
                  {t("showmore")}
                </Button>
              )
            ) : null}
          </div>
        ) : null}
      </section>

      <Appeal name={name} slug={slug}/>

      <SuccessAppealMassage/>

      <AddReview name={name} id={id} reviewable_type={reviewable_type}/>
    </>
  );
};

const ReviewComment = ({props}) => {
  const {from, body, score, created_at, updated_at, answer} = props;

  const { formattedDate } = useLadyService();

  return (
    <div className="reviews__comment color-main">
      <div className="reviews__header">

        <UserBadge
          props={{
            slug: from?.tariff?.data[0]?.slug,
            name: from?.name,
            score
          }}
          rating={false}
        />

        <span className="reviews__day p2 color-600">
          {formattedDate(created_at)}
        </span>

        {score && <RatingStars color={'gold'} value={score} readOnly={true}/>}
      </div>

      <p className={"mb-16 mb-8-mob"}>{props.body}</p>

      {answer && (
        <div className="reviews__answer mb-24">
          <p className="reviews__model-name color-green fz-18 fz-15-mob mb-8">
            {props.name}
          </p>
          <p className="reviews__model-text">{answer.body}</p>
        </div>
      )}
    </div>
  );
};

export default Reviews;
