import React, {Fragment, useEffect, useState} from "react";
import { Icon, ShowMoreText } from "@/components/ui";
import useLadyService from "@/services/LadyService";
import ZeroZone from "@/components/zeroZone/ZeroZone";
import {getSortedServices} from "@/stores/slices/oneProfileSlice";
import {useSelector} from "react-redux";
const ModelServices = ({ props, setOpen , preferences}) => {
  const {
    services,
    for_couples,
    for_men,
    for_women,
    for_groups,
    gender,
    orientation,
    new_prices
  } = props;

  const { gendersTitle, orientations, t, lang} = useLadyService();
  const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');
  const sortedServices = useSelector(getSortedServices)
  const [sortedCategories, setSortedCategories] = useState(sortedServices);
  useEffect(() => {
    const sorted = [...services].sort((a, b) => a.category_id - b.category_id);
    setSortedCategories(sorted);
  }, [services]);

  let gendersText = `${gendersTitle[gender]}${
    orientation ? ` — ${orientations[orientation]}` : ""
  }`;

  const servisesForArr = [
    for_men && t("men") , 
    for_women &&  t("women"), 
    for_couples && t("couples"),
    for_groups && t("groups")].filter(e => e !== 0)

  const Main = () => {
    const PriceTemplate = (clazz, translateKey, price, status) => (
      <span className={`girl-card__price--${clazz} ${status ? '' : 'disabled'}`}>
        <span className={'price-title'}>{t(translateKey)}</span>
        {status ? t('yes') : t('no')}
      </span>
    );
    return (
      <>
        <div className={'services-top-container'}>
          {!!gender ? (
            <h2
              className={
                "title d-flex align-center gap-8"
              }
            >
              <Icon
                clazz={"model__icon"}
                title={gendersText}
                spritePath={`gender-${gender ? gender : "female"}`}
                size={"m"}
              />
              {gendersText}
            </h2>
          ) : null}

          <div id={'dev-pull'} className="girl-card__prices model-page-prices grid-row-2">
            {PriceTemplate("private", "incall", new_prices?.incall?.price, new_prices?.incall?.status)}
            {PriceTemplate("escort", "outcall", new_prices?.outcall?.price, new_prices?.outcall?.status)}
            {PriceTemplate("accompaniment", "accompaniment", new_prices?.escort?.price, new_prices?.escort?.status)}
          </div>
  
          {!!for_couples || !!for_men || !!for_women || !!for_groups ? (
            <h3>
              <span className={"color-700"}>{t("servicesfor")}:</span>{" "}
              {servisesForArr.map((e, index)=>{
                if(index < servisesForArr.length - 1){
                  return <span key={index}>{e + ', '} </span>
                }
                else{
                  return <span key={index}>{e}</span>
                }
              })}
            </h3>
          ) : null}

          
        </div>

        {!!services.length
          ? sortedCategories.map((service, index) => {
              return (
                <Fragment key={index}>
                  <p className={"title_h3 d-block color-main"}>
                    {service.category_name}
                  </p>

                  <ul className="model__serfices-box color-main">
                    {service.services.map((item, index) => {
                      let clazz = item.tag
                        ? { className: `${item.tag ? " _active" : ""}` }
                        : null;

                      let price = String(item?.additional_cost);

                      return (
                        <li {...clazz} key={index}>
                          {item.name}


                            <span className={"color-600"}>
                              {item?.additional_cost ? 
                              <span className={`${lang === 'cz' ? 'currency--czk' : 'currency--euro'}`}>
                                {` +${lang === 'cz' ? Math.floor(item?.additional_cost * 25) : Math.floor(item?.additional_cost)}`}
                              </span> : <></>}
                              {!!item.note && (
                                <>
                                  {" "}
                                  <br /> {item.note}
                                </>
                              )}
                            </span>
                          
                        </li>
                      );
                    })}
                  </ul>
                </Fragment>
              );
            })
          : null}
        {!isJsdom ? (<>{!!preferences && <ShowMoreText>{preferences}</ShowMoreText>}</>) : (<>{preferences}</>)}

      </>
    );
  };

  if (!setOpen && !isJsdom) {
    return <Main />;
  }

  if(isJsdom){
    return (<div><Main /></div>)
  }

  return !!gender ||
    !!for_couples ||
    !!for_men ||
    !!for_women ||
    !!for_groups ||
    !!services?.length ||
    !!preferences?.length  ? (
    isJsdom ? <div><Main /></div> : <Main />
  ) : (
    <ZeroZone
      mainText={t("addad") + " " + t("services").toLowerCase()}
      setOpen={setOpen}
      disc={25}
    />
  );
};
export default ModelServices;
