import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import useLadyService from "@/services/LadyService";
import { Accordion, Icon } from "@/components/ui";
import {Button} from "@/components/ui";

const GuideAside = ({ props }) => {
  const { show, setShow, scrolledClass, setSearchTerm, searchTerm, handleScrollToSearch, showSearch, setShowSearch } = props;

  const { userType, windowWidth, t } = useLadyService();

  let data = {
    indi: [
      {
        first: true,
        title: t("i-maxeffective"),
        links: [
          { link: "maxViews", text: t("i-offermaxviews") },
          { link: "mediaContent", text: t("i-mediacontent") },
          { link: "questionnairePosition", text: t("i-positionpromo") },
          { link: "statusElite", text: `${t("i-markaselite")} Elite` },
          { link: "statuses", text: t("i-statusesobtain") },
          { link: "verify", text: `${t("verification")} ${t("myads")}` },
          { link: "healthControl", text: t("healthy") },
          { link: "specials", text: t("i-otherfeatures") },
        ],
      },
      {
        title: t("i-howearmoney"),
        links: [
          { link: "eroCoins", text: t("i-erocoinandpay") },
          { link: "videochat", text: t("videochat") },
          { link: "onlineOrder", text: t("i-onlineorder") },
          { link: "eroContent", text: t("erocontent") },
        ],
      },
      {
        title: t("i-rulesandinfo"),
        links: [
          { link: "statistic", text: t("i-detailedstat") },
          {
            link: "yourContacts",
            text: `${t("i-yourcontacts")}: Email, WhatsApp, Telegram`,
          },
          { link: "uniqueFeatures", text: t("i-uniquefeatures") },
        ],
      },
    ],
    customer: [
      {
        first: true,
        title: t("g-portaltext"),
        links: [
          { link: "guarantee", text: t("g-guarantee") },
          { link: "sortAndFilter", text: t("g-sortandfilter") },
          { link: "saveFilters", text: t("g-savefilters") },
          { link: "statusElite", text: t("g-searchmodels") },
          { link: "interactions", text: t("g-interactions") },
          { link: "notes", text: t("g-modelsnotes") },
          { link: "feedback", text: t("g-feedback") },
          { link: "complaints", text: t("g-complaints") },
        ],
      },
      {
        title: t("g-uniquefeatures"),
        links: [
          { link: "eroCoins", text: `EroCoins ${t("and")} ${t("i-payonportal")}` },
          { link: "vidWithModel", text: t("videochatswithmodel") },
          { link: "eroContent", text: t("erocontent") },
          { link: "preorders", text: t("g-preorders") },
          { link: "previews", text: t("modelpreviews") },
          { link: "parties", text: t("g-party") },
          { link: "modelStatuses", text: t("g-modelstatuses") },
          { link: "tariffs", text: t("g-usertariffs") },
        ],
      },
      {
        title: t("lk"),
        links: [
          { link: "numbuster", text: t("checknum") },
          { link: "deleteProfile", text: t("deactdelete") },
          { link: "tariffs", text: t("tarifbenefit") },
        ],
      },
    ],
    default: [
      {
        first: true,
        title: t("g-portaltext"),
        links: [
          { link: "guarantee", text: t("g-guarantee") },
          { link: "sortAndFilter", text: t("g-sortandfilter") },
          { link: "saveFilters", text: t("g-savefilters") },
          { link: "statusElite", text: t("g-searchmodels") },
          { link: "interactions", text: t("g-interactions") },
          { link: "notes", text: t("g-modelsnotes") },
          { link: "feedback", text: t("g-feedback") },
          { link: "complaints", text: t("g-complaints") },
        ],
      },
      {
        title: t("g-uniquefeatures"),
        links: [
          { link: "videochat", text: t("videochatswithmodel") },
          { link: "eroContent", text: t("erocontent") },
          { link: "preorders", text: t("g-preorders") },
          { link: "previews", text: t("modelpreviews") },
          { link: "parties", text: t("g-party") },
          { link: "modelStatuses", text: t("g-modelstatuses") },
          { link: "tariffs", text: t("g-usertariffs") },
        ],
      },
    ],
    agency: [
      {
        first: true,
        title: t("featuresagacc"),
        links: [
          { link: "generalset", text: t("generalset") },
          { link: "operatormanageand", text: t("operatormanageand") },
          { link: "sexpartiesorg", text: t("sexpartiesorg") },
          { link: "modelpreview", text: `${t("modelpreviews")}` },
        ],
      },
      {
        first: true,
        title: t("i-maxeffective"),
        links: [
          { link: "uniquefeatures", text: t("uniquefeatures") },
          { link: "linkingmodels", text: t("linkingmodels") },
          { link: "mediaContent", text: t("i-mediacontent") },
          { link: "questionnairePosition", text: `${t("i-positionpromo")}` },
          { link: "statusElite", text: `${t("i-markaselite")} Elite` },
          { link: "statuses", text: t("i-statusesobtain") },
          { link: "verify", text: `${t("verification")} ${t("myads")}` },
          { link: "healthControl", text: t("healthy") },
          { link: "specials", text: t("i-otherfeatures") },
        ],
      },
      {
        title: t("i-howearmoney"),
        links: [
          { link: "eroCoins", text: t("i-erocoinandpay") },
          { link: "videochat", text: t("videochat") },
          { link: "onlineOrder", text: t("i-onlineorder") },
          { link: "modelpreviews", text: t("modelpreviews") },
          { link: "eroContent", text: t("erocontent") },
        ],
      },
      {
        title: t("i-rulesandinfo"),
        links: [
          { link: "statistic", text: t("i-detailedstat") },
          {
            link: "yourContacts",
            text: `${t("i-yourcontacts")}: Email, WhatsApp, Telegram`,
          },
        ],
      },
    ]
  };

  const [dataContent, setDataContent] = useState(data.indi);

  useEffect(() => {
    switch (userType) {
      case "indi":
        return setDataContent(data.indi);

      case "customer":
        return setDataContent(data.customer);
      case "agency":
        return setDataContent(data.agency);

      default:
        return setDataContent(data.default);
    }
  }, [userType, t]);

  return (
      <div>
        <div className={`search ${showSearch ? " _active" : ""}${scrolledClass}`}>
          <label className="search__label">
              <input
                placeholder={t('search').trim().split(/\s+/)[0]}
                value={searchTerm}
                className={`search__input p-0`}
                onChange={(e)=>setSearchTerm?.(e.target.value)}
                type={"search"}
                onKeyPress={(e)=>{if(e.key == "Enter")handleScrollToSearch()}}
              />

              {searchTerm && <Button
                title={t("reset")}
                onClick={() => {setSearchTerm(''); setShowSearch(false)}}
                clazz="search__close"
              >
                <Icon size={"m"} spritePath={"close"}/>
              </Button>}

              <Button
                size={"xs"}
                clazz="search__icon"
                onClick={handleScrollToSearch}
              >
                <Icon size={"m"} spritePath={"search"} />
              </Button>
          </label>
        </div>
  
        <aside className={`p1${show ? " _active" : ""}${scrolledClass}`}>
          {!!show ? (
            <Helmet>
              <html className={"lock"}></html>
            </Helmet>
          ) : null}
        
          {windowWidth > 767.98 && (
            <span>
              <Icon size={"l"} spritePath={"instruction-cont"} />
              {t("i-contents")}
            </span>
          )}
    
          {dataContent.map((i, index) => {
            return (
              <Accordion
                type={"no-border"}
                open={i.first}
                clazz={"p2"}
                title={i.title}
                key={index}
              >
                {i.links.map((i, index) => (
                  <a onClick={() => setShow(false)} href={`#${i.link}`} key={index}>
                    {i.text}
                  </a>
                ))}
              </Accordion>
            );
          })}
        </aside>
      </div>
  );
};

export default GuideAside;
