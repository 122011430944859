import {
  BurgerMenu,
  DropdownLanguage,
  HeaderTabs,
  InstructionLink,
  Logo, MenuNavBar,
  SelectCity,
  SupportButton,
  UserWrapperDesktop
} from "../components";
import Filters from "../components/Filters";
import ShortFilter from "@/components/shortFilter/ShortFilter";
import React from "react";
import useLadyService from "@/services/LadyService";
import { Burger, Toggle } from "@/components/ui";
import { useSelector } from "react-redux";
import { getScrolledClass } from "@/stores/slices/headerSlice";


const UserOrPhotograph = () => {
  const { windowWidth, } = useLadyService();

  const scrolledClass = useSelector(getScrolledClass);

  if (windowWidth > 1199.98)
    return (
      <>
        <div className="header__top">
          <div className="header__tabs">
            <HeaderTabs />
          </div>

          <InstructionLink />

          <SupportButton square={false} size={"xs"} iconSize={"m"} />

          <DropdownLanguage />

          <Toggle type={"theme"} id={'theme-main-toggle'} />
        </div>

        <div className={"header__bottom"}>
          <Logo scrolledClass={scrolledClass} />

          <SelectCity />

          <Filters />

          <UserWrapperDesktop />
        </div>
      </>
    );

  if (windowWidth > 767.98)
    return (
      <div className={"header__bottom"}>
        <Logo scrolledClass={scrolledClass} />

        <SelectCity />

        <Filters />

        <div className="user__wrapper">
          <SupportButton square={false} size={"s"} iconSize={"m"} />

          <InstructionLink size={'s'} />

          <DropdownLanguage size={"s"} />

          <Burger />
          <Toggle type={"theme"} id={'theme-main-toggle'} />
          <BurgerMenu />
        </div>

        <ShortFilter />
      </div>
    );

  return (
    <>
      <div className="header__top">
        <div className="user__wrapper">
          <SupportButton square={true} size={"s"} />

          <Toggle type={"theme"} id={'theme-main-toggle'} />
        </div>


        <Logo scrolledClass={scrolledClass} />

        <div className="user__wrapper">
          <DropdownLanguage />
          <Burger />
        </div>

        <BurgerMenu />

        <MenuNavBar />

        <ShortFilter />
      </div>
    </>
  );
};

export default UserOrPhotograph